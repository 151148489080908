import * as React from 'react';
import { VideoWidgetService } from '@process-street/subgrade/process';
import { Box } from 'components/design/next';
import { match, P } from 'ts-pattern';

export type EmbedProps = {
  service: VideoWidgetService;
  serviceCode?: string;
  fileUrl?: string; // Only exists for S3 videos
};

export const Embed: React.FC<React.PropsWithChildren<{ embed: EmbedProps }>> = ({ embed }) =>
  match(embed)
    .with({ service: VideoWidgetService.S3, fileUrl: P.string }, ({ fileUrl }) => (
      <video
        style={{ objectFit: 'contain', backgroundColor: 'black' }}
        src={fileUrl}
        controls={true}
        className="embed-responsive-item"
      />
    ))
    .with({ service: VideoWidgetService.YouTube, serviceCode: P.string }, ({ serviceCode }) => (
      <Iframe src={`https://www.youtube.com/embed/${serviceCode}?modestbranding=1&showinfo=0`} />
    ))
    .with({ service: VideoWidgetService.Vimeo, serviceCode: P.string }, ({ serviceCode }) => (
      <Iframe src={`https://player.vimeo.com/video/${serviceCode}`} />
    ))
    .with({ service: VideoWidgetService.Loom, serviceCode: P.string }, ({ serviceCode }) => (
      <Iframe src={`https://www.loom.com/embed/${serviceCode}`} />
    ))
    .with({ service: VideoWidgetService.Wistia, serviceCode: P.string }, ({ serviceCode }) => (
      <WistiaEmbed serviceCode={serviceCode} />
    ))
    .otherwise(({ service }) => <Box>Unknown service: {service}</Box>);

const Iframe: React.FC<React.PropsWithChildren<{ src: string }>> = ({ src }) => (
  <iframe
    title="Video Embed"
    width="100%"
    height="100%"
    src={src}
    frameBorder="0"
    allowFullScreen={true}
    sandbox="allow-scripts allow-forms allow-popups allow-same-origin allow-presentation"
  />
);

const WistiaEmbed: React.FC<React.PropsWithChildren<{ serviceCode: string }>> = ({ serviceCode }) => (
  <Box title="video embed" className={`wistia_embed wistia_async_${serviceCode}`} />
);
