import { Muid, OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { QueryKey, useQuery as useRQ, UseQueryOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace GetDeletedByIdQuery {
  export type Params = { organizationMembershipId?: Muid };

  export type Response = OrganizationMembershipWithUser;

  export const key = ['deleted-organization-membership'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = (params: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/organization-memberships/${params.organizationMembershipId}/deleted`)
      .then(res => res.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      enabled: Boolean(params.organizationMembershipId) && options.enabled !== false,
    });
  };
}
