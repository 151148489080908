import * as React from 'react';
import { Box } from 'components/design/next';
import { CrossLinkContentWidgetMachineActorRef } from './cross-link-content-widget-machine';
import { ContentWidgetMachineHooks } from '../content-widget-machine-hooks';
import { useSelector } from '@xstate/react';
import { ChecklistWidgetMachineSelectors } from '../../../utils/widget-machine-selectors';
import { CrossLinkCardDisplay } from 'app/components/widgets/cross-link/display';
import { TemplateTypeProvider } from 'app/utils/template/template-type-context';
import { TemplateType } from '@process-street/subgrade/process';

export type CrossLinkContentWidgetProps = {
  actor: CrossLinkContentWidgetMachineActorRef;
};

export const CrossLinkContentWidget = ({ actor }: CrossLinkContentWidgetProps) => {
  const widget = ContentWidgetMachineHooks.useWidget(actor);
  const isHiddenByRule = useSelector(actor, ChecklistWidgetMachineSelectors.getIsHiddenByRule);

  if (isHiddenByRule || !widget.templateId) return null;

  return (
    <Box w="full">
      <TemplateTypeProvider templateType={TemplateType.Page}>
        <CrossLinkCardDisplay templateId={widget.templateId} />
      </TemplateTypeProvider>
    </Box>
  );
};
