import { useSelector } from '@xstate/react';
import { FormResponseMachineRulesReceptionist } from '../../../form-response-machine-receptionist';
import { RulesEngineMachineActorRef } from './rules-engine-machine';
import { RulesEngineMachineSelectors } from './rules-engine-machine-selectors';

export namespace RulesEngineMachineHooks {
  export const useChecklistState = () => {
    return useSelector(
      FormResponseMachineRulesReceptionist.get<RulesEngineMachineActorRef>('rules-engine-actor'),
      RulesEngineMachineSelectors.getChecklistState,
    );
  };
}
