import { Muid } from '@process-street/subgrade/core';
import { TaskTemplate, Widget } from '@process-street/subgrade/process';
import { makeReceptionist } from 'utils/machines';

type WidgetHiddenByRuleKey = `rules-engine-target-actor:widget-${Widget['header']['group']['id']}`;
type WidgetKey = `widget-actor:${Widget['id']}`;
type TaskHiddenByRuleKey = `rules-engine-target-actor:task-${TaskTemplate['group']['id']}`;

type RulesRecipients = 'rules-engine-actor' | WidgetHiddenByRuleKey | TaskHiddenByRuleKey;

export const FormResponseMachineRulesReceptionist = makeReceptionist<RulesRecipients>();

export const makeRulesEngineTargetWidgetKey = (widget: Widget | Muid): WidgetHiddenByRuleKey => {
  return `rules-engine-target-actor:widget-${typeof widget === 'string' ? widget : widget.header.group.id}`;
};

export const makeRulesEngineTargetTaskKey = (taskTemplate: TaskTemplate | Muid): TaskHiddenByRuleKey => {
  return `rules-engine-target-actor:task-${typeof taskTemplate === 'string' ? taskTemplate : taskTemplate.group.id}`;
};

type WidgetRecipients = WidgetKey;
export const FormResponseMachineWidgetReceptionist = makeReceptionist<WidgetRecipients>();

export const makeWidgetKey = (widget: Widget | Muid): WidgetKey => {
  return `widget-actor:${typeof widget === 'string' ? widget : widget.id}`;
};
