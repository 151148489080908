import { FormControl, FormErrorMessage, VStack } from 'components/design/next';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from '@xstate/react';
import { TableFormFieldActor, TableFormFieldHooks } from './table-form-field-machine';
import { FormResponseLabel } from '../common';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { Box } from '@chakra-ui/react';
import '@ag-grid-community/styles/ag-grid-no-native-widgets.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import 'app/styles/ag-grid/ag-theme-standard.scss';
import { useAgGridColDefs } from 'pages/forms/_id/shared/hooks/use-ag-grid-col-defs';
import { TableFieldValue } from '@process-street/subgrade/process/field-values/table-field-value';
import { GetContextMenuItemsParams, ILargeTextEditorParams, MenuItemDef } from '@ag-grid-community/core';
import { TableFormFieldUtils } from '@process-street/subgrade/process/widget-utils/table-form-field-utils';
import { TemplateType } from '@process-street/subgrade/process';
import { ChecklistWidgetMachineSelectors } from '../../../utils/widget-machine-selectors';
import { useMatch } from '@process-street/adapters/navigation';
import { FormFieldAudit } from 'app/components/widgets/form-field/common/FormFieldAudit';

export interface TableFormFieldProps {
  actor: TableFormFieldActor;
  templateType: TemplateType;
}

const CellEditorProps = {
  cellEditor: 'agLargeTextCellEditor',
  cellEditorPopup: true,
  cellEditorParams: {
    // AG Grid doesn't support autosized cell editors
    // We default to a size that conveys multiline capabilities yet it's not disturbing
    rows: 2,
    cols: 40,
    // Practically unlimited. Default max length for the non multiline text field was 524288, we keep that.
    maxLength: 524288,
  } as ILargeTextEditorParams,
};

export const TableFormField: React.FC<React.PropsWithChildren<TableFormFieldProps>> = ({ actor, templateType }) => {
  const api = TableFormFieldHooks.useApi(actor);
  const widget = TableFormFieldHooks.useWidget(actor);
  const formFieldValue = TableFormFieldHooks.useFormFieldValue(actor);
  const rows = TableFormFieldHooks.useValue(actor);
  const inputNode = TableFormFieldHooks.useInputNode(actor);
  const isDisabled = TableFormFieldHooks.useIsInputDisabled(actor);
  const isInvalid = TableFormFieldHooks.useIsInvalid(actor);
  const errorMessage = TableFormFieldHooks.useValidationErrorMessage(actor);
  const { columnDefs } = widget.config;
  const isChecklistV2 = useMatch('checklistV2');

  const isHiddenByRule = useSelector(actor, ChecklistWidgetMachineSelectors.getIsHiddenByRule);

  const colDefs = useAgGridColDefs<TableFieldValue.Row>(columnDefs, {
    editable: !isDisabled,
    suppressHeaderMenuButton: true,
    flex: 1,
    minWidth: 200,
    ...CellEditorProps,
  });
  const [rowData, setRowData] = useState<TableFieldValue.Row[]>(rows);
  useEffect(() => {
    // We add missing row cells because AG Grid needs them, and they might get added during a migration
    const newRowData = rows.map(row => TableFormFieldUtils.addMissingRowCells(row, columnDefs));
    const newRowDataWithEmptyRow = TableFormFieldUtils.addLastEmptyRow(newRowData, columnDefs);
    setRowData(newRowDataWithEmptyRow);
  }, [rows, columnDefs]);

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      return [
        {
          name: 'Delete Row',
          disabled: rowData.length === 1,
          action: () => {
            const newRows = rowData.filter(row => row.id !== params.node?.data.id);
            const cleanedRows = TableFormFieldUtils.removeLastEmptyRows(newRows);
            api.onChange(cleanedRows);
            // Make sure we reveal any error message this might trigger
            api.onRevealInvalid();
          },
        },
      ];
    },
    [rowData, api],
  );

  const ref = React.useRef<HTMLDivElement | null>(null);

  return isHiddenByRule ? null : (
    <FormControl
      ref={node => {
        ref.current = node;
        if (node && !inputNode) {
          api.onSetNode(node);
        }
      }}
      as={VStack}
      alignItems="stretch"
      isRequired={widget.required}
      isInvalid={isInvalid}
      sx={{
        '&[data-invalid]': {
          '.ag-root-wrapper': {
            borderColor: 'red.500',
            boxShadow: '0 0 0 1px var(--ps-colors-red-500)',
          },
        },
      }}
    >
      <FormResponseLabel>{widget.label || 'Untitled Table'}</FormResponseLabel>
      <Box
        width="full"
        height="300"
        className="ag-theme-alpine ag-theme-standard"
        sx={{
          // Remove padding for AG Grid text editor popup
          '.ag-popup-editor .ag-large-text-input': {
            padding: '0',
          },
          // Make AG Grid text editor popup fully resizable
          '.ag-text-area-input-wrapper textarea': {
            resize: 'both',
          },
        }}
        opacity={isDisabled ? '0.4' : 'inherit'}
      >
        <AgGridReact<TableFieldValue.Row>
          getRowId={params => params.data.id}
          modules={[ClientSideRowModelModule, MenuModule]}
          overlayNoRowsTemplate={'No rows to display.'}
          columnDefs={colDefs}
          getContextMenuItems={getContextMenuItems}
          suppressContextMenu={isDisabled}
          rowData={rowData}
          stopEditingWhenCellsLoseFocus={true}
          suppressMovableColumns={true}
          suppressDragLeaveHidesColumns={true}
          onRowEditingStarted={() => api.onFocus()}
          onRowEditingStopped={() => api.onBlur()}
          onCellValueChanged={e => {
            const rows: TableFieldValue.Row[] = [];
            e.api.forEachNode(node => {
              if (node.data) {
                rows.push(node.data);
              }
            });
            const cleanedRows = TableFormFieldUtils.removeLastEmptyRows(rows);
            api.onChange(cleanedRows);
          }}
        />
      </Box>
      {isChecklistV2 && formFieldValue?.audit && <FormFieldAudit fontSize="xs" mt={1} audit={formFieldValue.audit} />}
      {/* Workflow mode handles error messages outside the component */}
      {templateType === TemplateType.Form && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};
