import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  UseModalProps,
} from '@chakra-ui/react';
import { NativeAutomation } from '@process-street/subgrade/process';
import React from 'react';
import { Button } from 'components/design/next';
import { ThemeProvider2024 } from 'components/design/next/theme-provider-2024';
import { DateFormat, DateUtils } from '@process-street/subgrade/util';

export interface CodeTaskDetailedRunLogsProps extends UseModalProps {
  log?: NativeAutomation.Log;
  taskTemplateName?: string;
}

export const CodeTaskDetailedRunLogs = ({ taskTemplateName, log, ...props }: CodeTaskDetailedRunLogsProps) => {
  if (!log) return null;
  const date = DateUtils.formatDateTime(log.createdAt, DateFormat.DateTimeShortMonthWithComma);
  const prettyName = taskTemplateName?.trim().replace(/^Code Task:$/, 'Code Task');
  const title = `${prettyName} - ${date} run log`;
  return (
    <Modal size="2xl" {...props}>
      <ModalOverlay />
      <ThemeProvider2024>
        <ModalContent>
          <ModalHeader fontSize="18px">{title}</ModalHeader>
          <ModalCloseButton top={3} right={6} />
          <ModalBody>
            <Textarea
              whiteSpace="pre-wrap"
              spellCheck={false}
              fontFamily="monospace"
              value={JSON.stringify(log.logs?.logs, null, 2)}
              // readOnly wouldn't allow the user to select text
              onChange={() => {}}
              h="sm"
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="brand" onClick={props?.onClose} mt={2}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </ThemeProvider2024>
    </Modal>
  );
};
