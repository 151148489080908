import React, { forwardRef } from 'react';
import { Input, InputProps } from '@chakra-ui/react';

/**
 * A drop-in replacement for <Input> that:
 * - When isDisabled is true, the user can still copy text (read-only),
 * - But it looks visually disabled (opacity/cursor).
 * - Retains all of Chakra's default Input styling, theming, etc.
 */
export const SelectableDisabledInput = forwardRef<HTMLInputElement, InputProps>(({ isDisabled, ...rest }, ref) => {
  // If isDisabled => isReadOnly + “disabled” styling
  const computedProps = isDisabled
    ? {
        isReadOnly: true,
        _readOnly: {
          opacity: 0.4,
        },
      }
    : {};

  return <Input ref={ref} {...computedProps} {...rest} />;
});
