import { Muid } from '@process-street/subgrade/core';
import {
  MigrateAllByTemplateIdResponse,
  TemplateMigrationResource,
  TemplateMigrationStats,
} from 'app/resources/template-migration-resource.interface';
import { MigrationService } from 'features/migrations/services/migration-service';
import { queryClient } from 'components/react-root';
import angular from 'angular';
import { DataServiceActions } from 'reducers/data-service/data-service.actions';
import ngRedux from 'ng-redux';

export interface TemplateMigrationApi {
  getMigrationStatsByTemplateRevisionId(templateRevisionId: Muid): angular.IPromise<TemplateMigrationStats>;

  scheduleAllMigrationsByTemplateRevisionId(
    finishedTemplateRevisionId: Muid,
  ): angular.IPromise<MigrateAllByTemplateIdResponse>;
}

export class TemplateMigrationApiImpl implements TemplateMigrationApi {
  public static $inject = ['$ngRedux', 'TemplateMigration'];
  constructor(private $ngRedux: ngRedux.INgRedux, private templateMigrationResource: TemplateMigrationResource) {}

  public getMigrationStatsByTemplateRevisionId(templateRevisionId: Muid): angular.IPromise<TemplateMigrationStats> {
    return this.templateMigrationResource.getMigrationStatsByTemplateRevisionId({ templateRevisionId }).$promise;
  }

  public scheduleAllMigrationsByTemplateRevisionId(
    finishedTemplateRevisionId: Muid,
  ): angular.IPromise<MigrateAllByTemplateIdResponse> {
    return this.templateMigrationResource
      .scheduleAllMigrationsByTemplateRevisionId({ finishedTemplateRevisionId })
      .$promise.then(result => {
        this.$ngRedux.dispatch(DataServiceActions.clearDataStore());
        MigrationService.invalidateChecklistRevisionQueriesPostMigration(queryClient);
        return result;
      });
  }
}
