import * as React from 'react';
import { Box } from 'components/design/next';
import { RichTextEditor } from 'features/rich-text';
import { TextContentWidgetMachineActorRef } from './text-content-widget-machine';
import { useSelector } from '@xstate/react';
import { ChecklistWidgetMachineSelectors } from '../../../utils/widget-machine-selectors';
import { ContentWidgetMachineHooks } from '../content-widget-machine-hooks';
import { useParseChecklistMergeTagsByTarget } from 'app/features/widgets/components/send-rich-email/checklist/use-parse-checklist-merge-tags-by-target';
import { MergeTagMode, MergeTagTarget } from '@process-street/subgrade/form';
import { Muid } from '@process-street/subgrade/core';
import { wrapLinks } from 'app/utils/wrap-links';

export interface TextContentWidgetProps {
  actor: TextContentWidgetMachineActorRef;
  checklistRevisionId: Muid;
  taskId: Muid;
}

export const TextContentWidget: React.FC<React.PropsWithChildren<TextContentWidgetProps>> = ({
  actor,
  checklistRevisionId,
  taskId,
}) => {
  const widget = ContentWidgetMachineHooks.useWidget(actor);
  const isHiddenByRule = useSelector(actor, ChecklistWidgetMachineSelectors.getIsHiddenByRule);

  const parseGeneral = useParseChecklistMergeTagsByTarget({
    checklistRevisionId,
    taskId,
    mergeTagTarget: MergeTagTarget.RICH_CONTENT,
  });

  return isHiddenByRule ? null : (
    <Box sx={textContentStyles} w="full">
      <RichTextEditor
        editorProps={{
          value: wrapLinks(parseGeneral({ content: widget.content ?? '', mode: MergeTagMode.HTML })),
          disabled: true,
        }}
        editorWrapperProps={{ 'aria-label': 'text content container', 'alignSelf': 'flex-start' }}
      />
    </Box>
  );
};

export const textContentStyles = {
  'fontSize': 'md',
  '.style-success': {
    color: 'gray.600',
    bgColor: 'green.200',
    borderWidth: 'thin',
    borderStyle: 'solid',
    borderColor: 'green.300',
    borderRadius: '4px',
    marginTop: '1',
    p: 4,
    w: 'full',
  },
  '.style-info': {
    color: 'gray.600',
    bgColor: 'blue.200',
    borderWidth: 'thin',
    borderStyle: 'solid',
    borderColor: 'blue.300',
    borderRadius: '4px',
    marginTop: '1',
    p: 4,
    w: 'full',
  },
  '.style-warning': {
    color: 'gray.600',
    bgColor: 'yellow.200',
    borderWidth: 'thin',
    borderStyle: 'solid',
    borderColor: 'yellow.300',
    borderRadius: '4px',
    marginTop: '1',
    p: 4,
    w: 'full',
  },
  '.style-danger': {
    color: 'gray.600',
    bgColor: 'red.200',
    borderWidth: 'thin',
    borderStyle: 'solid',
    borderColor: 'red.300',
    borderRadius: '4px',
    marginTop: '1',
    p: 4,
    w: 'full',
  },
  '.style-blockquote': {
    color: 'gray.600',
    borderLeftStyle: 'solid',
    borderLeftWidth: '3px',
    borderLeftColor: 'gray.200',
    p: 4,
  },
  'code': {
    bgColor: 'red.100',
    color: 'red.700',
    p: 1,
  },
};
