import {
  FormFieldWidget,
  SendRichEmailFormFieldWidget,
  TaskTemplate,
  Template,
  TemplateRevision,
} from '@process-street/subgrade/process';
import { UpdateWidgetMutation, WidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import { ActorRefFrom, assign, createMachine, send, sendParent, spawn } from 'xstate';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { FormFieldLabelActor, makeFormFieldLabelMachine } from '../../form-fields/common/form-field-label';
import { match } from 'ts-pattern';
import { WidgetEvent } from '../../../types';
import { SharedContext } from 'pages/forms/_id/shared';
import { GetNewestTemplateRevisionsByTemplateIdQuery } from 'features/template/query-builder';
import { QueryActor, QueryActorSelectors, SystemUpdateEvent } from 'app/utils/query-builder';
import { GetTemplateRevisionQuery } from 'app/features/template-revisions/query-builder';
import { makeDeleteWidgetMutation } from '../../form-fields/common/mutations/make-delete-widget-mutation';
import { makeErrorLoggerAction } from 'app/utils/machines';
import { WidgetMachineHelpers } from '../../../helpers/machine';

type Context = {
  /** The current widget state */
  widget: SendRichEmailFormFieldWidget;
  /** The version we revert to if a save fails. */
  widgetBeforeSave: SendRichEmailFormFieldWidget;
  /**
   * The widget version upon creation or a forced external update.
   * Used for syncing external updates into the widget.
   * */
  initialWidget: SendRichEmailFormFieldWidget;
  template: Template;
  labelActor: FormFieldLabelActor<SendRichEmailFormFieldWidget>;
  recentlyMovedFrom?: TaskTemplate;
  inputNode: HTMLElement | null;
  templateRevision?: TemplateRevision;
  templateRevisionQuery?: QueryActor<GetTemplateRevisionQuery.Response>;
  isReadOnly?: boolean;
};

type Event =
  | WidgetEvent<SendRichEmailFormFieldWidget>
  | { type: 'CHANGE'; widget: SendRichEmailFormFieldWidget }
  | { type: 'UNMOUNT' }
  | { type: 'AUTO_FOCUS' }
  | SystemUpdateEvent<{ id: 'template-revision-query'; data: GetTemplateRevisionQuery.Response }>
  // Internal type
  | { type: 'done.invoke.updateWidgetMutation'; data: SendRichEmailFormFieldWidget };

export type EmailContentMachine = ReturnType<typeof makeEmailContentMachine>;
export type EmailContentActorRef = ActorRefFrom<EmailContentMachine>;

export const makeEmailContentMachine = ({
  widget,
  template,
  sharedContext,
  isReadOnly = false,
}: {
  widget: SendRichEmailFormFieldWidget;
  template: Template;
  sharedContext: SharedContext;
  isReadOnly?: boolean;
}) => {
  const { queryClient, templateId } = sharedContext;

  const cacheSetter = WidgetsByTemplateRevisionIdQuery.makeCacheSetter({
    queryClient,
    templateRevisionId: (widget as FormFieldWidget).templateRevision?.id,
  });

  const templateRevisionCacheSetter = GetNewestTemplateRevisionsByTemplateIdQuery.makeCacheSetter({
    queryClient,
    templateId,
  });

  const id = WidgetMachineHelpers.getId(widget);

  return createMachine(
    {
      id,
      initial: 'idle',
      predictableActionArguments: true,
      schema: {
        events: {} as Event,
        context: {} as Context,
      },
      tsTypes: {} as import('./email-content-machine.typegen').Typegen0,
      context: () =>
        ({
          widget,
          widgetBeforeSave: widget,
          initialWidget: widget,
          template,
          labelActor: spawn(makeFormFieldLabelMachine<SendRichEmailFormFieldWidget>({ widget, queryClient })),
          recentlyMovedFrom: undefined,
          templateRevision: undefined,
          inputNode: null,
          isReadOnly,
        } as Context),
      states: {
        loading: {
          entry: ['spawnTemplateRevisionQuery'],
          on: {
            'xstate.update': {
              cond: 'isDataReady',
              target: 'idle',
              actions: ['assignTemplateRevision'],
            },
          },
        },
        idle: {
          on: {
            DELETE_WIDGET: { target: 'deleting' },
            MOVE_DOWN: { actions: ['sendMoveDown'] },
            MOVE_UP: { actions: ['sendMoveUp'] },
            DUPLICATE: { actions: ['sendDuplicate'] },
            MOVE_TO_STEP: { actions: ['sendMoveToStep'] },
            MOVED_FROM_STEP: { actions: ['assignRecentlyMovedFrom'] },
            UPDATE_WIDGET_HEADER: { actions: ['assignHeader', 'assignInitialWidget'] },
            SET_WIDGET_LABEL: { actions: ['assignLabel'] },
            SET_NODE: { actions: ['assignNode'] },
            SCROLL_INTO_VIEW: { actions: ['scrollIntoView'] },
          },
        },
        editing: {},
        saving: {
          invoke: [
            {
              id: 'updateWidgetMutation',
              src: 'updateWidgetMutation',
              onDone: {
                target: 'idle',
                actions: ['assignWidget', 'assignWidgetBeforeSave', 'sendUpdateDone'],
              },
              onError: {
                target: 'error',
                actions: ['logError', 'resetWidget', 'sendUpdateError'],
              },
            },
          ],
        },
        deleting: {
          invoke: [
            {
              id: 'deleteWidget',
              src: 'deleteWidget',
              onDone: { target: 'deleted' },
              onError: { target: 'idle', actions: 'logError' },
            },
          ],
        },
        deleted: {
          type: 'final',
        },
        error: {},
      },
      on: {
        AUTO_FOCUS: { actions: ['sendAutoFocus'] },
        // forced external update
        UPDATE_WIDGET: { target: 'saving', actions: ['assignInitialWidget', 'assignWidget'] },
        CHANGE: { target: 'saving', actions: ['assignWidget'] },
        // persist changes so that next time the widget loads them as initial values
        UNMOUNT: { actions: ['assignInitialWidget'] },
      },
    },
    {
      actions: {
        logError: makeErrorLoggerAction(id),
        assignWidget: assign({
          widget: (ctx, e) =>
            match(e)
              .with({ type: 'UPDATE_WIDGET' }, ({ widget }) => widget)
              .with({ type: 'done.invoke.updateWidgetMutation' }, ({ data }) => {
                const { templateRevision } = ctx;
                return {
                  ...data,
                  header: {
                    ...data.header,
                    taskTemplate: {
                      ...data.header.taskTemplate,
                      templateRevision,
                    },
                  },
                };
              })
              .with({ type: 'CHANGE' }, ({ widget }) => widget)
              .otherwise(() => ctx.widget),
        }),
        assignWidgetBeforeSave: assign({
          widgetBeforeSave: (_, e) => e.data,
        }),
        assignInitialWidget: assign({
          initialWidget: (ctx, e) =>
            match(e)
              .with({ type: 'UPDATE_WIDGET' }, ({ widget }) => widget)
              .otherwise(() => ctx.widget),
        }),
        resetWidget: assign({
          widget: context => context.widgetBeforeSave,
          initialWidget: context => context.widgetBeforeSave,
        }),
        sendMoveUp: sendParent(ctx => ({
          type: 'MOVE_WIDGET',
          widget: ctx.widget,
          direction: 'up',
        })),
        sendMoveDown: sendParent(ctx => ({
          type: 'MOVE_WIDGET',
          widget: ctx.widget,
          direction: 'down',
        })),
        sendDuplicate: sendParent(ctx => ({
          type: 'DUPLICATE_WIDGET',
          widget: ctx.widget,
        })),
        sendAutoFocus: send({ type: 'AUTO_FOCUS' }, { to: ctx => ctx.labelActor }),
        sendUpdateDone: send(ctx => ({ type: 'UPDATE_DONE', data: ctx.widget }), { to: ctx => ctx.labelActor }),
        sendUpdateError: send({ type: 'UPDATE_ERROR' }, { to: ctx => ctx.labelActor }),
        sendMoveToStep: sendParent((ctx, e) => ({
          type: 'MOVE_WIDGET_TO_STEP',
          widget: ctx.widget,
          from: e.from,
          to: e.to,
        })),
        assignHeader: assign({
          widget: (ctx, e) =>
            match(e)
              .with({ type: 'UPDATE_WIDGET_HEADER' }, ({ header }) => ({
                ...ctx.widget,
                header: {
                  ...ctx.widget.header,
                  ...{ ...(header as SendRichEmailFormFieldWidget['header']) },
                },
              }))
              .otherwise(() => ctx.widget),
        }),
        assignLabel: send((_ctx, e) => ({ type: 'CHANGE', value: e.label }), { to: ctx => ctx.labelActor }),
        assignNode: assign({ inputNode: (_, evt) => evt.node }),
        scrollIntoView: ctx => {
          WidgetMachineHelpers.scrollToWidget(ctx.widget);
        },
        assignRecentlyMovedFrom: assign({
          recentlyMovedFrom: (_ctx, e) =>
            match(e)
              .with({ type: 'MOVED_FROM_STEP' }, ({ from }) => ({
                ...from,
              }))
              .otherwise(() => undefined),
        }),
        assignTemplateRevision: assign({
          templateRevision: ctx => QueryActorSelectors.getQueryData(ctx.templateRevisionQuery),
        }),
      },
      guards: {
        isDataReady: (ctx, _event) => QueryActorSelectors.getQueryData(ctx.templateRevisionQuery) !== undefined,
      },
      services: {
        updateWidgetMutation: async (context, evt) => {
          const widget = match(evt)
            .with({ type: 'UPDATE_WIDGET' }, ({ widget }) => widget)
            .with({ type: 'CHANGE' }, ({ widget }) => widget)
            .otherwise(() => context.widget);
          return makeMutation(queryClient, {
            mutationKey: UpdateWidgetMutation.getKey(),
            mutationFn: () => UpdateWidgetMutation.mutationFn<SendRichEmailFormFieldWidget>(widget),
            onSuccess: widget => {
              cacheSetter.update(widget);
              templateRevisionCacheSetter.updateDraftLastUpdatedDate();
            },
          }).execute();
        },
        deleteWidget: ({ widget }) =>
          makeDeleteWidgetMutation({
            widget,
            queryClient,
            templateId,
          }),
      },
    },
  );
};
