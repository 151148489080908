import * as React from 'react';
import { Box, FormControl, FormErrorMessage, VStack } from 'components/design/next';
import { useActor } from '@xstate/react';
import { SelectFormFieldActor, SelectFormFieldHooks } from './select-form-field-machine';
import { BlvdSelect, BlvdSelectProps } from 'components/design/BlvdSelect';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import _keyBy from 'lodash/keyBy';
import { useSelector } from '@xstate/react';
import { FieldType, isMultiChoiceFormFieldWidget, SelectFormFieldValue } from '@process-street/subgrade/process';
import { FormResponseLabel } from '../common';
import { useGetAllLinkedDataSetRowsQuery } from 'app/pages/reports/data-sets/query-builder';
import { ChecklistSelectFormFieldWidgetHelper } from 'app/pages/runs/_id/components/checklist-select-form-field-widget/helper';
import { ChecklistWidgetMachineSelectors } from '../../../utils/widget-machine-selectors';
import { useMatch } from '@process-street/adapters/navigation';
import { FormFieldAudit } from '../common/form-field-audit';

export interface SelectFormFieldProps {
  actor: SelectFormFieldActor;
}

type OptionType = {
  label: string;
  value: string;
};

export const SelectFormField: React.FC<React.PropsWithChildren<SelectFormFieldProps>> = ({ actor }) => {
  const api = SelectFormFieldHooks.useApi(actor);

  const widget = SelectFormFieldHooks.useWidget(actor);
  const formFieldValue = SelectFormFieldHooks.useFormFieldValue(actor);
  const inputNode = SelectFormFieldHooks.useInputNode(actor);
  const isInvalid = SelectFormFieldHooks.useIsInvalid(actor);
  const isDisabled = SelectFormFieldHooks.useIsInputDisabled(actor);
  const isAutofocused = SelectFormFieldHooks.useIsAutofocused(actor);
  const errorMessage = SelectFormFieldHooks.useValidationErrorMessage(actor);

  const [state, _] = useActor(actor);
  const { linkedDataSetId, linkId, linkedSavedViewId, linkedColumnId } = widget.config;

  const isHiddenByRule = useSelector(actor, ChecklistWidgetMachineSelectors.getIsHiddenByRule);

  const isChecklist = useMatch('checklistV2');

  const isDataSetLinkedDropdown =
    Boolean(linkedDataSetId) && Boolean(linkId) && Boolean(linkedSavedViewId) && Boolean(linkedColumnId);

  const dataSetRowsQuery = useGetAllLinkedDataSetRowsQuery(
    {
      dataSetId: linkedDataSetId!,
      linkId: linkId!,
      savedViewId: linkedSavedViewId!,
    },
    {
      enabled: isDataSetLinkedDropdown,
    },
  );

  const isMulti = isMultiChoiceFormFieldWidget(widget);
  type IsMulti = typeof isMulti;
  const setValue: BlvdSelectProps<OptionType, IsMulti>['onChange'] = value => {
    if (BlvdSelectHelpers.isOptionsType<OptionType>(value)) {
      api.onChange(value.map(option => ({ value: option.value })));
      return;
    }
    if (BlvdSelectHelpers.isOptionType<OptionType>(value) && isDataSetLinkedDropdown) {
      api.onChange([{ value: value.label, dataSetRowId: value.value }]);
      return;
    }
    if (BlvdSelectHelpers.isOptionType<OptionType>(value) && !isDataSetLinkedDropdown) {
      api.onChange([{ value: value.value }]);
      return;
    }
    if (value === null && isDataSetLinkedDropdown) {
      api.onChange([{ value: '' }]);
      return;
    }

    api.onChange([]);
  };

  const [options, optionsMap]: [OptionType[], Record<string, OptionType>] = React.useMemo(() => {
    if (widget.fieldType === FieldType.Select) {
      const fieldValue = formFieldValue as SelectFormFieldValue | undefined;
      if (isDataSetLinkedDropdown) {
        return ChecklistSelectFormFieldWidgetHelper.getLinkedOptions(
          dataSetRowsQuery.data ?? [],
          linkedColumnId!,
          fieldValue,
        );
      } else {
        return ChecklistSelectFormFieldWidgetHelper.getConfigOptions(
          widget.config.items,
          fieldValue?.fieldValue?.value ?? '',
          'value',
        );
      }
    } else {
      // MultiChoice widget
      const opts = widget.config.items
        .filter(item => item.name !== '')
        .map(item => ({
          value: item.id,
          label: item.name,
        }));
      const optsMap = _keyBy(opts, 'value');
      return [opts, optsMap];
    }
  }, [
    widget.fieldType,
    widget.config.items,
    formFieldValue,
    dataSetRowsQuery.data,
    linkedColumnId,
    isDataSetLinkedDropdown,
  ]);

  const selectedOption = React.useMemo(
    () =>
      state.context.value.map(v => optionsMap[isDataSetLinkedDropdown && !!v.dataSetRowId ? v.dataSetRowId : v.value]),
    [optionsMap, isDataSetLinkedDropdown, state.context.value],
  );

  const ref = React.useRef<HTMLDivElement | null>(null);

  return isHiddenByRule ? null : (
    <FormControl
      ref={node => {
        ref.current = node;
        if (node && !inputNode) {
          api.onSetNode(node);
        }
      }}
      as={VStack}
      alignItems="stretch"
      isRequired={widget.required}
      isInvalid={isInvalid}
    >
      <FormResponseLabel htmlFor={`form-field-widget-${widget.id}`}>
        {widget.label || 'Untitled Dropdown'}
      </FormResponseLabel>

      <Box maxW="175" w="full" _hover={{ cursor: isDisabled ? 'text' : 'inherit' }}>
        <BlvdSelect
          inputId={`form-field-widget-${widget.id}`}
          placeholder="Select"
          isMulti={isMulti}
          value={selectedOption}
          options={options}
          onChange={setValue}
          isDisabled={isDisabled}
          autoFocus={isAutofocused}
          onFocus={() => api.onFocus()}
          onBlur={() => api.onBlur()}
          isInvalid={isInvalid}
          isClearable
          styles={{
            control: ({ ...css }) =>
              isDisabled
                ? {
                    ...css,
                    backgroundColor: 'transarent',
                    opacity: 0.4,
                  }
                : { ...css },
          }}
        />

        {isChecklist && formFieldValue && <FormFieldAudit isDisabled={isDisabled} audit={formFieldValue.audit} />}
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      </Box>
    </FormControl>
  );
};
