import { AuditMetadata, Muid, Option, Organization, OrganizationMembership, Ref, User } from '../core';
import { TaskTemplate, TaskTemplateGroup, TaskTemplateTaskType, Template, TemplateRevision } from './template-model';
import { ChecklistWidget, FormFieldValue, FormFieldWidget, WidgetGroup } from './widget-model';

export interface DueDateState {
  readonly id: Muid;
  dueDate?: number;
  target: 'Task' | 'Checklist';
}

export enum TaskStatus {
  NotCompleted = 'NotCompleted',
  Completed = 'Completed',
}

export interface Task {
  readonly id: Muid;
  audit: AuditMetadata;
  completedDate?: number;
  completedBy?: Ref<User>;
  organization: Ref<Organization>;
  taskTemplate: Ref<TaskTemplate>;
  checklistRevision: Ref<ChecklistRevision>;
  status: TaskStatus;
  dueDate?: number;
  dueDateOverridden: boolean;
  hidden: boolean;
  stopped: boolean;
  approvable: boolean;
}

export function isHeading(taskTemplate: TaskTemplate): boolean {
  return taskTemplate?.taskType === TaskTemplateTaskType.Standard && taskTemplate.name?.endsWith(':') === true;
}

export function isApproval(taskTemplate: Option<TaskTemplate>): boolean {
  return taskTemplate?.taskType === TaskTemplateTaskType.Approval;
}

export function isAi(taskTemplate: TaskTemplate): boolean {
  return taskTemplate.taskType === TaskTemplateTaskType.AI;
}

export function isStandardTask(taskTemplate: TaskTemplate): boolean {
  return taskTemplate.taskType === TaskTemplateTaskType.Standard;
}

export const isAutomatedTask = (taskTemplate: TaskTemplate) =>
  taskTemplate.taskType === TaskTemplateTaskType.AI || taskTemplate.taskType === TaskTemplateTaskType.Code;

export type TaskWithTaskTemplate = Task & { taskTemplate: TaskTemplate };

export enum TaskTemplateUpdateResponseStatus {
  Ok = 'Ok',
  Conflict = 'Conflict',
  NotFound = 'NotFound',
  Forbidden = 'Forbidden',
  NothingToUpdate = 'NothingToUpdate',
  NotUpdated = 'NotUpdated',
}

export enum TaskTemplateUpdateResponseCode {
  InvalidDueOffset = 'InvalidDueOffset',
  TaskTemplateNotActive = 'TaskTemplateNotActive',
  TemplateRevisionNotDraft = 'TemplateRevisionNotDraft',
}

export interface TemplateTaskAssignment {
  id: Muid;
  audit: AuditMetadata;
  taskTemplate: Ref<TaskTemplate>;
  organizationMembership: Ref<OrganizationMembership>;
}

export enum BulkResult {
  Ok = 'Ok',
  Forbidden = 'Forbidden',
  NotFound = 'NotFound',
  NothingToUpdate = 'NothingToUpdate',
  PreconditionNotMet = 'PreconditionNotMet',
}

export interface TemplateTaskAssignmentWithOrganizationMembership extends TemplateTaskAssignment {
  organizationMembership: OrganizationMembership;
}

export interface TaskTemplateUpdateResponse {
  response: TaskTemplateUpdateResponseStatus;
  id: Muid;
  taskTemplate?: TaskTemplate;
  taskTemplateAssignment?: TemplateTaskAssignment;
  message?: string;
  code?: TaskTemplateUpdateResponseCode;
}

export interface TaskTemplatesDeleteResponse {
  response: TaskTemplateUpdateResponseStatus;
  id: Muid;
  taskTemplate?: TaskTemplate;
}

export interface TaskTemplateUpdateRequest {
  id: Muid;
  name: string;
  stop: boolean;
  hiddenByDefault: boolean;
}

export interface TaskTemplateUpdateResult {
  taskTemplate: TaskTemplate;
  deletedRulesIds: Muid[];
}

export interface TaskStatusUpdateSuccess {
  checklistStatus: ChecklistStatus;
  task: Task;
  dueDateTaskStates: DueDateState[];
}

export enum ConflictResultType {
  InvalidFormFields = 'InvalidFormFields',
  StopTaskRequired = 'StopTaskRequired',
  TimeBasedRuleRequired = 'TimeBasedRuleRequired',
}

export interface TaskStatusUpdateFailure {
  task: Task;
  message?: string;
  conflictType?: ConflictResultType;
  invalidFormFields: FormFieldWidget[];
}

export enum TaskPermissionRuleSourceType {
  ChecklistInitiator = 'ChecklistInitiator',
  FormField = 'FormField',
}

export interface TaskPermissionRule {
  id: Muid;
  organization: Ref<Organization>;
  templateRevision: Ref<TemplateRevision>;
  targetTaskTemplateGroup: TaskTemplateGroup;
  sourceType: TaskPermissionRuleSourceType;
  sourceFormFieldWidgetGroup?: WidgetGroup;
}

export enum ChecklistStatus {
  Active = 'Active',
  Completed = 'Completed',
  Archived = 'Archived',
  Deleted = 'Deleted',
}

export enum ChecklistMigrationStatus {
  Inactive = 'Inactive',
  Scheduled = 'Scheduled',
  Migrating = 'Migrating',
}

export interface Checklist {
  readonly id: Muid;
  audit: AuditMetadata;
  completedDate?: number;
  completedBy?: Ref<User>;
  archivedDate?: number;
  archivedBy?: Ref<User>;
  organization: Ref<Organization>;
  template: Ref<Template>;
  name?: string;
  status: ChecklistStatus;
  migrationStatus: ChecklistMigrationStatus;
  dueDate?: number;
  timeZone: string;
  shared: boolean;
  free: boolean;
  dueDateOverridden: boolean;
  formFields?: { [key: string]: string };
  hasDefaultName: boolean;
}

export interface ChecklistUpdateDueDateResult {
  dueDateTaskStates: DueDateState[];
  updatedChecklist: Checklist;
}

export enum ChecklistRevisionStatus {
  Active = 'Active',
  Migrating = 'Migrating',
  Migrated = 'Migrated',
}

export interface ChecklistRevision {
  readonly id: Muid;
  audit: AuditMetadata;
  organization: Ref<Organization>;
  checklist: Ref<Checklist>;
  templateRevision: Ref<TemplateRevision>;
  status: ChecklistRevisionStatus;
}

export interface TaskAssignmentRulesStats {
  checklistCreator: number;
  membersField: number;
  emailField: number;
}

export interface ChecklistStats {
  totalTasks: number;
  tasksCompleted?: number;
  comments?: number;
  attachments?: number;
  rulesCount?: number;
  taskRulesCount?: number;
  contentRulesCount?: number;
  dynamicDueDatesCount?: number;
  stopTasksCount?: number;
  taskAssignmentRulesStats: TaskAssignmentRulesStats;
  taskTemplatePermitsCount?: number;
  taskPermissionRulesCount?: number;
  approvalTasksCount?: number;
  checklistId: Muid;
}

export interface ChecklistStatsRequest {
  checklistIds: Muid[];
}

export interface ChecklistSubscription {
  readonly id: Muid;
  audit: AuditMetadata;
  organization: Ref<Organization>;
  checklist: Ref<Checklist>;
  organizationMembership: Ref<OrganizationMembership>;
}

export interface TaskState {
  task: Task;
  taskTemplateGroupId: Muid;
  updated: boolean;
}

export interface ChecklistWidgetState {
  checklistWidget: ChecklistWidget;
  widgetGroupId: Muid;
  updated: boolean;
}

export interface FormFieldValueState {
  formFieldValue: FormFieldValue;
  widgetGroupId: Muid;
}

export interface ChecklistState {
  checklist: Checklist;
  taskStates: TaskState[];
  checklistWidgetStates: ChecklistWidgetState[];
  formFieldValueStates: FormFieldValueState[];
}

export interface ConsolidatedTaskPermit {
  taskId: Muid;
  userId: Muid;
  taskPermissionMap: TaskPermissionMap;
}

export interface TaskPermissionMap {
  canUpdate: boolean;
}

export type Label = {
  id: Muid;
  audit: AuditMetadata;
  organizationId: Muid;
  name: string;
  color: string;
};

export type LabelRelationship = {
  id: Muid;
  audit: AuditMetadata;
  checklistId: Muid;
  labelId: Muid;
};

export enum ChecklistMode {
  Live = 'Live',
  Sandbox = 'Sandbox',
}
