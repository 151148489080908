import { AuditMetadata } from '@process-street/subgrade/core';
import { Comment, Task } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace CreateCommentMutation {
  export type Params = {
    audit: Partial<AuditMetadata>;
    content: Comment['content'];
    taskId: Task['id'];
    method: Comment['method'];
  };
  export type Response = Comment;
  export const key = ['post', 'comment'];
  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .post<Response>(`/1/comments`, params)
      .then(res => res.data);
  export const useMutation = (
    options: UseMutationOptions<CreateCommentMutation.Response, AxiosError, CreateCommentMutation.Params> = {},
  ) => {
    return useRQMutation(CreateCommentMutation.mutationFn, options);
  };
}
