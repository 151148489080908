import * as React from 'react';
import { NativeAutomation } from '@process-street/subgrade/process';
import { FormErrorMessage, Input } from 'components/design/next';
import { useField, useFormikContext } from 'formik';
import { FormControl } from '@chakra-ui/react';
import { CodeTaskUtils } from 'pages/templates/_id/components/code-task-template-editor/code-task-utils';
import { CodeTaskTemplateEditorMode } from 'pages/templates/_id/components/code-task-template-editor/machine/code-task-machine';

export type KeyEditorProps = {
  codeAction?: NativeAutomation.ExecuteCodeAction;
  index: number;
  mode: CodeTaskTemplateEditorMode;
};

export const KeyInput: React.FC<KeyEditorProps> = ({ codeAction, index, mode }) => {
  const { handleSubmit } = useFormikContext();
  const [field, meta, { setValue }] = useField(`mappings[${index}].key`);

  const key = CodeTaskUtils.extractKeyFromJsonPath(field.value);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = async event => {
    if (!codeAction) return;
    const newKey = CodeTaskUtils.convertKeyToJsonPath(codeAction, event.target.value);
    await setValue(newKey);
  };

  const isReadOnly = ['view', 'test'].includes(mode);

  return (
    <FormControl isInvalid={!!meta.error && meta.touched}>
      <Input
        {...field}
        placeholder="Enter key"
        value={key}
        onChange={handleChange}
        onBlur={() => handleSubmit()}
        isDisabled={isReadOnly}
      />
      {meta.error ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
    </FormControl>
  );
};
