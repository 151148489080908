import {} from '@process-street/subgrade/core';
import { NativeAutomation, TemplateRevision } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { QueryClient, useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import { makeMutation as doMakeMutation } from 'utils/query-builder/make-mutation';

export namespace ExecuteCodeMutation {
  export type Params = {
    templateRevisionId: TemplateRevision['id'];
    code: string;
    inputData: object;
  };

  export type Response = NativeAutomation.ExecuteCodeActionLog;

  export const key = ['post', 'automation-tests', 'execute-code'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .post<Response>(`/1/automation-tests/execute-code`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };

  export const makeMutation = ({ queryClient, params }: { queryClient: QueryClient; params: Params }) =>
    doMakeMutation(queryClient, {
      mutationKey: key,
      variables: params,
      mutationFn,
    });
}
