import { WidgetUtils } from '@process-street/subgrade/process';
import { FormFieldMachineHooks } from '../form-field-machine-hooks';
import { EmailFormFieldActor, EmailFormFieldHooks } from './email-form-field-machine';
import { useCallback, useMemo } from 'react';
import { MaskedInputParsers } from 'app/features/widgets/components/masked-input';
import { useSelector } from '@xstate/react';
import { ChecklistWidgetMachineSelectors } from '../../../utils/widget-machine-selectors';
import { MergeTagStringReplacementUtils } from '@process-street/subgrade/merge-tags';
import identity from 'lodash/identity';
import { Option } from 'space-monad';
import { match } from 'ts-pattern';

type UseEmailFormFieldProps = {
  actor: EmailFormFieldActor;
};

export const useEmailFormField = ({ actor }: UseEmailFormFieldProps) => {
  const widget = EmailFormFieldHooks.useWidget(actor);
  const formFieldValue = EmailFormFieldHooks.useFormFieldValue(actor);
  const api = FormFieldMachineHooks.useApi(actor);
  const machineValue = EmailFormFieldHooks.useValue(actor);
  const isHiddenByRule = useSelector(actor, ChecklistWidgetMachineSelectors.getIsHiddenByRule);
  const isInputDisabled = FormFieldMachineHooks.useIsInputDisabled(actor);
  const isAutofocused = FormFieldMachineHooks.useIsAutofocused(actor);
  const errorMessage = FormFieldMachineHooks.useValidationErrorMessage(actor);
  const isInvalid = FormFieldMachineHooks.useIsInvalid(actor);
  const inputNode = FormFieldMachineHooks.useInputNode(actor);

  const widgetDefaultValue = Option<string>(widget.config?.defaultValue)
    .map(defaultValue => MergeTagStringReplacementUtils.replaceUnknownTagsValues(defaultValue))
    .get();

  const handleReset = useCallback(() => {
    if (widgetDefaultValue) {
      api.onChange(WidgetUtils.hasVariables(widgetDefaultValue) ? '' : widgetDefaultValue ?? '', true);
    }
  }, [api, widgetDefaultValue]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => api.onChange(e.target.value), [api]);

  const handleFocus = useCallback(() => api.onFocus(), [api]);

  const handleBlur = useCallback(() => api.onBlur(), [api]);

  const handleSetNode = useCallback((node: HTMLDivElement | null) => api.onSetNode(node), [api]);

  const hasDefaultValue = WidgetUtils.hasDefaultValue({ formFieldValue, widget });
  const hasVariables = WidgetUtils.hasVariables(widget.config.defaultValue);
  const maskedInputParser = useMemo(
    () => (hasDefaultValue && hasVariables ? MaskedInputParsers.makeParser('urls', 'merge-tags') : identity),
    [hasDefaultValue, hasVariables],
  );
  const value = useMemo(() => {
    if (
      !machineValue &&
      widget.config.defaultValue &&
      (formFieldValue === undefined || formFieldValue?.fieldValue.hasDefaultValue)
    ) {
      return MergeTagStringReplacementUtils.replaceUnknownTagsValues(widget.config.defaultValue);
    }

    return machineValue;
  }, [formFieldValue, machineValue, widget.config.defaultValue]);
  const html = useMemo(() => maskedInputParser(String(value)), [maskedInputParser, value]);

  const bgColor = useMemo(
    () =>
      match({ hasDefaultValue })
        .with({ hasDefaultValue: true }, () => 'yellow.100')
        .otherwise(() => undefined),
    [hasDefaultValue],
  );

  return useMemo(
    () => ({
      bgColor,
      errorMessage,
      formFieldValue,
      html,
      inputNode,
      isAutofocused,
      isHiddenByRule,
      isInputDisabled,
      isInvalid,
      value,
      widget,
      onBlur: handleBlur,
      onChange: handleChange,
      onFocus: handleFocus,
      onReset: handleReset,
      onSetNode: handleSetNode,
    }),
    [
      bgColor,
      errorMessage,
      formFieldValue,
      html,
      inputNode,
      isAutofocused,
      isHiddenByRule,
      isInputDisabled,
      isInvalid,
      value,
      widget,
      handleBlur,
      handleChange,
      handleFocus,
      handleReset,
      handleSetNode,
    ],
  );
};
