import { CrossLinkWidget } from '@process-street/subgrade/process';
import { ActorRefFrom, createMachine, spawn, StateFrom } from 'xstate';
import { makeRulesEngineTargetMachine } from '../../form-response-body/rules-engine-machine';

type Context = {
  widget: CrossLinkWidget;
  rulesEngineTargetActor: ActorRefFrom<typeof makeRulesEngineTargetMachine>;
};

type MakeCrossLinkContentWidgetMachineArgs = {
  widget: CrossLinkWidget;
};

export const makeCrossLinkContentWidgetMachine = ({ widget }: MakeCrossLinkContentWidgetMachineArgs) => {
  return createMachine({
    id: `cross-link-widget-machine:${widget.id}`,
    predictableActionArguments: true,
    context: () => ({
      widget,
      rulesEngineTargetActor: spawn(
        makeRulesEngineTargetMachine({ type: 'widget', widgetHeaderGroupId: widget.header.group.id }),
        { name: 'hidden-by-rule-actor', sync: true },
      ),
    }),
    tsTypes: {} as import('./cross-link-content-widget-machine.typegen').Typegen0,
    schema: {
      context: {} as Context,
    },
  });
};

export type CrossLinkContentWidgetMachine = ReturnType<typeof makeCrossLinkContentWidgetMachine>;
export type CrossLinkContentWidgetMachineActorRef = ActorRefFrom<CrossLinkContentWidgetMachine>;

export type CrossLinkContentWidgetMachineState = StateFrom<CrossLinkContentWidgetMachine>;

const Selectors = {
  getWidget: (state: CrossLinkContentWidgetMachineState) => state.context.widget,
};
export { Selectors as CrossLinkContentWidgetMachineSelectors };
