import * as React from 'react';
import { Button, ButtonProps } from 'components/design/next';

export type TaskNameButtonProps = {
  taskName: string;
} & Partial<ButtonProps>;

export const TaskNameButton = ({ taskName, ...props }: TaskNameButtonProps) => {
  return (
    <Button variant="unstyled" fontSize="md" color="brand.500" {...props}>
      {taskName}
    </Button>
  );
};
