import { TableFormFieldValue, TableFormFieldWidget } from '@process-street/subgrade/process';
import { UpdateFormFieldValueMutationResponse } from 'features/widgets/query-builder';
import { FormFieldMachineBuilderProps, WithFormFieldMachineEvent } from 'pages/responses/_id/types';
import {
  ActionObject,
  actions,
  ActorRefFrom,
  assign,
  createMachine,
  forwardTo,
  sendParent,
  spawn,
  StateFrom,
} from 'xstate';
import {
  makeValidationMachine,
  ValidationActorRef,
  ValidationParentEvent,
  ValidationSelectors,
} from '../validation-machine';
import {
  makeRulesEngineTargetMachine,
  sendRulesActorFormFieldValueUpdate,
} from '../../form-response-body/rules-engine-machine';
import { makeUpdateFormFieldValueMutation } from '../make-update-form-field-value-mutation';
import { FormResponseErrorToasts } from 'pages/responses/_id/utils/form-response-error-toasts';
import { AxiosError } from 'axios';
import { TableFieldValue } from '@process-street/subgrade/process/field-values/table-field-value';
import { makeTableValidationSchema } from 'pages/forms/_id/edit/components/form-fields/table-form-field/table-form-field-schema';
import { makeErrorLoggerAction } from 'app/utils/machines';
import { useSelector } from '@xstate/react';
import { useCallback, useMemo } from 'react';
import { identitySelector } from '../form-field-machine-hooks';

const { send, cancel } = actions;

export type Context = {
  value: TableFieldValue.Row[];
  widget: TableFormFieldWidget;
  formFieldValue: TableFormFieldValue | undefined;
  validationActor: ValidationActorRef<TableFieldValue.Row[] | undefined>;
  rulesEngineTargetActor: ActorRefFrom<typeof makeRulesEngineTargetMachine>;
  inputNode: HTMLElement | null;
};

export type Event = WithFormFieldMachineEvent<
  ValidationParentEvent<TableFieldValue.Row[]>,
  TableFieldValue.Row[],
  TableFormFieldValue | undefined
>;

export const makeTableFormFieldMachine = ({
  checklistRevisionId,
  formFieldWidget,
  formFieldValue,
  sharedContext,
  isEditable,
  inputNode,
  // This is used by workflow runs to hook into their lifecycle
  update,
}: FormFieldMachineBuilderProps<TableFormFieldWidget>) => {
  const rows = formFieldValue?.fieldValue.rows || [];

  const validationSchema = makeTableValidationSchema({
    required: formFieldWidget.required,
  });

  const initialState = isEditable ? 'enabled' : 'disabled';

  const id = `table-form-field-machine:${formFieldWidget.id}`;

  return createMachine(
    {
      id,
      context: () => ({
        inputNode,
        value: rows,
        widget: formFieldWidget,
        formFieldValue,
        validationActor: spawn(
          makeValidationMachine({ validationSchema, initialValue: rows, isEmpty: value => value?.length === 0 }),
          {
            name: 'validation-actor',
          },
        ),
        rulesEngineTargetActor: spawn(
          makeRulesEngineTargetMachine({ type: 'widget', widgetHeaderGroupId: formFieldWidget.header.group.id }),
          { name: 'hidden-by-rule-actor', sync: true },
        ),
      }),
      tsTypes: {} as import('./table-form-field-machine.typegen').Typegen0,
      schema: {
        context: {} as Context,
        events: {} as Event,

        services: {} as {
          updateFormFieldValue: {
            data: UpdateFormFieldValueMutationResponse;
          };
        },
      },
      predictableActionArguments: true,
      preserveActionOrder: true,
      type: 'parallel',
      on: {
        SET_NODE: { actions: ['assignNode'] },
        SCROLL_INTO_VIEW: { actions: ['scrollIntoView'] },
        SYNC_FORM_FIELD_VALUE: { actions: ['syncFormFieldValue'] },
      },
      states: {
        input: {
          initial: initialState,
          states: {
            disabled: {
              on: {
                ENABLE: {
                  target: 'enabled',
                },
              },
            },
            enabled: {
              on: { DISABLE: 'disabled' },
              initial: 'idle',
              states: {
                idle: {
                  on: {
                    CHANGE: { actions: ['updateValue', 'sendRulesActorFormFieldValueUpdate'] },
                    SYNC: { actions: ['updateValue'] },
                  },
                },
              },
            },
          },
        },

        mutation: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                UPDATE_VALUE: { target: 'debouncing', actions: ['sendDebouncedUpdateValue'] },
              },
            },
            debouncing: {
              on: {
                UPDATE_VALUE: {
                  actions: ['cancelDebouncedUpdateValue', 'sendDebouncedUpdateValue'],
                },
                DEBOUNCED_UPDATE_VALUE: 'updating',
              },
            },
            updating: {
              invoke: {
                src: 'updateFormFieldValue',
                onDone: {
                  target: 'idle',
                  actions: ['sendParentFormFieldValueUpdate', 'assignFormFieldValue'],
                },
                onError: {
                  target: 'idle',
                  actions: ['logError', 'showErrorToast'],
                },
              },
            },
          },
        },

        // This state is a kind of controller to forward events up and down
        // Since it is a parallel state, in can listen for events without blocking or getting blocked by nested states
        validation: {
          initial: 'enabled',
          states: {
            enabled: {
              on: {
                CHANGE: { actions: 'forwardToValidation' },
                REVEAL_INVALID: { actions: 'forwardToValidation' },
                BLUR: { actions: 'forwardToValidation' },
                VALID: { actions: 'sendParentValid' },
                INVALID: { actions: 'sendParentInvalid' },
                HIDE: { actions: 'sendParentValid', target: 'disabled' },
              },
            },
            disabled: {
              on: {
                REVEAL: { target: 'enabled', actions: 'restoreValidationWithParent' },
              },
            },
          },
        },
      },
    },
    {
      services: {
        updateFormFieldValue: async (_, event) => {
          return (
            update?.({ rows: event.value }) ??
            (await makeUpdateFormFieldValueMutation({
              queryClient: sharedContext.queryClient,
              body: { checklistRevisionId, widgetId: formFieldWidget.id, rows: event.value },
            }).execute())
          );
        },
      },
      actions: {
        assignNode: assign({ inputNode: (_, event) => event.node }),
        updateValue: assign({
          value: (_, event) => event.value,
        }),
        syncFormFieldValue: assign({
          value: (ctx, event) => event.value?.fieldValue.rows ?? ctx.value,
          formFieldValue: (_, event) => event.value,
        }),
        showErrorToast: (_, event) => FormResponseErrorToasts.showFormFieldUpdateErrorToast(event.data as AxiosError),
        sendDebouncedUpdateValue: send(
          (_, event) => ({
            type: 'DEBOUNCED_UPDATE_VALUE',
            value: event.value,
          }),
          {
            delay: 500,
            id: 'debounced-update-value',
          },
        ),
        cancelDebouncedUpdateValue: cancel('debounced-update-value'),
        sendParentInvalid: sendParent({ type: 'INVALID_WIDGET', widgetId: formFieldWidget.id }),
        sendParentValid: sendParent({ type: 'VALID_WIDGET', widgetId: formFieldWidget.id }),
        restoreValidationWithParent: sendParent((ctx, _evt) => {
          if (ctx.validationActor.getSnapshot()?.matches('valid')) {
            return { type: 'VALID_WIDGET', widgetId: formFieldWidget.id };
          }
          return { type: 'INVALID_WIDGET', widgetId: formFieldWidget.id };
        }),
        forwardToValidation: forwardTo(ctx => ctx.validationActor) as ActionObject<Context, Event>,

        sendRulesActorFormFieldValueUpdate: (_ctx, event) =>
          sendRulesActorFormFieldValueUpdate({
            formFieldValue: { ...formFieldValue!, fieldValue: { rows: event.value } },
            formFieldWidget,
          }),
        scrollIntoView: ctx => {
          ctx.inputNode?.scrollIntoView();
        },
        sendParentFormFieldValueUpdate: sendParent((_ctx, evt) => ({
          type: 'FORM_FIELD_VAUE_UPDATE',
          formFieldValue: evt.data.formFieldValue,
        })),
        assignFormFieldValue: assign({
          formFieldValue: (ctx, evt) => ({
            ...ctx.formFieldValue,
            ...(evt.data.formFieldValue as TableFormFieldValue),
          }),
        }),
        logError: makeErrorLoggerAction(id),
      },
    },
  );
};

export type TableFormFieldMachine = ReturnType<typeof makeTableFormFieldMachine>;
export type TableFormFieldState = StateFrom<TableFormFieldMachine>;
export type TableFormFieldActor = ActorRefFrom<TableFormFieldMachine>;

export namespace TableFormFieldSelectors {
  export function getIsInputDisabled(state: TableFormFieldState) {
    return state.matches('input.disabled');
  }

  export function getValidationActor(state: TableFormFieldState) {
    return state.context.validationActor;
  }

  export const getInputNode = (state: TableFormFieldState) => state.context.inputNode;

  export const getIsInvallid = (state: TableFormFieldState) => state.context.inputNode;

  export const getFormFieldValue = (state: TableFormFieldState) => state.context.formFieldValue;

  export const getValue = (state: TableFormFieldState) => state.context.value;

  export const getWidget = (state: TableFormFieldState) => state.context.widget;
}

export namespace TableFormFieldHooks {
  export function useValidationActor(actorRef: TableFormFieldActor) {
    return useSelector(actorRef, TableFormFieldSelectors.getValidationActor);
  }

  export function useIsInvalid(actorRef: TableFormFieldActor) {
    const validationActor = useValidationActor(actorRef);
    return ValidationSelectors.isActorInvalidVisible(validationActor);
  }

  export const useInputNode = (actorRef: TableFormFieldActor) => {
    return useSelector(actorRef, TableFormFieldSelectors.getInputNode);
  };

  export function useIsInputDisabled(actorRef: TableFormFieldActor) {
    return useSelector(actorRef, TableFormFieldSelectors.getIsInputDisabled);
  }

  const validationMachineSelector = (state: TableFormFieldState) => state.context.validationActor;
  export function useValidationSnapshot(parentRef: TableFormFieldActor) {
    const validationActor = useSelector(parentRef, validationMachineSelector) as ValidationActorRef<any>;
    const validationSnapshot = useSelector(validationActor, identitySelector);
    return validationSnapshot;
  }

  export function useValidationErrorMessage(parentRef: TableFormFieldActor) {
    const validationSnapshot = useValidationSnapshot(parentRef);
    return validationSnapshot.context.errorMessage;
  }

  export const useFormFieldValue = (actorRef: TableFormFieldActor) => {
    return useSelector(actorRef, TableFormFieldSelectors.getFormFieldValue);
  };

  export const useValue = (actorRef: TableFormFieldActor) => {
    return useSelector(actorRef, TableFormFieldSelectors.getValue);
  };

  export const useWidget = (actorRef: TableFormFieldActor) => {
    return useSelector(actorRef, TableFormFieldSelectors.getWidget);
  };

  export function useApi(actorRef: TableFormFieldActor) {
    const onChange = useCallback(
      (value: TableFieldValue.Row[]) => {
        actorRef.send({ type: 'CHANGE', value });
      },
      [actorRef],
    );

    const onBlur = useCallback(() => {
      actorRef.send({ type: 'BLUR' });
    }, [actorRef]);

    const onFocus = useCallback(() => {
      actorRef.send({ type: 'FOCUS' });
    }, [actorRef]);

    const onRevealInvalid = useCallback(() => {
      actorRef.send({ type: 'REVEAL_INVALID' });
    }, [actorRef]);

    const onSetNode = useCallback(
      (node: HTMLDivElement | null) => {
        actorRef.send({ type: 'SET_NODE', node });
      },
      [actorRef],
    );

    return useMemo(
      () => ({ onChange, onBlur, onFocus, onRevealInvalid, onSetNode }),
      [onChange, onBlur, onFocus, onRevealInvalid, onSetNode],
    );
  }
}
