import { FormFieldWidget, NativeAutomationType, TaskTemplate } from '@process-street/subgrade/process';
import { useInputWidgetAutomatedTasksMap } from 'features/native-automations/hooks/use-input-widget-automated-tasks-map';
import { useOutputWidgetAutomatedTasksMap } from 'features/native-automations/hooks/use-output-widget-automated-tasks-map';
import { useTaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';

interface Result {
  inputsTo: TaskTemplate[];
  outputsFrom: TaskTemplate[];
}

/**
 * Returns automated (AI/Code) task templates for which a widget is input or output connected.
 * @param automationType if provided, filters by automation type
 */
export function useAssociatedAutomatedTaskTemplates({
  widget,
  automationType,
}: {
  widget: FormFieldWidget;
  automationType?: NativeAutomationType;
}): Result {
  // Race condition with optimistic widgets and tmpl rev id
  const templateRevisionId = widget.templateRevision?.id;
  const outputFromAutomatedTasks = useOutputWidgetAutomatedTasksMap({ templateRevisionId })[widget.id] ?? [];
  const outputTaskTemplateIds = outputFromAutomatedTasks
    .filter(aiTaskTemplate =>
      automationType ? aiTaskTemplate.nativeAutomation.automationType === automationType : true,
    )
    .map(aiTaskTemplate => aiTaskTemplate.taskTemplateId);

  const inputWidgetAutomatedTasksMap = useInputWidgetAutomatedTasksMap({ templateRevisionId });
  const inputToAutomatedTasks = inputWidgetAutomatedTasksMap[widget.id] ?? [];
  const inputTaskTemplateIds = inputToAutomatedTasks
    .filter(aiTaskTemplate =>
      automationType ? aiTaskTemplate.nativeAutomation.automationType === automationType : true,
    )
    .map(aiTaskTemplate => aiTaskTemplate.taskTemplateId);

  const taskTemplateQuery = useTaskTemplatesByTemplateRevisionIdQuery(
    { templateRevisionId },
    {
      enabled: inputTaskTemplateIds.length > 0 || outputTaskTemplateIds.length > 0,
      select: taskTemplates =>
        taskTemplates.reduce(
          (acc, taskTemplate) => {
            if (inputTaskTemplateIds.includes(taskTemplate.id)) {
              acc.inputsTo.push(taskTemplate);
            }
            if (outputTaskTemplateIds.includes(taskTemplate.id)) {
              acc.outputsFrom.push(taskTemplate);
            }
            return acc;
          },
          { inputsTo: [], outputsFrom: [] } as Result,
        ),
    },
  );
  return taskTemplateQuery.data ?? { inputsTo: [], outputsFrom: [] };
}
