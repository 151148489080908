import * as React from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  Hide,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Show,
  Tooltip,
  useBreakpointValue,
  useDisclosure,
} from 'components/design/next';

import {
  TemplateMenu,
  TemplateMenuContext,
  TemplateMenuItem,
  TemplateMenuDisclosureContext,
} from 'app/features/template/components/template-menu/template-menu';

import { useDraftStatus } from '../../draft-status-indicator/use-draft-status';
import { DraftStatusIndicator } from '../../draft-status-indicator';
import { useGetTemplateQuery } from 'features/template/query-builder';
import {
  DiscardTemplateAlert,
  DiscardTemplateButton,
} from 'features/template/components/template-menu/discard-template-button';
import { noop, queryString } from '@process-street/subgrade/util';
import { useFeatureFlags } from 'features/feature-flags';
import { FocusBarIconButton } from './focus-bar-icon-button';
import { ConditionalLogicButtonWrapper } from 'pages/templates/_id/components/conditional-logic-button-wrapper';
import { useTemplateSettingsModalContext } from 'pages/templates/_id/components/template-settings-modal/template-settings-modal-context';
import { PublishDraftMutation, useTemplateRevisionQuery } from 'features/template-revisions/query-builder';
import { ChecklistMigrationModal } from '../../migration-modal';
import { useGetChecklistRevisionsByTemplateIdQuery } from 'features/checklist-revisions/query-builder';
import { useDisplayWorkflowSetupModal } from 'components/focus-bar/workflow/right-button-group/edit/use-display-workflow-setup-modal';
import { usePublishAndRun } from 'pages/templates/_id/components/ai-generated-workflow-settings-modal/use-publish-and-run';
import { AiGeneratedWorkflowSettingsModalHelpers } from 'pages/templates/_id/components/ai-generated-workflow-settings-modal/helpers';
import { RunChecklist } from 'app/components/run-checklist/components/RunChecklist/run-checklist';
import { CustomNotificationsModal } from 'pages/templates/_id/components/custom-notifications-modal';
import { SandboxButton } from 'features/template/components/sandbox-button';
import { useCreateSandboxAndRedirect } from 'hooks/use-create-sandbox-and-redirect';
import { useMatch, useNavigate } from '@process-street/adapters/navigation';
import { useAutomationSelector } from 'app/pages/templates/_id/automation/components/selector/context';
import { SolutionTypeTag } from '@process-street/subgrade/automation';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { useTemplateMenuDisclosureContextValue } from 'features/template/components/template-menu/hooks/use-template-menu-disclosure-context-value';

const buttonStyles = { bgColor: 'gray.200', color: 'gray.700', textDecor: 'none' };

export const EditButtonGroupV2: React.FC<React.PropsWithChildren<ButtonGroupProps>> = props => {
  const templateMenuDisclosure = useDisclosure();
  const migrationDisclosure = useDisclosure();
  const runChecklistDisclosure = useDisclosure();
  const customNotificationsDisclosure = useDisclosure();
  const isEditorV2 = useMatch('templateV2');
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { openSetup, templateId, templateRevisionId } = useTemplateSettingsModalContext();
  if (!templateRevisionId) {
    throw new Error('Expecting template revision ID for this component.');
  }
  const [shouldPublishAndRun, setShouldPublishAndRun] = React.useState(false);

  const draftStatus = useDraftStatus();

  const templateQuery = useGetTemplateQuery({ templateId });
  const template = templateQuery.data;
  const ffs = useFeatureFlags('publishAndRun', 'customNotifications', 'sandboxMode');

  const newestTemplateRevisionQuery = useTemplateRevisionQuery({ templateRevisionId });

  useDisplayWorkflowSetupModal();

  const publishDraftMutation = PublishDraftMutation.useMutation();
  const canPublish = !!template && draftStatus === 'saved' && publishDraftMutation.isIdle;

  const navigate = useNavigate();
  const checklistRevisionsQuery = useGetChecklistRevisionsByTemplateIdQuery({ templateId });
  const workflowRunCount = checklistRevisionsQuery.data?.length ?? 0;

  const publishAndRunMutation = usePublishAndRun({
    templateId,
    templateRevisionId,
    templateName: template?.name ?? '',
  });

  const publish = async () => {
    return checklistRevisionsQuery.refetch().then(res => {
      if (res.data?.length === 0) {
        return publishDraftMutation.mutateAsync({ tmplRevId: templateRevisionId }).then(async () => {
          navigate({
            pathname: 'templateDashboard',
            search: queryString.stringify({ id: templateId }),
          });
        });
      }

      setShouldPublishAndRun(false);
      migrationDisclosure.onOpen();

      return;
    });
  };

  const handlePublishAndRun = async () => {
    void checklistRevisionsQuery.refetch().then(async res => {
      if (res.data?.length === 0) {
        // Show the WFR setup modal in case the defaultChecklistName is not set
        // to allow the user to choose a name for the WF
        if (newestTemplateRevisionQuery.data?.defaultChecklistName) {
          return publishAndRunMutation.mutate({});
        } else {
          runChecklistDisclosure.onOpen();
          return;
        }
      }

      setShouldPublishAndRun(true);
      migrationDisclosure.onOpen();
    });
  };

  const runChecklistAndRedirect = useCreateSandboxAndRedirect({
    templateName: template?.name,
    templateId: template?.id,
    openInNewTab: true,
  });

  const [_, send] = useAutomationSelector();

  const openAutomationsModal = () => {
    send({
      type: 'SOLUTION_TYPE_SELECTED',
      payload: { solutionTypeTag: SolutionTypeTag.WhenTaskCheckedThen, modalView: 'all' },
    });
  };

  const templateMenuDisclosureContextValue = useTemplateMenuDisclosureContextValue({ setCloseOnBlur: () => {} });

  const shouldShowPublishAndPublishAndRun = !isEditorV2 || (isEditorV2 && isMobile);

  return (
    <ButtonGroup spacing="2" alignItems="center" {...props}>
      <HStack color="white">
        <DraftStatusIndicator />
        <Box as="span" display="flex">
          <TemplateMenuContext.Provider value={{ templateId, view: 'show', closeOnBlur: false, setCloseOnBlur: noop }}>
            <TemplateMenuDisclosureContext.Provider value={templateMenuDisclosureContextValue}>
              <Tooltip label="Discard">
                <DiscardTemplateButton>
                  <IconButton
                    aria-label="discard"
                    minW={6}
                    height={6}
                    icon={<Icon icon="trash" variant="far" size="4" />}
                    variant="link"
                    color="white"
                    _active={buttonStyles}
                    _hover={buttonStyles}
                    _focus={buttonStyles}
                  />
                </DiscardTemplateButton>
              </Tooltip>
              <DiscardTemplateAlert />
            </TemplateMenuDisclosureContext.Provider>
          </TemplateMenuContext.Provider>
        </Box>
      </HStack>

      <TemplateMenu
        autoSelect={false}
        mode="edit"
        {...templateMenuDisclosure}
        placement="bottom-start"
        menuButton={
          <MenuButton
            as={FocusBarIconButton}
            aria-label="actions"
            icon={<Icon size="4" variant="far" icon="ellipsis-h" color="white" />}
          />
        }
      >
        {shouldShowPublishAndPublishAndRun && (
          <>
            <TemplateMenuItem
              fontSize="md"
              onClick={publish}
              isDisabled={!canPublish}
              color="gray.600"
              aria-label="publish changes"
              icon={<Icon icon="file-arrow-up" size="4" color="gray.500" />}
            >
              Publish
            </TemplateMenuItem>

            {ffs.publishAndRun && checklistRevisionsQuery.isSuccess && (
              <TemplateMenuItem
                onClick={handlePublishAndRun}
                isDisabled={!canPublish || publishAndRunMutation.isLoading}
                color="gray.600"
                aria-label="publish changes and run workflow"
                icon={<Icon icon="play" color="gray.500" size="4" />}
                closeOnSelect
              >
                Publish and Run
              </TemplateMenuItem>
            )}
          </>
        )}

        {template?.id && (
          <Show breakpoint="(max-width: 1100px)">
            <TemplateMenuItem
              onClick={() =>
                navigate({
                  pathname: 'templateView',
                  search: queryString.stringify({
                    id: template?.id,
                  }),
                })
              }
              icon={<Icon icon="eye" size="4" color="gray.500" />}
            >
              View
            </TemplateMenuItem>
          </Show>
        )}

        {shouldShowPublishAndPublishAndRun && <MenuDivider />}

        {ffs.sandboxMode && template && (
          <TemplateMenuItem
            aria-label="sandbox"
            onClick={runChecklistAndRedirect}
            color="gray.600"
            icon={<Icon icon="flask" color="gray.500" size="4" />}
            isDisabled={!canPublish}
            mb={0}
          >
            Preview
          </TemplateMenuItem>
        )}

        <Hide breakpoint="(min-width: 850px)">
          {newestTemplateRevisionQuery.data && (
            <ConditionalLogicButtonWrapper templateRevisionId={newestTemplateRevisionQuery.data.id} isMenuItem>
              <TemplateMenuItem icon={<Icon icon="shuffle" color="gray.500" size="4" />} closeOnSelect>
                Conditional logic
              </TemplateMenuItem>
            </ConditionalLogicButtonWrapper>
          )}

          <TemplateMenuItem
            icon={<Icon icon="bolt" variant="far" size="4" color="gray.500" />}
            onClick={openAutomationsModal}
            closeOnSelect
          >
            Automations
          </TemplateMenuItem>
        </Hide>

        <Hide breakpoint="(min-width: 1300px)">
          <TemplateMenuItem
            aria-label="Workflow Settings"
            icon={<Icon icon="gear" variant="far" size="4" color="gray.500" />}
            onClick={openSetup}
            closeOnSelect
          >
            Workflow settings
          </TemplateMenuItem>
        </Hide>
        <MenuDivider />
      </TemplateMenu>

      <Show breakpoint="(min-width: 1300px)">
        <FocusBarIconButton
          aria-label="Workflow Settings"
          icon={<Icon icon="gear" variant="far" size="4" color="white" />}
          onClick={openSetup}
          tooltipText={`Workflow Setup`}
        />

        {newestTemplateRevisionQuery.data && !isEditorV2 && (
          <ConditionalLogicButtonWrapper templateRevisionId={newestTemplateRevisionQuery.data.id}>
            <FocusBarIconButton
              icon={<Icon icon="shuffle" variant="far" size="4" color="white" />}
              aria-label="Conditional logic"
              tooltipText={`Conditional logic`}
            />
          </ConditionalLogicButtonWrapper>
        )}

        {ffs.sandboxMode && template && (
          <SandboxButton template={template}>
            <FocusBarIconButton
              icon={<Icon icon="flask" variant="far" size="4" color="white" />}
              aria-label="Preview"
              tooltipText="Preview"
            />
          </SandboxButton>
        )}
      </Show>

      <Show breakpoint="(min-width: 1100px)">
        {ffs.publishAndRun ? (
          <ButtonGroup isAttached>
            <Button
              variant="cta"
              onClick={publish}
              isDisabled={
                !canPublish ||
                publishDraftMutation.isLoading ||
                publishDraftMutation.isSuccess ||
                publishAndRunMutation.isLoading ||
                publishAndRunMutation.isSuccess
              }
              isLoading={
                publishDraftMutation.isLoading || (publishDraftMutation.isSuccess && !runChecklistDisclosure.isOpen)
              }
              color="gray.700"
              aria-label="publish changes"
            >
              Publish
            </Button>

            <Menu isLazy>
              <MenuButton
                as={IconButton}
                borderLeftColor="gray.700"
                borderLeftWidth="thin"
                borderLeftStyle="solid"
                variant="cta"
                color="gray.700"
                aria-label="Open menu"
                icon={<Icon color="gray.700" icon="chevron-down" size="3" />}
                isDisabled={
                  !canPublish ||
                  publishDraftMutation.isLoading ||
                  publishDraftMutation.isSuccess ||
                  publishAndRunMutation.isLoading ||
                  publishAndRunMutation.isSuccess
                }
                isLoading={publishAndRunMutation.isLoading || publishAndRunMutation.isSuccess}
              />
              <Portal>
                <ThemeProvider2024>
                  <MenuList py="2" zIndex={3}>
                    <MenuItem
                      onClick={handlePublishAndRun}
                      icon={<Icon icon="play" size="4" />}
                      py="3"
                      fontSize="md"
                      isDisabled={
                        !canPublish ||
                        publishDraftMutation.isLoading ||
                        publishDraftMutation.isSuccess ||
                        publishAndRunMutation.isLoading ||
                        publishAndRunMutation.isSuccess
                      }
                    >
                      Publish and Run
                    </MenuItem>
                  </MenuList>
                </ThemeProvider2024>
              </Portal>
            </Menu>
          </ButtonGroup>
        ) : (
          <Button
            variant="cta"
            onClick={publish}
            isDisabled={!canPublish}
            isLoading={publishDraftMutation.isLoading || publishDraftMutation.isSuccess}
            color="gray.700"
            aria-label="publish changes"
          >
            Publish
          </Button>
        )}
      </Show>

      {migrationDisclosure.isOpen && (
        <ChecklistMigrationModal
          {...{
            ...migrationDisclosure,
            templateId,
            templateRevisionId,
            workflowRunCount,
            templateName: template?.name ?? '',
            defaultChecklistName: AiGeneratedWorkflowSettingsModalHelpers.getDefaultChecklistName({
              templateName: template?.name ?? '',
              defaultChecklistName: newestTemplateRevisionQuery.data?.defaultChecklistName,
            }),
            runWorkflow: shouldPublishAndRun,
          }}
        />
      )}

      {runChecklistDisclosure.isOpen && (
        <RunChecklist
          {...runChecklistDisclosure}
          onCancel={runChecklistDisclosure.onClose}
          templateId={templateId}
          onChecklistCreate={runChecklistDisclosure.onClose}
          isFromEditorV2={true}
        />
      )}

      {ffs.customNotifications && newestTemplateRevisionQuery.data && customNotificationsDisclosure.isOpen && (
        <CustomNotificationsModal
          {...customNotificationsDisclosure}
          templateRevision={newestTemplateRevisionQuery.data}
        />
      )}
    </ButtonGroup>
  );
};
