import { useField, useFormikContext } from 'formik';
import { FormErrorMessage, Input } from 'components/design/next';
import { FormControl } from '@chakra-ui/react';
import * as React from 'react';

export type KeyInputProps = {
  index: number;
  isReadOnly: boolean;
};

export const KeyInput: React.FC<KeyInputProps> = ({ index, isReadOnly }) => {
  const { handleSubmit } = useFormikContext();

  const [field, meta, { setValue }] = useField(`mappings[${index}].key`);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = async event => {
    await setValue(event.target.value);
  };

  return (
    <FormControl isInvalid={!!meta.error && meta.touched}>
      <Input
        {...field}
        placeholder="Enter key"
        onChange={handleChange}
        onBlur={() => handleSubmit()}
        isDisabled={isReadOnly}
      />
      {meta.error ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
    </FormControl>
  );
};
