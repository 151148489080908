import { NativeAutomation, UrlFormFieldValue, UrlFormFieldWidget, WidgetUtils } from '@process-street/subgrade/process';
import { InputProps } from 'components/design/next';
import * as React from 'react';
import { ChecklistFormFieldWidgetProps } from 'components/widgets/form-field/common/types';
import { urlService } from 'services/url-service';
import { formFieldKeyUpHandler } from 'components/widgets/form-field/form-field-key-up-handler';
import { Option } from 'space-monad';
import { useMachine } from '@xstate/react';
import { validatedInputMachine } from 'utils/widget/validated-input-machine';
import { assign } from 'xstate';
import { useSyncedAndMaskedFormFieldValues } from 'components/widgets/form-field/common/use-synced-and-masked-form-field-values';
import { useAutomatedTaskOutputWidgetStatus } from '../../hooks/use-automated-task-output-widget-status';
import { MergeTagStringReplacementUtils } from '@process-street/subgrade/merge-tags';
import { useFeatureFlag } from 'features/feature-flags';

export interface Args
  extends Pick<
    ChecklistFormFieldWidgetProps<UrlFormFieldWidget, UrlFormFieldValue>,
    'widget' | 'editable' | 'formFieldValue' | 'onUpdateValue' | 'onInteractionEnd'
  > {}

const isValid = (newValue: string) => newValue === '' || urlService.isValidHttpUrl(newValue);

const normalizeValue = (valueStr: string): string => {
  const trimmed = valueStr.trim();
  return trimmed ? urlService.addHttp(trimmed) : trimmed;
};

export function useChecklistUrlFormField({ widget, editable, formFieldValue, onUpdateValue, onInteractionEnd }: Args): {
  inputProps: Partial<InputProps>;
  errorMessage: string | undefined;
  onReset: () => void;
  onOpen: () => void;
  urlIsOpenable: boolean;
  nativeAutomationStatus: NativeAutomation.EventStatus | undefined;
} {
  const isMergeTagImprovementsEnabled = useFeatureFlag('mergeTagImprovements');
  const { status: nativeAutomationStatus } = useAutomatedTaskOutputWidgetStatus(widget.id);
  const isReadOnly = !editable;
  const stringValue = Option(formFieldValue)
    .map(ffv => ffv.fieldValue.value)
    .map(String)
    .getOrElse('');

  const initiallyValid = isValid(stringValue);
  const widgetDefaultValue = Option<string>(widget.config?.defaultValue)
    .map(defaultValue =>
      isMergeTagImprovementsEnabled
        ? MergeTagStringReplacementUtils.replaceUnknownTagsValues(defaultValue)
        : defaultValue,
    )
    .get();

  const [state, send] = useMachine(validatedInputMachine, {
    context: { value: stringValue, isValid: initiallyValid, shouldShowError: !initiallyValid },
    actions: {
      validate: assign({
        isValid: ({ value }) => {
          return isValid(value);
        },
      }),
      onInteractionEnd: (ctx, _event) => {
        onInteractionEnd(widget, { value: ctx.value, hasDefaultValue: formFieldValue?.fieldValue.hasDefaultValue });
      },
      onReset: () => {
        onUpdateValue(widget, {
          value: WidgetUtils.hasVariables(widgetDefaultValue) ? '' : widgetDefaultValue,
          hasDefaultValue: true,
        });
      },
      onChange: (ctx, _event) => {
        onUpdateValue(widget, { value: ctx.value, hasDefaultValue: ctx.value === widgetDefaultValue });
      },
      normalizeValue: assign({
        value: ctx => normalizeValue(ctx.value),
      }),
    },
  });

  useSyncedAndMaskedFormFieldValues({ send, widget, formFieldValue });

  const onChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    send({ type: 'CHANGE', value: event.target.value });
  };

  const onFocus = () => {
    send({ type: 'FOCUS' });
  };

  const onBlur = () => {
    send({ type: 'BLUR' });
  };

  const errorMessage = state.context.shouldShowError ? 'This is not a valid url' : undefined;

  const urlIsOpenable = Boolean(state.context.value) && !errorMessage;

  const handleOpenUrl = () => {
    const url = state.context.value;
    if (url) {
      window.open(urlService.addHttp(url));
    }
  };

  const onReset = () => {
    send({ type: 'RESET', value: widgetDefaultValue ?? '' });
  };

  return {
    errorMessage,
    urlIsOpenable,
    onOpen: handleOpenUrl,
    inputProps: {
      onFocus,
      isReadOnly,
      onChange,
      onBlur,
      type: 'url',
      placeholder: 'Type URL Here...',
      value: state.context.value || '',
      onKeyUp: formFieldKeyUpHandler,
    },
    onReset,
    nativeAutomationStatus,
  };
}
