import * as React from 'react';

import { FormFieldValue, FormFieldWidget, Task } from '@process-street/subgrade/process';

import { UploadButton } from 'features/widgets/components/send-rich-email/email_attachment/index';
import { useShowFileUploadErrorToast } from 'features/widgets/components/send-rich-email/email_attachment/use-show-file-upload-error-toast';
import { useUploadChecklistEmailAttachment } from './use-upload-checklist-email-attachment';
import { UploadEventParams } from './send-email-widget-machine';
import { Box } from 'components/design/next';

type UploadChecklistEmailAttachmentProps = {
  taskId: Task['id'];
  widgetId: FormFieldWidget['id'];
  formFieldValue?: FormFieldValue;
  /** Empty state form field value creator if form field value ID doesn't exist when uploading. */
  onCreateFormFieldValue: () => FormFieldValue;
  progress: number | undefined;
  onStartUpload: (params: UploadEventParams) => void;
  setProgress: (progress: number | undefined) => void;
  totalSize: number;
};

export const UploadChecklistEmailAttachment = ({
  taskId,
  widgetId,
  formFieldValue,
  onCreateFormFieldValue,
  onStartUpload,
  setProgress,
  progress,
  totalSize,
}: UploadChecklistEmailAttachmentProps) => {
  const { dropzoneState } = useUploadChecklistEmailAttachment({
    taskId,
    widgetId,
    formFieldValue,
    onCreateFormFieldValue,
    onStartUpload,
    setProgress,
    totalSize,
  });

  useShowFileUploadErrorToast(dropzoneState);
  const shouldShowUploadButton = progress === undefined || progress === 100;
  return (
    <Box w="full" {...dropzoneState.getRootProps()}>
      {shouldShowUploadButton && (
        <UploadButton aria-label="add attachment" dropzoneInputProps={dropzoneState.getInputProps()} />
      )}
    </Box>
  );
};
