import { MergeTagMode, MergeTagStringReplacementUtils, ResolvedTag } from '@process-street/subgrade/merge-tags';
import { AutomatedTaskTemplate, ExecuteAiPrompt, NativeAutomation } from '@process-street/subgrade/process';
import { toResult } from '@process-street/subgrade/util';
import { TestPromptCompletionMutation } from 'features/ai-generation/query-builder';
import { QueryClient } from 'react-query';
import { MergeTagsServiceUtils } from 'services/merge-tags';
import { match, P } from 'ts-pattern';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { assign, createMachine } from 'xstate';
import {
  GetAllNativeAutomationsQuery,
  UpdateNativeAutomationActionsMutation,
} from 'features/native-automations/query-builder';

interface Context {
  actions: NativeAutomation.Action[];
  prompt: string;
  initialPrompt: string;
  inputs: Record<string, string>;
  systemPrompt: string;
  initialSystemPrompt: string;
  systemPromptInputs: Record<string, string>;
  formatPromptInputs: Record<string, string>;
  response: string;
}

type Event =
  | { type: 'INPUT_CHANGE'; key: string; value: string }
  | { type: 'PROMPT_CHANGE'; value: string }
  | { type: 'SYSTEM_PROMPT_CHANGE'; value: string }
  | { type: 'SYSTEM_PROMPT_INPUT_CHANGE'; key: string; value: string }
  | { type: 'FORMAT_PROMPT_INPUT_CHANGE'; key: string; value: string }
  | { type: 'NATIVE_AUTOMATION_ACTIONS_UPDATE'; actions: NativeAutomation.Action[] }
  | { type: 'CREATE_DRAFT' }
  | { type: 'REFRESH' }
  | { type: 'SAVE_PROMPT' }
  | { type: 'MODAL_CLOSED' }
  | {
      type: 'done.invoke.testPromptCompletionMutation';
      data: TestPromptCompletionMutation.Response;
    }
  | {
      type: 'done.invoke.updateNativeAutomationActionsMutation';
      data: UpdateNativeAutomationActionsMutation.Response;
    };

export const makeStorageKey = (taskTemplateId: string) => `test-ai-task-modal-context:${taskTemplateId}`;

export const makeTestAiTaskModalMachine = ({
  aiTaskTemplate,
  queryClient,
}: {
  aiTaskTemplate: AutomatedTaskTemplate;
  queryClient: QueryClient;
}) => {
  const promptConfig = aiTaskTemplate.nativeAutomation.actions.find(NativeAutomation.isExecuteAiPromptAction)?.config;
  if (!promptConfig) {
    throw new Error('No prompt config found');
  }

  const storageKey = makeStorageKey(aiTaskTemplate.taskTemplate.id);

  function getPromptAndInputs(prompt: string) {
    const foundTags = MergeTagStringReplacementUtils.findTags(prompt ?? '', MergeTagMode.PLAINTEXT);
    const inputs = foundTags.reduce((acc, tag) => {
      acc[tag] = '';
      return acc;
    }, {} as Record<string, string>);
    return { inputs, prompt };
  }

  const storedContext = getStoredContext(storageKey);

  const promptInit = getPromptAndInputs(promptConfig.prompt);
  const systemPromptInit = getPromptAndInputs(promptConfig.systemPrompt ?? '');

  // add input prefix like the back-end does for better parsing and resolution
  const formatPromptInputs = ExecuteAiPrompt.sanitizePromptInputs(
    'promptInputs' in promptConfig ? promptConfig.promptInputs : {},
  );
  // ignore variables as those are derived from the keyval pairs
  const { [`${ExecuteAiPrompt.INPUT_PREFIX}variables`]: _, ...storedFormatPromptInputs } =
    storedContext.formatPromptInputs ?? {};

  return createMachine(
    {
      id: 'test-ai-task-modal',
      predictableActionArguments: true,
      schema: {
        context: {} as Context,
        events: {} as Event,
      },
      tsTypes: {} as import('./test-ai-task-modal-machine.typegen').Typegen0,
      context: () => {
        return {
          actions: aiTaskTemplate.nativeAutomation.actions,
          prompt: promptInit.prompt,
          initialPrompt: promptInit.prompt,
          inputs: { ...promptInit.inputs, ...storedContext.inputs },
          systemPrompt: systemPromptInit.prompt,
          initialSystemPrompt: systemPromptInit.prompt,
          systemPromptInputs: { ...systemPromptInit.inputs, ...storedContext.systemPromptInputs },
          formatPromptInputs: { ...formatPromptInputs, ...storedFormatPromptInputs },
          response: '',
        };
      },
      initial: 'gatheringInputs',
      exit: ['setSessionStorage'],
      on: {
        PROMPT_CHANGE: { target: 'gatheringInputs', actions: ['assignPrompt', 'assignInputs'] },
        SYSTEM_PROMPT_CHANGE: {
          target: 'gatheringInputs',
          actions: ['assignSystemPrompt', 'assignSystemPromptInputs'],
        },
        INPUT_CHANGE: { actions: 'assignInputs' },
        SYSTEM_PROMPT_INPUT_CHANGE: { actions: 'assignSystemPromptInputs' },
        FORMAT_PROMPT_INPUT_CHANGE: { actions: 'assignFormatPromptInputs' },
        SAVE_PROMPT: 'savingPrompt',
        MODAL_CLOSED: { actions: 'setSessionStorage' },
        NATIVE_AUTOMATION_ACTIONS_UPDATE: {
          actions: [
            'assignActions',
            'assignPrompt',
            'assignInputs',
            'assignSystemPrompt',
            'assignSystemPromptInputs',
            'assignInitialSystemPrompt',
            'assignInitialPrompt',
            'assignFormatPromptInputs',
          ],
        },
      },
      states: {
        gatheringInputs: {
          always: { target: 'ready', cond: 'allInputsFilled' },
          on: {
            INPUT_CHANGE: [{ target: 'ready', cond: 'allInputsFilled', actions: 'assignInputs' }],
            SYSTEM_PROMPT_INPUT_CHANGE: [
              { target: 'ready', cond: 'allInputsFilled', actions: 'assignSystemPromptInputs' },
            ],
            FORMAT_PROMPT_INPUT_CHANGE: [
              { target: 'ready', cond: 'allInputsFilled', actions: 'assignFormatPromptInputs' },
            ],
          },
        },
        ready: {
          always: { target: 'draftCreated', cond: 'responseExists' },
          on: {
            CREATE_DRAFT: { target: 'creatingDraft' },
          },
        },
        creatingDraft: {
          invoke: {
            id: 'testPromptCompletionMutation',
            src: 'testPromptCompletionMutation',
            onDone: { target: 'draftCreated', actions: 'assignResponse' },
            onError: {
              target: 'draftError',
              actions: ['assignResponse'],
            },
          },
        },
        draftError: {
          on: {
            CREATE_DRAFT: { target: 'creatingDraft' },
          },
        },
        draftCreated: {
          on: {
            CREATE_DRAFT: { target: 'creatingDraft' },
          },
        },
        savingPrompt: {
          on: {
            NATIVE_AUTOMATION_ACTIONS_UPDATE: { actions: [] }, // intercept default handler
          },
          invoke: {
            id: 'updateNativeAutomationActionsMutation',
            src: 'updateNativeAutomationActionsMutation',
            onDone: {
              target: 'gatheringInputs',
              actions: ['assignInitialPrompt', 'assignInitialSystemPrompt', 'closeModal', 'setSessionStorage'],
            },
          },
        },
      },
    },
    {
      guards: {
        allInputsFilled: (ctx, evt) => {
          const base = { ...ctx.inputs, ...ctx.systemPromptInputs, ...ctx.formatPromptInputs };
          const optimistic = match(evt)
            .with(
              { type: P.union('INPUT_CHANGE', 'SYSTEM_PROMPT_INPUT_CHANGE', 'FORMAT_PROMPT_INPUT_CHANGE') },
              ({ key, value }) => ({
                ...base,
                [key]: value,
              }),
            )
            .otherwise(() => base);

          return Object.values(optimistic).every(value => Boolean(value));
        },
        responseExists: (ctx, _evt) => ctx.response !== '',
      },
      actions: {
        assignActions: assign({ actions: (_ctx, evt) => evt.actions }),
        assignInputs: assign({
          inputs: (ctx, evt) => {
            return match(evt)
              .with({ type: 'INPUT_CHANGE' }, ({ key, value }) => {
                return { ...ctx.inputs, [key]: value };
              })
              .with({ type: P.union('PROMPT_CHANGE', 'NATIVE_AUTOMATION_ACTIONS_UPDATE') }, () => {
                const { inputs } = getPromptAndInputs(ctx.prompt);
                return Object.fromEntries(Object.entries(inputs).map(([key]) => [key, ctx.inputs[key] ?? '']));
              })
              .exhaustive();
          },
        }),
        assignSystemPromptInputs: assign({
          systemPromptInputs: (ctx, evt) => {
            return match(evt)
              .with({ type: 'SYSTEM_PROMPT_INPUT_CHANGE' }, ({ key, value }) => {
                return { ...ctx.systemPromptInputs, [key]: value };
              })
              .with({ type: P.union('SYSTEM_PROMPT_CHANGE', 'NATIVE_AUTOMATION_ACTIONS_UPDATE') }, () => {
                const { inputs } = getPromptAndInputs(ctx.systemPrompt);
                return Object.fromEntries(
                  Object.entries(inputs).map(([key]) => [key, ctx.systemPromptInputs[key] ?? '']),
                );
              })
              .exhaustive();
          },
        }),
        assignFormatPromptInputs: assign({
          formatPromptInputs: (ctx, evt) => {
            return match(evt)
              .with({ type: 'FORMAT_PROMPT_INPUT_CHANGE' }, ({ key, value }) => {
                return { ...ctx.formatPromptInputs, [key]: value };
              })
              .with({ type: 'NATIVE_AUTOMATION_ACTIONS_UPDATE' }, ({ actions }) => {
                const config = actions.find(NativeAutomation.isExecuteAiPromptAction)?.config ?? {};
                const promptInputs = 'promptInputs' in config ? config.promptInputs : {};
                const storedContext = getStoredContext(storageKey);

                const { [`${ExecuteAiPrompt.INPUT_PREFIX}variables`]: _, ...storedFormatPromptInputs } =
                  storedContext.formatPromptInputs ?? {};
                const inputs = ExecuteAiPrompt.sanitizePromptInputs(promptInputs);
                return { ...inputs, ...storedFormatPromptInputs };
              })
              .exhaustive();
          },
        }),
        assignResponse: assign({
          response: (ctx, evt) =>
            match(evt)
              .with({ type: 'error.platform.testPromptCompletionMutation' }, () => '')
              .with({ type: 'done.invoke.testPromptCompletionMutation' }, ({ data }) => data)
              .otherwise(() => ctx.response),
        }),
        assignPrompt: assign({
          prompt: (_ctx, evt) =>
            match(evt)
              .with({ type: 'PROMPT_CHANGE' }, ({ value }) => value)
              .with({ type: 'NATIVE_AUTOMATION_ACTIONS_UPDATE' }, ({ actions }) => {
                return actions.find(NativeAutomation.isExecuteAiPromptAction)?.config.prompt ?? '';
              })
              .exhaustive(),
        }),
        assignInitialPrompt: assign({ initialPrompt: (ctx, _evt) => ctx.prompt }),
        assignSystemPrompt: assign({
          systemPrompt: (_ctx, evt) =>
            match(evt)
              .with({ type: 'SYSTEM_PROMPT_CHANGE' }, ({ value }) => value)
              .with({ type: 'NATIVE_AUTOMATION_ACTIONS_UPDATE' }, ({ actions }) => {
                return actions.find(NativeAutomation.isExecuteAiPromptAction)?.config.systemPrompt ?? '';
              })
              .exhaustive(),
        }),
        assignInitialSystemPrompt: assign({ initialSystemPrompt: (ctx, _evt) => ctx.systemPrompt }),

        setSessionStorage: ctx => {
          sessionStorage.setItem(storageKey, JSON.stringify(ctx));
        },

        // define in component
        closeModal: () => {},
      },

      services: {
        updateNativeAutomationActionsMutation: async (ctx, _evt) => {
          return makeMutation(queryClient, {
            mutationKey: UpdateNativeAutomationActionsMutation.key,
            variables: {
              nativeAutomationId: aiTaskTemplate.nativeAutomation.id,
              actions: ctx.actions.map(action => {
                return match(action)
                  .with({ actionType: 'ExecuteAiPrompt' }, action => {
                    return {
                      ...action,
                      config: { ...action.config, prompt: ctx.prompt, systemPrompt: ctx.systemPrompt },
                    } as typeof action;
                  })
                  .otherwise(() => action);
              }),
            },

            mutationFn: vars => UpdateNativeAutomationActionsMutation.mutationFn(vars),
            onSuccess: nativeAutomation => {
              queryClient.setQueryData(
                GetAllNativeAutomationsQuery.getKey({
                  templateRevisionId: aiTaskTemplate.taskTemplate.templateRevision.id,
                }),
                GetAllNativeAutomationsQuery.taskTemplateAutomationUpdater(
                  aiTaskTemplate.taskTemplate.id,
                  nativeAutomation,
                ),
              );
            },
          }).execute();
        },

        testPromptCompletionMutation: async (ctx, _evt) => {
          function getResolvedTags(inputs: Record<string, string>): ResolvedTag[] {
            return Object.entries(inputs).map(([key, value]) => ({
              key,
              replacement: value,
              default: value,
            }));
          }

          const systemPromptTags = getResolvedTags(ctx.systemPromptInputs);
          const systemPrompt = MergeTagsServiceUtils.replaceResolvedTagsWithPlaintextValues(
            systemPromptTags,
            ctx.systemPrompt,
          );

          const tags: ResolvedTag[] = getResolvedTags(ctx.inputs);
          const prompt = MergeTagsServiceUtils.replaceResolvedTagsWithPlaintextValues(tags, ctx.prompt);

          const formatPromptTags: ResolvedTag[] = getResolvedTags(ctx.formatPromptInputs);
          const formatPrompt = MergeTagsServiceUtils.replaceResolvedTagsWithPlaintextValues(
            formatPromptTags,
            'formatPrompt' in promptConfig ? promptConfig.formatPrompt ?? '' : '',
          );

          const fullPrompt = [systemPrompt, prompt, formatPrompt].join('\n');

          return makeMutation(queryClient, {
            mutationFn: vars => TestPromptCompletionMutation.mutationFn(vars),
            mutationKey: TestPromptCompletionMutation.key,
            variables: { prompt: fullPrompt },
          }).execute();
        },
      },
    },
  );
};

const getStoredContext = (key: string) => {
  return toResult(() => JSON.parse(sessionStorage.getItem(key) ?? '')).fold(
    () => ({} as Partial<Context>),
    context => context as Context,
  );
};
