import { Muid } from '@process-street/subgrade/core';
import { ChecklistSubscription } from '@process-street/subgrade/process';
import { axiosService } from 'app/services/axios-service';
import { AxiosError } from 'axios';
import { UseMutationOptions, useMutation as useRQMutation } from 'react-query';

export namespace CreateChecklistSubscriptionMutation {
  export type Params = { checklistId: Muid; userId: Muid };

  export type Response = ChecklistSubscription;

  export const key = ['create', 'checklist', 'subscriptions'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .post<Response>('/1/checklist-subscriptions', params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
