import * as React from 'react';
import { RightSidebarButton } from '../right-sidebar-button';
import { GetChecklistSubscriptionsQuery } from 'app/features/subscription/query-builder/get-checklist-subscriptions-query';
import { useCurrentUser } from 'app/hooks/use-current-user';
import { Checklist } from '@process-street/subgrade/process';
import { isModelRef } from '@process-street/subgrade/core';
import { Icon, Text, useToast } from 'app/components/design/next';
import { CreateChecklistSubscriptionMutation } from 'app/features/subscription/query-builder/create-checklist-subscriptions-mutation';
import { DeleteChecklistSubscriptionMutation } from 'app/features/subscription/query-builder/delete-checklist-subscription-mutation';
import { DefaultErrorMessages } from 'app/components/utils/error-messages';
import { useQueryClient } from 'react-query';
import invariant from 'tiny-invariant';

export type SubscribeButtonProps = {
  checklist: Checklist;
};

export const SubscribeButton = ({ checklist }: SubscribeButtonProps) => {
  const toast = useToast();
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();

  const checklistSubscriptionsQuery = GetChecklistSubscriptionsQuery.useQuery({
    checklistId: checklist.id,
    userId: currentUser?.id,
  });

  const showErrorToast = () => {
    toast({
      status: 'error',
      title: 'Failed to update subscription',
      description: DefaultErrorMessages.unexpectedErrorDescription,
    });
  };

  const createChecklistSubscriptionMutation = CreateChecklistSubscriptionMutation.useMutation({
    onSuccess: async () => {
      await GetChecklistSubscriptionsQuery.invalidate(queryClient, {
        checklistId: checklist.id,
        userId: currentUser?.id,
      });

      toast({
        status: 'success',
        title: 'Subscription updated',
        description: "You'll now get email notifications for this workflow run.",
      });
    },
    onError: () => {
      showErrorToast();
    },
  });

  const deleteChecklistSubscriptionMutation = DeleteChecklistSubscriptionMutation.useMutation({
    onSuccess: async () => {
      await GetChecklistSubscriptionsQuery.invalidate(queryClient, {
        checklistId: checklist.id,
        userId: currentUser?.id,
      });

      toast({
        status: 'success',
        title: 'Subscription updated',
        description: "You won't get email notifications for this workflow run anymore.",
      });
    },
    onError: () => {
      showErrorToast();
    },
  });

  const subscription = checklistSubscriptionsQuery.data?.find(subscription => {
    if (isModelRef(subscription.organizationMembership)) {
      return subscription.organizationMembership.user.id === currentUser?.id;
    }

    return false;
  });
  const isSubscribed = Boolean(subscription);

  const handleSubscribe = () => {
    try {
      invariant(currentUser?.id, '`currentUser.id` is not defined');

      createChecklistSubscriptionMutation.mutate({
        checklistId: checklist.id,
        userId: currentUser?.id,
      });
    } catch (e) {
      console.error(e);
      showErrorToast();
    }
  };

  const handleUnsubscribe = () => {
    try {
      invariant(currentUser?.id, '`currentUser.id` is not defined');
      invariant(subscription, '`subscription` is not defined');

      deleteChecklistSubscriptionMutation.mutate({
        subscriptionId: subscription.id,
      });
    } catch (e) {
      console.error(e);
      showErrorToast();
    }
  };

  return (
    <RightSidebarButton
      iconName="envelope"
      rightIcon={isSubscribed ? <Icon icon="check" size="4" color="green.500" /> : undefined}
      onClick={isSubscribed ? handleUnsubscribe : handleSubscribe}
      isLoading={createChecklistSubscriptionMutation.isLoading || deleteChecklistSubscriptionMutation.isLoading}
      loadingText={isSubscribed ? 'Unsubscribing' : 'Subscribing'}
    >
      <Text textAlign="left" flex="1">
        {isSubscribed ? 'Subscribed' : 'Subscribe'}
      </Text>
    </RightSidebarButton>
  );
};
