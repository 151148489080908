import { FieldType, FormFieldWidget } from '@process-street/subgrade/process';
import { Text } from 'components/design/next';
import * as React from 'react';
import { match } from 'ts-pattern';

export type ApprovalWidegtLabelRendererProps = {
  widget: FormFieldWidget;
};

export const ApprovalWidegtLabelRenderer = ({ widget }: ApprovalWidegtLabelRendererProps) => {
  const label =
    widget.label && widget.label !== ''
      ? widget.label
      : match(widget.fieldType)
          .with(FieldType.Text, () => 'Untitled Short Text')
          .with(FieldType.Textarea, () => 'Untitled Long Text')
          .with(FieldType.Date, () => 'Untitled Date')
          .with(FieldType.Email, () => 'Untitled Email')
          .with(FieldType.File, () => 'Untitled File')
          .with(FieldType.Number, () => 'Untitled Number')
          .with(FieldType.Select, () => 'Untitled Dropdown')
          .with(FieldType.Table, () => 'Untitled Table')
          .with(FieldType.Url, () => 'Untitled Website')
          .otherwise(() => '');

  const displayLabel = `${label}${label !== '' && !label.endsWith(':') ? ':' : ''}`;
  return (
    <Text fontSize="sm" fontWeight="medium" color="gray.500">
      {displayLabel}
    </Text>
  );
};
