import React from 'react';
import { OneOffTask } from '@process-street/subgrade/one-off-task';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'features/checklist-revisions/query-builder';
import {
  GetTasksAssignmentsByChecklistRevisionQuery,
  useGetTasksAssignmentsByChecklistRevisionQuery,
} from 'features/checklist-revisions/query-builder/get-tasks-assignments-by-checklist-revision-query';
import { isNotIdRef, OrganizationMembershipWithUser, TaskErrorCodes } from '@process-street/subgrade/core';
import { useGetAllOrganizationMembershipsQuery } from 'features/organization-memberships/query-builder';
import { isStandardUserOrNonSystemGroupOm } from '@process-street/subgrade/util/membership-utils';
import { useQueryClient } from 'react-query';
import { DeleteTaskAssignmentMutation, UpdateTaskAssignmentMutation } from 'features/task/query-builder';
import { useNextThemeToast } from 'components/design/next/use-next-theme-toast';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import {
  GetOneOffTaskQuery,
  GetOneOffTasksByChecklistQuery,
  UpdateTaskDueDateMutation,
} from 'features/one-off-tasks/query-builder';
import { TaskStatus } from '@process-street/subgrade/process';
import { useCanEditOneOfftask } from './use-can-edit-one-off-task';
import { GetInboxItemsQuery } from 'features/microsoft-teams/query-builder';

// Manage one-off topbar functionalities: list assigned memberships, available ones, and mutations.
export const useOneOffTaskTopbar = (
  task: OneOffTask,
): {
  assignedMemberships: OrganizationMembershipWithUser[];
  availableMemberships: OrganizationMembershipWithUser[];
  handleMembershipAssignment: (membership: OrganizationMembershipWithUser) => void;
  handleMembershipRemoval: (membership: OrganizationMembershipWithUser) => void;
  handleDueDateUpdate: (dueDate: number | null) => void;
  isDisabled: boolean;
} => {
  const queryClient = useQueryClient();
  const toast = useNextThemeToast();

  const checklistRevisionQuery = GetActiveChecklistRevisionByChecklistIdQuery.useQuery({
    checklistId: task.internalChecklistId,
  });

  const taskAssignmentsQuery = useGetTasksAssignmentsByChecklistRevisionQuery(
    { checklistRevisionId: checklistRevisionQuery.data?.id ?? '' },
    {
      enabled: Boolean(checklistRevisionQuery.data?.id),
      onSuccess: data => data.filter(taskAssignment => taskAssignment.task.id === task.id),
    },
  );

  const { data: allMemberships } = useGetAllOrganizationMembershipsQuery(
    {
      organizationId: checklistRevisionQuery.data?.organization.id,
    },
    {
      enabled: Boolean(checklistRevisionQuery.data?.organization.id),
      select: oms => oms.filter(isStandardUserOrNonSystemGroupOm),
    },
  );

  const assignedMemberships = React.useMemo(() => {
    if (taskAssignmentsQuery.data) {
      return taskAssignmentsQuery.data
        .map(assignment => isNotIdRef(assignment.organizationMembership) && assignment.organizationMembership)
        .filter(Boolean) as OrganizationMembershipWithUser[];
    }
    return [];
  }, [taskAssignmentsQuery.data]);

  const availableMemberships = React.useMemo(() => {
    if (!allMemberships) {
      return [];
    }
    const assignedMembershipEmailSet = new Set(assignedMemberships.map(membership => membership.user.email));
    return allMemberships?.filter(om => !assignedMembershipEmailSet.has(om.user.email));
  }, [allMemberships, assignedMemberships]);

  const invalidateAssignmentsQuery = () => {
    void queryClient.invalidateQueries(
      GetTasksAssignmentsByChecklistRevisionQuery.getKey({
        checklistRevisionId: checklistRevisionQuery.data?.id ?? '',
      }),
    );
    // Invalidate task query so that the task list item refreshes with the right assignee count.
    if (task.linkedChecklist) {
      void queryClient.invalidateQueries(
        GetOneOffTasksByChecklistQuery.getKey({ checklistId: task.linkedChecklist.id }),
      );
    }
    void queryClient.invalidateQueries(GetOneOffTaskQuery.getKey({ id: task.id }));
  };

  const showAssignmentErrorToast = () => {
    toast({
      status: 'error',
      title: `We're having problems assigning to this Task.`,
      description: DefaultErrorMessages.unexpectedErrorDescription,
    });
  };

  const showUpdateDueDateErrorToast = (title: string) => {
    toast({
      status: 'error',
      title,
      description: DefaultErrorMessages.unexpectedErrorDescription,
    });
  };

  const updateTaskAssignmentMutation = UpdateTaskAssignmentMutation.useMutation({
    onSuccess: () => invalidateAssignmentsQuery(),
    onError: showAssignmentErrorToast,
  });

  const handleMembershipAssignment = (membership: OrganizationMembershipWithUser) => {
    updateTaskAssignmentMutation.mutate({
      taskId: task.id,
      email: membership.user.email,
    });
  };

  const deleteTaskAssignmentMutation = DeleteTaskAssignmentMutation.useMutation({
    onSuccess: () => invalidateAssignmentsQuery(),
    onError: showAssignmentErrorToast,
  });

  const handleMembershipRemoval = (membership: OrganizationMembershipWithUser) => {
    deleteTaskAssignmentMutation.mutate({
      taskId: task.id,
      assignmentId: membership.user.email,
    });
  };

  const updateTaskDueDateMutation = UpdateTaskDueDateMutation.useMutation({
    onSuccess: () => {
      if (task.linkedChecklist) {
        queryClient.invalidateQueries(GetOneOffTasksByChecklistQuery.getKey({ checklistId: task.linkedChecklist.id }));
      }
      queryClient.invalidateQueries(GetOneOffTaskQuery.getKey({ id: task.id }));
      GetInboxItemsQuery.invalidate(queryClient);
    },
    onError: e => {
      const isInvalidDueDate = e.response?.data?.code === TaskErrorCodes.InvalidDueDate;
      const title = isInvalidDueDate
        ? 'Please set due dates for future dates.'
        : "We're having updating the due date of this Task";
      showUpdateDueDateErrorToast(title);
    },
  });

  const handleDueDateUpdate = (dueDate: number | null) => {
    updateTaskDueDateMutation.mutate({
      dueDate,
      dueDateOverridden: false,
      id: task.id,
    });
  };

  const canEdit = useCanEditOneOfftask(task);
  const isDisabled = task.status === TaskStatus.Completed || !canEdit;

  return {
    assignedMemberships,
    availableMemberships,
    handleMembershipAssignment,
    handleMembershipRemoval,
    handleDueDateUpdate,
    isDisabled,
  };
};
