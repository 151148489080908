import { Checklist } from '@process-street/subgrade/process';
import { ChecklistAssignment } from '@process-street/subgrade/role-assignment';
import { AxiosError } from 'axios';
import { useQuery as useRQQuery, QueryKey, UseQueryOptions, QueryClient } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace GetChecklistAssignmentsQuery {
  export type Params = { checklistId: Checklist['id'] };

  export type Response = ChecklistAssignment[];

  export const key = ['checklists', 'assignments'];
  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = (params: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/checklists/${params.checklistId}/assignments`)
      .then(res => res.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQQuery(getKey(params), () => queryFn(params), options);
  };

  export const invalidate = (queryClient: QueryClient, params: Params) => queryClient.invalidateQueries(getKey(params));

  export const createCacheUtils = (queryClient: QueryClient) => {
    const get = (params: Params) => queryClient.getQueryData<Response | undefined>(getKey(params));

    const set = (params: Params, data: Response | undefined) =>
      queryClient.setQueryData<Response | undefined>(getKey(params), data);

    const append = (params: Params, item: ChecklistAssignment) =>
      queryClient.setQueryData<Response | undefined>(getKey(params), (existing = []) => [...existing, item]);

    const remove = (params: Params, itemId: ChecklistAssignment['id']) =>
      queryClient.setQueryData<Response | undefined>(getKey(params), (existing = []) =>
        existing.filter(item => item.id !== itemId),
      );

    return {
      append,
      get,
      remove,
      set,
    };
  };
}
