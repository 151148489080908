import { library } from '@fortawesome/fontawesome-svg-core';
import { fasLibrary } from './fas-library';
import { customLibrary } from './custom-library';
import { farLibrary } from './far-library';
import { fabLibrary } from './fab-library';
import { fadLibrary } from './fad-library';
import { falLibrary } from './fal-library';
import { fadrLibrary } from './fadr-library';

library.add(farLibrary, fasLibrary, fabLibrary, fadLibrary, fadrLibrary, falLibrary, customLibrary);
