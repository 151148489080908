import { NativeAutomation, TaskTemplate, Template } from '@process-street/subgrade/process';
import { ActorRefFrom, assign, createMachine, spawn } from 'xstate';
import {
  makeQueryMachine,
  QueryActor,
  QueryActorSelectors,
  RefetchOnMountOnly,
  SystemUpdateEvent,
} from 'utils/query-builder';
import {
  GetAllNativeAutomationsQuery,
  UpdateNativeAutomationActionsMutation,
} from 'features/native-automations/query-builder';
import { QueryClient } from 'react-query';
import { CodeTaskUtils } from 'pages/templates/_id/components/code-task-template-editor/code-task-utils';
import {
  GetConsolidatedTemplatePermissionsResult,
  makeGetConsolidatedTemplatePermissionsQueryObserver,
} from 'features/permissions/query-builder';
import { KeyValueMapping } from 'pages/templates/_id/components/code-task-template-editor/key-value-list/key-value-list';
import ExecuteCodeAction = NativeAutomation.ExecuteCodeAction;
import UpdateFormFieldsAction = NativeAutomation.UpdateFormFieldsAction;
import { ExecuteCodeMutation } from 'features/automations/query-builder';
import { match } from 'ts-pattern';
import { makeErrorLoggerAction } from 'utils/machines';

export type CodeTaskTemplateEditorMode = 'loading' | 'view' | 'edit' | 'test';

export type Context = {
  queryClient: QueryClient;

  // incoming
  codeTask: TaskTemplate;
  templateId: Template['id'];
  isReadOnly: boolean;

  // automation
  nativeAutomation?: NativeAutomation;
  codeAction?: NativeAutomation.ExecuteCodeAction;
  updateFormFieldsAction?: NativeAutomation.UpdateFormFieldsAction;

  // other
  canEditTemplate?: boolean;
  testExecutionLog?: NativeAutomation.ExecuteCodeActionLog;

  // inputs
  inputMapping: KeyValueMapping[];
  testInputMapping: KeyValueMapping[];

  // outputs
  outputMapping: KeyValueMapping[];
  testOutputMapping: KeyValueMapping[];

  // queries
  nativeAutomationsQuery?: QueryActor<GetAllNativeAutomationsQuery.Response>;
  consolidatedTemplatePermissionsQuery?: QueryActor<GetConsolidatedTemplatePermissionsResult>;
};

export type Event =
  | SystemUpdateEvent<{ id: 'native-automations-query'; data: GetAllNativeAutomationsQuery.Response }>
  | SystemUpdateEvent<{ id: 'consolidated-template-permissions-query'; data: GetConsolidatedTemplatePermissionsResult }>
  | { type: 'SET_TEST_MODE' }
  | { type: 'SET_EDIT_MODE' }
  | { type: 'EXECUTE_CODE' }
  | { type: 'SET_TEST_INPUT_MAPPINGS'; value: KeyValueMapping[] }
  | { type: 'SET_INPUT_MAPPINGS'; value: KeyValueMapping[] }
  | { type: 'SET_OUTPUT_MAPPINGS'; value: KeyValueMapping[] }
  | { type: 'SET_CODE'; value: string };

export const makeCodeTaskMachine = ({
  codeTask,
  queryClient,
  templateId,
  isReadOnly,
}: {
  codeTask: TaskTemplate;
  queryClient: QueryClient;
  templateId: Template['id'];
  isReadOnly: boolean;
}) => {
  const id = `code-task-machine:${codeTask.id}`;

  return createMachine(
    {
      id,
      type: 'compound',
      context: () =>
        ({
          queryClient,
          codeTask,
          mode: 'loading',
          templateId,
          isReadOnly,
          inputMapping: [],
          testInputMapping: [],
          outputMapping: [],
          testOutputMapping: [],
        } as Context),
      tsTypes: {} as import('./code-task-machine.typegen').Typegen0,
      schema: {
        context: {} as Context,
        events: {} as Event,
        services: {} as {
          executeCodeMutation: {
            data: ExecuteCodeMutation.Response;
          };
          updateNativeAutomation: {
            data: UpdateNativeAutomationActionsMutation.Response;
          };
        },
      },
      predictableActionArguments: true,
      preserveActionOrder: true,
      initial: 'loading',
      states: {
        loading: {
          initial: isReadOnly ? 'loading_permissions' : 'loadingRest',
          states: {
            loading_permissions: {
              entry: ['assignConsolidatedTemplatePermissionsQuery'],
              on: {
                'xstate.update': {
                  cond: 'isConsolidatedTemplatePermissionsQueryReady',
                  actions: ['assignPermissions'],
                  target: 'permission_check',
                },
              },
            },
            permission_check: {
              always: [
                { target: 'loadingRest', cond: 'canEditTemplate' },
                { target: '#view', cond: 'cannotEditTemplate' },
              ],
            },
            loadingRest: {
              entry: ['assignNativeAutomationsQuery'],
              on: {
                'xstate.update': {
                  cond: 'isNativeAutomationReady',
                  actions: ['assignActions'],
                  target: isReadOnly ? '#view' : '#edit',
                },
              },
            },
          },
        },
        view: {
          id: 'view',
        },
        edit: {
          id: 'edit',
          initial: 'idle',
          states: {
            idle: {
              on: {
                SET_TEST_MODE: {
                  target: '#test',
                  actions: ['setTestMode'],
                },
                SET_INPUT_MAPPINGS: {
                  target: 'debouncingSave',
                  actions: ['assignInputMapping'],
                },
                SET_OUTPUT_MAPPINGS: {
                  target: 'debouncingSave',
                  actions: ['assignOutputMapping'],
                },
                SET_CODE: {
                  target: 'debouncingSave',
                  actions: ['assignCode'],
                },
              },
            },
            debouncingSave: {
              after: {
                DEBOUNCE_DELAY: {
                  target: 'saving',
                },
              },
            },
            saving: {
              id: 'saving',
              invoke: {
                src: 'updateNativeAutomation',
                onDone: {
                  target: 'idle',
                  actions: [
                    assign({
                      nativeAutomation: (_ctx, event) => event.data,
                    }),
                  ],
                },
                onError: {
                  target: 'idle',
                  actions: ['logError'],
                },
              },
            },
          },
        },
        test: {
          id: 'test',
          initial: 'idle',
          states: {
            idle: {
              on: {
                SET_EDIT_MODE: {
                  target: '#edit',
                },
                SET_TEST_INPUT_MAPPINGS: {
                  actions: ['assignTestInputMapping'],
                },
                EXECUTE_CODE: {
                  target: 'executingCode',
                },
              },
            },
            executingCode: {
              invoke: {
                src: 'executeCodeMutation',
                onDone: {
                  target: 'idle',
                  actions: [
                    assign({
                      testOutputMapping: (ctx, event) =>
                        CodeTaskUtils.getUpdatedTestOutputMappings(ctx.testOutputMapping, event.data.outputData),
                      testExecutionLog: (_ctx, event) => event.data,
                    }),
                  ],
                },
                onError: {
                  target: 'idle',
                },
              },
            },
          },
        },
      },
    },
    {
      services: {
        executeCodeMutation: async ctx =>
          ExecuteCodeMutation.makeMutation({
            queryClient: ctx.queryClient,
            params: {
              templateRevisionId: ctx.codeTask.templateRevision.id,
              code: ctx.codeAction?.config.code ?? '',
              inputData: CodeTaskUtils.getObjectFromMappings(ctx.testInputMapping),
            },
          }).execute(),

        updateNativeAutomation: async ctx => {
          if (!ctx.nativeAutomation || !ctx.codeAction || !ctx.updateFormFieldsAction) {
            return Promise.reject('Assumptions failed');
          }

          const { actions } = ctx.nativeAutomation;

          const newActions: NativeAutomation.Action[] = actions.map(action =>
            match(action.actionType)
              .with('ExecuteCode', () => ctx.codeAction!)
              .with('UpdateFormFields', () => ctx.updateFormFieldsAction!)
              .otherwise(() => action),
          );

          return UpdateNativeAutomationActionsMutation.makeMutation({
            queryClient: ctx.queryClient,
            params: {
              nativeAutomationId: ctx.nativeAutomation.id,
              actions: newActions,
            },
            onSuccess: data => {
              queryClient.setQueryData<GetAllNativeAutomationsQuery.Response>(
                GetAllNativeAutomationsQuery.getKey({ templateRevisionId: ctx.codeTask.templateRevision.id }),
                GetAllNativeAutomationsQuery.taskTemplateAutomationUpdater(ctx.codeTask.id, data),
              );
            },
          }).execute();
        },
      },
      actions: {
        logError: makeErrorLoggerAction(id),
        assignNativeAutomationsQuery: assign({
          nativeAutomationsQuery: (ctx: Context) =>
            spawn(
              makeQueryMachine({
                observer: GetAllNativeAutomationsQuery.makeQueryObserver({
                  queryClient: ctx.queryClient,
                  options: {
                    ...RefetchOnMountOnly,
                  },
                  templateRevisionId: ctx.codeTask.templateRevision.id,
                }),
              }),
              { sync: true, name: 'native-automations-query' },
            ),
        }),
        // @ts-expect-error -- TODO
        assignConsolidatedTemplatePermissionsQuery: assign({
          consolidatedTemplatePermissionsQuery: (ctx: Context) =>
            spawn(
              makeQueryMachine({
                observer: makeGetConsolidatedTemplatePermissionsQueryObserver({
                  queryClient: ctx.queryClient,
                  templateId: ctx.templateId,
                  options: {
                    ...RefetchOnMountOnly,
                  },
                }),
              }),
              { sync: true, name: 'consolidated-template-permissions-query' },
            ),
        }),

        assignActions: assign((ctx: Context) => {
          const automations = QueryActorSelectors.getQueryData(ctx.nativeAutomationsQuery) ?? [];
          const [nativeAutomation] =
            GetAllNativeAutomationsQuery.makeTaskTemplateAutomationMap(automations)[ctx.codeTask.id];
          const { actions } = nativeAutomation;
          const codeAction = CodeTaskUtils.findActionByType<ExecuteCodeAction>(actions, 'ExecuteCode');
          const updateFormFieldsAction = CodeTaskUtils.findActionByType<UpdateFormFieldsAction>(
            actions,
            'UpdateFormFields',
          );

          return {
            nativeAutomation,
            codeAction,
            updateFormFieldsAction,
            inputMapping: CodeTaskUtils.getInputMappingsFromCodeAction(codeAction),
            outputMapping: CodeTaskUtils.getOutputMappingsFromUpdateFormFieldsAction(updateFormFieldsAction),
          };
        }),
        assignPermissions: assign((ctx: Context) => {
          const result = QueryActorSelectors.getQueryData(ctx.consolidatedTemplatePermissionsQuery);
          const canEditTemplate = result?.permissionMap.templateUpdate;
          return { canEditTemplate };
        }),
        setTestMode: assign(ctx => ({
          testInputMapping: CodeTaskUtils.getTestInputData(ctx.inputMapping, ctx.testInputMapping),
          testOutputMapping: CodeTaskUtils.getTestOutputData(ctx.outputMapping),
        })),
        assignTestInputMapping: assign((_ctx, event) => ({
          testInputMapping: event.value,
        })),
        assignInputMapping: assign((ctx, event) => ({
          inputMapping: event.value,
          codeAction: ctx.codeAction
            ? {
                ...ctx.codeAction,
                config: {
                  ...ctx.codeAction?.config,
                  inputData: CodeTaskUtils.getObjectFromMappings(event.value),
                },
              }
            : undefined,
        })),
        assignOutputMapping: assign((ctx, event) => ({
          outputMapping: event.value,
          updateFormFieldsAction: ctx.updateFormFieldsAction
            ? {
                ...ctx.updateFormFieldsAction,
                config: {
                  ...ctx.updateFormFieldsAction?.config,
                  mapping: CodeTaskUtils.getObjectFromMappings(event.value),
                },
              }
            : undefined,
        })),
        assignCode: assign((ctx, event) => ({
          codeAction: ctx.codeAction
            ? {
                ...ctx.codeAction,
                config: {
                  ...ctx.codeAction?.config,
                  code: event.value,
                },
              }
            : undefined,
        })),
      },
      guards: {
        isNativeAutomationReady: (ctx: Context) =>
          QueryActorSelectors.getQueryData(ctx.nativeAutomationsQuery) !== undefined,
        isConsolidatedTemplatePermissionsQueryReady: (ctx: Context) =>
          QueryActorSelectors.getQueryData(ctx.consolidatedTemplatePermissionsQuery) !== undefined,
        canEditTemplate: (ctx: Context) => !!ctx.canEditTemplate,
        cannotEditTemplate: (ctx: Context) => !ctx.canEditTemplate,
      },
      delays: {
        DEBOUNCE_DELAY: 500,
      },
    },
  );
};

export type CodeTaskMachine = ReturnType<typeof makeCodeTaskMachine>;
export type CodeTaskActor = ActorRefFrom<CodeTaskMachine>;
