import { Box, HStack, IconButton, Portal, Spacer, Text, useBreakpointValue, VStack } from '@chakra-ui/react';
import { Checklist } from '@process-street/subgrade/process';
import { DateFormat } from '@process-street/subgrade/util';
import { Button, Icon, useToast } from 'app/components/design/next';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { DefaultErrorMessages } from 'app/components/utils/error-messages';
import { GetChecklistQuery, UpdateChecklistDueDateMutation } from 'app/features/checklists/query-builder';
import { DatePicker } from 'app/features/conditional-logic/components/date-picker';
import { useTimezone } from 'app/hooks/use-timezone';
import { createRQObjectCacheUtils } from 'app/utils/react-query-cache-utils';
import dayjs from 'dayjs';
import { isFunction } from 'lodash';
import * as React from 'react';
import { useQueryClient } from 'react-query';

type DatePickerPopoverProps = React.PropsWithChildren<{
  checklist: Checklist;
}>;

const DatePickerPopover = ({ checklist, children }: DatePickerPopoverProps) => {
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' });
  const timeZone = useTimezone({ fallback: 'UTC' });
  const queryClient = useQueryClient();

  const updateChecklistDueDateMutation = UpdateChecklistDueDateMutation.useMutation({
    onMutate: variables => {
      const cacheUtils = createRQObjectCacheUtils<Checklist>(queryClient);
      const checklistQueryKey = GetChecklistQuery.getKey({ checklistId: variables.id });
      const oldData = cacheUtils.get(checklistQueryKey);

      const rollback = () => cacheUtils.set(checklistQueryKey, oldData);

      cacheUtils.update(checklistQueryKey, data =>
        data
          ? {
              ...data,
              dueDate: variables.dueDate ?? undefined,
            }
          : undefined,
      );
      return rollback;
    },
    onError: (error, _variables, rollback) => {
      console.error(error);

      if (isFunction(rollback)) {
        rollback();
      }

      toast({
        status: 'error',
        title: 'Failed to update due date',
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const handleChange = (value: number | null) => {
    updateChecklistDueDateMutation.mutate({
      dueDate: value,
      id: checklist.id,
    });
  };

  const renderTrigger = () => <Box>{children}</Box>;

  return (
    <DatePicker
      title={
        <Text fontWeight="bold" px={2} alignSelf="flex-start">
          Due date
        </Text>
      }
      onChange={handleChange}
      value={checklist.dueDate}
      timeZone={timeZone}
      renderTrigger={renderTrigger}
      renderContent={children => (
        <ThemeProvider2024>
          <Box
            sx={{
              '.rdp-day': {
                fontSize: 'sm',
              },
            }}
          >
            {children}
          </Box>
        </ThemeProvider2024>
      )}
      PopoverContentWrapper={({ children }) => {
        return isMobile ? (
          <Portal>
            <Box
              sx={{
                '.chakra-popover__popper': {
                  transform: { base: 'unset !important' },
                  position: { base: 'fixed !important' },
                  top: { base: '0 !important' },
                  left: { base: '0 !important' },
                  display: { base: 'flex' },
                  alignItems: { base: 'center' },
                  justifyContent: { base: 'center' },
                  width: { base: '100vw' },
                  height: { base: '100vh' },
                  backgroundColor: { base: 'blackAlpha.200' },
                },
              }}
            >
              {children}
            </Box>
          </Portal>
        ) : (
          <Portal>{children}</Portal>
        );
      }}
      pickerProps={{
        disabled: [
          {
            before: dayjs().toDate(),
          },
        ],
        styles: {
          cell: {
            fontSize: 'var(--ps-fontSizes-sm)',
          },
        },
      }}
      size="md"
    />
  );
};

export type RightSidebarDueDateProps = {
  checklist: Checklist;
  isReadOnly?: boolean;
};

export const RightSidebarDueDate = ({ checklist, isReadOnly }: RightSidebarDueDateProps) => {
  const timeZone = useTimezone({ fallback: 'UTC' });

  const formattedDate = checklist.dueDate
    ? dayjs(checklist.dueDate).tz(timeZone).format(DateFormat.DateTimeMergeTagLong)
    : null;

  if (!formattedDate) {
    return (
      <HStack w="full" alignItems="center" spacing={0}>
        <VStack w="full" alignItems="flex-start" spacing={0}>
          <Text color="gray.400" variant="-1u">
            Due Date
          </Text>

          {isReadOnly && (
            <Text fontWeight="500" color="gray.400">
              No due date set
            </Text>
          )}
        </VStack>

        <Spacer />

        {!isReadOnly && (
          <DatePickerPopover checklist={checklist}>
            <IconButton
              variant="solid"
              colorScheme="gray"
              size="sm"
              aria-label="Due date"
              icon={<Icon color="gray.500" icon="calendar-days" size="3" />}
            />
          </DatePickerPopover>
        )}
      </HStack>
    );
  }

  return (
    <VStack w="full" alignItems="flex-start">
      <Text color="gray.400" variant="-1u">
        Due Date
      </Text>

      {isReadOnly ? (
        <Text fontWeight="500" color="gray.400">
          {formattedDate}
        </Text>
      ) : (
        <DatePickerPopover checklist={checklist}>
          <Button variant="link" colorScheme="gray" fontWeight="500" color="gray.400">
            {formattedDate}
          </Button>
        </DatePickerPopover>
      )}
    </VStack>
  );
};
