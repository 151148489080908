import { StateFrom } from 'xstate';
import { TaskMachine } from './task-machine';
import { FieldType, isApproval, MultiSelectItemValueStatus } from '@process-street/subgrade/process';
import { match, P } from 'ts-pattern';
import { FormFieldWidgetActorLookup } from '../form-fields/form-field-machine';

type State = StateFrom<TaskMachine>;

export namespace TaskMachineSelectors {
  export const getIsCompleted = (state: State) => state.matches('task.complete');

  export const getWidgetActors = (state: State) => Object.values(state.context.widgetActorsMap);

  export const getWidgetActorsMap = (state: State) => state.context.widgetActorsMap;

  export const getTask = (state: State) => state.context.task;

  export const getRulesEngineTargetActor = (state: State) => state.context.rulesEngineTargetActor;

  export const getApprovals = (state: State) => state.context.approvals;

  export const getInvalidWidgetCount = (state: State) => Object.values(state.context.invalidWidgetMap).length;

  export const getIsApproval = (state: State) => isApproval(state.context.task.taskTemplate);

  export const shouldShowErrors = (state: State) => state.matches('validation.invalid.visible');

  export const getTaskSubtasksCompletionPercentage = (state: State) => {
    const widgetActors = getWidgetActors(state);

    const counts = widgetActors.reduce(
      (acc, widgetActor) => {
        const widget = widgetActor.getSnapshot()?.context.widget;
        if (!widget) return acc;

        return match({ widget, actor: widgetActor })
          .with({ widget: { fieldType: FieldType.MultiSelect }, actor: P.not(undefined) }, ({ widget: w, actor }) => {
            const subtasksActor = actor as FormFieldWidgetActorLookup<typeof w>;

            const context = subtasksActor.getSnapshot()?.context;
            if (!context) return acc;

            const isHidden = context.rulesEngineTargetActor.getSnapshot()?.matches('hidden');

            if (isHidden) return acc;

            const itemValues = context.value?.itemValues ?? [];

            const completedCount = itemValues.filter(
              item => item.status === MultiSelectItemValueStatus.Completed,
            ).length;

            const totalCount = w.config.items.length;

            return {
              completed: acc.completed + completedCount,
              total: acc.total + totalCount,
            };
          })
          .otherwise(() => acc);
      },
      { completed: 0, total: 0 },
    );

    if (counts.total === 0) return undefined;

    return (counts.completed / counts.total) * 100;
  };
}
