import { useWidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import React from 'react';
import { ExecuteAiPrompt, getFormFieldWidgetLabel, TemplateRevision } from '@process-street/subgrade/process';
import groupBy from 'lodash/groupBy';
import { indexOf } from 'lodash';
import { useTaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';

export const useCodeEditorWidgetOptions = ({ templateRevisionId }: { templateRevisionId: TemplateRevision['id'] }) => {
  const widgetsQuery = useWidgetsByTemplateRevisionIdQuery(templateRevisionId);
  const widgets = React.useMemo(() => widgetsQuery.data ?? [], [widgetsQuery.data]);
  const formFieldWidgets = React.useMemo(() => widgets.filter(ExecuteAiPrompt.isOutputWidget), [widgets]);
  const taskTemplatesQuery = useTaskTemplatesByTemplateRevisionIdQuery({ templateRevisionId });
  const taskTemplates = React.useMemo(() => taskTemplatesQuery.data ?? [], [taskTemplatesQuery.data]);

  const taskWidgetOptions = React.useMemo(() => {
    const widgetsByTaskTemplateId = groupBy(formFieldWidgets, widget => widget.header.taskTemplate.id);
    return taskTemplates.map(taskTemplate => ({
      label: `${indexOf(taskTemplates, taskTemplate) + 1}: ${taskTemplate.name ?? 'Untitled'}`,
      options: (widgetsByTaskTemplateId[taskTemplate.id] ?? []).map(w => ({
        label: getFormFieldWidgetLabel(w),
        value: w.id,
        fieldType: w.fieldType,
      })),
    }));
  }, [formFieldWidgets, taskTemplates]);

  return {
    taskWidgetOptions,
  };
};
