import * as React from 'react';
import { Box, VStack, Text, BoxProps } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Button, Icon } from 'app/components/design/next';
import { RightSidebarButton } from './right-sidebar-button';
import { FormResponseMachineHooks } from 'app/pages/responses/_id/components/form-response-body/form-response-machine-hooks';
import { FormResponsePageProviders } from '../../providers';
import { useNavigate } from '@process-street/adapters/navigation';
import { queryString } from '@process-street/subgrade/util';
import { TaskMachineHooks } from 'app/pages/responses/_id/components/task/task-machine-hooks';
import { RightSidebarMenuItems } from './menu-items';
import { match } from 'ts-pattern';
import { RightSidebarAssignments } from './right-sidebar-assignments';
import { RightSidebarDueDate } from './right-sidebar-due-date';
import { RightSidebarShareLink } from './right-sidebar-share-link';

const MotionBox = motion<BoxProps>(Box);

const DRAWER_WIDTH = 232;

export const RightSidebarContent = () => {
  const actorRef = FormResponsePageProviders.FormResponseActorRef.useActorRef();
  const machineApi = FormResponseMachineHooks.useApi(actorRef);
  const isChecklistComplete = FormResponseMachineHooks.useIsComplete();
  const isCompletingRun = FormResponseMachineHooks.useIsCompletingRun();
  const isActivatingRun = FormResponseMachineHooks.useIsActivatingRun();
  const template = FormResponseMachineHooks.useTemplate();
  const templateRevision = FormResponseMachineHooks.useTemplateRevision();
  const currentTaskActorRef = FormResponseMachineHooks.useCurrentTaskActorRef();
  const currentTask = TaskMachineHooks.useTask(currentTaskActorRef);
  const checklist = FormResponseMachineHooks.useChecklist();
  const isMigrating = FormResponseMachineHooks.useIsMigrating();
  const isArchived = FormResponseMachineHooks.useIsArchived();
  const isArchiving = FormResponseMachineHooks.useIsArchiving();
  const isUnarchiving = FormResponseMachineHooks.useIsUnarchiving();

  const navigate = useNavigate();

  const handleCompleteRun = () => {
    machineApi.onCompleteRun();
  };

  const handleActivateRun = () => {
    machineApi.onActivateRun();
  };

  const handleViewWorkflowRuns = () => {
    navigate({
      pathname: 'reports',
      search: queryString.stringify({
        templateId: template.id,
      }),
    });
  };

  const handleActivityClick = () => {
    navigate({
      pathname: 'checklistManage.tab',
      search: queryString.stringify({
        id: checklist.id,
        tab: 'activity',
      }),
    });
  };

  const handlePrintClick = () => {
    navigate({
      pathname: 'checklistPrintNew',
      search: queryString.stringify({
        id: checklist.id,
      }),
    });
  };

  return (
    <MotionBox
      position="fixed"
      bg="white"
      h="full"
      borderLeft="1px"
      borderColor="gray.200"
      w={`${DRAWER_WIDTH}px`}
      initial={{ x: 0 }}
      animate={{ x: -DRAWER_WIDTH }}
      exit={{ x: 0 }}
    >
      <VStack height="full" w="full" p="4" spacing={4}>
        {match({ isArchived, isChecklistComplete, isMigrating })
          .with({ isArchived: true }, () => (
            <>
              <VStack w="full" spacing={2}>
                <RightSidebarMenuItems.ArchiveRunButton
                  checklist={checklist}
                  isArchiving={isArchiving}
                  isUnarchiving={isUnarchiving}
                  isArchived={isArchived}
                  onArchive={machineApi.onArchiveRun}
                  onUnarchive={machineApi.onActivateRun}
                />

                <VStack w="full" spacing="2" alignItems="stretch">
                  <Text color="gray.400" variant="-1u">
                    Options
                  </Text>
                  <VStack w="full" spacing={0} alignItems="stretch">
                    <RightSidebarMenuItems.DeleteRunButton checklist={checklist} />

                    <RightSidebarButton iconName="print" onClick={handlePrintClick}>
                      Print
                    </RightSidebarButton>
                  </VStack>
                </VStack>
              </VStack>

              <VStack w="full" spacing={4}>
                <RightSidebarAssignments checklist={checklist} isReadOnly />
                <RightSidebarDueDate checklist={checklist} isReadOnly />
                <RightSidebarShareLink checklist={checklist} />
              </VStack>
            </>
          ))
          .with({ isChecklistComplete: true }, () => (
            <>
              <VStack w="full" spacing={0}>
                <Button
                  justifyContent="flex-start"
                  iconSpacing={2}
                  leftIcon={<Icon size="4" icon="undo" />}
                  w="full"
                  onClick={handleActivateRun}
                  isLoading={isActivatingRun}
                  isDisabled={isActivatingRun}
                  loadingText="Reactivating"
                >
                  Reactivate
                </Button>
              </VStack>

              <VStack w="full" spacing={0}>
                <RightSidebarMenuItems.CommentsButton checklist={checklist} />
              </VStack>

              <VStack w="full" spacing={4}>
                <RightSidebarAssignments checklist={checklist} isReadOnly />
                <RightSidebarDueDate checklist={checklist} isReadOnly />
                <RightSidebarShareLink checklist={checklist} />
              </VStack>
            </>
          ))
          .with({ isMigrating: true }, () => (
            <>
              <VStack w="full" spacing="2" alignItems="stretch">
                <Text color="gray.400" variant="-1u">
                  Options
                </Text>

                <VStack w="full" spacing={0}>
                  <RightSidebarMenuItems.MigrateRunButton checklist={checklist} />
                </VStack>

                <VStack spacing={4}>
                  <RightSidebarAssignments checklist={checklist} />
                  <RightSidebarDueDate checklist={checklist} />
                  <RightSidebarShareLink checklist={checklist} />
                </VStack>
              </VStack>
            </>
          ))
          .otherwise(() => (
            <>
              <Button
                justifyContent="flex-start"
                iconSpacing={2}
                leftIcon={<Icon size="4" icon="check" />}
                w="full"
                onClick={handleCompleteRun}
                isLoading={isCompletingRun}
                isDisabled={isCompletingRun}
                loadingText="Completing run"
              >
                Complete Run
              </Button>

              <VStack w="full" spacing="2" alignItems="stretch">
                <Text color="gray.400" variant="-1u">
                  Options
                </Text>

                <VStack w="full" spacing={0}>
                  <RightSidebarMenuItems.MigrateRunButton checklist={checklist} />
                  <RightSidebarMenuItems.EditWorkflowButton template={template} currentTask={currentTask} />
                  <RightSidebarButton iconName="th-list" onClick={handleViewWorkflowRuns}>
                    View Workflow Runs
                  </RightSidebarButton>
                  <RightSidebarButton iconName="history" onClick={handleActivityClick}>
                    Activity
                  </RightSidebarButton>
                  <RightSidebarMenuItems.CommentsButton checklist={checklist} />
                  <RightSidebarMenuItems.SubscribeButton checklist={checklist} />
                  <RightSidebarButton iconName="print" onClick={handlePrintClick}>
                    Print
                  </RightSidebarButton>
                  <RightSidebarMenuItems.PrintConditionalLogicButton templateRevision={templateRevision} />
                  <RightSidebarMenuItems.ArchiveRunButton
                    checklist={checklist}
                    isArchiving={isArchiving}
                    isUnarchiving={isUnarchiving}
                    isArchived={isArchived}
                    onArchive={machineApi.onArchiveRun}
                    onUnarchive={machineApi.onActivateRun}
                  />
                  <RightSidebarMenuItems.DeleteRunButton checklist={checklist} />
                </VStack>

                <VStack spacing={4}>
                  <RightSidebarAssignments checklist={checklist} />
                  <RightSidebarDueDate checklist={checklist} />
                  <RightSidebarShareLink checklist={checklist} />
                </VStack>
              </VStack>
            </>
          ))}
      </VStack>
    </MotionBox>
  );
};
