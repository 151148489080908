import { env } from 'components/common/env';
import { Angular as AngularIntegration, Dedupe as DedupeIntegration } from '@sentry/integrations';
import { BrowserOptions, Event } from '@sentry/browser';

const ignoredErrors = [
  'Non-Error promise rejection captured',
  /Possibly unhandled rejection:.*/,
  // Wistia error
  "Cannot read properties of null (reading 'duration')",
  /Failed to execute '(replaceState|pushState)'.*(file:\/\/|localhost)/,
  'Invalid time zone specified: Etc/Unknown',
  // Auth0 token renewal fails because of expired token
  /Login required/,
  // Not helpful and eating up a lot of events
  'Request failed with status code 401',
  'Request failed with status code 403',
  'Request failed with status code 404',
  // This is normal, we don't need to log it, eating up 150K of the monthly quota
  'No widget found for id',
];

/** true: keep, false: discard */
type BeforeSend = (event: Event) => boolean;

const discardEventInDev: BeforeSend = event => {
  if (env.APP_STAGE === 'dev') {
    console.warn('The following error would be sent to Sentry:', event);
    return false;
  } else {
    return true;
  }
};

const discardNewSignupUserInfo401: BeforeSend = event => {
  const is401 = event.exception?.values?.[0].value === 'Request failed with status code 401';
  const isSignupScreen = event.request?.url?.includes('/login');
  const discardEvent = is401 && isSignupScreen;
  return !discardEvent;
};

const sentryBeforeSend = [discardNewSignupUserInfo401, discardEventInDev];

const BUILD_NUM = process.env.CIRCLE_BUILD_NUM || '42';

export const sentryConfig = {
  dsn: env.SENTRY_DSN,
  environment: env.APP_STAGE,
  release: `${env.APP_STAGE}-build-${BUILD_NUM}`,
  integrations: [new DedupeIntegration()],
  ignoreErrors: ignoredErrors,
  beforeSend: event => (sentryBeforeSend.every(v => v(event)) ? event : null),
} satisfies BrowserOptions;

export const sentryConfigAngular = {
  ...sentryConfig,
  integrations: [new AngularIntegration(), ...sentryConfig.integrations],
};
