import { Box, BoxProps, Grid, GridItem, Text, VStack } from '@chakra-ui/react';
import { useInterpret, useSelector } from '@xstate/react';
import { useStateParam } from 'app/hooks/use-state-param';
import { TOP_BAR_HEIGHT_CSS_VAR, TOP_BAR_HEIGHT_TOKEN } from 'app/pages/forms/_id/shared';
import { makeFormResponsePageMachine } from 'app/pages/responses/_id/form-response-page-machine';
import { useFormResponseSharedContext } from 'app/pages/responses/_id/hooks/use-form-response-shared-context';
import * as React from 'react';
import { match } from 'ts-pattern';
import { TaskSection } from './components/task-section/task-section';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { FormResponsePageProviders as Providers } from './providers';
import { FormResponsePageMachineSelectors } from 'app/pages/responses/_id/form-response-page-selectors';
import { useChecklistViewAnonymousAuth } from './hooks/use-checklist-view-anonymous-auth';
import { TaskListSection } from './components/task-list-section';
import { ChecklistTopBar } from './components/checklist-top-bar';
import { Confetti } from './components/confetti';
import { BackgroundImage } from 'app/pages/workflows/_id/edit-v2/components/background-image/background-image';
import { useListenToWorkflowRunUpdated } from 'app/pages/inbox/hooks/use-listen-to-checklist-run-update';
import { ChecklistUtils } from '@process-street/subgrade/process';
import { FormResponseMachineHooks } from 'app/pages/responses/_id/components/form-response-body/form-response-machine-hooks';
import { RightSidebar } from './components/right-sidebar';
import { useNotifyPendingMigration } from './hooks/use-notify-pending-migration';
import { MigrateWorkflowModal } from './components/migrate-workflow-modal/migrate-workflow-modal';

const PROGRESS_BAR_HEIGHT_IN_PX = '5px';
const STATUS_BAR_HEIGHT_IN_PX = '64px';

export const ChecklistV2PageImpl = () => {
  const formResponsePageActor = Providers.FormResponsePageActorRef.useActorRef();
  const formResponseActor = Providers.FormResponseActorRef.useActorRef();

  const formStatus = useSelector(formResponsePageActor, FormResponsePageMachineSelectors.getValue);
  const currentTaskActor = FormResponseMachineHooks.useCurrentTaskActorRef();
  const checklist = FormResponseMachineHooks.useChecklist();
  const isChecklistComplete = FormResponseMachineHooks.useIsComplete();
  const hasChecklistStartedAsMigrating = FormResponseMachineHooks.useHasChecklistStartedAsMigrating();
  const templateId = checklist.template.id;

  useNotifyPendingMigration(checklist);

  React.useEffect(
    function updatePageTitle() {
      if (checklist.name) {
        document.title = `${checklist.name} | Process Street`;
      }
    },
    [checklist.name],
  );

  const checklistTopBarHeightInPx = React.useMemo(() => {
    if (checklist && (ChecklistUtils.isArchived(checklist) || ChecklistUtils.isCompleted(checklist))) {
      return STATUS_BAR_HEIGHT_IN_PX;
    } else {
      return PROGRESS_BAR_HEIGHT_IN_PX;
    }
  }, [checklist]);

  const heightProps: BoxProps = {
    height: `calc(100vh - ${TOP_BAR_HEIGHT_CSS_VAR} - ${checklistTopBarHeightInPx})`,
  };

  return (
    <>
      <MigrateWorkflowModal isDisabled={!hasChecklistStartedAsMigrating} checklist={checklist} />
      <Box bgColor="brand.50">
        {isChecklistComplete && <Confetti checklist={checklist} />}

        <BackgroundImage templateId={templateId} topOffset={TOP_BAR_HEIGHT_TOKEN} />
        <VStack w="full" h="full" alignItems="stretch" justifyContent="stretch" spacing={0} bgColor="brand.50">
          <ChecklistTopBar />

          <Grid
            gridTemplateColumns={{ base: '0 1fr 0', lg: '480px 1fr 0' }}
            position="relative"
            background="transparent"
            overflowX="hidden"
          >
            <GridItem>
              <TaskListSection {...heightProps} />
            </GridItem>

            <GridItem
              pt={{ base: 8, md: 32, lg: 10 }}
              px={{ base: 2, md: 0 }}
              overflowY="auto"
              h="full"
              position="relative"
              display="flex"
              flexDirection="column"
              w="full"
              {...heightProps}
            >
              {match(formStatus)
                .with('loading', () => <Text>Loading...</Text>)
                .with('ready', () =>
                  currentTaskActor ? (
                    <TaskSection currentTaskActor={currentTaskActor} formResponseActor={formResponseActor} />
                  ) : null,
                )
                .otherwise(() => null)}
            </GridItem>

            <GridItem>
              <RightSidebar />
            </GridItem>
          </Grid>
        </VStack>
      </Box>
    </>
  );
};

export const ChecklistV2Page = () => {
  const checklistId = useStateParam({ key: 'id' })!;
  const sharedContext = useFormResponseSharedContext();
  const formResponsePageActor = useInterpret(() =>
    makeFormResponsePageMachine({ formResponseId: checklistId, sharedContext }),
  );
  const formResponseActor = useSelector(formResponsePageActor, FormResponsePageMachineSelectors.getFormResponseActor);

  useChecklistViewAnonymousAuth();
  useListenToWorkflowRunUpdated(checklistId);

  return (
    <ThemeProvider2024>
      <Providers.FormResponsePageActorRef.Provider actor={formResponsePageActor}>
        <Providers.FormResponseActorRef.Provider actor={formResponseActor}>
          <ChecklistV2PageImpl />
        </Providers.FormResponseActorRef.Provider>
      </Providers.FormResponsePageActorRef.Provider>
    </ThemeProvider2024>
  );
};
