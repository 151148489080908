import { NativeAutomation, TaskTemplate } from '@process-street/subgrade/process';
import { match } from 'ts-pattern';
import { Icon, IconProps } from 'components/design/next';
import { useTimezone } from 'hooks/use-timezone';
import { DateFormat, DateUtils } from '@process-street/subgrade/util';
import { TableRowProps, Td, Tr } from '@chakra-ui/react';
import * as React from 'react';

interface LogItemProps extends TableRowProps {
  log: NativeAutomation.Log;
  taskTemplate: TaskTemplate;
}

// allows keeping role="row" on the row instead of role="group" that _groupHover requires
const ROW_HOVER_SELECTOR = '[role="row"]:hover &';

export const CodeTaskRunLogItem = ({ log, taskTemplate, ...props }: LogItemProps) => {
  const icon = match<NativeAutomation.Log['currentActionStatus'], Pick<IconProps, 'icon' | 'color' | 'aria-label'>>(
    log.currentActionStatus,
  )
    .with('Completed', () => ({
      'color': 'green.500',
      'icon': 'check',
      'aria-label': 'Completed',
    }))
    .with('Failed', () => ({
      'color': 'red.500',
      'icon': 'times',
      'aria-label': 'Failed',
    }))
    .otherwise(() => ({
      'color': 'gray.400',
      'icon': 'sync',
      'aria-label': 'Running',
    }));

  const timeZone = useTimezone();
  const eventDate = DateUtils.formatDateTime(log.createdAt, DateFormat.DateTimeShortMonthWithComma, timeZone);
  const eventDateTooltip = DateUtils.formatDateTime(log.createdAt, DateFormat.DateTimeShortMonth, timeZone);
  const taskName = taskTemplate.name?.trim() === 'Code Task:' ? 'Code Task' : taskTemplate.name;

  return (
    <Tr role="row" h={8} cursor="default" {...props}>
      <Td
        title={icon['aria-label']}
        sx={{
          [ROW_HOVER_SELECTOR]: {
            backgroundColor: 'gray.100',
            borderTopLeftRadius: 'base',
            borderBottomLeftRadius: 'base',
          },
        }}
      >
        <Icon {...icon} variant="far" size="4" mb="2px" />
      </Td>
      <Td
        sx={{
          [ROW_HOVER_SELECTOR]: {
            backgroundColor: 'gray.100',
          },
        }}
        fontSize="md"
        title={taskName}
        maxW={40}
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {taskName}
      </Td>
      <Td
        sx={{
          [ROW_HOVER_SELECTOR]: {
            backgroundColor: 'gray.100',
            borderTopRightRadius: 'base',
            borderBottomRightRadius: 'base',
          },
        }}
        fontSize="sm"
        title={eventDateTooltip}
        textAlign="right"
      >
        {eventDate}
      </Td>
    </Tr>
  );
};
