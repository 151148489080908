import { Invitation, Muid, OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace CreateInvitationMutation {
  export type Params = {
    email: string;
    resource: { type: string; id: Muid };
  };

  export type Response = {
    invitation: Invitation;
    organizationMembership: OrganizationMembershipWithUser;
  };

  export const key = ['post', 'invitations'];
  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .post<Response>(`/1/invitations`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(CreateInvitationMutation.mutationFn, { mutationKey: key, ...options });
  };
}
