import * as React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonProps,
  forwardRef,
  HStack,
  Icon,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useBoolean,
  useDisclosure,
  VStack,
} from 'components/design/next';
import {
  ChecklistRevision,
  EmailFormat,
  FormFieldValue,
  RichEmailWidgetAttachmentWithS3File,
  SendRichEmailFieldValue,
  SendRichEmailFormFieldConfig,
  SendRichEmailFormFieldValue,
  SendRichEmailFormFieldWidget,
  Task,
  TemplateRevision,
} from '@process-street/subgrade/process';
import {
  getEmailBody,
  isSendEmailDisabled,
  normalizeBodyToHtml,
} from 'features/widgets/components/send-rich-email/util';
import {
  EmailMetaData,
  EmailMetaDataRowKey,
  EmailMetaDataRowValue,
  sanitizeEmailBody,
  sanitizeEmailRecipientsField,
  sanitizeEmailSubject,
} from 'features/widgets/components/send-rich-email/common/common';
import { SendRichEmailTimestamp } from 'features/widgets/components/send-rich-email-timestamp';
import { useParseChecklistMergeTagsByTarget } from 'features/widgets/components/send-rich-email/checklist/use-parse-checklist-merge-tags-by-target';
import { MergeTagMode, MergeTagTarget } from '@process-street/subgrade/form';
import { padTrailingBr, preserveEmptyParagraphsWithNbsp, safeEscapeHtml } from '@process-street/subgrade/util';
import { useAreFormFieldsUpdating } from 'features/widgets/components/send-rich-email/checklist/use-are-form-fields-updating';
import { isValidEmail } from '@process-street/subgrade/process/validation';
import { EmailFieldValues } from 'features/widgets/components/send-rich-email/common/email-fields';
import { SendTestMailtoButton } from 'features/widgets/components/send-rich-email/template/send-test-mailto-button';
import { EmailAttachmentItem } from 'features/widgets/components/send-rich-email/email_attachment/email-attachment-item';
import { useGetIsPaidAccount } from 'utils/plans/get-is-paid-account/use-get-is-paid-account';
import { PaidPlanRequiredPopover } from 'features/ui/popovers/paid-plan-required/paid-plan-required-popover';
import { WidgetProvider } from 'pages/runs/_id/hooks/use-widget-context';
import { useMatch } from '@process-street/adapters/navigation';
import { EMAIL_SENDING_PREVENT_RAPID_FIRE_TIMEOUT } from 'features/widgets/components/send-rich-email/checklist/component';
import { SendRichEmailChecklistEditor } from './send-rich-email-checklist-editor';
import { noop } from 'lodash/fp';
import { SendEmailWidgetActor } from 'app/pages/responses/_id/components/form-fields/send-email-widget';
import { SendEmailHooks } from 'app/pages/responses/_id/components/form-fields/send-email-widget/send-email-hooks';
import { FormResponseMachineHooks } from 'app/pages/responses/_id/components/form-response-body/form-response-machine-hooks';
import { useOutputWidgetAutomatedTasksMap } from 'app/features/native-automations/hooks/use-output-widget-automated-tasks-map';
import { useAutomatedTaskOutputWidgetStatus } from 'app/pages/runs/_id/hooks/use-automated-task-output-widget-status';
import { AutomatedTaskAudit } from 'app/pages/runs/_id/components/automated-task-audit';

export type SendRichEmailChecklistWidgetProps = {
  actor: SendEmailWidgetActor;
  checklistRevisionId: ChecklistRevision['id'];
  formFieldValue?: SendRichEmailFormFieldValue;
  onCancel?: () => void;
  onEditModeEnabled?: () => void;
  readOnly: boolean;
  taskId: Task['id'];
  templateRevisionId: TemplateRevision['id'];
  value?: SendRichEmailFieldValue;
  widget: SendRichEmailFormFieldWidget;
};

export const SendRichEmailChecklistWidget = ({
  actor,
  checklistRevisionId,
  formFieldValue,
  onCancel = noop,
  onEditModeEnabled = noop,
  readOnly,
  taskId,
  templateRevisionId,
  value,
  widget,
}: SendRichEmailChecklistWidgetProps) => {
  const api = SendEmailHooks.useApi(actor);
  const shouldShowUnsentWarning = SendEmailHooks.useShouldShowUnsentWarning(actor);
  const sendEmailErrorMessage = SendEmailHooks.useSendEmailErrorMessage(actor);

  const { status: automationStatus } = useAutomatedTaskOutputWidgetStatus(widget.id);
  const outputWidgetAutomatedTasksMap = useOutputWidgetAutomatedTasksMap({ templateRevisionId });
  const outputFromAutomatedTasks = React.useMemo(() => {
    return [...(outputWidgetAutomatedTasksMap[widget.id] ?? [])].reverse();
  }, [outputWidgetAutomatedTasksMap, widget.id]);

  const [isEditMode, setEditMode] = useBoolean();

  const handleEditClick = () => {
    onEditModeEnabled();
    setEditMode.on();
  };

  const handleSave =
    (isCreatingFormFieldValue: boolean) =>
    async (fieldValues: EmailFieldValues, body: string, attachments: RichEmailWidgetAttachmentWithS3File[]) => {
      !isCreatingFormFieldValue && setEditMode.off();
      const result = await api.onChange({
        ...value,
        body,
        to: fieldValues.to,
        cc: fieldValues.cc,
        bcc: fieldValues.bcc,
        subject: fieldValues.subject,
        attachments,
      });
      return result;
    };

  const onSave = handleSave(false);

  const onCreateFormFieldValue = (
    fieldValues: EmailFieldValues,
    body: string,
    attachments: RichEmailWidgetAttachmentWithS3File[],
  ): FormFieldValue => {
    handleSave(true)(fieldValues, body, attachments);
    return value as FormFieldValue;
  };

  const parseEmail = useParseChecklistMergeTagsByTarget({
    checklistRevisionId,
    taskId,
    mergeTagTarget: MergeTagTarget.EMAIL,
  });
  const parseGeneral = useParseChecklistMergeTagsByTarget({
    checklistRevisionId,
    taskId,
    mergeTagTarget: MergeTagTarget.RICH_CONTENT,
  });

  const parseAndSanitizeEmails = (emails?: SendRichEmailFormFieldConfig['to' | 'cc' | 'bcc']) =>
    parseEmail({
      content: sanitizeEmailRecipientsField(safeEscapeHtml(emails?.join(',') ?? '')),
      mode: MergeTagMode.HTML,
    });

  const parseAndSanitizeRawStringRecipients = (emails?: SendRichEmailFormFieldConfig['to' | 'cc' | 'bcc']) =>
    parseEmail({
      content: sanitizeEmailRecipientsField(safeEscapeHtml(emails?.join(',') ?? '')),
      mode: MergeTagMode.PLAINTEXT,
    });

  const parseAndSanitizeSubject = (subject?: SendRichEmailFormFieldConfig['subject']) =>
    parseGeneral({ content: sanitizeEmailSubject(safeEscapeHtml(subject ?? '')), mode: MergeTagMode.HTML });

  const parseAndSanitizePlainTextSubject = (subject?: SendRichEmailFormFieldConfig['subject']) =>
    parseGeneral({ content: subject ?? '', mode: MergeTagMode.PLAINTEXT });

  const parseAndSanitizeBody = React.useCallback(
    (body?: SendRichEmailFormFieldConfig['richEditorBody' | 'rawHTMLBody' | 'plainTextBody']) =>
      preserveEmptyParagraphsWithNbsp(
        padTrailingBr(
          normalizeBodyToHtml(
            parseGeneral({
              content: sanitizeEmailBody(body ?? ''),
              mode: MergeTagMode.HTML, // This is always HTML because even in plaintext mode it's normalized to HTML
            }),
            widget.config.emailFormat,
          ),
        ),
      ),
    [parseGeneral, widget.config.emailFormat],
  );

  const parseAndSanitizePlainTextBody = (body?: SendRichEmailFormFieldConfig['plainTextBody']) =>
    padTrailingBr(parseGeneral({ content: body ?? '', mode: MergeTagMode.PLAINTEXT }));

  const richEmailAutomation = FormResponseMachineHooks.useNativeAutomationsByFormFieldWidget(widget.id);

  const isAutoSendEnabled = richEmailAutomation && richEmailAutomation[0].status === 'Active'; // index 0 is the automations, 1 is the link.

  const emailWasSent = Boolean(value?.lastSentDate);
  const editButtonVisible = widget.config.emailFormat === EmailFormat.RichTextOrHtml && widget.config.editAllowed;

  const currentUser = FormResponseMachineHooks.useCurrentUser();
  const handleSendPlainText = () => {
    if (currentUser) {
      api.onSendPlainText({ ...value, lastSentByUserId: currentUser.id, lastSentDate: Date.now() });
    }
  };

  const [isWaitingForSendingCooldown, setIsWaitingForSendingCooldown] = React.useState(false);

  const [sendEmailWasClicked, setSendEmailWasClicked] = useBoolean();
  const areFormFieldValuesUpdating = useAreFormFieldsUpdating();
  const isWaitingToSend = sendEmailWasClicked && areFormFieldValuesUpdating; // TODO Add loading here&&  !sendEmailMutation.isLoading;
  const isReadyToSend = sendEmailWasClicked && !areFormFieldValuesUpdating && !isWaitingForSendingCooldown; // TODO Add loading here

  React.useEffect(
    function disableSendEmailButtonAfterClick() {
      if (isReadyToSend) {
        api.onSend(widget.id);
        setSendEmailWasClicked.off();
        setIsWaitingForSendingCooldown(true);
      }
    },
    [checklistRevisionId, isReadyToSend, widget, isWaitingForSendingCooldown, setSendEmailWasClicked, api],
  );

  React.useEffect(
    function enableSendEmailButtonAfterCooldown() {
      if (isWaitingForSendingCooldown) {
        const timeout = setTimeout(() => {
          setIsWaitingForSendingCooldown(false);
        }, EMAIL_SENDING_PREVENT_RAPID_FIRE_TIMEOUT);
        return () => clearTimeout(timeout);
      }
    },
    [isWaitingForSendingCooldown],
  );

  const to = value?.to ?? widget.config.to;
  const cc = value?.cc ?? widget.config.cc;
  const bcc = value?.bcc ?? widget.config.bcc;
  const subject = value?.subject ?? widget.config.subject;
  const body = value?.body ?? (widget.config ? getEmailBody(widget.config) : '');
  const templateAttachments = widget.config.attachments ?? [];
  const checklistAttachments = value?.attachments ?? [];
  const isEmailSendable = !isSendEmailDisabled({ to, subject, body }) || readOnly;

  const disclosure = useDisclosure();

  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const getInvalidRecipients = () => {
    const invalidRecipients = [to, cc, bcc]
      .map(parseAndSanitizeRawStringRecipients)
      .flatMap(emails => emails.split(/[,;]/))
      .map(email => email.trim())
      .filter(email => !isValidEmail(email));
    const unique = [...new Set(invalidRecipients)];
    return unique.filter(rec => rec);
  };
  const invalidRecipients = getInvalidRecipients();

  const isRichTextSendEnabled = useGetIsPaidAccount();

  const sendRichButton = React.useMemo(
    () => (
      <RequiredButton
        {...{
          variant: 'tertiary',
          onClick: () => {
            if (invalidRecipients.length === 0) {
              setSendEmailWasClicked.on();
            } else {
              disclosure.onToggle();
            }
          },
          leftIcon: isEmailSendable ? (
            <Icon icon="paper-plane" size="4" variant="far" />
          ) : (
            <Icon icon="exclamation-circle" size="4" variant="far" color="red.500" />
          ),
          iconSpacing: '2',
          isLoading: isWaitingToSend, // TODO Add loading here sendEmailMutation.isLoading,
          isDisabled: !isEmailSendable || isWaitingForSendingCooldown || !isRichTextSendEnabled || readOnly,
          loadingText: 'Sending...',
          isRequired: widget.required,
        }}
      >
        Send Email
      </RequiredButton>
    ),
    [
      disclosure,
      invalidRecipients,
      isEmailSendable,
      isRichTextSendEnabled,
      isWaitingForSendingCooldown,
      isWaitingToSend,
      readOnly,
      widget.required,
      setSendEmailWasClicked,
    ],
  );

  const sendRichButtonWithTooltip = React.useMemo(
    () =>
      isRichTextSendEnabled ? (
        <Tooltip
          label={
            !isEmailSendable
              ? "Emails must have a 'To' recipient and a subject or body"
              : 'You must wait a few seconds before trying to send this email again'
          }
          hasArrow
          shouldWrapChildren
          isDisabled={isEmailSendable && !isWaitingForSendingCooldown && isRichTextSendEnabled}
        >
          {sendRichButton}
        </Tooltip>
      ) : (
        <PaidPlanRequiredPopover needPaidFor={'performAction'}>{sendRichButton}</PaidPlanRequiredPopover>
      ),
    [isEmailSendable, isRichTextSendEnabled, sendRichButton, isWaitingForSendingCooldown],
  );

  const isPrintView =
    Boolean(useMatch('checklistPrintNew')) || Boolean(useMatch('checklistPrint')) || Boolean(useMatch('templatePrint'));

  const sanitizedEmailBody = React.useMemo(() => parseAndSanitizeBody(body), [body, parseAndSanitizeBody]);

  return (
    <WidgetProvider widget={widget}>
      <Stack spacing="4" backgroundColor={isEditMode ? 'inherit' : 'brand.50'} padding={6} fontSize="md">
        {isEditMode && (
          <SendRichEmailChecklistEditor
            actor={actor}
            templateRevisionId={templateRevisionId}
            taskId={taskId}
            widget={widget}
            formFieldValue={formFieldValue}
            onCancel={() => {
              onCancel();
              setEditMode.off();
            }}
            onSave={onSave}
            onCreateFormFieldValue={onCreateFormFieldValue}
            handleOnDeleteAttachment={api.onDeleteAttachment}
            fieldValue={value}
          />
        )}

        {!isEditMode && (
          <>
            <EmailMetaData
              mb="2"
              ccVisible={(cc?.length ?? 0) > 0}
              bccVisible={(bcc?.length ?? 0) > 0}
              sx={{ fontSize: 'md' }}
            >
              <EmailMetaDataRowKey row="to">To</EmailMetaDataRowKey>
              <EmailMetaDataRowValue row="to" dangerouslySetInnerHTML={{ __html: parseAndSanitizeEmails(to) }} />

              {(cc?.length ?? 0) > 0 && (
                <>
                  <EmailMetaDataRowKey row="cc">CC</EmailMetaDataRowKey>
                  <EmailMetaDataRowValue row="cc" dangerouslySetInnerHTML={{ __html: parseAndSanitizeEmails(cc) }} />
                </>
              )}

              {(bcc?.length ?? 0) > 0 && (
                <>
                  <EmailMetaDataRowKey row="bcc">BCC</EmailMetaDataRowKey>
                  <EmailMetaDataRowValue row="bcc" dangerouslySetInnerHTML={{ __html: parseAndSanitizeEmails(bcc) }} />
                </>
              )}

              <EmailMetaDataRowKey row="subject">Subject</EmailMetaDataRowKey>
              <EmailMetaDataRowValue
                row="subject"
                dangerouslySetInnerHTML={{ __html: parseAndSanitizeSubject(subject) }}
              />

              <EmailMetaDataRowKey row="body">Body</EmailMetaDataRowKey>
              <EmailMetaDataRowValue row="body" borderLeft="solid 1px" borderLeftColor="gray.300">
                <Box
                  px={2}
                  py={4}
                  sx={{
                    h1: {
                      fontSize: '2xl',
                      fontWeight: 'semibold',
                      my: 1,
                      lineHeight: 'tall',
                    },
                    h2: {
                      fontSize: 'xl',
                      fontWeight: 'semibold',
                      my: 1,
                      lineHeight: 'tall',
                    },
                    h3: {
                      fontSize: 'lg',
                      fontWeight: 'semibold',
                      my: 1,
                      lineHeight: 'tall',
                    },
                    maxH: isPrintView ? 'none' : '500px',
                    overflow: 'auto',
                    wordBreak: 'break-word',
                  }}
                  dangerouslySetInnerHTML={{ __html: sanitizedEmailBody }}
                />
              </EmailMetaDataRowValue>
            </EmailMetaData>

            <VStack alignItems="start" paddingLeft={22}>
              {templateAttachments.map(attachment => (
                <EmailAttachmentItem
                  key={attachment.attachment.id}
                  attachment={attachment}
                  mode="view"
                  backgroundColor="gray.100"
                />
              ))}
              {checklistAttachments.map(attachment => (
                <EmailAttachmentItem key={attachment.attachment.id} attachment={attachment} mode="view" />
              ))}
            </VStack>
          </>
        )}

        <VStack alignItems="flex-start">
          <AlertDialog
            leastDestructiveRef={cancelRef}
            isOpen={disclosure.isOpen}
            onClose={disclosure.onClose}
            onEsc={disclosure.onClose}
            onOverlayClick={disclosure.onClose}
            size="xl"
            scrollBehavior="inside"
          >
            <AlertDialogOverlay />
            <AlertDialogContent pt="0">
              <AlertDialogHeader py="4" px="8" borderBottom="1px" borderColor="gray.200" fontSize="lg">
                Warning
              </AlertDialogHeader>
              <AlertDialogCloseButton />
              <AlertDialogBody pt="4" px="8" pb="8">
                <Text>It looks like there are some invalid email addresses, would you like send anyway?</Text>
                <Text mt="4">
                  <Text as="span" fontWeight="700">
                    {'Invalid addresses: '}
                  </Text>
                  {invalidRecipients.join(', ')}
                </Text>
              </AlertDialogBody>
              <AlertDialogFooter py="6" px="8" borderTop="1px" borderColor="gray.200">
                <Button ref={cancelRef} variant="ghost" mr="4" onClick={disclosure.onClose}>
                  Cancel
                </Button>
                <Button
                  variant="warning"
                  onClick={() => {
                    disclosure.onClose();
                    setSendEmailWasClicked.on();
                  }}
                >
                  Send anyway
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          {!isEditMode && (
            <HStack
              className="send-rich-email-checklist-widget__controls"
              alignItems="flex-start"
              justifyContent="end"
              width="full"
            >
              {editButtonVisible && (
                <Tooltip
                  hasArrow
                  label="You can’t edit once an email has been sent"
                  shouldWrapChildren
                  mt="2"
                  isDisabled={!emailWasSent}
                >
                  <Button
                    variant="tertiary"
                    leftIcon={<Icon icon="pen-to-square" size="4" variant="far" />}
                    onClick={handleEditClick}
                    isDisabled={emailWasSent || automationStatus === 'AutomationRunning' || readOnly}
                  >
                    Edit
                  </Button>
                </Tooltip>
              )}
              {!isAutoSendEnabled &&
                (widget.config.emailFormat === 'PlainText' ? (
                  <VStack spacing={2}>
                    <SendTestMailtoButton
                      isRequired={widget.required}
                      onClick={handleSendPlainText}
                      caption="Send Email"
                      disabled={readOnly}
                      config={{
                        ...widget.config,
                        to: [parseAndSanitizeRawStringRecipients(to)],
                        cc: [parseAndSanitizeRawStringRecipients(cc)],
                        bcc: [parseAndSanitizeRawStringRecipients(bcc)],
                        subject: parseAndSanitizePlainTextSubject(subject),
                        plainTextBody: parseAndSanitizePlainTextBody(body),
                      }}
                    />
                    {shouldShowUnsentWarning && (
                      <Text fontSize="sm" color="red.500" mt="2">
                        {sendEmailErrorMessage}
                      </Text>
                    )}
                  </VStack>
                ) : (
                  <VStack spacing={2}>
                    {sendRichButtonWithTooltip}
                    {shouldShowUnsentWarning && (
                      <Text fontSize="sm" color="red.500" mt="2">
                        {sendEmailErrorMessage}
                      </Text>
                    )}
                  </VStack>
                ))}
            </HStack>
          )}
          {value ? <SendRichEmailTimestamp {...value} showIfNotSent={false} /> : null}

          {outputFromAutomatedTasks.length > 0 && (
            <HStack>
              <AutomatedTaskAudit
                fontSize="xs"
                formFieldValue={formFieldValue}
                automatedTaskTemplates={outputFromAutomatedTasks}
              />
              {automationStatus === 'AutomationRunning' && (
                <Spinner color="gray.300" data-testid="native-automation-running-spinner" />
              )}
            </HStack>
          )}
        </VStack>
      </Stack>
      {isAutoSendEnabled && (
        <Text fontStyle="italic" fontSize="sm" mt={2}>
          This email will be sent automatically when the task is complete.
        </Text>
      )}
    </WidgetProvider>
  );
};

const RequiredButton = forwardRef<ButtonProps & { isRequired: boolean }, 'button'>(({ isRequired, ...props }, ref) => (
  <Button
    {...{
      ref,
      ...(isRequired
        ? {
            'aria-required': true,
            '_after': {
              content: '"*"',
              color: 'red.500',
              position: 'absolute',
              top: '0',
              right: '-3',
              lineHeight: 'none',
            },
          }
        : {}),
      ...props,
    }}
  />
));
