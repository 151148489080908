import * as React from 'react';
import { useField } from 'formik';
import { Input } from '@chakra-ui/react';

export type TestValueOutputProps = {
  index: number;
};

export const TestValueOutput: React.FC<TestValueOutputProps> = ({ index }) => {
  const [field] = useField(`mappings[${index}].value`);

  return <Input value={field.value} isDisabled={true} placeholder="Run the code to see value" />;
};
