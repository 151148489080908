import {
  FieldType,
  isMultiChoiceFormFieldWidget,
  MultiChoiceFormFieldValue,
  MultiChoiceFormFieldWidget,
  MultiChoiceItemValueStatus,
  SelectFormFieldValue,
  SelectFormFieldWidget,
} from '@process-street/subgrade/process';
import { UpdateFormFieldValueMutationResponse } from 'features/widgets/query-builder';
import { Option } from 'space-monad';
import { ActionObject, ActorRefFrom, assign, createMachine, forwardTo, sendParent, spawn, StateFrom } from 'xstate';
import { FormFieldMachineBuilderProps, WithFormFieldMachineEvent } from '../../../types';
import {
  makeValidationMachine,
  ValidationActorRef,
  ValidationParentEvent,
  ValidationSelectors,
} from '../validation-machine';
import { makeSelectValidationSchema } from 'pages/forms/_id/edit/components/form-fields/select-form-field/select-form-field-schema';
import {
  makeRulesEngineTargetMachine,
  sendRulesActorFormFieldValueUpdate,
} from '../../form-response-body/rules-engine-machine';
import { makeUpdateFormFieldValueMutation } from '../make-update-form-field-value-mutation';
import { FormResponseErrorToasts } from 'pages/responses/_id/utils/form-response-error-toasts';
import { AxiosError } from 'axios';
import { match, P } from 'ts-pattern';
import { Muid } from '@process-street/subgrade/core';
import { makeErrorLoggerAction } from 'app/utils/machines';
import { useSelector } from '@xstate/react';
import { useCallback, useMemo } from 'react';
import { identitySelector } from '../form-field-machine-hooks';

//** Type to handle Select, linked Select and MultiChoice. We should split into different machines when reactifying the runner! */
export type ValueType = Array<{ value: string; dataSetRowId?: Muid }>;

export type Context = {
  value: ValueType;
  widget: SelectFormFieldWidget | MultiChoiceFormFieldWidget;
  formFieldValue: SelectFormFieldValue | MultiChoiceFormFieldValue | undefined;
  validationActor: ValidationActorRef<ValueType | undefined>;
  rulesEngineTargetActor: ActorRefFrom<typeof makeRulesEngineTargetMachine>;
  inputNode: HTMLElement | null;
};

export type Event = WithFormFieldMachineEvent<
  ValidationParentEvent<ValueType>,
  ValueType,
  SelectFormFieldValue | MultiChoiceFormFieldValue | undefined
>;

export const makeSelectFormFieldMachine = ({
  formFieldWidget,
  formFieldValue,
  autoFocus,
  checklistRevisionId,
  sharedContext,
  isEditable,
  inputNode,
}: FormFieldMachineBuilderProps<SelectFormFieldWidget> | FormFieldMachineBuilderProps<MultiChoiceFormFieldWidget>) => {
  const validationSchema = makeSelectValidationSchema({ required: formFieldWidget.required });

  const valueToFieldValue = makeValueToFieldValue(formFieldWidget);

  const { items } = formFieldWidget.config;
  const itemsById = new Map(items.map(item => [item.id, item]));
  const itemsByName = new Map(items.map(item => [item.name, item]));
  const isDataSetLinkedDropdown = Boolean(formFieldWidget.config.linkId);

  const value = match({ formFieldWidget, isDataSetLinkedDropdown })
    .with({ formFieldWidget: { fieldType: FieldType.MultiChoice } }, () => {
      const multiChoiceValues =
        (formFieldValue as MultiChoiceFormFieldValue)?.fieldValue.itemValues
          .filter(itemValue => itemValue.status === MultiChoiceItemValueStatus.Selected)
          .map(itemValue => itemsById.get(itemValue.id)!.id) ?? [];
      return multiChoiceValues.map(v => ({ value: v })) as ValueType;
    })
    .with({ formFieldWidget: { fieldType: FieldType.Select }, isDataSetLinkedDropdown: false }, () => {
      const simpleSelectValues = Option(formFieldValue as SelectFormFieldValue | undefined)
        .map(ffv => ffv.fieldValue.value)
        .map(name => itemsByName.get(String(name)))
        .map(item => [item.id])
        .getOrElse([]);
      return simpleSelectValues.map(v => ({ value: v })) as ValueType;
    })
    .with({ formFieldWidget: { fieldType: FieldType.Select }, isDataSetLinkedDropdown: true }, () => {
      return Option(formFieldValue as SelectFormFieldValue | undefined)
        .map(ffv => ffv.fieldValue)
        .map(x => [{ value: x.value ?? '', dataSetRowId: x.dataSetRowId }])
        .getOrElse([{ value: '', dataSetRowId: undefined }]) as ValueType;
    })
    .exhaustive();

  const initialState = isEditable ? 'enabled' : 'disabled';

  const isEmpty = (v: ValueType | undefined) => {
    return v === undefined || v?.length === 0 || v?.every(value => value.value === '');
  };

  const id = `select-form-field-machine:${formFieldWidget.id}`;

  return createMachine(
    {
      context: () => ({
        inputNode,
        value,
        widget: formFieldWidget,
        formFieldValue,
        validationActor: spawn(makeValidationMachine({ validationSchema, initialValue: value, isEmpty }), {
          name: 'validation-actor',
        }),
        rulesEngineTargetActor: spawn(
          makeRulesEngineTargetMachine({ type: 'widget', widgetHeaderGroupId: formFieldWidget.header.group.id }),
          { name: 'hidden-by-rule-actor', sync: true },
        ),
      }),
      schema: {
        context: {} as Context,
        events: {} as Event,
        services: {} as {
          updateFormFieldValue: {
            data: UpdateFormFieldValueMutationResponse;
          };
        },
      },
      tsTypes: {} as import('./select-form-field-machine.typegen').Typegen0,
      id,
      predictableActionArguments: true,
      preserveActionOrder: true,
      type: 'parallel',
      on: {
        SET_NODE: { actions: ['assignNode'] },
        SCROLL_INTO_VIEW: { actions: ['scrollIntoView'] },
        SYNC_FORM_FIELD_VALUE: { actions: ['syncFormFieldValue'] },
      },
      states: {
        input: {
          initial: initialState,
          states: {
            disabled: {
              on: {
                ENABLE: {
                  target: 'enabled',
                },
              },
            },
            enabled: {
              initial: 'idle',
              on: { DISABLE: 'disabled' },
              states: {
                idle: { on: { FOCUS: 'focused' } },
                focused: {
                  on: {
                    BLUR: 'idle',
                    CHANGE: { actions: ['updateValue', 'sendRulesActorFormFieldValueUpdate'] },
                  },
                },
              },
            },
          },
        },

        autoFocus: {
          initial: autoFocus ? 'enabled' : 'disabled',
          states: { disabled: {}, enabled: {} },
        },

        mutation: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                UPDATE_VALUE: 'updating',
              },
            },
            updating: {
              invoke: {
                src: 'updateFormFieldValue',
                onDone: {
                  target: 'idle',
                  actions: ['sendParentFormFieldValueUpdate', 'assignFormFieldValue'],
                },
                onError: {
                  target: 'idle',
                  actions: ['logError', 'showErrorToast'],
                },
              },
            },
          },
        },

        // This state is a kind of controller to forward events up and down
        // Since it is a parallel state, in can listen for events without blocking or getting blocked by nested states
        validation: {
          initial: 'enabled',
          states: {
            enabled: {
              on: {
                CHANGE: { actions: 'forwardToValidation' },
                REVEAL_INVALID: { actions: 'forwardToValidation' },
                BLUR: { actions: 'forwardToValidation' },
                VALID: { actions: 'sendParentValid' },
                INVALID: { actions: 'sendParentInvalid' },
                HIDE: { actions: 'sendParentValid', target: 'disabled' },
              },
            },
            disabled: {
              on: {
                REVEAL: { target: 'enabled', actions: 'restoreValidationWithParent' },
              },
            },
          },
        },
      },
    },
    {
      services: {
        updateFormFieldValue: async (_, event) =>
          makeUpdateFormFieldValueMutation({
            queryClient: sharedContext.queryClient,
            body: {
              checklistRevisionId,
              widgetId: formFieldWidget.id,
              ...valueToFieldValue(event.value ?? [], isDataSetLinkedDropdown),
            },
          }).execute(),
      },
      actions: {
        logError: makeErrorLoggerAction(id),
        assignNode: assign({ inputNode: (_, event) => event.node }),
        updateValue: assign({
          value: (_, event) => event.value ?? [],
        }),
        syncFormFieldValue: assign({
          value: (ctx, event) => {
            const fieldValue: ValueType | undefined = match(event.value?.fieldValue)
              // MultiChoice form field widget
              .with({ itemValues: P.not(P.nullish) }, fieldValue =>
                fieldValue.itemValues
                  .filter(item => item.status === MultiChoiceItemValueStatus.Selected)
                  .map(item => ({
                    value: item.id,
                  })),
              )
              // Dropdown form field widget
              .with({ value: P.not(P.nullish) }, fieldValue => {
                const item = ctx.widget.config.items.find(item => item.name === fieldValue.value);

                return item ? [{ value: item.id, dataSetRowId: fieldValue.dataSetRowId }] : [];
              })
              .otherwise(() => undefined);

            return fieldValue ?? ctx.value;
          },
          formFieldValue: (_, event) => event.value,
        }),
        showErrorToast: (_, event) => FormResponseErrorToasts.showFormFieldUpdateErrorToast(event.data as AxiosError),
        sendParentInvalid: sendParent({ type: 'INVALID_WIDGET', widgetId: formFieldWidget.id }),
        sendParentValid: sendParent({ type: 'VALID_WIDGET', widgetId: formFieldWidget.id }),
        forwardToValidation: forwardTo(ctx => ctx.validationActor) as ActionObject<Context, Event>,
        restoreValidationWithParent: sendParent((ctx, _evt) => {
          if (ctx.validationActor.getSnapshot()?.matches('valid')) {
            return { type: 'VALID_WIDGET', widgetId: formFieldWidget.id };
          }
          return { type: 'INVALID_WIDGET', widgetId: formFieldWidget.id };
        }),

        sendRulesActorFormFieldValueUpdate: (_ctx, event) =>
          sendRulesActorFormFieldValueUpdate({
            formFieldValue: {
              ...formFieldValue!,
              fieldValue: valueToFieldValue(event.value ?? [], isDataSetLinkedDropdown),
            },
            formFieldWidget,
          }),
        scrollIntoView: ctx => {
          ctx.inputNode?.scrollIntoView();
        },
        sendParentFormFieldValueUpdate: sendParent((_ctx, evt) => ({
          type: 'FORM_FIELD_VAUE_UPDATE',
          formFieldValue: evt.data.formFieldValue,
        })),
        assignFormFieldValue: assign({
          // @ts-expect-error -- TODO
          formFieldValue: (ctx, evt) => ({ ...ctx.formFieldValue, ...evt.data.formFieldValue }),
        }),
      },
    },
  );
};

const makeValueToFieldValue =
  (widget: SelectFormFieldWidget | MultiChoiceFormFieldWidget) =>
  (
    value: ValueType,
    isDataSetLinkedDropdown: boolean,
  ): MultiChoiceFormFieldValue['fieldValue'] | SelectFormFieldValue['fieldValue'] => {
    if (isDataSetLinkedDropdown) {
      return value.map(v => ({
        value: v.value,
        dataSetRowId: v.dataSetRowId,
      }))[0] as SelectFormFieldValue['fieldValue'];
    } else {
      if (isMultiChoiceFormFieldWidget(widget)) {
        const valueSet = new Set(value.map(v => v.value));
        return {
          itemValues: widget.config.items.map(item => ({
            id: item.id,
            status: valueSet.has(item.id)
              ? MultiChoiceItemValueStatus.Selected
              : MultiChoiceItemValueStatus.NotSelected,
          })),
        } as MultiChoiceFormFieldValue['fieldValue'];
      }

      const itemsMap = new Map(widget.config.items.map(item => [item.id, item]));
      return {
        value: itemsMap.get(value.map(v => v.value)[0])?.name ?? '',
      } as SelectFormFieldValue['fieldValue'];
    }
  };

export type SelectFormFieldMachine = ReturnType<typeof makeSelectFormFieldMachine>;
export type SelectFormFieldMachineState = StateFrom<SelectFormFieldMachine>;
export type SelectFormFieldActor = ActorRefFrom<SelectFormFieldMachine>;

export namespace SelectFormFieldSelectors {
  export const getInputNode = (state: SelectFormFieldMachineState) => state.context.inputNode;

  export const getIsAutofocused = (state: SelectFormFieldMachineState) => state.matches('autoFocus.enabled');

  export const getIsInputDisabled = (state: SelectFormFieldMachineState) => {
    return state.matches('input.disabled');
  };

  export const getFormFieldValue = (state: SelectFormFieldMachineState) => state.context.formFieldValue;

  export const getValidationActor = (state: SelectFormFieldMachineState) => {
    return state.context.validationActor;
  };

  export const getWidget = (state: SelectFormFieldMachineState) => state.context.widget;
}

export namespace SelectFormFieldHooks {
  export const useFormFieldValue = (actorRef: SelectFormFieldActor) => {
    return useSelector(actorRef, SelectFormFieldSelectors.getFormFieldValue);
  };

  export const useInputNode = (actorRef: SelectFormFieldActor) => {
    return useSelector(actorRef, SelectFormFieldSelectors.getInputNode);
  };

  export const useIsAutofocused = (actorRef: SelectFormFieldActor) => {
    return useSelector(actorRef, SelectFormFieldSelectors.getIsAutofocused);
  };

  export const useIsInputDisabled = (actorRef: SelectFormFieldActor) => {
    return useSelector(actorRef, SelectFormFieldSelectors.getIsInputDisabled);
  };

  export const useIsInvalid = (actorRef: SelectFormFieldActor) => {
    const validationActor = useValidationActor(actorRef);
    return ValidationSelectors.isActorInvalidVisible(validationActor);
  };

  export const useValidationActor = (actorRef: SelectFormFieldActor) => {
    return useSelector(actorRef, SelectFormFieldSelectors.getValidationActor);
  };

  const validationMachineSelector = (state: SelectFormFieldMachineState) => state.context.validationActor;
  export function useValidationSnapshot(parentRef: SelectFormFieldActor) {
    const validationActor = useSelector(parentRef, validationMachineSelector) as ValidationActorRef<any>;
    const validationSnapshot = useSelector(validationActor, identitySelector);
    return validationSnapshot;
  }

  export const useValidationErrorMessage = (parentRef: SelectFormFieldActor) => {
    const validationSnapshot = useValidationSnapshot(parentRef);
    return validationSnapshot.context.errorMessage;
  };

  export const useWidget = (actorRef: SelectFormFieldActor) => {
    return useSelector(actorRef, SelectFormFieldSelectors.getWidget);
  };

  export const useApi = (actorRef: SelectFormFieldActor) => {
    const onChange = useCallback(
      (value: ValueType) => {
        actorRef.send({ type: 'CHANGE', value });
      },
      [actorRef],
    );

    const onBlur = useCallback(() => {
      actorRef.send({ type: 'BLUR' });
    }, [actorRef]);

    const onFocus = useCallback(() => {
      actorRef.send({ type: 'FOCUS' });
    }, [actorRef]);

    const onSetNode = useCallback(
      (node: HTMLDivElement | null) => {
        actorRef.send({ type: 'SET_NODE', node });
      },
      [actorRef],
    );

    return useMemo(() => ({ onChange, onBlur, onFocus, onSetNode }), [onChange, onBlur, onFocus, onSetNode]);
  };
}
