import * as React from 'react';
import { Box, BoxProps, HStack, Link, Tooltip } from 'components/design/next';
import { usePrintStore } from 'components/react-root/print-store';
import {
  AutomatedTaskAuditHelperText,
  CodeTaskIcon,
  CommonAuditProps,
  getAutomatedTaskOutputTooltip,
  ProcessPeteCircle,
  ProcessPeteIcon,
} from './common';
import { useHandleRetryFailedNativeAutomation } from '../../hooks/use-handle-retry-failed-native-automation';

import { match, P } from 'ts-pattern';
import { Muid } from '@process-street/subgrade/core';

export interface FailedAutomatedTaskAuditProps extends BoxProps, CommonAuditProps {
  errorMessage?: string;
  latestExecutionId?: Muid;
}

export const FailedAutomatedTaskAudit: React.FC<React.PropsWithChildren<FailedAutomatedTaskAuditProps>> = ({
  automatedTaskTemplates,
  nativeAutomation,
  errorMessage,
  latestExecutionId,
  ...props
}) => {
  const { isPrintView, isPrintPage } = usePrintStore();
  const isPrintMode = isPrintView || isPrintPage;

  const handleRetry = useHandleRetryFailedNativeAutomation({ nativeAutomationExecutionId: latestExecutionId });

  // otherwise the latest update was from a Code Task
  const isAutomatedTaskAudit = nativeAutomation?.automationType === 'AiTask';
  const automatedTaskOutputTooltip = getAutomatedTaskOutputTooltip(automatedTaskTemplates);

  const message = match([errorMessage])
    .with([P.when(msg => msg && msg.includes('context_length_exceeded'))], () => (
      <>AI task content length exceeded, please contact the workflow creator to reduce the generated length.</>
    ))
    .otherwise(() => (
      <>
        An error occurred executing the {isAutomatedTaskAudit ? 'AI Task' : 'Code Task'}. Fill manually or{' '}
        <Link color={isPrintMode ? 'red.200' : 'red.500'} onClick={handleRetry} textDecor="underline">
          retry
        </Link>
        .
      </>
    ));

  return (
    <Box {...props}>
      <HStack>
        <Tooltip label={automatedTaskOutputTooltip} shouldWrapChildren hasArrow>
          <ProcessPeteCircle>{isAutomatedTaskAudit ? <ProcessPeteIcon /> : <CodeTaskIcon />}</ProcessPeteCircle>
        </Tooltip>

        <AutomatedTaskAuditHelperText color={isPrintMode ? 'red.200' : 'red.500'}>
          {message}
        </AutomatedTaskAuditHelperText>
      </HStack>
    </Box>
  );
};
