import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { axiosService } from 'services/axios-service';
import { MigrationService } from 'features/migrations/services/migration-service';
import { useInjector } from 'components/injection-provider';
import { DataServiceActions } from 'reducers/data-service/data-service.actions';

export namespace ScheduleMigrationMutation {
  export type Params = {
    checklistRevisionId: Muid;
  };

  export type Response = {
    message: string;
    code: null | string;
  };

  export const key = ['checklist-revisions', 'schedule-migration'];

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .post<Response>(`/1/checklist-revisions/${params.checklistRevisionId}/schedule-migration`, params)
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    const { $ngRedux } = useInjector('$ngRedux');
    const queryClient = useQueryClient();
    return useRQMutation(mutationFn, {
      ...options,
      mutationKey: key,
      onSuccess: async (...args) => {
        // TODO Drop this Redux dispatch once we switch to Runner V2
        $ngRedux.dispatch(DataServiceActions.clearDataStore());
        MigrationService.invalidateChecklistRevisionQueriesPostMigration(queryClient);
        await options.onSuccess?.(...args);
      },
    });
  };
}
