import { Button, Flex, useToast } from 'components/design/next';
import { FormMachineUtils } from 'app/pages/responses/_id/components/form-response-body/form-response-machine-utils';
import * as React from 'react';
import { FormResponsePageProviders } from '../../providers';
import { Icon } from 'app/components/design/next';
import { TaskMachineHooks } from 'app/pages/responses/_id/components/task/task-machine-hooks';
import { FormResponseMachineHooks } from 'app/pages/responses/_id/components/form-response-body/form-response-machine-hooks';
import { ApprovalTaskFooter } from 'app/pages/responses/_id/components/approval-task/approval-task-footer';

export const TaskFooter = () => {
  const toast = useToast();

  const formResponseActor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
  const api = FormResponseMachineHooks.useApi(formResponseActor);
  const taskActorsMap = FormResponseMachineHooks.useTaskActorsMap();
  const currentTaskActor = FormResponseMachineHooks.useCurrentTaskActorRef();
  const task = TaskMachineHooks.useTask(currentTaskActor);
  const isCompletingCurrentTask = FormResponseMachineHooks.useIsCompletingCurrentTask();
  const isUncompletingCurrentTask = FormResponseMachineHooks.useIsUncompletingCurrentTask();
  const isChecklistComplete = FormResponseMachineHooks.useIsComplete();
  const isTaskComplete = TaskMachineHooks.useIsTaskComplete(currentTaskActor);
  const taskIsInvalid = TaskMachineHooks.useShouldShowErrors(currentTaskActor);
  const isTaskApproval = TaskMachineHooks.useIsTaskApproval(currentTaskActor);
  const invalidWidgetCount = TaskMachineHooks.useInvalidWidgetCount(currentTaskActor);
  const isMigrating = FormResponseMachineHooks.useIsMigrating();

  const hasMoreTasks = FormMachineUtils.hasMoreTasks(taskActorsMap, currentTaskActor);

  const isCompleteButtonVisible = hasMoreTasks && !isTaskComplete && !isChecklistComplete && !isTaskApproval;
  const isSkipButtonVisible = hasMoreTasks && !isChecklistComplete && !isTaskApproval;
  const isNextButtonVisible = hasMoreTasks && isTaskApproval;
  const isFinishButtonVisible = !hasMoreTasks && !isTaskComplete && !isChecklistComplete && !isTaskApproval;
  const isUncompleteButtonVisible = isTaskComplete && !isChecklistComplete && !isTaskApproval;
  const completeButtonText = hasMoreTasks ? 'Complete' : 'Finish';

  const shouldDisableCompleteButton = invalidWidgetCount > 0 && taskIsInvalid;

  const handleComplete = React.useCallback(() => {
    const isInvalid = api.getIsCurrentTaskActorInvalid() && invalidWidgetCount > 0;

    if (isInvalid) {
      toast({
        title: `We couldn't complete the step`,
        description: `${invalidWidgetCount} form field${invalidWidgetCount > 1 ? 's' : ''} still need to be completed.`,
        status: 'warning',
        isClosable: true,
      });
    }
    api.onNextTask();
  }, [invalidWidgetCount, toast, api]);

  const handleUncomplete = React.useCallback(() => {
    if (!task) return;
    api.onUncompleteCurrentTask();
  }, [task, api]);

  const handleSkip = React.useCallback(() => {
    api.onSkipTask();
  }, [api]);

  if (isMigrating) return null;

  return (
    <Flex
      direction={{ base: isTaskApproval ? 'column' : 'row', md: 'row' }}
      w="full"
      justifyContent="flex-end"
      gap={2}
      pt="6"
    >
      {isSkipButtonVisible && (
        <Button
          variant="ghost"
          fontWeight="500"
          colorScheme="gray"
          onClick={handleSkip}
          _hover={{ bgColor: 'transparent' }}
        >
          Skip
        </Button>
      )}
      {isCompleteButtonVisible ? (
        <Button
          isLoading={isCompletingCurrentTask}
          loadingText={completeButtonText}
          onClick={handleComplete}
          isDisabled={shouldDisableCompleteButton}
          borderWidth="1px"
        >
          Complete
        </Button>
      ) : null}
      {isNextButtonVisible ? (
        <Button
          variant="ghost"
          fontWeight="500"
          colorScheme="gray"
          onClick={handleSkip}
          _hover={{ bgColor: 'transparent' }}
        >
          Next
        </Button>
      ) : null}
      {isFinishButtonVisible ? (
        <Button
          isLoading={isCompletingCurrentTask}
          loadingText={completeButtonText}
          onClick={handleComplete}
          isDisabled={shouldDisableCompleteButton}
        >
          Finish
        </Button>
      ) : null}
      {isUncompleteButtonVisible && (
        <Button
          variant="outline"
          colorScheme="gray"
          borderWidth="px"
          onClick={handleUncomplete}
          leftIcon={<Icon icon="check" size="3" colorScheme="gray.600" />}
          isLoading={isUncompletingCurrentTask}
          loadingText="Completed"
        >
          Completed
        </Button>
      )}

      {isTaskApproval && <ApprovalTaskFooter taskMachine={currentTaskActor} formResponseActor={formResponseActor} />}
    </Flex>
  );
};
