import * as React from 'react';
import { SendEmailSelectors, SendEmailWidgetActor, UploadEventParams } from './send-email-widget-machine';
import { useSelector } from '@xstate/react';
import { ValidationSelectors } from '../validation-machine';
import { SendRichEmailFieldValue } from '@process-street/subgrade/process';
import { Muid, User } from '@process-street/subgrade/core';

export namespace SendEmailHooks {
  export const useValidationActor = (actorRef: SendEmailWidgetActor) =>
    useSelector(actorRef, SendEmailSelectors.getValidationActor);
  export const useSendEmailValidationActor = (actorRef: SendEmailWidgetActor) =>
    useSelector(actorRef, SendEmailSelectors.getSendEmailValidationActor);
  export const useChecklistRevisionId = (actorRef: SendEmailWidgetActor) =>
    useSelector(actorRef, SendEmailSelectors.getChecklistRevisionId);
  export const useFormFieldValue = (actorRef: SendEmailWidgetActor) =>
    useSelector(actorRef, SendEmailSelectors.getFormFieldValue);
  export const useValue = (actorRef: SendEmailWidgetActor) => useSelector(actorRef, SendEmailSelectors.getValue);
  export const useWidget = (actorRef: SendEmailWidgetActor) => useSelector(actorRef, SendEmailSelectors.getWidget);
  export const useIsReadOnly = (actorRef: SendEmailWidgetActor) =>
    useSelector(actorRef, SendEmailSelectors.getIsReadOnly);
  export const useSendEmailErrorMessage = (actorRef: SendEmailWidgetActor) =>
    useSelector(actorRef, state => ValidationSelectors.errorMessage(state.context.sendEmailValidationActor));
  export const useShouldShowUnsentWarning = (actorRef: SendEmailWidgetActor) =>
    useSelector(actorRef, state => ValidationSelectors.isActorInvalidVisible(state.context.sendEmailValidationActor));
  export const useCurrentNode = (actorRef: SendEmailWidgetActor) =>
    useSelector(actorRef, SendEmailSelectors.getCurrentNode);
  export const useIsUploading = (actorRef: SendEmailWidgetActor) =>
    useSelector(actorRef, SendEmailSelectors.getIsUploading);

  export function useApi(actorRef: SendEmailWidgetActor) {
    const onBlur = React.useCallback(() => actorRef.send({ type: 'BLUR' }), [actorRef]);

    const onChange = React.useCallback(
      (value: SendRichEmailFieldValue) => actorRef.send({ type: 'CHANGE', value }),
      [actorRef],
    );

    const onDeleteAttachment = React.useCallback(
      (attachmentId: string) => actorRef.send({ type: 'DELETE_EMAIL_ATTACHMENT', attachmentId }),
      [actorRef],
    );

    const onFocus = React.useCallback(() => actorRef.send({ type: 'FOCUS' }), [actorRef]);

    const onSetNode = React.useCallback(
      (node: HTMLDivElement | null) => actorRef.send({ type: 'SET_NODE', node }),
      [actorRef],
    );

    const onSend = React.useCallback((widgetId: Muid) => actorRef.send({ type: 'SEND_EMAIL', widgetId }), [actorRef]);
    const onSendPlainText = React.useCallback(
      (value: SendRichEmailFieldValue) => actorRef.send({ type: 'SEND_PLAIN_TEXT_EMAIL', value }),
      [actorRef],
    );

    const onSetCurrentUser = React.useCallback(
      (user: User) => actorRef.send({ type: 'SET_CURRENT_USER', currentUser: user }),
      [actorRef],
    );

    const onUploadAttachment = React.useCallback(
      (uploadParams: UploadEventParams) => actorRef.send({ type: 'UPLOAD_EMAIL_ATTACHMENT', uploadParams }),
      [actorRef],
    );

    return React.useMemo(
      () => ({
        onBlur,
        onChange,
        onDeleteAttachment,
        onFocus,
        onSetNode,
        onSend,
        onSendPlainText,
        onSetCurrentUser,
        onUploadAttachment,
      }),
      [
        onBlur,
        onChange,
        onDeleteAttachment,
        onFocus,
        onSetNode,
        onSend,
        onSendPlainText,
        onSetCurrentUser,
        onUploadAttachment,
      ],
    );
  }
}
