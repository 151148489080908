import { Muid } from '@process-street/subgrade/core';
import { TaskWithTaskTemplate } from '@process-street/subgrade/process';
import { TaskAssignment } from '@process-street/subgrade/role-assignment';
import { VStack, Text } from 'components/design/next';
import { ArrayUtils, NonEmptyArray } from 'app/utils/array-utils';
import * as React from 'react';
import { TaskNameButton } from '../common/task-name-button';
import { ApprovalAssignees } from '../common/approval-assignees';

export type WaitingForSubmissionTasksSectionProps = {
  tasks: NonEmptyArray<TaskWithTaskTemplate>;
  taskAssignmentsByTaskMap: Record<Muid, TaskAssignment[]>;
  onSelectTask: (taskId: Muid) => void;
};

export const WaitingForSubmissionTasksSection = ({
  tasks,
  taskAssignmentsByTaskMap,
  onSelectTask,
}: WaitingForSubmissionTasksSectionProps) => {
  return (
    <VStack alignItems="stretch" gap={4} width="full">
      <Text variant="1" color="gray.400" fontWeight="bold">
        Waiting for submission
      </Text>

      {tasks.map(task => {
        const taskAssignments = taskAssignmentsByTaskMap[task.id] ?? [];

        return (
          <>
            <VStack key={task.id} spacing="3" alignItems="flex-start" backgroundColor="gray.50" padding={4}>
              {ArrayUtils.isNonEmptyArray(taskAssignments) && <ApprovalAssignees taskAssignments={taskAssignments} />}
              <TaskNameButton
                taskName={task.taskTemplate.name || '<unnamed task>'}
                onClick={() => onSelectTask(task.id)}
              />
              <Text fontStyle="italic" color="gray.500">
                Will be submitted
              </Text>
            </VStack>
          </>
        );
      })}
    </VStack>
  );
};
