import * as React from 'react';
import { ChecklistTaskListComponents as Components } from './components';
import { FormResponsePageMachineHooks } from 'app/pages/responses/_id/form-response-page-hooks';
import { FormResponseMachineHooks } from 'app/pages/responses/_id/components/form-response-body/form-response-machine-hooks';
import { FormResponsePageProviders } from '../../providers';
import { Task, TaskStatus, TaskTemplateTaskType, TaskUtils } from '@process-street/subgrade/process';
import { OneOffTaskList } from 'pages/checklists/_id/components/task-list/components/one-off-task-list';
import { TaskMachineHooks } from 'pages/responses/_id/components/task/task-machine-hooks';
import { CoverImage } from './components/top-controls/cover-image';
import { AnimatePresence, motion } from 'framer-motion';
import { Box } from 'app/components/design/next';
import { RulesEngineMachineHooks } from 'app/pages/responses/_id/components/form-response-body/rules-engine-machine/rules-engine-machine-hooks';

const MotionBox = motion(Box);

export const TaskList = ({ onSelect }: { onSelect?: (task: Task) => void }) => {
  const formResponseActor = FormResponsePageProviders.FormResponseActorRef.useActorRef();
  const api = FormResponseMachineHooks.useApi(formResponseActor);
  const taskActorsMap = FormResponseMachineHooks.useTaskActorsMap();
  const checklistState = RulesEngineMachineHooks.useChecklistState();

  const checklist = FormResponsePageMachineHooks.useChecklist();
  const oneOffTasks = FormResponsePageMachineHooks.useOneOffTasks();
  const tasks = FormResponsePageMachineHooks.useTasks();

  const currentTaskId = FormResponseMachineHooks.useCurrentTaskId();
  const currentTaskActor = FormResponseMachineHooks.useCurrentTaskActorRef();
  const currentTask = TaskMachineHooks.useTask(currentTaskActor);

  const shouldHideCompletedTasks = FormResponseMachineHooks.useShouldHideCompletedTasks();
  const shouldHideStoppedTasks = FormResponseMachineHooks.useShouldHideStoppedTasks();

  const taskNumberById = React.useMemo(
    () =>
      Object.fromEntries(
        tasks
          ?.filter(t => t.taskTemplate.taskType !== TaskTemplateTaskType.AI)
          .map((task, index) => [task.id, index + 1]) ?? [],
      ),
    [tasks],
  );

  const [tasksUntilNearestStop, tasksAfterNearestStop] = React.useMemo(() => {
    const safeTasks = tasks ?? [];
    const nearestStopIndex = safeTasks.findIndex(task => TaskUtils.isStopped(task));

    if (nearestStopIndex < 0)
      return [
        // There are not stopped tasks, so we return all of them
        safeTasks,
        // Return a empty list of hidden tasks
        [],
      ];

    const untilStop = safeTasks.slice(0, nearestStopIndex);
    const afterStop = safeTasks.slice(nearestStopIndex, safeTasks.length);

    return [untilStop, afterStop];
  }, [tasks]);

  const displayedTasks = React.useMemo(() => {
    const safeTasks = shouldHideStoppedTasks ? tasksUntilNearestStop : tasks ?? [];

    return safeTasks.filter(task => {
      const isTaskCompleted = TaskUtils.isCompleted(task);
      const isHiddenByRule = checklistState.taskStates.find(taskState => taskState.task.id === task.id)?.task.hidden;

      return !isHiddenByRule && !(shouldHideCompletedTasks && isTaskCompleted);
    });
  }, [shouldHideStoppedTasks, tasksUntilNearestStop, tasks, shouldHideCompletedTasks, checklistState]);

  const handleSelectTask = React.useCallback(
    (task: Task) => {
      api.onSelectTask(task.id);
      onSelect?.(task);
    },
    [onSelect, api],
  );

  const handleTaskStatusChange = React.useCallback(
    (task: Task) => {
      if (task.status === TaskStatus.Completed) {
        api.onUncompleteTask(task.id);
      } else {
        api.onCompleteTask(task.id);
      }
    },
    [api],
  );

  const templateId = checklist?.template.id;

  return (
    <>
      {templateId && <CoverImage templateId={templateId} />}
      <Components.Container>
        {checklist && <Components.TopControls checklist={checklist} />}

        <Components.TasksWrapper>
          <AnimatePresence initial={false}>
            {displayedTasks?.map((task, index, array) => {
              // grab original task number, before hiding completed tasks
              const stepNumber = taskNumberById[task.id] ?? 0;
              const isCurrentTask = currentTaskId === task.id;
              const taskActor = taskActorsMap[task.id];
              return (
                <MotionBox
                  key={task.id}
                  initial={{ opacity: 0, top: -100 }}
                  animate={{ opacity: 1, top: 0 }}
                  exit={{
                    opacity: 0,
                    top: -100,
                  }}
                  w="full"
                  layout
                  transition={{ duration: 0.2 }}
                  className={
                    isCurrentTask ? 'task-list-item-wrapper task-list-item-wrapper--selected' : 'task-list-item-wrapper'
                  }
                  sx={{
                    '&.task-list-item-wrapper:has(+ .task-list-item-wrapper--selected)': {
                      '.task-list-item__task-name-wrapper': {
                        borderBottom: 'transparent',
                      },

                      '.task-list-item__progress-bar': {
                        bottom: '0 !important',
                      },
                    },
                  }}
                >
                  <Components.Item
                    key={task.id}
                    task={task}
                    stepNumber={stepNumber}
                    isFirst={index === 0}
                    isLast={array.length - 1 === index}
                    isSelected={isCurrentTask}
                    onSelect={handleSelectTask}
                    onTaskStatusChange={handleTaskStatusChange}
                    selectedTask={currentTask}
                    taskActor={taskActor}
                  />
                </MotionBox>
              );
            })}
          </AnimatePresence>

          <Components.ToggleStoppedTasks hiddenTasksCount={tasksAfterNearestStop.length} />
        </Components.TasksWrapper>

        {/* requiredTaskIds were passed on failed WFR completion to indicate what completion was blocked by these tasks */}
        {checklist && oneOffTasks && <OneOffTaskList tasks={oneOffTasks} checklist={checklist} requiredTaskIds={[]} />}
      </Components.Container>
    </>
  );
};
