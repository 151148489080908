import {
  HStack,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody as ChakraPopoverBody,
  PopoverBodyProps,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverProps,
  PopoverTrigger,
  Portal,
  Text,
  UnorderedList,
  UseDisclosureReturn,
  VStack,
} from 'components/design/next';
import { AssignmentLike, TaskAssignmentUtils } from '@process-street/subgrade/role-assignment';
import * as React from 'react';
import { TaskListItemAssigneeIndicatorAvatar } from './task-list-item-assignee-indicator-avatar';
import { User } from '@process-street/subgrade/core';
import { isGroupUser } from '@process-street/subgrade/util/user-type-utils';
import sortBy from 'lodash/sortBy';

export type AssignmentsPopoverProps<Assignment extends AssignmentLike = AssignmentLike> = React.PropsWithChildren<{
  assignments: Array<Assignment>;
  title?: React.ReactNode;
  disclosure?: UseDisclosureReturn;
  popoverProps?: Partial<PopoverProps>;
  components?: Partial<{
    Item: React.FunctionComponent<{ user: User; assignments: Array<Assignment> }>;
    PopoverBody: React.FunctionComponent<PopoverBodyProps>;
  }>;
}>;

export const AssignmentsPopover = <Assignment extends AssignmentLike = AssignmentLike>({
  children,
  title,
  assignments,
  disclosure,
  popoverProps,
  components,
}: AssignmentsPopoverProps<Assignment>) => {
  const PopoverBody = React.useMemo(() => components?.PopoverBody ?? ChakraPopoverBody, [components?.PopoverBody]);

  const users = assignments
    .map(TaskAssignmentUtils.getUserFromTaskAssignment)
    .filter((user): user is User => Boolean(user));

  const sortedUsers = React.useMemo(() => sortBy(users, user => user.username.toLowerCase()), [users]);

  return (
    <Popover {...popoverProps} {...disclosure} isLazy>
      <PopoverTrigger>{children}</PopoverTrigger>

      <Portal>
        <PopoverContent maxH="275px" overflowY="auto">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader fontSize="sm" fontWeight="medium" color="gray.700">
            {title ?? 'Assignments'}
          </PopoverHeader>
          <PopoverBody>
            <UnorderedList as={VStack} spacing={4} w="full" mb={0} ml={0}>
              {sortedUsers.map(user => {
                const { Item } = components ?? {};
                return Item ? (
                  <Item key={user.id} user={user} assignments={assignments} />
                ) : (
                  <ListItem key={user.id} as={HStack} alignItems="flex-start" spacing={2}>
                    <TaskListItemAssigneeIndicatorAvatar user={user} />

                    <VStack alignItems="flex-start" spacing="px">
                      <Text w="full" variant="-1" fontWeight="medium">
                        {user.username}
                      </Text>
                      <Text w="full" variant="-2" color="gray.400">
                        {isGroupUser(user) ? 'Group' : user.email}
                      </Text>
                    </VStack>
                  </ListItem>
                );
              })}
            </UnorderedList>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
