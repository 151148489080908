import * as React from 'react';
import { match } from 'ts-pattern';
import {
  Button,
  ButtonProps,
  HStack,
  Icon,
  List,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
} from 'components/design/next';
import { Checklist } from '@process-street/subgrade/process';

import { useGetAllActivitiesByTypeQuery } from 'features/activities/query-builder';
import { Activity, ActivityObjectType, AttachmentActivity, CommentActivity } from '@process-street/subgrade/activity';
import { getActiveAttachmentsFromActivityLog, getActiveCommentsFromActivityLog } from 'features/comments/utils';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { OverviewAttachmentItem, OverviewCommentItem } from '../../common';
import { useInjector } from 'components/injection-provider';

export type ChecklistCommentsOverviewPopoverProps = {
  checklistId?: Checklist['id'];
  triggerProps?: Partial<ButtonProps>;
  ContentContainer?: React.FC<React.PropsWithChildren<unknown>>;
};

export const ChecklistCommentsOverviewPopover: React.FC<
  React.PropsWithChildren<ChecklistCommentsOverviewPopoverProps>
> = ({ checklistId, triggerProps, ContentContainer }) => {
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();
  const { $state, $stateParams } = useInjector('$state', '$stateParams');

  const { data: currentUser } = useGetCurrentUserInfoQuery({
    select: data => data.user,
  });

  const commentsQuery = useGetAllActivitiesByTypeQuery<Activity[]>(
    {
      type: 'checklist',
      checklistId,
      limit: 100,
      objectType: ActivityObjectType.Comment,
    },
    {
      enabled: Boolean(checklistId),
      select: ({ data }) => getActiveCommentsFromActivityLog(data as CommentActivity[]),
    },
  );

  const attachmentsQuery = useGetAllActivitiesByTypeQuery<Activity[]>(
    {
      type: 'checklist',
      checklistId,
      limit: 100,
      objectType: ActivityObjectType.Attachment,
    },
    {
      enabled: Boolean(checklistId),
      select: ({ data }) => getActiveAttachmentsFromActivityLog(data as AttachmentActivity[]),
    },
  );

  const allItems = match([commentsQuery, attachmentsQuery])
    .with(
      [{ status: 'success' }, { status: 'success' }],
      ([{ data: comments }, { data: attachments }]) =>
        comments?.concat(attachments ?? []).sort((a, b) => b.date - a.date) ?? [],
    )
    .otherwise(() => []);

  const count = allItems?.length;

  // This is used to open the popover when we link it from Reports
  React.useEffect(() => {
    if (Boolean($stateParams.comments) && allItems.length > 0) {
      setTimeout(() => {
        onOpen();
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$stateParams.comments, allItems.length]);

  const removeModalQueryParam = () => {
    if ($stateParams.comments) {
      const newParams = $state.params;
      delete newParams.comments;
      $state.go($state.current, newParams, { inherit: false, location: 'replace' });
    }
  };

  const content = (
    <PopoverContent w="md" maxH="xl" overflowY="auto">
      <PopoverHeader px="6" pt="6" color="gray.700" fontWeight="bold">
        Comments <Text as="span" color="gray.500" fontWeight="normal">{`(${count})`}</Text>
      </PopoverHeader>
      <PopoverBody px="6" pb="2">
        <List spacing="4" w="full">
          {currentUser &&
            allItems.map(activity => {
              if ('attachmentId' in activity.data) {
                return (
                  <OverviewAttachmentItem
                    key={activity.id}
                    {...{ activity, currentUserId: currentUser.id, type: 'checklist' }}
                  />
                );
              }
              if ('commentId' in activity.data) {
                return (
                  <OverviewCommentItem
                    key={activity.id}
                    {...{
                      activity,
                      currentUserId: currentUser.id,
                      type: 'checklist',
                    }}
                  />
                );
              }
              return null;
            })}
        </List>
      </PopoverBody>
    </PopoverContent>
  );

  return (
    <Popover
      isLazy
      placement="left-start"
      isOpen={isOpen}
      onClose={() => {
        onClose();
        removeModalQueryParam();
      }}
    >
      <PopoverTrigger>
        <Button
          display="flex"
          justifyContent="flex-start"
          // matching a.list-group-item:hover (angular bootstrap)
          _hover={{ bg: '#f5f5f5', borderRadius: 'none' }}
          p="2"
          variant="unstyled"
          color="gray.600"
          width="full"
          leftIcon={<Icon size="4" variant="far" icon="comment" color="gray.600" mr="0" />}
          isDisabled={!count}
          onClick={onToggle}
          {...triggerProps}
        >
          {count ? (
            <HStack>
              <Text>Comments</Text>
              <Text as="span" color="gray.500" fontWeight="normal">{`(${count})`}</Text>
            </HStack>
          ) : (
            <Text> No comments </Text>
          )}
        </Button>
      </PopoverTrigger>
      <Portal>{ContentContainer ? <ContentContainer>{content}</ContentContainer> : content}</Portal>
    </Popover>
  );
};
