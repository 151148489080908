import { FileWidget } from '@process-street/subgrade/process';
import { ActorRefFrom, createMachine, spawn, StateFrom } from 'xstate';
import { makeRulesEngineTargetMachine } from '../../form-response-body/rules-engine-machine';

type Context = {
  widget: FileWidget;
  rulesEngineTargetActor: ActorRefFrom<typeof makeRulesEngineTargetMachine>;
};

type MakeFileContentWidgetMachineArgs = {
  widget: FileWidget;
};

export const makeFileContentWidgetMachine = ({ widget }: MakeFileContentWidgetMachineArgs) => {
  return createMachine({
    id: `file-widget-machine:${widget.id}`,
    predictableActionArguments: true,
    context: () => ({
      widget,
      rulesEngineTargetActor: spawn(
        makeRulesEngineTargetMachine({ type: 'widget', widgetHeaderGroupId: widget.header.group.id }),
        { name: 'hidden-by-rule-actor', sync: true },
      ),
    }),
    tsTypes: {} as import('./file-content-widget-machine.typegen').Typegen0,
    schema: {
      context: {} as Context,
    },
  });
};

export type FileContentWidgetMachine = ReturnType<typeof makeFileContentWidgetMachine>;
export type FileContentWidgetMachineActorRef = ActorRefFrom<FileContentWidgetMachine>;
export type FileContentWidgetMachineState = StateFrom<FileContentWidgetMachine>;

const Selectors = {
  getWidget: (state: FileContentWidgetMachineState) => state.context.widget,
};
export { Selectors as FileContentWidgetMachineSelectors };
