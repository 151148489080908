import { Muid } from '@process-street/subgrade/core';
import {
  AiTask,
  AutomatedTaskTemplate,
  ExecuteAiPrompt,
  FormFieldWidget,
  NativeAutomation,
} from '@process-street/subgrade/process';
import { StringUtils } from '@process-street/subgrade/util';
import { trace } from 'components/trace';
import {
  GetAllNativeAutomationsQuery,
  UpdateNativeAutomationActionsMutation,
} from 'features/native-automations/query-builder';
import produce from 'immer';
import { QueryClient } from 'react-query';
import { Option } from 'space-monad';
import { match } from 'ts-pattern';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { assign, createMachine } from 'xstate';
import { makeErrorLoggerAction } from 'app/utils/machines';

type VariableOutputId = [variable: string | undefined, outputId: Muid | undefined];
type Context = {
  prompt: string;
  inputWidgetId?: Muid;
  variablesOutputIds: VariableOutputId[];
};

type Event =
  | { type: 'SET_INPUT_WIDGET_ID'; inputWidgetId: Muid | undefined }
  | { type: 'SET_VARIABLE'; index: number; variable: string }
  | { type: 'SET_OUTPUT_ID'; index: number; outputId: string }
  | { type: 'ADD_VARIABLE_OUTPUT_ID' }
  | { type: 'REMOVE_VARIABLE_OUTPUT_ID'; index: number }
  | { type: 'SET_PROMPT'; prompt: string }
  | { type: 'SET_INITIAL_PROMPT'; prompt: string }
  | { type: 'OPEN_TEST_MODAL' }
  | { type: 'CLOSE_TEST_MODAL' };

export type MakeParseAiTaskFormMachineArgs = {
  aiTaskTemplate: AutomatedTaskTemplate;
  formFieldWidgets: FormFieldWidget[];
  queryClient: QueryClient;
};

const VARIABLES_SEPARATOR = ', ';

const logger = trace({ name: 'parse-ai-task-form-machine' });

export const makeParseAiTaskFormMachine = ({
  aiTaskTemplate,
  formFieldWidgets: widgets,
  queryClient,
}: MakeParseAiTaskFormMachineArgs) => {
  const widgetIdKeyMap = widgets.reduce((acc, widget) => {
    acc[widget.id] = widget.key;
    return acc;
  }, {} as Record<Muid, string>);

  const promptConfig = AiTask.getPromptConfig(
    aiTaskTemplate.nativeAutomation,
  ) as ExecuteAiPrompt.GetPromptConfig<'Parse'>;
  const variables = promptConfig.promptInputs.variables?.split(VARIABLES_SEPARATOR) ?? [];
  const outputs = aiTaskTemplate.nativeAutomation.actions.find(NativeAutomation.isUpdateFormFieldsAction)?.config
    .mapping;

  const variablesOutputIds = variables.map(variable => {
    const encodedVariable = VariableUtils.encode(variable);
    const key = NativeAutomation.makeMappingKey({ path: `output.${encodedVariable}` });
    const output = outputs?.[key];
    // Check to make sure the widget still exists
    const safeOutput = output && widgetIdKeyMap[output] ? output : undefined;
    if (output && !widgetIdKeyMap[output]) {
      logger.error(`Widget doesn't exist for output: ${output}`);
    }
    return [variable, safeOutput] as VariableOutputId;
  });

  const inputWidgetId = Option(promptConfig.promptInputs.source)
    .map(source => source.match(/\{\{\s*form.(.*)\s*\}\}/)?.[1])
    .map(key => widgets.find(widget => widget.key === key)?.id)
    .get();

  const id = 'parse-ai-task-form-machine';

  return createMachine(
    {
      id,
      predictableActionArguments: true,
      schema: {
        context: {} as Context,
        events: {} as Event,
      },
      context: {
        inputWidgetId,
        variablesOutputIds,
        prompt: promptConfig.prompt,
      },
      tsTypes:
        {} as import('./parse-ai-task-form-machine.typegen').Typegen0 as import('./parse-ai-task-form-machine.typegen').Typegen0,
      type: 'parallel',
      states: {
        inputs: {
          initial: 'checkInitialState',
          on: {
            SET_PROMPT: { actions: ['assignPrompt'] },
            SET_INITIAL_PROMPT: { actions: ['assignPrompt'] },
            SET_INPUT_WIDGET_ID: { actions: ['assignInputWidgetId'] },
            SET_VARIABLE: { actions: ['assignVariable'] },
            SET_OUTPUT_ID: { actions: ['assignOutputId'] },
            ADD_VARIABLE_OUTPUT_ID: { actions: ['assignVariableOutputId'] },
            REMOVE_VARIABLE_OUTPUT_ID: { actions: ['assignVariableOutputId'] },
          },
          states: {
            checkInitialState: {
              entry: 'assignVariableOutputId',
              always: [
                { cond: 'hasNoInputWidgetId', target: 'getInputWidgetId' },
                { cond: 'hasNoOutputWidgets', target: 'getOutputWidget' },
                { target: 'configured' },
              ],
            },
            getInputWidgetId: {
              entry: 'focusInputWidget',
              on: {
                SET_INPUT_WIDGET_ID: {
                  target: ['getOutputWidget'],
                  actions: ['assignInputWidgetId'],
                },
              },
            },
            getOutputWidget: {
              on: {
                SET_OUTPUT_ID: {
                  target: ['configured'],
                  actions: ['assignOutputId'],
                },
              },
            },
            configured: {},
          },
        },

        testModal: {
          initial: 'closed',
          states: {
            open: { on: { CLOSE_TEST_MODAL: 'closed' } },
            closed: { on: { OPEN_TEST_MODAL: 'open' } },
          },
        },

        mutation: {
          initial: 'idle',
          on: {
            SET_PROMPT: { target: '.debouncing', internal: false },
            SET_INPUT_WIDGET_ID: { target: '.debouncing', internal: false },
            SET_VARIABLE: { target: '.debouncing', internal: false },
            SET_OUTPUT_ID: { target: '.debouncing', internal: false },
            REMOVE_VARIABLE_OUTPUT_ID: { target: '.debouncing', internal: false },
          },
          states: {
            idle: {},
            debouncing: { after: { MUTATION_DEBOUNCE: 'mutating' } },
            mutating: {
              invoke: {
                id: 'updateNativeAutomationActionsMutation',
                src: 'updateNativeAutomationActionsMutation',
                onDone: 'idle',
                onError: {
                  target: 'failure',
                  actions: ['logError'],
                },
              },
            },
            failure: {},
          },
        },
      },
    },
    {
      actions: {
        assignPrompt: assign({ prompt: (_, event) => event.prompt }),
        assignInputWidgetId: assign({ inputWidgetId: (_, event) => event.inputWidgetId }),
        assignVariable: assign({
          variablesOutputIds: (ctx, event) => {
            const { index, variable } = event;
            const variablesOutputIds = [...ctx.variablesOutputIds];
            variablesOutputIds[index][0] = variable;
            return variablesOutputIds;
          },
        }),
        assignOutputId: assign({
          variablesOutputIds: (ctx, event) => {
            const { index, outputId } = event;
            const variablesOutputIds = [...ctx.variablesOutputIds];
            variablesOutputIds[index][1] = outputId;
            return variablesOutputIds;
          },
        }),
        assignVariableOutputId: assign({
          variablesOutputIds: (ctx, event) => {
            return match(event)
              .with({ type: 'REMOVE_VARIABLE_OUTPUT_ID' }, ({ index }) => {
                const variablesOutputIds = [...ctx.variablesOutputIds];
                variablesOutputIds.splice(index, 1);
                if (variablesOutputIds.length === 0) {
                  variablesOutputIds.push(['', undefined]);
                }
                return variablesOutputIds;
              })
              .with({ type: 'ADD_VARIABLE_OUTPUT_ID' }, () => {
                const variablesOutputIds = [...ctx.variablesOutputIds];
                variablesOutputIds.push(['', undefined]);
                return variablesOutputIds;
              })
              .with({ type: 'xstate.init' }, () => {
                const variablesOutputIds = [...ctx.variablesOutputIds];
                if (variablesOutputIds.length === 0) {
                  variablesOutputIds.push(['', undefined]);
                }
                return variablesOutputIds;
              })
              .exhaustive();
          },
        }),

        // define in component
        focusInputWidget: () => {},
        logError: makeErrorLoggerAction(id),
      },

      guards: {
        hasNoInputWidgetId: ctx => !ctx.inputWidgetId,
        hasNoOutputWidgets: ctx => ctx.variablesOutputIds.length === 0,
      },

      services: {
        updateNativeAutomationActionsMutation: async (ctx, _event) => {
          const { nativeAutomation } = aiTaskTemplate;
          const { actions } = nativeAutomation;

          return makeMutation(queryClient, {
            mutationKey: UpdateNativeAutomationActionsMutation.key,
            variables: {
              nativeAutomationId: nativeAutomation.id,
              actions: actions.map(action => {
                return match(action)
                  .with({ actionType: 'ExecuteAiPrompt', config: { promptType: 'Parse' } }, action =>
                    produce(action, draft => {
                      draft.config.prompt = ctx.prompt;

                      draft.config.promptInputs.variables = ctx.variablesOutputIds
                        .filter(([variable, outputId]) => Boolean(variable || outputId))
                        .map(([variable]) => variable ?? '')
                        .join(VARIABLES_SEPARATOR);

                      draft.config.promptInputs.source = Option(ctx.inputWidgetId)
                        .map(id => widgetIdKeyMap[id])
                        .map(key => `{{form.${key}}}`)
                        .getOrElse('');
                    }),
                  )
                  .with({ actionType: 'UpdateFormFields' }, action =>
                    produce(action, draft => {
                      draft.config.mapping = {};
                      ctx.variablesOutputIds
                        .filter(([variable, outputId]) => Boolean(variable || outputId))
                        .forEach(([variable, outputId]) => {
                          const key = NativeAutomation.makeMappingKey({
                            path: `output.${VariableUtils.encode(variable ?? '')}`,
                          });
                          if (outputId) {
                            draft.config.mapping[key] = outputId;
                          } else {
                            delete draft.config.mapping[key];
                          }
                        });
                    }),
                  )
                  .otherwise(() => action);
              }),
            },
            mutationFn: vars => UpdateNativeAutomationActionsMutation.mutationFn(vars),
            onSuccess: nativeAutomation => {
              queryClient.setQueryData<GetAllNativeAutomationsQuery.Response>(
                GetAllNativeAutomationsQuery.getKey({
                  templateRevisionId: aiTaskTemplate.taskTemplate.templateRevision.id,
                }),
                GetAllNativeAutomationsQuery.taskTemplateAutomationUpdater(
                  aiTaskTemplate.taskTemplate.id,
                  nativeAutomation,
                ),
              );
            },
          }).execute();
        },
      },
      delays: { MUTATION_DEBOUNCE: 500 },
    },
  );
};

export const VariableUtils = {
  encode(str: string) {
    return str
      .replace(/[_-]+/g, ' ')
      .split(' ')
      .map((word, index) => {
        if (index === 0) {
          return StringUtils.uncapitalize(word);
        }
        return StringUtils.capitalize(word);
      })
      .join('');
  },
};
