import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace CreateChecklistAssignmentMutation {
  export type Params = {
    assignmentId: Muid;
    checklistId: Muid;
    email: string;
  };

  export type Response = {};

  export const key = ['put', 'checklist-assignments'];
  export const mutationFn = ({ assignmentId, checklistId, email }: Params) =>
    axiosService
      .getAxios()
      .put<Response>(`/1/checklist-assignments/${assignmentId}`, {
        checklistId,
        email,
        autoAssign: false,
      })
      .then(res => res.data);

  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(CreateChecklistAssignmentMutation.mutationFn, { mutationKey: key, ...options });
  };
}
