import { OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { Checklist, EMAIL_REGEXP } from '@process-street/subgrade/process';
import { Icon, IconButton, Input, InputProps, Text, useDisclosure } from 'app/components/design/next';
import { AssignmentPicker } from 'app/features/one-off-tasks/components/shared/assignment-picker';
import { useGetAllOrganizationMembershipsQuery } from 'app/features/organization-memberships/query-builder';
import { useGetCurrentUserInfoQuery } from 'app/features/user/query-builder';
import * as React from 'react';
import { useAssignOrInviteMutation } from './use-assign-or-invite-mutation';

export type RightSidebarAssignmentsAssignmentPopoverProps = {
  assignedMemberships: Array<OrganizationMembershipWithUser>;
  checklist: Checklist;
};

const USERS_NOT_ALLOWED_TO_ASSIGN = [
  'Automation User',
  'All Members',
  'All Guests',
  'All Free Members',
  'All Anonymous',
  'All Admins',
];

type SearchInputProps = InputProps & {
  checklist: Checklist;
  onClose: () => void;
  organizationMemberships: Array<OrganizationMembershipWithUser>;
};

const SearchInput = ({ checklist, onClose, ...props }: SearchInputProps) => {
  const assignOrInviteMutation = useAssignOrInviteMutation({
    checklist,
    onCreateInvitationSuccess: () => {
      onClose();
    },
  });

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const email = props.value?.toString() ?? '';
    if (event.key !== 'Enter' || !email.match(EMAIL_REGEXP)) return;

    event.preventDefault();

    assignOrInviteMutation.mutate({
      checklistId: checklist.id,
      email,
    });
  };

  return <Input {...props} onKeyUp={handleKeyUp} />;
};

const EmptyState = () => (
  <Text px="3" color="gray.600">
    Search for a person in your organization by name or email address, or enter an email address to invite a Guest
    (External).
  </Text>
);

export const RightSidebarAssignmentsAssignmentButton = ({
  assignedMemberships,
  checklist,
}: RightSidebarAssignmentsAssignmentPopoverProps) => {
  const currentUserInfoQuery = useGetCurrentUserInfoQuery();
  const allOrganizationMembershipsQuery = useGetAllOrganizationMembershipsQuery({
    organizationId: currentUserInfoQuery.data?.organizationMembership?.organization.id,
    include: 'group',
  });
  const popoverDisclosure = useDisclosure();

  const createChecklistAssignmentMutation = useAssignOrInviteMutation({ checklist });

  const availableMemberships = React.useMemo(() => {
    if (!allOrganizationMembershipsQuery.data) {
      return [];
    }

    return allOrganizationMembershipsQuery.data
      .filter(om => !USERS_NOT_ALLOWED_TO_ASSIGN.includes(om.user.username))
      .filter(om => {
        return !assignedMemberships.some(am => am.id === om.id);
      });
  }, [allOrganizationMembershipsQuery.data, assignedMemberships]);

  const handleSelect = (om: OrganizationMembershipWithUser) => {
    createChecklistAssignmentMutation.mutate({
      checklistId: checklist.id,
      email: om.user.email,
    });
  };

  const isLoading = currentUserInfoQuery.isLoading || allOrganizationMembershipsQuery.isLoading;

  return (
    <AssignmentPicker
      onRemove={() => {}}
      onSelect={handleSelect}
      availableMemberships={availableMemberships}
      assignedMemberships={assignedMemberships}
      title="Assign to Workflow Run"
      hideAssigneeList
      popoverProps={{ ...popoverDisclosure }}
      components={{
        SearchInput: props => (
          <SearchInput
            {...props}
            organizationMemberships={allOrganizationMembershipsQuery.data ?? []}
            checklist={checklist}
            onClose={popoverDisclosure.onClose}
          />
        ),
        EmptyState,
      }}
    >
      <IconButton
        variant="solid"
        colorScheme="gray"
        size="sm"
        aria-label="Assign checklist"
        icon={<Icon color="gray.500" icon="plus" size="3" />}
        isLoading={isLoading}
      />
    </AssignmentPicker>
  );
};
