import { Muid, MuidUtils } from '../../core';
import { ExecuteAiPrompt, NativeAutomation, NativeAutomationLink, NativeAutomationWithLink } from '../../process';
import { generateAudit } from './audit-generator';
import { match, P } from 'ts-pattern';
import ExecuteCodeAction = NativeAutomation.ExecuteCodeAction;

export function generateNativeAutomationTrigger(trigger?: NativeAutomation.Trigger): NativeAutomation.Trigger {
  return (
    trigger ?? {
      triggerType: 'TaskCompleted',
      config: { taskTemplateId: MuidUtils.randomMuid() },
    }
  );
}

export function generateNativeAutomationAction(
  partial: Partial<NativeAutomation.Action> = {},
): NativeAutomation.Action {
  return match<typeof partial, NativeAutomation.Action>(partial)
    .with({ actionType: 'ExecuteAiPrompt', config: P.not(P.nullish) }, action => ({
      actionType: 'ExecuteAiPrompt',
      key: 'executeAiPrompt0',
      config: {
        ...ExecuteAiPrompt.PROMPT_CONFIGS_BY_TYPE.Custom,
        prompt: action.config.prompt ?? 'Tell me a story',
      } as ExecuteAiPrompt.GetPromptConfig<'Custom'>,
    }))
    .with({ actionType: 'UpdateFormFields', config: P.not(P.nullish) }, action => ({
      actionType: 'UpdateFormFields',
      key: 'updateFormFields0',
      config: {
        checklistRevisionIdPath: action.config.checklistRevisionIdPath ?? NativeAutomation.DEFAULT_MAPPING_KEY,
        mapping: action.config.mapping ?? {},
      },
    }))
    .otherwise(() => ({
      actionType: 'ExecuteAiPrompt',
      key: 'executeAiPrompt0',
      config: { promptType: 'Custom', prompt: 'Tell me a story' },
    }));
}

export function generateNativeAutomation(partial: Partial<NativeAutomation> = {}): NativeAutomation {
  return {
    id: MuidUtils.randomMuid(),
    stableId: MuidUtils.randomMuid(),
    organizationId: MuidUtils.randomMuid(),
    audit: generateAudit(),
    status: 'Active',
    trigger: generateNativeAutomationTrigger(),
    actions: [],
    automationType: 'AiTask',
    ...partial,
  };
}

export function generateNativeAutomationLink(partial: Partial<NativeAutomationLink> = {}): NativeAutomationLink {
  return {
    id: MuidUtils.randomMuid(),
    organizationId: MuidUtils.randomMuid(),
    taskTemplateId: MuidUtils.randomMuid(),
    nativeAutomationId: MuidUtils.randomMuid(),
    ...partial,
  };
}

export function generateNativeAutomationWithLink(
  partial: Partial<NativeAutomationWithLink> = {},
): NativeAutomationWithLink {
  return {
    automation: generateNativeAutomation(),
    link: {
      ...generateNativeAutomationLink(),
      nativeAutomationId: partial.automation?.id ?? MuidUtils.randomMuid(),
    },
    ...partial,
  };
}

export function generateAiTaskNativeAutomation<Config extends ExecuteAiPrompt.PromptConfig>(
  partial: Partial<{
    prompt: string;
    mapping: Record<string, Muid>;
    id: Muid;
    stableId: Muid;
    promptConfig: Config;
    promptType: ExecuteAiPrompt.PromptType;
  }> = {},
) {
  return generateNativeAutomation({
    automationType: 'AiTask',
    trigger: {
      triggerType: 'TaskCompleted',
      config: { taskTemplateId: MuidUtils.randomMuid() },
    },
    actions: [
      {
        actionType: 'ExecuteAiPrompt',
        key: 'executeAiPrompt0',
        config: partial.promptType
          ? ExecuteAiPrompt.PROMPT_CONFIGS_BY_TYPE[partial.promptType]
          : partial.promptConfig ??
            ({
              ...ExecuteAiPrompt.PROMPT_CONFIGS_BY_TYPE.Custom,
              prompt: partial.prompt ?? '',
            } as ExecuteAiPrompt.GetPromptConfig<'Custom'>),
      },
      {
        actionType: 'UpdateFormFields',
        key: 'updateFormFields0',
        config: {
          checklistRevisionIdPath: NativeAutomation.DEFAULT_MAPPING_KEY,
          mapping: partial.mapping ?? {
            [NativeAutomation.DEFAULT_MAPPING_KEY]: MuidUtils.randomMuid(),
          },
        },
      },
      {
        actionType: 'CompleteTask',
        key: 'completeTask0',
        config: {
          checklistRevisionIdPath: NativeAutomation.DEFAULT_MAPPING_KEY,
          taskTemplateId: MuidUtils.randomMuid(),
        },
      },
    ],
    ...partial,
  });
}

export function generateCodeTaskNativeAutomation(
  partial: Partial<{
    inputMapping: Record<string, string>;
    outputMapping: Record<string, Muid>;
    id: Muid;
    stableId: Muid;
    code: string;
  }> = {},
) {
  return generateNativeAutomation({
    automationType: 'CodeTask',
    trigger: {
      triggerType: 'TaskCompleted',
      config: { taskTemplateId: MuidUtils.randomMuid() },
    },
    actions: [
      generateExecuteCodeAction({ code: partial.code, inputData: partial.inputMapping }),
      {
        actionType: 'UpdateFormFields',
        key: 'updateFormFields0',
        config: {
          checklistRevisionIdPath: NativeAutomation.DEFAULT_MAPPING_KEY,
          mapping: partial.outputMapping ?? {
            [NativeAutomation.DEFAULT_MAPPING_KEY]: MuidUtils.randomMuid(),
          },
        },
      },
      {
        actionType: 'CompleteTask',
        key: 'completeTask0',
        config: {
          checklistRevisionIdPath: NativeAutomation.DEFAULT_MAPPING_KEY,
          taskTemplateId: MuidUtils.randomMuid(),
        },
      },
    ],
    ...partial,
  });
}

export function generateMathTaskNativeAutomation<
  Config extends NativeAutomation.GetActionConfigByType<'ExecuteMathFormula'>,
>(
  partial: Partial<{
    operands: { left: string; right: string };
    outputWidgetId: Muid;
    operator: NativeAutomation.Operator | null;
  }> = {},
) {
  return generateNativeAutomation({
    trigger: {
      triggerType: 'TaskCompleted',
      config: { taskTemplateId: MuidUtils.randomMuid() },
    },
    actions: [
      {
        actionType: 'ExecuteMathFormula',
        key: 'executeMathFormula0',
        config: {
          checklistRevisionIdPath: NativeAutomation.MATH_MAPPING_KEY,
          mapping: {
            [NativeAutomation.MATH_LEFT_OPERAND_MAPPING_KEY]: partial.operands?.left ?? MuidUtils.randomMuid(),
            [NativeAutomation.MATH_RIGHT_OPERAND_MAPPING_KEY]: partial.operands?.right ?? '0.2',
          },
          operator: partial.operator ?? (partial.operator === null ? null : 'Add'),
        } as Config,
      },
      {
        actionType: 'UpdateFormFields',
        key: 'updateFormFields0',
        config: {
          checklistRevisionIdPath: NativeAutomation.MATH_MAPPING_KEY,
          mapping: {
            [NativeAutomation.MATH_MAPPING_KEY]: partial.outputWidgetId ?? MuidUtils.randomMuid(),
          },
        },
      },
      {
        actionType: 'CompleteTask',
        key: 'completeTask0',
        config: {
          checklistRevisionIdPath: NativeAutomation.MATH_MAPPING_KEY,
          taskTemplateId: MuidUtils.randomMuid(),
        },
      },
    ],
    ...partial,
  });
}

export function generateCustomTemplateNotificationAutomations<
  Config extends NativeAutomation.GetActionConfigByType<'SendEmail'>,
>(
  partial: Partial<{
    customMessage?: string;
    status?: NativeAutomation['status'];
    recipients?: NativeAutomation.SendEmailActionRecipient[];
  }> = {},
) {
  return generateNativeAutomation({
    trigger: {
      triggerType: 'TaskCompleted',
      config: { taskTemplateId: MuidUtils.randomMuid() },
    },
    actions: [
      {
        actionType: 'SendEmail',
        key: 'sendEmail0',
        config: {
          emailTemplate: 'CustomTemplateNotification',
          emailTemplateConfig: {
            customNotificationType: 'OnTaskCompleted',
            checklistRevisionIdPath: MuidUtils.randomMuid(),
            customMessage: partial.customMessage ?? 'Hello World!',
          },
          recipients: partial.recipients ?? ([] as NativeAutomation.SendEmailActionRecipient[]),
        } as Config,
      },
    ],
    status: partial.status ?? 'Active',
  });
}

export function generateAutoSendRichEmailWidgetAutomation<
  Config extends NativeAutomation.GetActionConfigByType<'SendRichEmailWidget'>,
>(
  partial: Partial<{
    richEmailFormFieldWidgetId: Muid;
    status: NativeAutomation['status'];
  }> = {},
) {
  return generateNativeAutomation({
    trigger: {
      triggerType: 'TaskCompleted',
      config: { taskTemplateId: MuidUtils.randomMuid() },
    },
    actions: [
      {
        actionType: 'SendRichEmailWidget',
        key: 'sendRichEmailWidget0',
        config: {
          checklistRevisionIdPath: '$trigger.task.checklistRevision.id',
          richEmailFormFieldWidgetId: partial.richEmailFormFieldWidgetId ?? MuidUtils.randomMuid(),
        } as Config,
      },
    ],
    status: partial.status ?? 'Active',
  });
}

export function generateNativeAutomationLog(partial: Partial<NativeAutomation.Log> = {}): NativeAutomation.Log {
  return {
    id: MuidUtils.randomMuid(),
    createdAt: Date.now(),
    executionId: MuidUtils.randomMuid(),
    currentActionIndex: 0,
    currentActionType: 'UpdateFormFields',
    currentActionStatus: 'Waiting',
    automationStatus: 'Running',
    ...partial,
  };
}

export function generateExecuteMathFormulaAction(
  options: Partial<{
    leftOperand: string;
    rightOperand: string;
    operator: NativeAutomation.Operator | null;
  }> = {},
): NativeAutomation.GetActionByType<'ExecuteMathFormula'> {
  return {
    actionType: 'ExecuteMathFormula',
    key: 'executeMathFormula0',
    config: {
      checklistRevisionIdPath: '',
      mapping: {
        [NativeAutomation.MATH_LEFT_OPERAND_MAPPING_KEY]: options.leftOperand ?? MuidUtils.randomMuid(),
        [NativeAutomation.MATH_RIGHT_OPERAND_MAPPING_KEY]: options.rightOperand ?? '0.2',
      },
      operator: options.operator === null ? undefined : options.operator ?? 'Add',
    },
  };
}

export function generateUpdateFormFieldsAction(
  options: Partial<{ output: string; mapping: Record<string, string> }> = {},
): NativeAutomation.GetActionByType<'UpdateFormFields'> {
  return {
    actionType: 'UpdateFormFields',
    key: 'updateFormFields0',
    config: {
      checklistRevisionIdPath: '',
      mapping: options.mapping ?? {
        [NativeAutomation.DEFAULT_MAPPING_KEY]: options.output ?? MuidUtils.randomMuid(),
      },
    },
  };
}

export function generateExecuteAiPromptAction(
  options: Partial<{ config: ExecuteAiPrompt.PromptConfig; prompt: string; systemPrompt: string }> = {},
): NativeAutomation.GetActionByType<'ExecuteAiPrompt'> {
  return {
    actionType: 'ExecuteAiPrompt',
    key: 'executeAiPrompt0',
    config: options.config ?? {
      promptType: 'Custom',
      prompt: options.prompt ?? 'Tell me a story',
      systemPrompt: options.systemPrompt ?? '',
    },
  };
}

export function generateCompleteTaskAction(): NativeAutomation.GetActionByType<'CompleteTask'> {
  return {
    actionType: 'CompleteTask',
    config: {
      checklistRevisionIdPath: '',
      taskTemplateId: MuidUtils.randomMuid(),
    },
    key: 'completeTask0',
  };
}

export function generateExecuteCodeAction(
  options: Partial<ExecuteCodeAction['config']> = {},
): NativeAutomation.GetActionByType<'ExecuteCode'> {
  return {
    actionType: 'ExecuteCode',
    config: {
      code: options.code ?? 'console.log("Hello World!")',
      ...options,
    },
    key: 'executeCode0',
  };
}
