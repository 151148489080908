import { useCallback } from 'react';
import { DropzoneState, useDropzone } from 'react-dropzone';
import { FormFieldValue, FormFieldWidget, Template } from '@process-street/subgrade/process';
import { UploadEventParams } from './send-email-widget-machine';

export const SIZE_TOO_LARGE = 'size-too-large';
const MAX_FILE_SIZE_MB = 25;
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;

type UploadAttachmentReturn = {
  dropzoneState: DropzoneState;
};

type UploadChecklistEmailAttachmentProps = {
  taskId: Template['id'];
  widgetId: FormFieldWidget['id'];
  formFieldValue?: FormFieldValue;
  onCreateFormFieldValue: () => FormFieldValue;
  onStartUpload: (params: UploadEventParams) => void;
  setProgress: (progress: number | undefined) => void;
  totalSize: number;
};

export const useUploadChecklistEmailAttachment = ({
  taskId,
  widgetId,
  formFieldValue,
  onCreateFormFieldValue,
  onStartUpload,
  setProgress,
  totalSize,
}: UploadChecklistEmailAttachmentProps): UploadAttachmentReturn => {
  const getFormFieldValue = () => {
    const value = onCreateFormFieldValue();
    return value;
  };
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) return;

      const [file] = acceptedFiles;

      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = () => {
        if (reader.readyState === 2 && reader.result !== null) {
          onStartUpload({
            formFieldValue: formFieldValue ?? getFormFieldValue(),
            file,
            data: reader.result,
            fileName: file.name,
            mimeType: file.type,
            onProgress: (progress: number | undefined) => setProgress(progress),
          });
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- memoization
    [taskId, widgetId, formFieldValue],
  );

  const dropzoneState = useDropzone({
    onDrop,
    validator: file => {
      if (file.size > MAX_FILE_SIZE - totalSize) {
        return {
          code: SIZE_TOO_LARGE,
          message: `Uploaded files must not exceed ${MAX_FILE_SIZE_MB} MB in total.`,
        };
      }
      return null;
    },
  });

  return {
    dropzoneState,
  };
};
