import {
  faBell,
  faCalendarPlus,
  faClock,
  faExclamationCircle,
  faFileEdit,
  faFolder,
  faMessageCaptions,
  faPartyHorn,
  faPlay,
  faPlug,
  faRocketLaunch,
  faStars,
  faUserLock,
  faUserPlus,
  faLockKeyhole,
  faUsers,
  faDatabase,
  faCirclePlay,
  faThumbsUp,
  faSquareCheck,
  faCircleCheck,
  faHexagonXmark,
} from '@fortawesome/pro-duotone-svg-icons';

export const fadLibrary = {
  faBell,
  faCalendarPlus,
  faClock,
  faExclamationCircle,
  faFileEdit,
  faFolder,
  faMessageCaptions,
  faPartyHorn,
  faPlay,
  faPlug,
  faRocketLaunch,
  faStars,
  faUsers,
  faUserLock,
  faLockKeyhole,
  faUserPlus,
  faDatabase,
  faCirclePlay,
  faThumbsUp,
  faSquareCheck,
  faCircleCheck,
  faHexagonXmark,
};
