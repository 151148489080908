import { OrganizationMembershipWithUser, toModelRef, User } from '@process-street/subgrade/core';
import { Checklist } from '@process-street/subgrade/process';
import { getAvatar } from 'app/components/common/Avatar';
import {
  Icon,
  HStack,
  Text,
  VStack,
  AvatarGroup,
  Tooltip,
  useDisclosure,
  Box,
  SkeletonCircle,
  ListItem,
  IconButton,
  useToast,
} from 'app/components/design/next';
import { ChakraAvatar } from 'app/components/design/next/chakra-avatar';
import { GetChecklistAssignmentsQuery } from 'app/features/checklists/query-builder';
import { useCurrentUser } from 'app/hooks/use-current-user';
import { isFunction, sortBy } from 'lodash';
import * as React from 'react';
import { Option } from 'space-monad';
import { AssignmentsPopover } from '../../task-list/components/task-list-item-assignee-indicator-popover';
import { match, P } from 'ts-pattern';
import { ArrayUtils } from 'app/utils/array-utils';
import { RightSidebarAssignmentsAssignmentButton as AssignmentButton } from './right-sidebar-assignments-assignment-popover';
import { isGroupUser } from '@process-street/subgrade/util/user-type-utils';
import { ChecklistAssignment } from '@process-street/subgrade/role-assignment';
import { DefaultErrorMessages } from 'app/components/utils/error-messages';
import invariant from 'tiny-invariant';
import { useDeleteChecklistAssignmentMutation } from 'app/features/checklists/query-builder/delete-checklist-assignment-mutation';
import { useQueryClient } from 'react-query';

export type RightSidebarAssignmentsProps = {
  checklist: Checklist;
  isReadOnly?: boolean;
};

const LoadingState = () => {
  const skeletonProps = {
    w: '8',
    h: '8',
    borderWidth: '1px',
    borderColor: 'white',
    borderStyle: 'solid',
  };

  return (
    <VStack w="full" alignItems="flex-start">
      <Text color="gray.400" variant="-1u">
        Assigned Users
      </Text>

      <HStack w="full" spacing={0} justifyContent="flex-start" alignItems="center">
        <SkeletonCircle {...skeletonProps} />
        <SkeletonCircle {...skeletonProps} ml={-2} />
        <SkeletonCircle {...skeletonProps} ml={-2} />
      </HStack>
    </VStack>
  );
};

const EmptyState = ({ checklist, isReadOnly }: { checklist: Checklist; isReadOnly?: boolean }) => (
  <HStack w="full" alignItems="center" justifyContent="space-between">
    <VStack w="full" alignItems="flex-start" spacing={0}>
      <Text color="gray.400" variant="-1u">
        {isReadOnly ? 'Assigned Users' : 'Assign Users'}
      </Text>
      {isReadOnly && (
        <Text color="gray.400" fontWeight="500">
          No users assigned
        </Text>
      )}
    </VStack>

    {!isReadOnly && <AssignmentButton assignedMemberships={[]} checklist={checklist} />}
  </HStack>
);

const ErrorState = () => (
  <VStack w="full" alignItems="flex-start">
    <Text color="gray.400" variant="-1u">
      Assign Users
    </Text>

    <HStack>
      <Icon color="red.500" icon="warning" size="4" />
      <Text color="red.500" variant="-1">
        Error loading assignments
      </Text>
    </HStack>
  </VStack>
);

const AssignmentsPopoverItem = ({
  user,
  assignments,
  isReadOnly,
}: {
  user: User;
  assignments: Array<ChecklistAssignment>;
  isReadOnly?: boolean;
}) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const deleteChecklistAssignmentMutation = useDeleteChecklistAssignmentMutation({
    onMutate: ({ assignmentId }) => {
      const cacheManager = GetChecklistAssignmentsQuery.createCacheUtils(queryClient);
      const assignment = assignments.find(assignment => assignment.id === assignmentId);

      if (!assignment) return;

      const queryParams = { checklistId: assignment.checklist.id };
      const existingData = cacheManager.get(queryParams);

      cacheManager.remove(queryParams, assignmentId);

      const rollback = () => {
        return cacheManager.set(queryParams, existingData);
      };

      return rollback;
    },
    onSuccess: (_data, variables) => {
      const assignment = assignments.find(assignment => assignment.id === variables.assignmentId);

      if (!assignment) return;

      void GetChecklistAssignmentsQuery.invalidate(queryClient, { checklistId: assignment.checklist.id });
    },
    onError: (_error, _variables, rollback) => {
      toast({
        status: 'error',
        title: 'We could not unassign the user',
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
      if (isFunction(rollback)) {
        rollback();
      }
    },
  });

  const handleRemove = () => {
    try {
      const assignment = assignments.find(
        assignment => toModelRef(assignment.organizationMembership)?.user.id === user.id,
      );

      invariant(assignment, 'Assignment not found');

      deleteChecklistAssignmentMutation.mutate({
        assignmentId: assignment.id,
      });
    } catch (e) {
      console.error(e);

      toast({
        status: 'error',
        title: 'We could not unassign the user',
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    }
  };
  return (
    <ListItem key={user.id} as={HStack} alignItems="center" justifyContent="space-between" spacing={2}>
      <HStack justifyContent="flex-start" alignItems="center">
        <ChakraAvatar user={user} />

        <VStack alignItems="flex-start" spacing="px">
          <Text w="full" variant="-1" fontWeight="medium">
            {user.username}
          </Text>
          <Text w="full" variant="-2" color="gray.400">
            {isGroupUser(user) ? 'Group' : user.email}
          </Text>
        </VStack>
      </HStack>

      {!isReadOnly && (
        <IconButton
          variant="unstyled"
          p="1"
          borderRadius="full"
          aria-label="Unassign user"
          size="sm"
          icon={<Icon icon="user-xmark" size="4" color="gray.500" />}
          onClick={handleRemove}
          isLoading={deleteChecklistAssignmentMutation.isLoading}
        />
      )}
    </ListItem>
  );
};

const WithAssignments = ({
  assignments,
  checklist,
  isReadOnly,
}: {
  assignments: GetChecklistAssignmentsQuery.Response;
  checklist: Checklist;
  isReadOnly?: boolean;
}) => {
  const currentUser = useCurrentUser();
  const assignmentsPopoverDisclosure = useDisclosure();

  const assignedMemberships = React.useMemo(
    () =>
      assignments
        .map(assignment =>
          Option(assignment)
            .map(assignment => toModelRef(assignment.organizationMembership))
            .get(),
        )
        .filter((maybeMembership): maybeMembership is OrganizationMembershipWithUser => {
          return (maybeMembership && Boolean(toModelRef(maybeMembership.user))) ?? false;
        }),
    [assignments],
  );

  const users = React.useMemo(
    () =>
      assignments
        ?.map(assignment =>
          Option(assignment)
            .map(assignment => toModelRef(assignment.organizationMembership))
            .map(organizationMembership => toModelRef(organizationMembership))
            .map(om => om.user)
            .get(),
        )
        .filter((maybeUser): maybeUser is User => Boolean(maybeUser)) ?? [],
    [assignments],
  );

  // Sort users by username, with the current user first
  const sortedUsers = React.useMemo(() => {
    const sorted = sortBy(users, user => user.username.toLowerCase());

    const currentUserIndex = currentUser ? sorted.findIndex(user => user.id === currentUser.id) : -1;

    if (currentUserIndex !== -1) {
      const usersWithoutCurrentUser = sorted.filter((_, index) => index !== currentUserIndex);
      return [sorted[currentUserIndex], ...usersWithoutCurrentUser];
    }

    return sorted;
  }, [users, currentUser]);

  const [user] = sortedUsers;

  const { title } = getAvatar(user);
  const count = users.length - 1;
  const tooltipLabel =
    count > 0 ? `Assigned to ${title} and ${count} ${count > 1 ? 'others' : 'other'}` : `Assigned to ${title}`;

  return (
    <VStack w="full" alignItems="flex-start">
      <Text color="gray.400" variant="-1u">
        Assigned Users
      </Text>

      <HStack w="full" justifyContent="space-between" alignItems="center">
        <Tooltip hasArrow shouldWrapChildren label={tooltipLabel} isDisabled={assignmentsPopoverDisclosure.isOpen}>
          <AssignmentsPopover
            assignments={assignments}
            title="Workflow Run assignees"
            disclosure={assignmentsPopoverDisclosure}
            components={{
              Item: props => <AssignmentsPopoverItem {...props} isReadOnly={isReadOnly} />,
            }}
          >
            <Box cursor="pointer">
              <AvatarGroup
                aria-label="Approval assignees"
                max={5}
                sx={{
                  '.chakra-avatar__excess': {
                    w: 8,
                    h: 8,
                    fontSize: 'sm',
                  },
                  '.chakra-avatar__initials': {
                    fontSize: 'sm',
                  },
                }}
              >
                {sortedUsers.map(user => (
                  <ChakraAvatar user={user} key={user.id} w="8" h="8" />
                ))}
              </AvatarGroup>
            </Box>
          </AssignmentsPopover>
        </Tooltip>

        {!isReadOnly && <AssignmentButton assignedMemberships={assignedMemberships} checklist={checklist} />}
      </HStack>
    </VStack>
  );
};

export const RightSidebarAssignments = ({ checklist, isReadOnly }: RightSidebarAssignmentsProps) => {
  const assignmentsQuery = GetChecklistAssignmentsQuery.useQuery({ checklistId: checklist.id });

  return match(assignmentsQuery)
    .with({ status: 'loading' }, () => <LoadingState />)
    .with({ status: 'error' }, () => <ErrorState />)
    .with({ status: 'success', data: P.when(ArrayUtils.isEmptyArray) }, () => (
      <EmptyState checklist={checklist} isReadOnly={isReadOnly} />
    ))
    .with({ status: 'success', data: P.when(ArrayUtils.isNonEmptyArray) }, ({ data: assignments }) => (
      <WithAssignments assignments={assignments} checklist={checklist} isReadOnly={isReadOnly} />
    ))
    .otherwise(() => <EmptyState checklist={checklist} isReadOnly={isReadOnly} />);
};
