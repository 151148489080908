import { AutomatedTaskTemplateId, Widget } from '@process-street/subgrade/process';
import { match } from 'ts-pattern';
import React from 'react';
import { Muid } from '@process-street/subgrade/core';
import { GetAllNativeAutomationsQuery } from 'features/native-automations/query-builder';

type WidgetAutomatedTasksMap = Record<Widget['id'], AutomatedTaskTemplateId[]>;

export function useOutputWidgetAutomatedTasksMap({
  templateRevisionId,
}: {
  templateRevisionId?: Muid;
}): WidgetAutomatedTasksMap {
  const taskTemplateAutomations = GetAllNativeAutomationsQuery.useGetTaskTemplateAutomationsMapQuery({
    templateRevisionId,
  });

  return React.useMemo(
    () => (taskTemplateAutomations.data ? makeWidgetAutomatedTasksMap(taskTemplateAutomations.data) : {}),
    [taskTemplateAutomations.data],
  );
}

/**
 * Extract connected output widgets from a list of automations.
 * Compatible with Code and AI automations because both contain UpdateFormFields actions.
 * @param automationsByTaskTemplateId automations by task template ID
 */
export function makeWidgetAutomatedTasksMap(
  automationsByTaskTemplateId: GetAllNativeAutomationsQuery.TaskTemplateAutomationMap,
): WidgetAutomatedTasksMap {
  const automations = Object.entries(automationsByTaskTemplateId);
  return automations.reduce((acc, [taskTemplateId, nativeAutomations]) => {
    nativeAutomations.forEach(nativeAutomation => {
      nativeAutomation.actions.forEach(action => {
        match(action)
          .with({ actionType: 'UpdateFormFields' }, ({ config: { mapping } }) => {
            const outputWidgetIds = Object.values(mapping);
            outputWidgetIds.forEach(outputWidgetId => {
              if (!acc[outputWidgetId]) acc[outputWidgetId] = [];
              acc[outputWidgetId].push({ taskTemplateId, nativeAutomation });
            });
          })
          .otherwise(() => {});
      });
    });
    return acc;
  }, {} as WidgetAutomatedTasksMap);
}
