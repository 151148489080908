import {
  FormFieldWidgetWithValue,
  isTextareaFormFieldWidget,
  SimpleFieldValue,
} from '@process-street/subgrade/process';
import { Box } from 'components/design/next';
import { TextAreaRichEditorHelper } from 'app/pages/runs/_id/components/checklist-textarea-form-field-widget/textarea-rich-editor-helper';
import { escapeHtml } from 'markdown-it/lib/common/utils';
import * as React from 'react';

export type ApprovalWidgetTextRendererProps = {
  widget: FormFieldWidgetWithValue;
};

export const ApprovalWidgetTextRenderer = ({ widget }: ApprovalWidgetTextRendererProps) => {
  const rendererValue = React.useMemo(() => {
    const isRichText = isTextareaFormFieldWidget(widget) && widget.config.format === 'RichText';
    const escapedValue = escapeHtml(
      (widget.formFieldValue?.fieldValue && (widget.formFieldValue?.fieldValue as SimpleFieldValue).value) ?? '-',
    );
    return isRichText ? TextAreaRichEditorHelper.markdown2html(escapedValue) : escapedValue;
  }, [widget]);

  return (
    <Box color="gray.600" fontSize="md" fontWeight="normal" dangerouslySetInnerHTML={{ __html: rendererValue }}></Box>
  );
};
