import { AutomatedTaskTemplateId, NativeAutomation } from '@process-street/subgrade/process';
import { Circle, Icon, IconProps, SquareProps, Text, TextProps } from 'components/design/next';
import React from 'react';
import { match } from 'ts-pattern';

export const ProcessPeteCircle: React.FC<React.PropsWithChildren<SquareProps>> = props => {
  return <Circle bg="gray.100" p="1.5" size="22px" {...props} />;
};

export const ProcessPeteIcon: React.FC<React.PropsWithChildren<Partial<IconProps>>> = props => {
  return <Icon icon="sparkles" color="gray.500" size="4" variant="far" {...props} />;
};

export const CodeTaskIcon: React.FC<React.PropsWithChildren<Partial<IconProps>>> = props => {
  return <Icon icon="code" color="gray.500" size="4" variant="far" {...props} />;
};

export const AutomatedTaskAuditHelperText: React.FC<React.PropsWithChildren<TextProps>> = props => {
  return <Text as="span" fontWeight="bold" variant="-2" {...props} />;
};

export type CommonAuditProps = {
  /* Connected automated tasks */
  automatedTaskTemplates: AutomatedTaskTemplateId[];
  /** The latest native automation that has been executed */
  nativeAutomation?: NativeAutomation;
};

export function getAutomatedTaskOutputTooltip(automatedTaskTemplates: AutomatedTaskTemplateId[]) {
  const isAiTaskOutput = NativeAutomation.hasAutomationOfType(automatedTaskTemplates, 'AiTask');
  const isCodeTaskOutput = NativeAutomation.hasAutomationOfType(automatedTaskTemplates, 'CodeTask');

  const outputTypeCopy = match({ isAiTaskOutput, isCodeTaskOutput })
    .with({ isAiTaskOutput: true, isCodeTaskOutput: true }, () => 'an AI Task and a Code Task')
    .with({ isAiTaskOutput: false, isCodeTaskOutput: true }, () => 'a Code Task')
    .otherwise(() => 'an AI Task');

  return `This form field is an output of ${outputTypeCopy}`;
}
