import {
  GetAllChecklistWidgetsQuery,
  GetAllChecklistWidgetsQueryResponse,
  UpdateFormFieldValueMutation,
  UpdateFormFieldValueMutationParams,
} from 'features/widgets/query-builder';
import uniqBy from 'lodash/uniqBy';
import { QueryClient } from 'react-query';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { FormFieldValue } from '@process-street/subgrade/process';

export const makeUpdateFormFieldValueMutation = <T extends FormFieldValue = FormFieldValue>({
  queryClient,
  body,
}: {
  queryClient: QueryClient;
  body: UpdateFormFieldValueMutationParams;
}) => {
  return makeMutation(queryClient, {
    mutationKey: UpdateFormFieldValueMutation.key,
    variables: body,
    mutationFn: UpdateFormFieldValueMutation.mutationFn<T>,
    onSuccess: data => {
      queryClient.setQueryData<GetAllChecklistWidgetsQueryResponse>(
        GetAllChecklistWidgetsQuery.getKey({ checklistRevisionId: body.checklistRevisionId }),
        current => uniqBy([...data.checklistWidgets, ...(current ?? [])], w => w.id),
      );
    },
  });
};
