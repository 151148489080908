import { AuditMetadata } from '@process-street/subgrade/core';
import { DateFormat, DateUtils, UserUtils } from '@process-street/subgrade/util';
import { Box, Text, Tooltip } from 'components/design/next';
import { usePrintStore } from 'components/react-root/print-store';
import { useUserByIdQuery } from 'app/features/user/query-builder/user-by-id';
import React from 'react';
import { useGetCurrentUserQuery } from 'app/features/user/query-builder';

export type FormFieldAuditProps = {
  audit: AuditMetadata;
  isDisabled: boolean;
};

export const FormFieldAudit = ({ audit, isDisabled }: FormFieldAuditProps) => {
  const currentUserQuery = useGetCurrentUserQuery();

  const { isPrintView, isPrintPage } = usePrintStore();
  const isPrintMode = isPrintView || isPrintPage;

  const auditedByUserQuery = useUserByIdQuery({ id: audit.updatedBy.id }, { retry: false });

  // Note - The user may still be undefined if they've left the organization
  const auditedBy = auditedByUserQuery.data;

  const username = UserUtils.getUsername(auditedBy);

  const formattedPrintDate = auditedBy ? DateUtils.formatDateFromNow(audit.updatedDate) : 'at some point';

  const formattedDate = auditedBy
    ? DateUtils.formatDateTime(audit.updatedDate, undefined, currentUserQuery.data?.timeZone)
    : 'at some point';

  const formattedDateWithTimezone = auditedBy
    ? DateUtils.formatDateTime(
        audit.updatedDate,
        DateFormat.DateTimeLongWeekdayShortMonthWithZone,
        currentUserQuery.data?.timeZone,
      )
    : 'at some point';

  if (auditedByUserQuery.isLoading) return null;

  return (
    <Box opacity={isDisabled ? '0.4' : '1'}>
      <Tooltip label={formattedDate} hasArrow>
        <Text as="span" color={isPrintMode ? 'gray.200' : 'gray.400'} fontWeight="bold" variant="-2">
          Updated by{' '}
          <Text as="span" variant="inherit">
            {username}{' '}
          </Text>
          {isPrintMode ? (
            <Text as="span" variant="inherit">
              {formattedDateWithTimezone}
            </Text>
          ) : (
            <Text as="span" variant="inherit">
              {formattedPrintDate}
            </Text>
          )}
        </Text>
      </Tooltip>
    </Box>
  );
};
