import * as React from 'react';
import { FormControl } from 'components/design/next';
import { Box, Center, Spinner, useToken } from '@chakra-ui/react';
import { useSpinDelay } from 'spin-delay';
import { useAsync } from 'react-use';
import ecmaScriptGlobalKeys from './ecmascript-global-keys.json';
import { CodeTaskUtils } from 'pages/templates/_id/components/code-task-template-editor/code-task-utils';
import { NativeAutomation } from '@process-street/subgrade/process';
import { useDebouncedCallback, useThrottledCallback } from 'use-debounce';

export type CodeEditorProps = {
  codeAction: NativeAutomation.ExecuteCodeAction;
  updateFormFieldsAction: NativeAutomation.UpdateFormFieldsAction;
  onUpdate: (code: string) => void;
  isReadOnly: boolean;
};

const EDITOR_HEIGHT = '250px';
// for safety, save every 10 seconds, 1 second after finishing typing
const CODE_SAVE_THROTTLE = 10000;
const CODE_SAVE_DEBOUNCE = 1000;

export const CodeEditor: React.FC<CodeEditorProps> = ({ codeAction, updateFormFieldsAction, onUpdate, isReadOnly }) => {
  const [value, setValue] = React.useState<string>(codeAction.config.code ?? '');
  const throttledOnUpdate = useThrottledCallback(onUpdate, CODE_SAVE_THROTTLE);
  const debouncedOnUpdate = useDebouncedCallback(throttledOnUpdate, CODE_SAVE_DEBOUNCE);

  const handleChange = (value: string) => {
    setValue(value);
    debouncedOnUpdate(value);
  };

  const handleBlur = () => {
    throttledOnUpdate.cancel();
    debouncedOnUpdate.cancel();
    onUpdate(value);
  };

  // this can be reworked to React.use with React 19
  // React 18 would in theory support React.lazy for React components,
  // but it's easier to treat both components and libraries with the same approach
  const modules = useAsync(async () => {
    const javascriptModule = import('@codemirror/lang-javascript');
    const CodeMirror = import('@uiw/react-codemirror');
    await Promise.all([javascriptModule, CodeMirror]);

    return {
      CodeMirror: (await CodeMirror).default,
      js: await javascriptModule,
    };
  });

  const outputData = React.useMemo(
    () =>
      Object.fromEntries(
        Object.entries(updateFormFieldsAction?.config.mapping ?? []).map(([key, value]) => [
          CodeTaskUtils.extractKeyFromJsonPath(key),
          value,
        ]),
      ),
    [updateFormFieldsAction?.config.mapping],
  );

  const completionSource = React.useMemo(() => {
    return { ...getEcmaScriptGlobals(), inputData: codeAction.config.inputData, outputData };
  }, [codeAction.config.inputData, outputData]);

  const shouldShowSpinner = useSpinDelay(!modules);
  const fallback = <Center h={EDITOR_HEIGHT}>{shouldShowSpinner && <Spinner />}</Center>;

  return (
    <FormControl flex="1">
      {modules.value ? (
        <CodeEditorThemeProvider>
          <modules.value.CodeMirror
            value={value}
            height={EDITOR_HEIGHT}
            extensions={[
              modules.value.js.javascript(),
              modules.value.js.javascriptLanguage.data.of({
                // compile completion source (including fields like Array.from) from current global object
                autocomplete: modules.value.js.scopeCompletionSource(completionSource),
              }),
            ]}
            onChange={handleChange}
            onBlur={handleBlur}
            readOnly={isReadOnly}
          />
        </CodeEditorThemeProvider>
      ) : (
        fallback
      )}
    </FormControl>
  );
};

/**
 * The best way would be to use EditorView.theme,
 * but that's a static import which would prevent us from bundle splitting.
 * Vanilla CSS allows us not to import anything else from the library.
 */
const CodeEditorThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const blue500 = useToken('colors', 'blue.500');
  return (
    <Box
      sx={{
        '.cm-editor': {
          fontSize: 'md',
          border: `1px solid`,
          borderColor: 'gray.200',
          borderRadius: 'base',
        },
        '.cm-editor.cm-focused': {
          outline: 'none',
          borderColor: 'blue.500',
          boxShadow: `0 0 0 1px ${blue500}`,
        },
        '.cm-gutters': {
          color: 'gray.800',
          backgroundColor: 'brand.50',
          borderTopLeftRadius: 'base',
          borderBottomLeftRadius: 'base',
        },
        '.cm-activeLine, .cm-activeLineGutter': {
          // has to be semi-transparent so that text selection shows through
          backgroundColor: 'rgba(0, 0, 0, 0.07)',
        },
      }}
    >
      {children}
    </Box>
  );
};

// non-standard ECMAScript globals that are available both in the browser and Node
const NON_STANDARD_GLOBALS = ['console', 'fetch', 'setTimeout', 'clearTimeout', 'setInterval', 'clearInterval'];

/**
 * Extracts ECMAScript globals from the current browser context, for autocompletion.
 * This is needed because the user is creating Node.js code in a browser environment,
 * so we need the minimum set of globals.
 * */
function getEcmaScriptGlobals() {
  const globalsSet = new Set(Object.getOwnPropertyNames(globalThis));
  const globalKeys = ecmaScriptGlobalKeys.concat(NON_STANDARD_GLOBALS);
  return Object.fromEntries(
    // list copied from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects
    globalKeys
      .filter(key => globalsSet.has(key))
      .map(key => {
        // @ts-expect-error -- we know that the key exists
        const value = globalThis[key] as unknown;
        return [key, value];
      }),
  );
}
