import * as React from 'react';
import { FormControl, FormErrorMessage, Icon, InputGroup, InputLeftElement, VStack } from 'components/design/next';
import { EmailFormFieldActor } from './email-form-field-machine';
import { FormResponseLabel } from '../common';
import { SelectableDisabledInput } from 'app/pages/responses/_id/components/form-fields/common/ui';
import { useMatch } from '@process-street/adapters/navigation';
import { useEmailFormField } from './use-email-form-field';
import { MaskedInput } from 'app/features/widgets/components/masked-input';
import { FormFieldValueHelperText } from 'app/pages/runs/_id/components/form-field-value-helper-text';

export interface EmailFormFieldProps {
  actor: EmailFormFieldActor;
}

export const EmailFormField: React.FC<React.PropsWithChildren<EmailFormFieldProps>> = ({ actor }) => {
  const {
    bgColor,
    errorMessage,
    formFieldValue,
    html,
    inputNode,
    isAutofocused,
    isHiddenByRule,
    isInputDisabled,
    isInvalid,
    value,
    widget,
    onBlur,
    onChange,
    onFocus,
    onReset,
    onSetNode,
  } = useEmailFormField({ actor });

  const isChecklist = useMatch('checklistV2');

  const ref = React.useRef<HTMLDivElement | null>(null);

  return isHiddenByRule ? null : (
    <FormControl
      ref={node => {
        ref.current = node;
        if (node && !inputNode) {
          onSetNode(node);
        }
      }}
      as={VStack}
      alignItems="stretch"
      isInvalid={isInvalid}
      isRequired={widget.required}
    >
      <FormResponseLabel>{widget.label || 'Untitled Email'}</FormResponseLabel>
      <InputGroup>
        <InputLeftElement px="3">
          {/* mt because it looks slightly off center to input */}
          <Icon mt="px" icon="envelope" size="4" color="gray.400" />
        </InputLeftElement>
        <MaskedInput html={html} fontSize="md" maskProps={{ pt: '7.5px', pl: '39px' }} w={{ base: '100%', lg: 78 }}>
          <SelectableDisabledInput
            w={{ base: '100%', lg: '78' }}
            borderColor="gray.300"
            bgColor={bgColor}
            type="email"
            value={value}
            placeholder={widget.config.placeholder || 'email@address.com'}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            isDisabled={isInputDisabled}
            autoFocus={isAutofocused}
            pl="10"
          />
        </MaskedInput>
      </InputGroup>
      {isChecklist && (
        <FormFieldValueHelperText
          defaultValue={widget.config.defaultValue}
          formFieldValue={formFieldValue}
          onResetDefaultValue={onReset}
        />
      )}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
