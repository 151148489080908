import * as React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  Spacer,
  useBreakpointValue,
  VStack,
} from 'components/design/next';
import { ExecuteAiPrompt, isFormFieldWidget, isTextareaFormFieldWidget } from '@process-street/subgrade/process';
import { useMachine } from '@xstate/react';
import { makeParseAiTaskFormMachine } from './parse-ai-task-form-machine';
import { useAiTaskTemplateFormContext } from '../../ai-task-template-form-context';
import { ProcessPeteTextarea, ProcessPeteTextareaHeader, ProcessPeteTextareaHeaderTitle } from '../../../common';
import { TestAiTaskModal } from '../../../test-ai-task-modal';
import { useQueryClient } from 'react-query';
import { SelectInstance } from 'react-select';
import { AiTaskWidgetOption, AiTaskWidgetSelect } from '../ai-task-widget-select';
import { BlvdSelectHelpers } from 'components/design/BlvdSelect/helpers/blvd-select-helpers';
import { isNativeAutomationPristine } from '../../is-native-automation-pristine';
import { match } from 'ts-pattern';
import { ProcessPeteIcon } from 'pages/runs/_id/components/automated-task-audit/common';

export interface ParseAiTaskFormProps {}

export const ParseAiTaskForm: React.FC<React.PropsWithChildren<ParseAiTaskFormProps>> = () => {
  const inputWidgetRef = React.useRef<SelectInstance<AiTaskWidgetOption>>(null);

  const [isFocused, setIsFocused] = React.useState(false);

  const { widgets, isDisabled, aiTaskTemplate, templateRevision, setIsPristine } = useAiTaskTemplateFormContext();
  const { nativeAutomation } = aiTaskTemplate;
  const templateRevisionId = templateRevision.id;
  const formFieldWidgets = React.useMemo(() => widgets.filter(isFormFieldWidget), [widgets]);

  const queryClient = useQueryClient();
  const [state, send] = useMachine(
    () => makeParseAiTaskFormMachine({ formFieldWidgets, aiTaskTemplate, queryClient }),
    {
      actions: {
        focusInputWidget: () => {
          if (!isDisabled) {
            inputWidgetRef.current?.openMenu?.('first');
          }
        },
      },
    },
  );

  React.useEffect(() => {
    // prevent in progress changes from getting clobbered
    if (isFocused) return;

    nativeAutomation.actions.forEach(action => {
      match(action)
        .with({ actionType: 'ExecuteAiPrompt' }, ({ config }) => {
          send({
            type: 'SET_INITIAL_PROMPT',
            prompt: config.prompt ?? ExecuteAiPrompt.PROMPT_CONFIGS_BY_TYPE[config.promptType].prompt ?? '',
          });
        })
        .otherwise(() => {});
    });
  }, [isFocused, nativeAutomation.actions, send]);

  React.useEffect(() => {
    const isPristine = isNativeAutomationPristine(nativeAutomation);
    setIsPristine(isPristine);
  }, [nativeAutomation, setIsPristine, state.context.prompt]);

  const iconSize = useBreakpointValue({ base: '3', md: '4' }) ?? '4';
  const iconButtonSize = useBreakpointValue({ base: 'xs', md: 'sm' }) ?? 'sm';

  return (
    <VStack w="full" spacing="4">
      <FormControl w="full">
        <FormLabel>Data Source</FormLabel>
        <AiTaskWidgetSelect
          selectRef={inputWidgetRef}
          filter={isTextareaFormFieldWidget}
          value={state.context.inputWidgetId}
          isDisabled={isDisabled}
          onChange={inputOption => {
            if (BlvdSelectHelpers.isOptionType<AiTaskWidgetOption>(inputOption)) {
              send({ type: 'SET_INPUT_WIDGET_ID', inputWidgetId: inputOption.value });
            }
          }}
        />
      </FormControl>

      {(state.matches('inputs.getOutputWidget') || state.matches('inputs.configured')) && (
        <>
          <VStack spacing="3" w="full">
            <HStack w="full" spacing={{ base: '1', md: '3' }}>
              <Box flex="1">
                <FormLabel>Locate</FormLabel>
              </Box>

              {/* Arrow spacer */}
              <Box w={{ base: '3', md: '5' }} />

              <Box flex="1">
                <FormLabel>Output</FormLabel>
              </Box>

              {/* Delete button spacer */}
              {!isDisabled && <Box w={{ base: '6', md: '8' }} />}
            </HStack>

            {state.context.variablesOutputIds.map(([variable, outputId], index) => (
              <HStack key={index} w="full" spacing={{ base: '1', md: '3' }}>
                <Box flex="1">
                  <Input
                    autoFocus
                    value={variable}
                    placeholder={`Variable name ${index + 1}`}
                    isDisabled={isDisabled}
                    onChange={e => send({ type: 'SET_VARIABLE', index, variable: e.target.value })}
                  />
                </Box>
                <Box w={{ base: '3', md: '5' }}>
                  <Icon icon="arrow-right" size={iconSize} variant="far" color="gray.500" />
                </Box>
                <Box flex="1">
                  <AiTaskWidgetSelect
                    value={outputId}
                    isDisabled={isDisabled}
                    onChange={value => {
                      if (BlvdSelectHelpers.isOptionType<AiTaskWidgetOption>(value)) {
                        send({ type: 'SET_OUTPUT_ID', outputId: value.value, index });
                      }
                    }}
                  />
                </Box>
                {isDisabled ? null : (
                  <IconButton
                    icon={<Icon icon="times" variant="far" size={iconSize} />}
                    aria-label={`Delete variable output ${index + 1}`}
                    size={iconButtonSize}
                    variant="ghost"
                    colorScheme="gray"
                    onClick={() => send({ type: 'REMOVE_VARIABLE_OUTPUT_ID', index })}
                  />
                )}
              </HStack>
            ))}

            {isDisabled ? null : (
              <IconButton
                alignSelf="flex-start"
                icon={<Icon icon="plus" variant="far" size="4" />}
                isDisabled={isDisabled}
                aria-label="Add variable output"
                size="sm"
                variant="tertiary"
                onClick={() => send({ type: 'ADD_VARIABLE_OUTPUT_ID' })}
              />
            )}
          </VStack>
        </>
      )}

      <Accordion allowToggle w="full">
        <AccordionItem w="full">
          {({ isExpanded }) => (
            <>
              {/* as="div" to support nested Test button */}
              <AccordionButton as="div" role="button" w="full" p="0" m="0">
                <ProcessPeteTextareaHeader
                  w="full"
                  {...(isExpanded ? {} : { borderBottom: '1px solid var(--ps-colors-gray-300)' })}
                  isDisabled={isDisabled}
                >
                  <Icon
                    icon="chevron-right"
                    variant="far"
                    size="4"
                    color="gray.500"
                    transform={isExpanded ? 'rotate(90deg)' : 'rotate(0deg)'}
                    transition="transform 0.2s ease-in-out"
                  />
                  <ProcessPeteIcon color="gray.500" />
                  <ProcessPeteTextareaHeaderTitle>Instructions</ProcessPeteTextareaHeaderTitle>

                  <Spacer />

                  {!isDisabled && (
                    <>
                      <Button variant="secondary" onClick={() => send({ type: 'OPEN_TEST_MODAL' })}>
                        Test
                      </Button>
                      {aiTaskTemplate && (
                        <TestAiTaskModal
                          {...{
                            isOpen: state.matches('testModal.open'),
                            onClose: () => send({ type: 'CLOSE_TEST_MODAL' }),
                            aiTaskTemplate,
                            templateRevisionId,
                          }}
                        />
                      )}
                    </>
                  )}
                </ProcessPeteTextareaHeader>
              </AccordionButton>
              <AccordionPanel p={0}>
                <ProcessPeteTextarea
                  value={state.context.prompt}
                  onChange={e => send({ type: 'SET_PROMPT', prompt: e.target.value })}
                  placeholder="Add any additional instructions here..."
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  isDisabled={isDisabled}
                />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </VStack>
  );
};
