import * as React from 'react';
import { Icon as ChakraIcon, PropsOf, ThemeTypings } from '@chakra-ui/react';
import { useToken } from '..';
import { findIconDefinition, icon as faIcon, IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomIconName } from './icon-name';
import { Option } from 'space-monad';

export type IconProps = React.PropsWithChildren<PropsOf<typeof ChakraIcon>> & {
  // allow regular duotone version
  variant?: IconPrefix | 'fadr';
  icon: CustomIconName;
  size?: ThemeTypings['sizes'];
  primaryLayer?: {
    opacity?: number;
    color?: ThemeTypings['colors'];
  };
  secondaryLayer?: {
    opacity?: number;
    color?: ThemeTypings['colors'];
  };
  animation?: 'spin' | 'pulse';
};

export const Icon: React.FC<IconProps> = ({
  size = 'full',
  variant = 'far',
  icon,
  animation,
  primaryLayer = {
    opacity: 1,
    color: 'currentColor',
  },
  secondaryLayer = {
    opacity: 1,
    color: 'currentColor',
  },
  ...props
}) => {
  const [sizeToken] = useToken('sizes', [size]);
  const [primaryColorToken, secondaryColorToken] = useToken('colors', [
    primaryLayer.color ?? 'currentColor',
    secondaryLayer.color ?? 'currentColor',
  ]);

  const iconDefinition = React.useMemo(() => {
    return Option(faIcon(findIconDefinition({ prefix: variant as IconPrefix, iconName: icon as IconName }))).fold(
      () => {
        console.error(`Missing FontAwesome icon. Variant [${variant}] Icon [${icon}]`);
        return faIcon(findIconDefinition({ prefix: 'far', iconName: 'times-circle' }));
      },
      iconDef => iconDef,
    );
  }, [variant, icon]);

  const styles = React.useMemo(
    () =>
      ['fad', 'fadr'].includes(variant)
        ? {
            ...(primaryLayer.opacity
              ? ({
                  '--fa-primary-opacity': String(primaryLayer.opacity),
                } as React.CSSProperties)
              : {}),
            ...(primaryLayer.color
              ? ({
                  '--fa-primary-color': primaryColorToken,
                } as React.CSSProperties)
              : {}),
            ...(secondaryLayer.opacity
              ? ({
                  '--fa-secondary-opacity': String(secondaryLayer.opacity),
                } as React.CSSProperties)
              : {}),
            ...(secondaryLayer.color
              ? ({
                  '--fa-secondary-color': secondaryColorToken,
                } as React.CSSProperties)
              : {}),
          }
        : {},
    [variant, primaryLayer, secondaryLayer, primaryColorToken, secondaryColorToken],
  );

  const animationClass = animation ? `fa-${animation}` : '';
  const classNames = [animationClass].join(' ');

  return (
    <ChakraIcon
      style={styles}
      sx={{
        width: `${sizeToken} !important`,
        height: `${sizeToken} !important`,
      }}
      {...props}
      as={FontAwesomeIcon}
      icon={iconDefinition}
      className={classNames}
    />
  );
};
