import angular from 'angular';
import { connectService } from 'reducers/util';
import { MigrationService } from 'features/migrations/services/migration-service';
import { queryClient } from 'components/react-root';
import { DataServiceActions } from 'reducers/data-service/data-service.actions';

angular
  .module('frontStreetApp.services')
  .service('ChecklistRevisionService', function ($ngRedux, ChecklistRevisionActions) {
    const self = this;

    connectService('ChecklistRevisionService', $ngRedux, null, ChecklistRevisionActions)(self);

    /**
     * Gets active checklist revision by checklist id
     *
     * @param checklistId
     */
    self.getActiveByChecklistId = function (checklistId) {
      return self.actions.getActiveByChecklistId(checklistId).then(action => action.payload);
    };

    /**
     * Schedules checklist for migration
     * @param checklistRevisionId
     */
    self.scheduleOneMigrationByChecklistRevisionId = function (checklistRevisionId) {
      return self.actions.scheduleOneMigrationByChecklistRevisionId(checklistRevisionId).then(result => {
        $ngRedux.dispatch(DataServiceActions.clearDataStore());
        MigrationService.invalidateChecklistRevisionQueriesPostMigration(queryClient);
        return result;
      });
    };

    /**
     * Cancels scheduled migration (if possible) and migrates one synchronously
     * @param checklistRevisionId
     */
    self.migrateOneWithPriorityByChecklistRevisionId = function (checklistRevisionId) {
      return self.actions.migrateOneWithPriorityByChecklistRevisionId(checklistRevisionId).then(result => {
        $ngRedux.dispatch(DataServiceActions.clearDataStore());
        MigrationService.invalidateChecklistRevisionQueriesPostMigration(queryClient);
        return result;
      });
    };
  });
