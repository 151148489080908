import { NativeAutomation, TaskTemplate, TaskTemplateTaskType } from '@process-street/subgrade/process';
import {
  Box,
  Button,
  HStack,
  Icon,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  StyleProps,
  Text,
  VStack,
} from 'components/design/next';
import * as React from 'react';
import { match } from 'ts-pattern';
import { useChecklistRevisionNativeAutomationStatus } from '../../hooks/use-checklist-revision-native-automation-status';
import { useUpdateTaskStatus } from './use-update-task-status';
import { useHandleRetryFailedNativeAutomation } from '../../hooks/use-handle-retry-failed-native-automation';
import { GetAllNativeAutomationsQuery } from 'features/native-automations/query-builder';
import { CodeTaskRunLog } from 'pages/runs/_id/components/code-task-run-log-modal';
import { useDisclosure } from '@chakra-ui/react';

export type AutomatedTaskListItemWrapperProps = {
  taskTemplate: TaskTemplate;
  isFirst: boolean;
  isLast: boolean;
};

const getColorForAutomationStatus = (status: NativeAutomation.EventStatus | undefined): StyleProps['color'] =>
  match(status)
    .with('AutomationRunning', () => 'blue.500') // Intentionally blue, not branded
    .with('AutomationComplete', () => 'blue.500') // Intentionally blue, not branded
    .with('AutomationFailed', () => 'red.500')
    .otherwise(() => 'gray.400');

// Simple wrapper to make storybooks easier to model without websockets
export const AutomatedTaskListItemWrapper: React.FC<React.PropsWithChildren<AutomatedTaskListItemWrapperProps>> = ({
  taskTemplate,
  isFirst,
  isLast,
}) => {
  const { taskType } = taskTemplate;

  if (taskType !== TaskTemplateTaskType.AI && taskType !== TaskTemplateTaskType.Code) {
    throw new Error(`Unsupported task type for Automated Task indicator: ${taskTemplate.taskType}`);
  }

  const nativeAutomationQuery = GetAllNativeAutomationsQuery.useGetTaskTemplateAutomationQuery({
    templateRevisionId: taskTemplate.templateRevision.id,
    taskTemplateId: taskTemplate.id,
  });

  const nativeAutomation = nativeAutomationQuery.data;
  const { latestExecutionId, status, logsQuery } = useChecklistRevisionNativeAutomationStatus({
    nativeAutomation,
  });
  useUpdateTaskStatus({ taskTemplate, status });

  const nativeAutomationLogs = nativeAutomation ? logsQuery.data?.[nativeAutomation.stableId] : undefined;
  const handleRetry = useHandleRetryFailedNativeAutomation({ nativeAutomationExecutionId: latestExecutionId });

  const codeTaskLogsDisclosure = useDisclosure();

  const isCodeTask = taskType === TaskTemplateTaskType.Code;
  const handleOpenCodeTaskLogs = async () => {
    // in case the last log item shows a running automation that has just recently completed
    void logsQuery.refetch();
    codeTaskLogsDisclosure.onOpen();
  };

  const listItem = (
    <AutomatedTaskListItem
      taskType={taskType}
      status={status}
      isFirst={isFirst}
      isLast={isLast}
      handleRetry={handleRetry}
      handleOpenCodeTaskLogs={handleOpenCodeTaskLogs}
    ></AutomatedTaskListItem>
  );

  const shouldRenderCodeTaskRunLog = isCodeTask && nativeAutomationLogs?.length;
  return (
    <>
      {shouldRenderCodeTaskRunLog ? (
        <CodeTaskRunLog
          taskTemplate={taskTemplate}
          nativeAutomationLogs={nativeAutomationLogs}
          {...codeTaskLogsDisclosure}
        >
          {listItem}
        </CodeTaskRunLog>
      ) : (
        listItem
      )}
    </>
  );
};

export type AutomatedTaskListItemProps = {
  isFirst: boolean;
  isLast: boolean;
  status: NativeAutomation.EventStatus | undefined;
  handleRetry: () => void;
  handleOpenCodeTaskLogs?: () => void;
  taskType: TaskTemplateTaskType.AI | TaskTemplateTaskType.Code;
};

export const AutomatedTaskListItem = React.forwardRef<HTMLDivElement, AutomatedTaskListItemProps>(
  (
    { status, isFirst, isLast, handleRetry, handleOpenCodeTaskLogs, taskType },
    // ref forwarded to the popover trigger so code task logs open at the correct spot
    ref,
  ) => {
    const statusColor = getColorForAutomationStatus(status);
    // Otherwise it's Code Task
    const isAiTask = taskType === TaskTemplateTaskType.AI;
    const taskTypeCopy = isAiTask ? 'AI' : 'Code';

    // basic copy used for aria-label and tooltips
    const automationStatusCopy = match(status)
      .with('AutomationRunning', () => `${taskTypeCopy} task running`)
      .with('AutomationComplete', () => `${taskTypeCopy} task successful`)
      .with('AutomationFailed', () => `${taskTypeCopy} task failed`)
      // for V2 runner this copy will be different
      .otherwise(() => `At this point, the ${taskTypeCopy} task will be executed`);

    const viewRunLog = (
      <Link onClick={handleOpenCodeTaskLogs} textDecor="underline">
        View run log
      </Link>
    );

    return (
      <HStack spacing="0" w="full">
        <Box flex="1" />
        <VStack alignItems="stretch" alignContent="stretch" spacing="0">
          <Box
            background={
              isFirst
                ? 'none'
                : 'linear-gradient(var(--ps-colors-gray-200), var(--ps-colors-gray-200)) no-repeat center/1px 100%;'
            }
            h="9px"
          />
          <Popover trigger="hover" size="auto" variant="tooltip-dark">
            <PopoverTrigger>
              <HStack
                as={Button}
                variant="unstyled"
                ref={ref}
                borderRadius="full"
                border="1px solid"
                borderColor={statusColor}
                px="2"
                py="2px"
                position="relative"
                cursor="default"
                aria-label={automationStatusCopy}
              >
                <Icon icon={isAiTask ? 'process-pete' : 'code'} variant="far" size="4" color={statusColor} />
                <Text variant="-2u" color={statusColor} fontWeight="bold">
                  {taskTypeCopy} Task
                </Text>
                {status === 'AutomationRunning' && <Spinner size="sm" color="blue.500" />}
              </HStack>
            </PopoverTrigger>
            <PopoverContent w="auto">
              <PopoverArrow />
              <PopoverBody>
                <Text variant="-1">
                  {match({ status, isAiTask })
                    .with({ status: 'AutomationComplete', isAiTask: false }, () => {
                      return (
                        <>
                          {automationStatusCopy}.{' '}
                          <Link onClick={handleRetry} textDecor="underline">
                            Run again
                          </Link>
                          . {viewRunLog}.
                        </>
                      );
                    })
                    .with({ status: 'AutomationFailed' }, () => (
                      <>
                        {automationStatusCopy}. Try to{' '}
                        <Link onClick={handleRetry} textDecor="underline">
                          run again
                        </Link>
                        . {viewRunLog}.
                      </>
                    ))
                    .otherwise(() => automationStatusCopy)}
                </Text>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Box
            background={
              isLast
                ? 'none'
                : 'linear-gradient(var(--ps-colors-gray-200), var(--ps-colors-gray-200)) no-repeat center/1px 100%;'
            }
            h="9px"
          />
        </VStack>
        <Box flex="1" />
      </HStack>
    );
  },
);
