import { useCallback, useMemo } from 'react';
import { TextareaFormFieldActor, TextareaFormFieldMachineState } from './textarea-form-field';
import { useSelector } from '@xstate/react';
import { ValidationActorRef, ValidationSelectors } from './validation-machine';
import { NumberFormFieldActor, NumberFormFieldMachineState } from './number-form-field';
import { EmailFormFieldActor, EmailFormFieldMachineState } from './email-form-field';
import { UrlFormFieldActor, UrlFormFieldMachineState } from './url-form-field';
import { TextFormFieldActor, TextFormFieldMachineState } from './text-form-field';

export const identitySelector = <T>(snapshot: T) => snapshot;

export namespace FormFieldMachineHooks {
  export type ActorRef =
    | TextFormFieldActor
    | TextareaFormFieldActor
    | NumberFormFieldActor
    | EmailFormFieldActor
    | UrlFormFieldActor;
  export type State =
    | TextFormFieldMachineState
    | TextareaFormFieldMachineState
    | NumberFormFieldMachineState
    | EmailFormFieldMachineState
    | UrlFormFieldMachineState;

  export namespace Selectors {
    export function getValidationActor<S extends State>(state: S) {
      return state.context.validationActor;
    }

    export function getInputNode<S extends State>(state: S) {
      return state.context.inputNode;
    }

    export function getIsInputDisabled<S extends State>(state: S) {
      return state.matches('input.disabled');
    }

    export function getIsAutofocused<S extends State>(state: S) {
      return state.matches('autoFocus.enabled');
    }
  }

  export function useValidationActor<T extends ActorRef>(actorRef: T) {
    return useSelector(actorRef, Selectors.getValidationActor);
  }

  export function useIsInvalid<T extends ActorRef>(actorRef: T) {
    const validationActor = useValidationActor(actorRef);
    return ValidationSelectors.isActorInvalidVisible(validationActor);
  }

  export function useInputNode<T extends ActorRef>(actorRef: T) {
    return useSelector(actorRef, Selectors.getInputNode);
  }

  export function useIsInputDisabled<T extends ActorRef>(actorRef: T) {
    return useSelector(actorRef, Selectors.getIsInputDisabled);
  }

  export function useIsAutofocused<T extends ActorRef>(actorRef: T) {
    return useSelector(actorRef, Selectors.getIsAutofocused);
  }

  const validationMachineSelector = (state: State) => state.context.validationActor;
  export function useValidationSnapshot(parentRef: ActorRef) {
    const validationActor = useSelector(parentRef, validationMachineSelector) as ValidationActorRef<any>;
    const validationSnapshot = useSelector(validationActor, identitySelector);
    return validationSnapshot;
  }

  export function useValidationErrorMessage(parentRef: ActorRef) {
    const validationSnapshot = useValidationSnapshot(parentRef);
    return validationSnapshot.context.errorMessage;
  }

  export function useIsInvalidVisible(parentRef: ActorRef) {
    const validationSnapshot = useValidationSnapshot(parentRef);
    return validationSnapshot.matches('invalid.visible');
  }

  export function useApi<T extends ActorRef>(actorRef: T) {
    const onBlur = useCallback(() => {
      actorRef.send({ type: 'BLUR' });
    }, [actorRef]);

    const onChange = useCallback(
      (value: string, hasDefaultValue?: boolean) => {
        actorRef.send({ type: 'CHANGE', value, hasDefaultValue });
      },
      [actorRef],
    );

    const onFocus = useCallback(() => {
      actorRef.send({ type: 'FOCUS' });
    }, [actorRef]);

    const onSetNode = useCallback(
      (node: HTMLDivElement | null) => {
        actorRef.send({ type: 'SET_NODE', node });
      },
      [actorRef],
    );

    return useMemo(
      () => ({
        onBlur,
        onChange,
        onFocus,
        onSetNode,
      }),
      [onBlur, onChange, onFocus, onSetNode],
    );
  }
}
