import * as React from 'react';
import { Box, BoxProps, HStack, Tooltip } from 'components/design/next';
import { usePrintStore } from 'components/react-root/print-store';
import {
  AutomatedTaskAuditHelperText,
  CodeTaskIcon,
  CommonAuditProps,
  getAutomatedTaskOutputTooltip,
  ProcessPeteCircle,
  ProcessPeteIcon,
} from './common';
import { useWorkflowRunStore } from '../../workflow-run-store';
import { match } from 'ts-pattern';
import { useUnmount } from 'react-use';
import { useWidgetContext } from '../../hooks/use-widget-context';

export interface RunningAutomatedTaskAuditProps extends BoxProps, CommonAuditProps {}

export const RunningAutomatedTaskAudit: React.FC<React.PropsWithChildren<RunningAutomatedTaskAuditProps>> = ({
  automatedTaskTemplates,
  nativeAutomation,
  ...props
}) => {
  const { widget } = useWidgetContext();
  const { isPrintView, isPrintPage } = usePrintStore();
  const isPrintMode = isPrintView || isPrintPage;
  const hasBeenClicked = useWorkflowRunStore(state => {
    return state.outputWidgetsClickedDuringAiTask.has(widget.id);
  });
  const removeWasClicked = useWorkflowRunStore(state => state.removeOutputWidgetClickedDuringAiTask);
  useUnmount(() => {
    removeWasClicked(widget.id);
  });

  const isAutomatedTaskAudit = nativeAutomation?.automationType === 'AiTask';
  const automatedTaskOutputTooltip = getAutomatedTaskOutputTooltip(automatedTaskTemplates);

  return (
    <Box {...props}>
      <HStack>
        <Tooltip label={automatedTaskOutputTooltip} shouldWrapChildren hasArrow>
          <ProcessPeteCircle bg={`${hasBeenClicked ? 'yellow' : 'gray'}.100`}>
            {isAutomatedTaskAudit ? (
              <ProcessPeteIcon color={hasBeenClicked ? 'yellow.600' : undefined} />
            ) : (
              <CodeTaskIcon color={hasBeenClicked ? 'yellow.600' : undefined} />
            )}
          </ProcessPeteCircle>
        </Tooltip>

        <AutomatedTaskAuditHelperText
          color={match({ isPrintMode, hasBeenClicked })
            .with({ isPrintMode: true }, () => 'gray.200')
            .with({ hasBeenClicked: true }, () => 'yellow.600')
            .otherwise(() => 'gray.400')}
        >
          {isAutomatedTaskAudit ? 'AI Task' : 'Code Task'} is running&hellip;
        </AutomatedTaskAuditHelperText>
      </HStack>
    </Box>
  );
};
