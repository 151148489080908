import * as React from 'react';
import { Box, BoxProps, HStack, Tooltip } from 'components/design/next';
import { usePrintStore } from 'components/react-root/print-store';
import {
  AutomatedTaskAuditHelperText,
  CodeTaskIcon,
  CommonAuditProps,
  getAutomatedTaskOutputTooltip,
  ProcessPeteCircle,
  ProcessPeteIcon,
} from './common';
import { match } from 'ts-pattern';
import { NativeAutomation } from '@process-street/subgrade/process';

export interface EmptyAutomatedTaskAuditProps extends BoxProps, CommonAuditProps {}

export const EmptyAutomatedTaskAudit: React.FC<React.PropsWithChildren<EmptyAutomatedTaskAuditProps>> = ({
  automatedTaskTemplates,
  nativeAutomation: _,
  ...props
}) => {
  const { isPrintView, isPrintPage } = usePrintStore();
  const isPrintMode = isPrintView || isPrintPage;

  const automatedTaskOutputTooltip = getAutomatedTaskOutputTooltip(automatedTaskTemplates);

  // there is no latest native automation as it has not run yet
  // so looking at all connected automations
  const isAiTaskOutput = NativeAutomation.hasAutomationOfType(automatedTaskTemplates, 'AiTask');
  const isCodeTaskOutput = NativeAutomation.hasAutomationOfType(automatedTaskTemplates, 'CodeTask');
  const shouldShowIcon = !(isAiTaskOutput && isCodeTaskOutput);

  const outputTypeCopy = match({ isAiTaskOutput, isCodeTaskOutput })
    .with({ isAiTaskOutput: true, isCodeTaskOutput: true }, () => 'AI Task or Code Task have')
    .with({ isAiTaskOutput: false, isCodeTaskOutput: true }, () => 'Code Task has')
    .otherwise(() => 'AI Task has');

  return (
    <Box {...props}>
      <Tooltip label={automatedTaskOutputTooltip} shouldWrapChildren hasArrow>
        <HStack>
          {shouldShowIcon && (
            <ProcessPeteCircle>{isAiTaskOutput ? <ProcessPeteIcon /> : <CodeTaskIcon />}</ProcessPeteCircle>
          )}

          <AutomatedTaskAuditHelperText color={isPrintMode ? 'gray.200' : 'gray.400'}>
            {outputTypeCopy} not started yet.
          </AutomatedTaskAuditHelperText>
        </HStack>
      </Tooltip>
    </Box>
  );
};
