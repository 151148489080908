import { dayjs as moment } from './dayjs';
import dayjs from 'dayjs';

export enum DateFormat {
  DateLongMonth = 'MMMM D, YYYY',
  DateLongWeekdayShortMonth = 'ddd MMM D YYYY',
  DateMonthDay = 'MMM D',
  DateMonthDayAtTime = 'MMM D [at] h:mma',
  DateMonthDayYearAtTime = 'MMM D YYYY [at] h:mma',
  DateShortMonth = 'MMM D YYYY',
  DateShortMonthWithComma = 'MMM D, YYYY',
  DateTimeFullMonth = 'MMMM D, YYYY [at] h:mm:ss a',
  DateTimeLongWeekdayShortMonth = 'ddd MMM D YYYY [at] h:mmA',
  DateTimeLongWeekdayShortMonthWithZone = 'ddd MMM D YYYY [at] h:mmA z',
  DateTimeShortMonth = 'MMM D, YYYY [at] h:mm:ss a',
  DateTimeShortMonthFixed = 'MMM DD, YYYY [at] hh:mm:ss a',
  DateTimeShortMonthWithComma = 'MMM DD, hh:mmA',
  DateTimeShortMonthNoSeconds = 'MMM D YYYY h:mmA',
  DateTimeSnoozeShort = 'ddd, h:mmA',
  DateTimeSnoozeLong = 'ddd MMM D, h:mmA',
  DateTimeMergeTagLong = 'ddd MMM D [at] h:mmA',
  Year = 'YYYY',
  Month = 'MMMM',
  MonthShort = 'MMM',
  Day = 'D',
  Default = 'YYYY-MM-DD [at] h:mmA',
  DatePickerInputDefault = 'MM/DD/YYYY',
  DatePickerTimeDefault = 'HH:mm',
  FileNameDate = 'DD-MMM-YYYY',
  DefaultDateOnly = 'YYYY-MM-DD',
}

const formatDateFromNow = (date: Date | number, timeZone?: string) => {
  return timeZone ? moment.tz(date, timeZone).fromNow() : moment(date).fromNow();
};

/**
 * @param utcDate Assumes that this is in UTC
 * @param format
 * @param timeZone
 */
const formatDateTime = (utcDate: Date | number, format?: string, timeZone: string = moment.tz.guess()) => {
  let time = moment.tz(utcDate, 'UTC');
  if (timeZone) {
    time = time.tz(timeZone);
  }

  return time.format(format ? format : DateFormat.Default);
};

const formatDateTimeToLongMonth = (utcDate: Date | number, timezone: string) =>
  formatDateTime(utcDate, DateFormat.DateTimeFullMonth, timezone);

const formatDateToShortMonth = (utcDate: Date | number, timezone: string) =>
  formatDateTime(utcDate, DateFormat.DateShortMonthWithComma, timezone);

const formatDateToMonthDay = (utcDate: Date | number, { showYearIfPast = false, timeZone = '' } = {}) =>
  formatDateTime(
    utcDate,
    showYearIfPast && isOverYear(utcDate) ? DateFormat.DateShortMonthWithComma : DateFormat.DateMonthDay,
    timeZone,
  );

const formatSnoozeDate = (momentDate: dayjs.Dayjs) => {
  const dateIsInCurrentWeek =
    moment().startOf('week').isBefore(momentDate) && moment().endOf('week').isAfter(momentDate);

  return dateIsInCurrentWeek
    ? momentDate.format(DateFormat.DateTimeSnoozeShort)
    : momentDate.format(DateFormat.DateTimeSnoozeLong);
};

const isOverYear = (date: Date | number): boolean => {
  const thisYear = moment(new Date()).year();
  const activityYear = moment(new Date(date)).year();
  return activityYear < thisYear;
};

const formatActivityTime = ({ date, timeZone }: { date: Date | number; timeZone?: string }) => {
  return formatDateTime(
    date,
    isOverYear(date) ? DateFormat.DateMonthDayYearAtTime : DateFormat.DateMonthDayAtTime,
    timeZone,
  );
};

const formatActivityWithTimezone = ({ date, timeZone }: { date: Date | number; timeZone?: string }) => {
  return formatDateTime(date, DateFormat.DateTimeLongWeekdayShortMonthWithZone, timeZone);
};

const isUnixTimestamp = (value: number | string) => {
  const timestamp = Number(value);
  if (isNaN(timestamp) || !Number.isInteger(timestamp)) {
    return false;
  }
  // Check if the number is within a range for Unix timestamps
  // This assumes dates between 1970 and 2100
  const minTimestamp = 0; // 1970-01-01 00:00:00 UTC the "epoch"
  const maxTimestamp = 4102444800000; // 2100-01-01 00:00:00 UTC
  if (timestamp < minTimestamp || timestamp > maxTimestamp) {
    return false;
  }
  if (timestamp % 1000 !== 0) {
    return false;
  }
  const date = new Date(timestamp);
  return date.getTime() === timestamp;
};

export const DateUtils = {
  formatDateFromNow,
  formatDateTime,
  formatDateTimeToLongMonth,
  formatDateToMonthDay,
  formatDateToShortMonth,
  formatActivityTime,
  formatActivityWithTimezone,
  formatSnoozeDate,
  isOverYear,
  isUnixTimestamp,
};
