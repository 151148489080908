import {
  AuditMetadata,
  Muid,
  Organization,
  OrganizationMembership,
  OrganizationMembershipWithUser,
  Ref,
  toModelRef,
  User,
} from '../core';
import { Checklist, Task, TaskTemplateGroup, TemplateRevision, WidgetGroup } from '../process';
import { Option } from 'space-monad';
import get from 'lodash/fp/get';

export enum AssignmentStatus {
  Active = 'Active',
  Active_TaskCompleted = 'Active_TaskCompleted',
  Completed = 'Completed',
  Completed_TaskCompleted = 'Completed_TaskCompleted',
  Archived = 'Archived',
  Archived_TaskCompleted = 'Archived_TaskCompleted',
  Inactive = 'Inactive',
}

export enum AssignmentType {
  Task = 'Task',
  Checklist = 'Checklist',
}

export enum TaskAssignmentRuleSourceType {
  ChecklistInitiator = 'ChecklistInitiator',
  FormField = 'FormField',
}

export interface AssignmentLike {
  readonly id: Muid;
  organizationMembership: Ref<OrganizationMembership>;
}

export interface TaskAssignment {
  readonly id: Muid;
  audit: AuditMetadata;
  task: Ref<Task>;
  organization: Ref<Organization>;
  organizationMembership: Ref<OrganizationMembership>;
  status: AssignmentStatus;
  dueDate?: number;
  sourceTaskAssignmentRule?: Ref<TaskAssignmentRule>;
}

export type TaskAssignmentWithOrganizationMembershipAndUser = TaskAssignment & {
  organizationMembership: OrganizationMembershipWithUser;
};

export interface TaskAssignmentRule {
  readonly id: Muid;
  organization: Ref<Organization>;
  templateRevision: Ref<TemplateRevision>;
  targetTaskTemplateGroup: TaskTemplateGroup;
  sourceType: TaskAssignmentRuleSourceType;
  sourceFormFieldWidgetGroup?: WidgetGroup;
}

export interface ChecklistAssignment {
  readonly id: Muid;
  audit: AuditMetadata;
  checklist: Ref<Checklist>;
  organization: Ref<Organization>;
  organizationMembership: Ref<OrganizationMembership>;
  status: AssignmentStatus;
  dueDate?: number;
  sourceTaskAssignmentRule?: Ref<TaskAssignmentRule>;
}

export type ChecklistAssignmentWithOrganizationMembershipAndUser = ChecklistAssignment & {
  organizationMembership: OrganizationMembership & { user: User };
};

export type ChecklistAssignmentWithOrganizationMembership = ChecklistAssignment & {
  organizationMembership: OrganizationMembership;
};

export interface ChecklistAssignmentRequest {
  checklistId: Muid;
  email: string;
  autoAssign: boolean;
}

export interface TaskAssignmentRequest {
  taskId: Muid;
  email: string;
}

export const TaskAssignmentRuleUtils = {
  areRulesSameType: <T extends TaskAssignmentRule>(rule1: T, rule2: T) =>
    rule1.sourceType === rule2.sourceType &&
    (rule1.sourceType === TaskAssignmentRuleSourceType.ChecklistInitiator ||
      (rule1.sourceType === TaskAssignmentRuleSourceType.FormField &&
        rule1.sourceFormFieldWidgetGroup?.id === rule2.sourceFormFieldWidgetGroup?.id)),
};

export const TaskAssignmentUtils = {
  getUserFromTaskAssignment: (taskAssignment: AssignmentLike): User | undefined =>
    Option(taskAssignment).map(get('organizationMembership')).map(toModelRef).map(get('user')).map(toModelRef).get(),
};
