import { FormFieldWidgetWithValue, MembersFieldValue } from '@process-street/subgrade/process';
import { HStack, Text } from 'components/design/next';
import { useGetAllOrganizationMembershipsQuery } from 'app/features/organization-memberships/query-builder';
import * as React from 'react';
import { ChakraAvatar } from 'app/components/design/next/chakra-avatar';
import { NgLink } from 'app/features/app/components/ng-link';

export type ApprovalWidgetMembersRendererProps = {
  widget: FormFieldWidgetWithValue;
};

export const ApprovalWidgetMembersRenderer = ({ widget }: ApprovalWidgetMembersRendererProps) => {
  const organizationId = widget.header.organization.id;
  const organizationMembershipsQuery = useGetAllOrganizationMembershipsQuery({ organizationId });

  const organizationMembershipsWithUser = React.useMemo(() => {
    const fieldValue = widget.formFieldValue?.fieldValue && (widget.formFieldValue.fieldValue as MembersFieldValue);
    if (!fieldValue || !organizationMembershipsQuery.data) return [];

    return organizationMembershipsQuery.data.filter(om => fieldValue.organizationMembershipIds?.includes(om.id));
  }, [widget.formFieldValue, organizationMembershipsQuery.data]);

  return (
    <>
      {organizationMembershipsWithUser.map((omWithUser, index) => (
        <NgLink isExternal to="userManage" params={{ id: omWithUser.user.id }}>
          <HStack key={index}>
            <ChakraAvatar size="sm" user={omWithUser.user} key={index} />
            <Text>{omWithUser.user.username}</Text>
          </HStack>
        </NgLink>
      ))}
    </>
  );
};
