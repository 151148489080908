import {
  FormFieldWidget,
  isAi,
  isHeading,
  Task,
  TaskStatus,
  TaskWithTaskTemplate,
} from '@process-street/subgrade/process';
import { StateFrom } from 'xstate';
import { FormResponseMachine } from './form-response-machine';
import { FormMachineUtils } from './form-response-machine-utils';
import { Muid, OrganizationMembershipRole, User } from '@process-street/subgrade/core';
import _groupBy from 'lodash/groupBy';

export type State = StateFrom<FormResponseMachine>;

export namespace FormResponseMachineSelectors {
  export const getTotalTaskCount = (state: State) =>
    Object.values(state.context.taskActorsMap).filter(actor => !FormMachineUtils.taskActorIsHiddenByRule(actor)).length;

  export const getCurrentTaskIndex = (state: State) =>
    FormMachineUtils.getCurrentTaskIndex(state.context.taskActorsMap, state.context.currentTaskActor);

  export const getCurrentTaskId = (state: State) =>
    FormMachineUtils.getCurrentTaskId(state.context.taskActorsMap, state.context.currentTaskActor);

  export const getTaskActorsMap = (state: State) => state.context.taskActorsMap;

  export const getTaskActorByTaskId = (taskId: Muid) => (state: State) => state.context.taskActorsMap[taskId];

  export const getCurrentTaskActor = (state: State) => state.context.currentTaskActor;

  export const getIsCompletingCurrentTask = (state: State) => state.matches('response.active.completingCurrentTask');

  export const getIsUncompletingCurrentTask = (state: State) =>
    state.matches('response.active.uncompletingPreviousTask') ||
    state.matches('response.active.uncompletingCurrentTask');

  export const getIsComplete = (state: State) => state.matches('response.complete');

  export const getChecklist = (state: State) => state.context.checklist;

  export const getChecklistRevision = (state: State) => state.context.checklistRevision;

  export const getApprovals = (state: State) => state.context.approvals;

  export const getApprovalRules = (state: State) => state.context.approvalRules;

  export const getTasks = (state: State) =>
    Object.values(state.context.taskActorsMap)
      .map(actor => actor.getSnapshot()?.context.task)
      .filter((task): task is TaskWithTaskTemplate => Boolean(task));

  export const getCompletionPercentage = (state: State) => {
    const { oneOffTasks } = state.context;
    const completedOneOffTasksCount = oneOffTasks.filter(task => task.status === TaskStatus.Completed).length;

    const tasks = getTasks(state)
      .filter((task): task is TaskWithTaskTemplate => Boolean(task))
      .filter(task => !isHeading(task.taskTemplate) && !isAi(task.taskTemplate) && !task.hidden);

    const completedCount = tasks.filter(task => task.status === TaskStatus.Completed).length;

    const totalCompletedCount = completedCount + completedOneOffTasksCount;
    const totalTaskCount = tasks.length + oneOffTasks.length;

    return (totalCompletedCount / totalTaskCount) * 100;
  };

  export const getShouldHideCompletedTasks = (state: State) => state.context.shouldHideCompletedTasks;

  export const getShouldHideStoppedTasks = (state: State) => state.context.shouldHideStoppedTasks;

  export const getIsEveryTaskValid = (state: State) => {
    const { taskActorsMap } = state.context;

    return Object.values(taskActorsMap).every(actor => {
      return actor.getSnapshot()?.matches('validation.valid');
    });
  };

  export const getFormFieldWidgetsWithValuesByTaskMap = (state: State) =>
    _groupBy(state.context.formFieldWidgetsWithValues, ffwwv => ffwwv.header.taskTemplate.id);

  export const getWidgetsByTaskMap = (state: State) => _groupBy(state.context.widgets, w => w.header.taskTemplate.id);

  export const getIsCompletingRun = (state: State) => state.matches('response.active.completingRun');

  export const getIsActivatingRun = (state: State) => state.matches('response.complete.activatingRun');

  export const getTemplate = (state: State) => state.context.template;

  export const getTemplateRevision = (state: State) => state.context.templateRevision;

  export const getGroupMembershipsMap = (state: State) => state.context.groupMembershipsMap;

  export const getIsMigrating = (state: State) => state.matches('response.migrating');

  export const getIsArchived = (state: State) => state.matches('response.archived');

  export const getIsArchiving = (state: State) => state.matches('response.active.archivingRun');

  export const getIsUnarchiving = (state: State) => state.matches('response.archived.activatingRun');

  export const getHasChecklistStartedAsMigrating = (state: State) => state.context.initialResponseState === 'migrating';

  export const getCurrentUser = (state: State) => state.context.currentUser;

  export const getNativeAutomationsByFormFieldWidget = (formFieldWidgetId: FormFieldWidget['id']) => (state: State) =>
    state.context.nativeAutomationsMap[formFieldWidgetId];

  export const getOrganizationMembershipsMap = (state: State) => state.context.organizationMembershipsMap;
  export const getUserIsAdmin = (userId: User['id']) => (state: State) => {
    const organizationMembership = state.context.organizationMembershipsByUserId[userId];
    return organizationMembership.role === OrganizationMembershipRole.Admin;
  };

  export const getCommentsByTaskId = (taskId: Task['id']) => (state: State) => state.context.commentsByTaskMap[taskId];
  export const getAttachmentsByTaskGroupId = (taskGroupId: Muid) => (state: State) =>
    state.context.attachmentsMap[taskGroupId];
}
