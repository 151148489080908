import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { axiosService } from 'services/axios-service';
import { MigrationService } from 'features/migrations/services/migration-service';
import { useInjector } from 'components/injection-provider';
import { DataServiceActions } from 'reducers/data-service/data-service.actions';

export namespace ScheduleAllMigrationsByTemplateRevisionIdMutation {
  export type Params = { templateRevisionId: Muid };

  export type Result = { count: number; dateFrom: number };
  export const key = 'checklist-revisions/schedule-migration';

  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .post<Result>('/1/checklist-revisions/schedule-migration', {
        finishedTemplateRevisionId: params.templateRevisionId,
      })
      .then(r => r.data);

  export const useMutation = (
    options: UseMutationOptions<
      ScheduleAllMigrationsByTemplateRevisionIdMutation.Result,
      AxiosError,
      ScheduleAllMigrationsByTemplateRevisionIdMutation.Params
    > = {},
  ) => {
    const { $ngRedux } = useInjector('$ngRedux');
    const queryClient = useQueryClient();
    return useRQMutation<
      ScheduleAllMigrationsByTemplateRevisionIdMutation.Result,
      AxiosError,
      ScheduleAllMigrationsByTemplateRevisionIdMutation.Params
    >(ScheduleAllMigrationsByTemplateRevisionIdMutation.mutationFn, {
      ...options,
      onSuccess: async (...args) => {
        // TODO Drop this Redux dispatch once we switch to Runner V2
        $ngRedux.dispatch(DataServiceActions.clearDataStore());
        MigrationService.invalidateChecklistRevisionQueriesPostMigration(queryClient);
        await options.onSuccess?.(...args);
      },
    });
  };
}
