import {
  MultiSelectFieldValue,
  MultiSelectFormFieldValue,
  MultiSelectItemValueStatus,
} from '@process-street/subgrade/process';
import * as yup from 'yup';

type ValidationSchemaProps = {
  required: boolean;
};

export const makeSubtasksValidationSchema = ({ required }: ValidationSchemaProps) => {
  return yup.object().shape({
    itemValues: yup
      .array<MultiSelectFieldValue>()
      .optional()
      .test('required subtasks', 'All subtasks are required', values => {
        if (required) {
          if (!values) return false;
          return values.every(value => value.status === MultiSelectItemValueStatus.Completed);
        }

        return true;
      }),
  }) as yup.Schema<MultiSelectFormFieldValue | undefined>;
};

const SubtasksFormFieldConfigSchema = yup.object({
  items: yup.array().of(
    yup
      .object()
      .shape<MultiSelectFieldValue>({
        itemType: yup.string().oneOf(['Static']).required().default('Static'),
        id: yup.string().required(),
        name: yup.string().trim(),
        status: yup.string().oneOf(Object.values(MultiSelectItemValueStatus)).required(),
      })
      .required(),
  ),
});

export const makeSubtasksFormFieldValidationSchema = yup.object({
  config: SubtasksFormFieldConfigSchema.required(),
});
