import * as React from 'react';
import { Box, BoxProps, HStack, Switch, Text, Tooltip, useBoolean, VStack } from '@chakra-ui/react';
import { Checklist } from '@process-street/subgrade/process';
import { UpdateChecklistShareableMutation } from 'app/features/checklists/query-builder/update-checklist-shareable-mutation';
import { createRQObjectCacheUtils } from 'app/utils/react-query-cache-utils';
import { useQueryClient } from 'react-query';
import { GetChecklistQuery } from 'app/features/checklists/query-builder';
import { DefaultErrorMessages } from 'app/components/utils/error-messages';
import { isFunction } from 'lodash';
import { Button, Icon, useToast } from 'app/components/design/next';
import { useCopyToClipboard } from 'app/features/ui/hooks/use-copy-to-clipboard';
import { useHref } from '@process-street/adapters/navigation';
import { abbreviateForTitle, queryString } from '@process-street/subgrade/util';
import { AnimatePresence, motion } from 'framer-motion';

export type RightSidebarShareLinkProps = {
  checklist: Checklist;
};

const MotionBox = motion<BoxProps>(Box);

export const RightSidebarShareLink = ({ checklist }: RightSidebarShareLinkProps) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const [hasCopiedToClipboard, copyToClipboardFlag] = useBoolean(false);

  const copyToClipboard = useCopyToClipboard({
    onError: () => {
      copyToClipboardFlag.off();
    },
    onSuccess: () => {},
  });

  const checklistLink = useHref(
    {
      pathname: 'checklist',
      search: queryString.stringify({ id: checklist.id, title: `${abbreviateForTitle(checklist.name ?? '')}-` }),
    },
    { relative: 'path' },
  );

  const updateChecklistShareableMutation = UpdateChecklistShareableMutation.useMutation({
    onMutate: variables => {
      const cacheUtils = createRQObjectCacheUtils<Checklist>(queryClient);
      const queryKey = GetChecklistQuery.getKey({ checklistId: variables.id });

      const currentData = cacheUtils.get(queryKey);
      const rollback = () => cacheUtils.set(queryKey, currentData);

      cacheUtils.update(queryKey, data =>
        data
          ? {
              ...data,
              shared: variables.shared,
            }
          : undefined,
      );

      return rollback;
    },
    onError: (error, _variables, rollback) => {
      console.error(error);

      if (isFunction(rollback)) {
        rollback();
      }

      toast({
        status: 'error',
        title: "We couldn't update the checklist shareable status",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateChecklistShareableMutation.mutate({ id: checklist.id, shared: event.target.checked });
  };

  const handleCopyToClipboard = () => {
    copyToClipboard(checklistLink);

    copyToClipboardFlag.on();

    setTimeout(() => {
      copyToClipboardFlag.off();
    }, 2000);
  };

  return (
    <VStack w="full" alignItems="flex-start" spacing={2.5}>
      <HStack w="full" justifyContent="space-between">
        <Text color="gray.400" variant="-1u">
          Share Link
        </Text>

        <Switch isChecked={checklist.shared} onChange={handleSwitchChange} />
      </HStack>

      <AnimatePresence>
        {checklist.shared && (
          <Tooltip label="Link copied to clipboard" isOpen={hasCopiedToClipboard} placement="bottom">
            <MotionBox
              initial={{ opacity: 0, y: -5 }}
              animate={{ opacity: 1, y: 0, transition: { duration: 0.2 } }}
              exit={{ opacity: 0, y: -5, transition: { duration: 0.2 } }}
            >
              <Button
                variant="outline"
                colorScheme="gray"
                leftIcon={
                  <Icon
                    icon={hasCopiedToClipboard ? 'check' : 'link'}
                    size="4"
                    color={hasCopiedToClipboard ? 'green.500' : 'gray.600'}
                  />
                }
                color="gray.600"
                borderWidth="1px"
                borderColor="gray.300"
                bgColor="white"
                fontWeight="normal"
                size="sm"
                onClick={handleCopyToClipboard}
              >
                Copy Link
              </Button>
            </MotionBox>
          </Tooltip>
        )}
      </AnimatePresence>
    </VStack>
  );
};
