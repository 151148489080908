import * as React from 'react';
import { Button, Divider, FormHelperText, HStack, Text, Tooltip } from 'components/design/next';
import { DefaultableFormFieldValue, NativeAutomation, WidgetUtils } from '@process-street/subgrade/process';
import { FormFieldAudit } from 'components/widgets/form-field/common/FormFieldAudit';
import { match, P } from 'ts-pattern';
import { useOutputWidgetAutomatedTasksMap } from 'features/native-automations/hooks/use-output-widget-automated-tasks-map';
import { AutomatedTaskAudit } from '../automated-task-audit';
import { useStateParam } from 'hooks/use-state-param';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'features/checklist-revisions/query-builder';
import { useInputWidgetAutomatedTasksMap } from 'features/native-automations/hooks/use-input-widget-automated-tasks-map';
import {
  CodeTaskIcon,
  ProcessPeteCircle,
  ProcessPeteIcon,
} from 'pages/runs/_id/components/automated-task-audit/common';
import { useWidgetContext } from '../../hooks/use-widget-context';
import { usePrintStore } from 'app/components/react-root/print-store';

export interface FormFieldValueHelperTextProps {
  defaultValue?: string;
  formFieldValue?: DefaultableFormFieldValue;
  onResetDefaultValue: () => void;
}

export function FormFieldValueHelperText({
  defaultValue,
  formFieldValue,
  onResetDefaultValue: handleResetDefaultValue,
}: FormFieldValueHelperTextProps) {
  const { widget } = useWidgetContext();
  const checklistId = useStateParam({ key: 'id' });
  const checklistRevisionQuery = GetActiveChecklistRevisionByChecklistIdQuery.useQuery({ checklistId });
  const templateRevisionId = checklistRevisionQuery.data?.templateRevision.id;

  const outputWidgetAutomatedTasksMap = useOutputWidgetAutomatedTasksMap({ templateRevisionId });
  const outputFromAutomatedTasks = React.useMemo(() => {
    return [...(outputWidgetAutomatedTasksMap[widget.id] ?? [])].reverse();
  }, [outputWidgetAutomatedTasksMap, widget.id]);

  const inputWidgetAutomatedTasksMap = useInputWidgetAutomatedTasksMap({ templateRevisionId });
  const inputToAutomatedTasks = React.useMemo(() => {
    return [...(inputWidgetAutomatedTasksMap[widget.id] ?? [])].reverse();
  }, [inputWidgetAutomatedTasksMap, widget.id]);

  const { isPrintView, isPrintPage } = usePrintStore();
  const isPrintMode = isPrintView || isPrintPage;

  const helperText = match({
    defaultValueConfigured: (defaultValue?.length ?? 0) > 0,
    hasVariables: WidgetUtils.hasVariables(defaultValue),
    hasDefaultValue: WidgetUtils.hasDefaultValue({ formFieldValue, defaultValue }),
    hasInvalidDefaultValue: WidgetUtils.hasInvalidDefaultValue({ formFieldValue, defaultValue }),
    formFieldValue,
    outputFromAutomatedTasks,
  })
    .with({ outputFromAutomatedTasks: P.not([]) }, ({ formFieldValue, outputFromAutomatedTasks }) => (
      <AutomatedTaskAudit
        fontSize="xs"
        formFieldValue={formFieldValue}
        automatedTaskTemplates={outputFromAutomatedTasks}
      />
    ))
    .with(
      {
        hasDefaultValue: false,
        defaultValueConfigured: true,
        formFieldValue: { audit: P.not(P.nullish) },
      },
      ({ formFieldValue: { audit } }) => (
        <HStack>
          <FormFieldAudit fontSize="xs" audit={audit} />
          {!isPrintMode && (
            <>
              <Divider h="3" orientation="vertical" />
              <Button size="xs" textDecor="underline" variant="link" onClick={handleResetDefaultValue}>
                Reset to default value
              </Button>
            </>
          )}
        </HStack>
      ),
    )
    .with({ defaultValueConfigured: true, hasInvalidDefaultValue: true }, () => (
      <Text variant="-2" color="inherit" fontWeight="bold">
        Invalid default value
      </Text>
    ))
    .with({ hasDefaultValue: true, hasVariables: true }, () => (
      <Text variant="-2" color="inherit" fontWeight="bold">
        Pre-filled with a default value and{' '}
        {/* try to match MaskedInputParsers merge-tags Parser span styling for consistency */}
        <Text as="span" variant="inherit" bgColor="brand.100" borderRadius="base">
          <Text as="span" aria-hidden visibility="hidden" variant="inherit">
            {'{{'}
          </Text>
          variables
          <Text as="span" aria-hidden visibility="hidden" variant="inherit">
            {'}}'}
          </Text>
        </Text>
      </Text>
    ))
    .with({ hasDefaultValue: true, hasVariables: false }, () => (
      <Text variant="-2" color="inherit" fontWeight="bold">
        Pre-filled with a default value
      </Text>
    ))
    .with({ formFieldValue: { audit: P.not(P.nullish) } }, ({ formFieldValue: { audit } }) => (
      <FormFieldAudit fontSize="xs" audit={audit} />
    ))
    .otherwise(() => null);

  const isAutomatedTaskInputOnly = inputToAutomatedTasks.length > 0 && outputFromAutomatedTasks.length === 0;
  const isAiTaskInput = NativeAutomation.hasAutomationOfType(inputToAutomatedTasks, 'AiTask');
  const isCodeTaskInput = NativeAutomation.hasAutomationOfType(inputToAutomatedTasks, 'CodeTask');
  return (
    <HStack>
      {isAutomatedTaskInputOnly && (
        <>
          {isAiTaskInput && (
            <Tooltip hasArrow label="Field connected to an AI Task" shouldWrapChildren>
              <ProcessPeteCircle bg="blue.100">
                <ProcessPeteIcon color="blue.500" />
              </ProcessPeteCircle>
            </Tooltip>
          )}
          {isCodeTaskInput && (
            <Tooltip hasArrow label="Field connected to a Code Task" shouldWrapChildren>
              <ProcessPeteCircle bg="blue.100">
                <CodeTaskIcon color="blue.500" />
              </ProcessPeteCircle>
            </Tooltip>
          )}
        </>
      )}
      {helperText ? <FormHelperText mt="0">{helperText}</FormHelperText> : null}
    </HStack>
  );
}
