import { EmbedWidget } from '@process-street/subgrade/process';
import { ActorRefFrom, createMachine, spawn, StateFrom } from 'xstate';
import { makeRulesEngineTargetMachine } from '../../form-response-body/rules-engine-machine';
import { useSelector } from '@xstate/react';

type Context = {
  widget: EmbedWidget;
  rulesEngineTargetActor: ActorRefFrom<typeof makeRulesEngineTargetMachine>;
};

type MakeEmbedContentWidgetMachineProps = {
  widget: EmbedWidget;
};

export const makeEmbedContentWidgetMachine = ({ widget }: MakeEmbedContentWidgetMachineProps) => {
  return createMachine({
    id: `embed-widget-machine:${widget.id}`,
    predictableActionArguments: true,
    context: () => ({
      widget,
      rulesEngineTargetActor: spawn(
        makeRulesEngineTargetMachine({ type: 'widget', widgetHeaderGroupId: widget.header.group.id }),
        { name: 'hidden-by-rule-actor', sync: true },
      ),
    }),
    tsTypes: {} as import('./embed-content-widget-machine.typegen').Typegen0,
    schema: {
      context: {} as Context,
    },
  });
};

export type EmbedContentWidgetMachine = ReturnType<typeof makeEmbedContentWidgetMachine>;
export type EmbedContentWidgetMachineActorRef = ActorRefFrom<EmbedContentWidgetMachine>;
export type EmbedContentWidgetMachineState = StateFrom<EmbedContentWidgetMachine>;

export namespace EmbedContentWidgetMachineSelectors {
  export const getWidget = (state: EmbedContentWidgetMachineState) => state.context.widget;
}

export namespace EmbedContentWidgetmachineHooks {
  export const useWidget = (actorRef: EmbedContentWidgetMachineActorRef) => {
    return useSelector(actorRef, EmbedContentWidgetMachineSelectors.getWidget);
  };
}
