import * as React from 'react';
import { Box, BoxProps, HStack, Text, Tooltip } from 'components/design/next';
import { FormFieldValue } from '@process-street/subgrade/process';
import { useAudit } from 'components/widgets/form-field/common/FormFieldAudit/use-audit';
import {
  AutomatedTaskAuditHelperText,
  CodeTaskIcon,
  CommonAuditProps,
  getAutomatedTaskOutputTooltip,
  ProcessPeteCircle,
  ProcessPeteIcon,
} from './common';

export interface FilledAutomatedTaskAuditProps extends BoxProps, CommonAuditProps {
  formFieldValue: FormFieldValue;
}

const AUTOMATION_USER_NAME = 'Automation User';

export const FilledAutomatedTaskAudit: React.FC<React.PropsWithChildren<FilledAutomatedTaskAuditProps>> = ({
  nativeAutomation,
  automatedTaskTemplates,
  formFieldValue,
  ...props
}) => {
  const { audit } = formFieldValue;
  const { formattedDate, formattedPrintDate, isLoading, isPrintMode, username } = useAudit({ audit });

  if (isLoading) return null;

  // otherwise the latest update was from a Code Task
  const isAutomatedTaskAudit = nativeAutomation?.automationType === 'AiTask';

  const updatedByCopy = username.includes(AUTOMATION_USER_NAME)
    ? // legacy case using automation user
      username.replace(AUTOMATION_USER_NAME, isAutomatedTaskAudit ? 'Process AI' : 'Code Task')
    : // using a real user as updater
      username + ' ' + (isAutomatedTaskAudit ? '(with AI)' : '(with Code Task)');

  const automatedTaskOutputTooltip = getAutomatedTaskOutputTooltip(automatedTaskTemplates);

  return (
    <Box display="inline-block" {...props}>
      <HStack>
        <Tooltip label={automatedTaskOutputTooltip} shouldWrapChildren hasArrow>
          {isAutomatedTaskAudit ? (
            <ProcessPeteCircle bg="blue.100">
              <ProcessPeteIcon color="blue.500" />
            </ProcessPeteCircle>
          ) : (
            <ProcessPeteCircle bg="blue.100">
              <CodeTaskIcon color="blue.500" />
            </ProcessPeteCircle>
          )}
        </Tooltip>

        <Tooltip label={formattedDate} hasArrow shouldWrapChildren>
          <AutomatedTaskAuditHelperText color={isPrintMode ? 'blue.200' : 'blue.500'}>
            Updated by{' '}
            <Text as="span" variant="inherit">
              {updatedByCopy}{' '}
            </Text>
            {isPrintMode ? (
              <Text as="span" variant="inherit">
                {formattedDate}
              </Text>
            ) : (
              <Text as="span" variant="inherit">
                {formattedPrintDate}
              </Text>
            )}
          </AutomatedTaskAuditHelperText>
        </Tooltip>
      </HStack>
    </Box>
  );
};
