import * as React from 'react';
import { BoxProps } from 'components/design/next';
import { FormFieldValue } from '@process-street/subgrade/process';
import { match, P } from 'ts-pattern';
import { EmptyAutomatedTaskAudit } from './empty-automated-task-audit';
import { RunningAutomatedTaskAudit } from './running-automated-task-audit';
import { FailedAutomatedTaskAudit } from './failed-automated-task-audit';
import { FilledAutomatedTaskAudit } from './filled-automated-task-audit';
import { useChecklistRevisionNativeAutomationStatus } from '../../hooks/use-checklist-revision-native-automation-status';
import { CommonAuditProps } from './common';

export interface AutomatedTaskAuditProps extends BoxProps, CommonAuditProps {
  formFieldValue?: FormFieldValue;
}

export const AutomatedTaskAudit: React.FC<React.PropsWithChildren<AutomatedTaskAuditProps>> = ({
  formFieldValue,
  automatedTaskTemplates,
}) => {
  const nativeAutomations = React.useMemo(
    () => automatedTaskTemplates.map(({ nativeAutomation }) => nativeAutomation),
    [automatedTaskTemplates],
  );
  const {
    status: automationStatus,
    errorMessage,
    nativeAutomation,
    latestExecutionId,
  } = useChecklistRevisionNativeAutomationStatus({ nativeAutomations });

  return match({ automationStatus, formFieldValue })
    .with({ automationStatus: undefined }, () => (
      <EmptyAutomatedTaskAudit automatedTaskTemplates={automatedTaskTemplates} nativeAutomation={nativeAutomation} />
    ))
    .with({ automationStatus: 'AutomationRunning' }, () => (
      <RunningAutomatedTaskAudit automatedTaskTemplates={automatedTaskTemplates} nativeAutomation={nativeAutomation} />
    ))
    .with({ automationStatus: 'AutomationFailed' }, () => (
      <FailedAutomatedTaskAudit
        errorMessage={errorMessage}
        automatedTaskTemplates={automatedTaskTemplates}
        nativeAutomation={nativeAutomation}
        latestExecutionId={latestExecutionId}
      />
    ))
    .with({ formFieldValue: { audit: P.not(P.nullish) } }, ({ formFieldValue }) => (
      <FilledAutomatedTaskAudit
        formFieldValue={formFieldValue}
        automatedTaskTemplates={automatedTaskTemplates}
        nativeAutomation={nativeAutomation}
      />
    ))
    .otherwise(() => null);
};
