import * as React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Icon,
  Skeleton,
  Text,
  VStack,
} from 'components/design/next';
import { TaskTemplate, Template } from '@process-street/subgrade/process';
import { OutputList } from './output-list';
import { InputList } from 'pages/templates/_id/components/code-task-template-editor/input-list';
import { CodeEditor } from 'pages/templates/_id/components/code-task-template-editor/code-editor/code-editor';
import { LabelWithTooltip } from 'pages/templates/_id/components/code-task-template-editor/label-with-tooltip';
import { RunCodeButton } from './test-mode/run-code-button';
import { KeyValueMapping } from 'pages/templates/_id/components/code-task-template-editor/key-value-list/key-value-list';
import { SwitchModeButtons } from 'pages/templates/_id/components/code-task-template-editor/test-mode/switch-mode-buttons';
import { useActor, useInterpret } from '@xstate/react';
import { CodeTaskTemplateEditorMode, makeCodeTaskMachine } from './machine/code-task-machine';
import { CodeTaskUtils } from 'pages/templates/_id/components/code-task-template-editor/code-task-utils';
import { useQueryClient } from 'react-query';

export type CodeTaskTemplateEditorProps = {
  templateId: Template['id'];
  taskTemplate: TaskTemplate;
  isReadOnly: boolean;
};

export const CodeTaskTemplateEditor: React.FC<CodeTaskTemplateEditorProps> = ({
  isReadOnly,
  taskTemplate,
  templateId,
}) => {
  const queryClient = useQueryClient();

  const [machine] = React.useState(() =>
    makeCodeTaskMachine({
      codeTask: taskTemplate,
      queryClient,
      templateId,
      isReadOnly,
    }),
  );
  const actor = useInterpret(machine);

  // state
  const [current, send] = useActor(actor);
  const {
    canEditTemplate,
    codeAction,
    inputMapping,
    outputMapping,
    testInputMapping,
    testOutputMapping,
    updateFormFieldsAction,
  } = current.context;

  const mode = CodeTaskUtils.getModeFromState(current.value);

  const handleSwitchMode = (newMode: CodeTaskTemplateEditorMode) => {
    if (newMode === 'test') {
      send({ type: 'SET_TEST_MODE' });
    } else if (newMode === 'edit') {
      send({ type: 'SET_EDIT_MODE' });
    }
  };

  const handleInputsUpdate = (mappings: KeyValueMapping[]) => {
    if (!codeAction) return;

    if (mode === 'test') {
      actor.send({ type: 'SET_TEST_INPUT_MAPPINGS', value: mappings });
    } else if (mode === 'edit') {
      actor.send({ type: 'SET_INPUT_MAPPINGS', value: mappings });
    }
  };

  const handleCodeUpdate = (code: string) => {
    actor.send({ type: 'SET_CODE', value: code });
  };

  const handleOutputsUpdate = (mappings: KeyValueMapping[]) => {
    actor.send({ type: 'SET_OUTPUT_MAPPINGS', value: mappings });
  };

  if (isReadOnly && mode === 'loading') return null; // don't leak anything if we're still loading permissions in view mode

  const isTestModeEnabled =
    inputMapping.every(({ value }) => value !== '') && codeAction && codeAction?.config.code !== '';

  if (mode === 'view' && !canEditTemplate) {
    return (
      <VStack padding={8} gap={4}>
        <Icon
          icon="lock-keyhole"
          variant="fadr"
          size="12"
          primaryLayer={{ color: 'blue.500' }}
          secondaryLayer={{ color: 'blue.400' }}
        />
        <Text size="md" color="gray.500" fontWeight="medium">
          You need Edit rights to view the Code task
        </Text>
      </VStack>
    );
  }

  return (
    <>
      {mode !== 'view' && (
        <SwitchModeButtons mode={mode} onSwitchMode={handleSwitchMode} isTestModeEnabled={isTestModeEnabled ?? false} />
      )}

      <Accordion width="full" allowMultiple defaultIndex={[0, 1, 2]} mt={4} mb={9}>
        <AccordionItem>
          <AccordionButton alignItems="center" justifyContent="space-between">
            <LabelWithTooltip label="Input data" tooltip="Provide input data for your code" />
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel>
            <InputList
              mappings={mode === 'test' ? testInputMapping : inputMapping}
              templateRevisionId={taskTemplate.templateRevision.id}
              onUpdate={handleInputsUpdate}
              mode={mode}
            />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton alignItems="center" justifyContent="space-between">
            <LabelWithTooltip label="Code" tooltip="Code" />
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel>
            {codeAction && updateFormFieldsAction ? (
              <VStack alignItems="flex-start">
                <CodeEditor
                  onUpdate={handleCodeUpdate}
                  updateFormFieldsAction={updateFormFieldsAction}
                  codeAction={codeAction}
                  isReadOnly={isReadOnly}
                />
                {mode === 'test' && <RunCodeButton codeTaskActor={actor} />}
              </VStack>
            ) : (
              <Skeleton w="full" isLoaded={false}>
                <Box height={40}>Loading</Box>
              </Skeleton>
            )}
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton alignItems="center" justifyContent="space-between">
            <LabelWithTooltip label="Output data" tooltip="Output data" />
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel>
            <OutputList
              taskTemplate={taskTemplate}
              codeAction={codeAction}
              onUpdate={handleOutputsUpdate}
              mappings={mode === 'test' ? testOutputMapping : outputMapping}
              mode={mode}
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};
