import { MuidUtils } from '@process-street/subgrade/core';
import { Checklist } from '@process-street/subgrade/process';
import { generateChecklistAssignment } from '@process-street/subgrade/test';
import { HttpStatus } from '@process-street/subgrade/util';
import { useToast } from 'app/components/design/next';
import { CreateChecklistAssignmentByEmailMutation } from 'app/components/run-checklist/query-builder/create-checklist-assignment-by-email-mutation';
import { DefaultErrorMessages } from 'app/components/utils/error-messages';
import { GetChecklistAssignmentsQuery } from 'app/features/checklists/query-builder';
import { CreateInvitationMutation } from 'app/features/invitations/query-builder/create-invitation-mutation';
import { useGetAllOrganizationMembershipsQuery } from 'app/features/organization-memberships/query-builder';
import { useGetCurrentUserInfoQuery } from 'app/features/user/query-builder';
import { AxiosError } from 'axios';
import { isFunction } from 'lodash';
import { useQueryClient } from 'react-query';

type UseAssignOrInviteMutationProps = {
  checklist: Checklist;
  onCreateInvitationError?: (error: AxiosError, variables: CreateInvitationMutation.Params, context: unknown) => void;
  onCreateInvitationSuccess?: (
    data: CreateInvitationMutation.Response,
    variables: CreateInvitationMutation.Params,
  ) => void;
  onCreateAssignmentError?: (
    error: AxiosError,
    variables: CreateChecklistAssignmentByEmailMutation.Params,
    context: unknown,
  ) => void;
  onCreateAssignmentSuccess?: (
    data: CreateChecklistAssignmentByEmailMutation.Response,
    variables: CreateChecklistAssignmentByEmailMutation.Params,
  ) => void;
};

export const useAssignOrInviteMutation = ({
  checklist,
  onCreateInvitationError,
  onCreateInvitationSuccess,
  onCreateAssignmentError,
  onCreateAssignmentSuccess,
}: UseAssignOrInviteMutationProps) => {
  const toast = useToast();
  const currentUserInfoQuery = useGetCurrentUserInfoQuery();
  const allOrganizationMembershipsQuery = useGetAllOrganizationMembershipsQuery({
    organizationId: currentUserInfoQuery.data?.organizationMembership?.organization.id,
    include: 'group',
  });

  const queryClient = useQueryClient();

  const createInvitationMutation = CreateInvitationMutation.useMutation({
    onSuccess: (data, variables) => {
      onCreateInvitationSuccess?.(data, variables);
      const assigneeName = data.organizationMembership?.user.username || variables.email;

      toast({
        status: 'success',
        title: 'Assignments updated',
        description: `${assigneeName} has been assigned to the workflow run`,
      });

      void GetChecklistAssignmentsQuery.invalidate(queryClient, { checklistId: checklist.id });
    },
    onError: (error, variables, context) => {
      onCreateInvitationError?.(error, variables, context);
      toast({
        status: 'error',
        title: 'We could not invite the user',
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const createChecklistAssignmentMutation = CreateChecklistAssignmentByEmailMutation.useMutation({
    onMutate: ({ email }) => {
      const cacheManager = GetChecklistAssignmentsQuery.createCacheUtils(queryClient);
      const organizationMembership = allOrganizationMembershipsQuery.data?.find(om => om.user.email === email);
      const assignmentId = MuidUtils.randomMuid();
      const newAssignment = generateChecklistAssignment({
        organizationMembership,
        checklist,
        id: assignmentId,
      });

      const rollback = () => cacheManager.remove({ checklistId: checklist.id }, assignmentId);

      cacheManager.append({ checklistId: checklist.id }, newAssignment);

      return rollback;
    },
    onError: (error, variables, rollback) => {
      onCreateAssignmentError?.(error, variables, rollback);
      if (error.response?.status === HttpStatus.NOT_FOUND) {
        createInvitationMutation.mutate({
          email: variables.email,
          resource: { type: 'Checklist', id: checklist.id },
        });
      } else {
        toast({
          status: 'error',
          title: 'We could not assign the user',
          description: DefaultErrorMessages.unexpectedErrorDescription,
        });
      }

      if (isFunction(rollback)) {
        rollback();
      }
    },
    onSuccess: (data, variables) => {
      onCreateAssignmentSuccess?.(data, variables);
      const organizationMembership = allOrganizationMembershipsQuery.data?.find(
        om => om.user.email === variables.email,
      );
      const assigneeName = organizationMembership?.user.username || variables.email;

      toast({
        status: 'success',
        title: 'Assignments updated',
        description: `${assigneeName} has been assigned to the workflow run`,
      });

      void queryClient.invalidateQueries(
        GetChecklistAssignmentsQuery.getKey({
          checklistId: checklist.id,
        }),
      );
    },
  });

  return createChecklistAssignmentMutation;
};
