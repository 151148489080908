import * as React from 'react';
import { useInjector } from 'components/injection-provider';
import { onlineManager, useIsMutating } from 'react-query';
import { useSelector } from 'react-redux';
import { ReduxAppState } from 'reducers/types';
import { GetAllNativeAutomationsQuery } from 'features/native-automations/query-builder';
import { NativeAutomation, NativeAutomationType } from '@process-street/subgrade/process';
import { useStateParam } from 'hooks/use-state-param';
import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';
import { match } from 'ts-pattern';
import { UpdateNativeAutomationActionsMutation } from 'features/native-automations/query-builder';
import {
  UpdateTaskTemplateMutation,
  UpdateTaskTemplateOrderTreesMutation,
} from 'features/task-templates/query-builder';
import { UpdateWidgetMutation, UpdateWidgetOrderTreesMutation } from 'features/widgets/query-builder';

export const DRAFT_STATUSES = ['saving', 'saved', 'offline', 'error', 'invalidAiTask', 'invalidCodeTask'] as const;
export type DraftStatus = typeof DRAFT_STATUSES[number];

const CONFIRM_LEAVE_MESSAGE = 'You have unpublished changes. Discard changes and leave this page?';

const selector = (state: ReduxAppState) => state.statuses.currentTemplateRevision;

export const useDraftStatus = () => {
  const { $rootScope } = useInjector('$rootScope');
  const templateStatus = useSelector(selector);

  const isUpdatingTaskTemplateAutomation = Boolean(
    useIsMutating({
      mutationKey: UpdateNativeAutomationActionsMutation.key,
    }),
  );
  const isUpdatingTaskTemplate = Boolean(
    useIsMutating({
      mutationKey: UpdateTaskTemplateMutation.key,
    }),
  );
  const isUpdatingWidget = Boolean(
    useIsMutating({
      mutationKey: UpdateWidgetMutation.getKey(),
    }),
  );
  const isUpdatingTaskOrder = Boolean(
    useIsMutating({
      mutationKey: UpdateTaskTemplateOrderTreesMutation.key,
    }),
  );
  const isUpdatingWidgetOrder = Boolean(
    useIsMutating({
      mutationKey: UpdateWidgetOrderTreesMutation.key,
    }),
  );
  const templateId = useStateParam({ key: 'id' });
  const templateRevisionQuery = useNewestTemplateRevisionQuery({ templateId, editable: true });
  const templateRevisionId = templateRevisionQuery.data?.id;
  const taskTemplateAutomationsQuery = GetAllNativeAutomationsQuery.useGetTaskTemplateAutomationsMapQuery({
    templateRevisionId,
  });

  const hasUnconfiguredAutomation = React.useCallback(
    (automationType: NativeAutomationType) => {
      if (!taskTemplateAutomationsQuery.data) return false;
      const isEveryTaskConfigured = Object.values(taskTemplateAutomationsQuery.data)
        .flat()
        .filter(nativeAutomation => nativeAutomation.automationType === automationType)
        .every(NativeAutomation.isAutomationConfigured);
      return !isEveryTaskConfigured;
    },
    [taskTemplateAutomationsQuery.data],
  );

  const [isOnline, setIsOnline] = React.useState(onlineManager.isOnline());
  React.useEffect(() => {
    const unsubscribe = onlineManager.subscribe(() => {
      setIsOnline(onlineManager.isOnline());
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const pattern = {
    isUpdatingTaskTemplateAutomation,
    templateStatus,
    isOnline,
    hasUnconfiguredAiTasks: hasUnconfiguredAutomation('AiTask'),
    hasUnconfiguredCodeTasks: hasUnconfiguredAutomation('CodeTask'),
    isUpdatingTaskTemplate,
    isUpdatingWidget,
    isUpdatingTaskOrder,
    isUpdatingWidgetOrder,
  };
  const status = match<typeof pattern, DraftStatus>(pattern)
    .with({ templateStatus: { loading: true } }, () => 'saving')
    .with({ isUpdatingTaskTemplateAutomation: true }, () => 'saving')
    .with({ isUpdatingTaskTemplate: true }, () => 'saving')
    .with({ isUpdatingWidget: true }, () => 'saving')
    .with({ isUpdatingTaskOrder: true }, () => 'saving')
    .with({ isUpdatingWidgetOrder: true }, () => 'saving')
    .with({ templateStatus: { error: true } }, () => 'error')
    .with({ isOnline: false }, () => 'offline')
    .with({ hasUnconfiguredAiTasks: true }, () => 'invalidAiTask')
    .with({ hasUnconfiguredCodeTasks: true }, () => 'invalidCodeTask')
    .otherwise(() => 'saved');

  const [isSavingDebouncing, setIsSavingDebouncing] = React.useState(false);
  React.useEffect(() => {
    if (status === 'saving') {
      setIsSavingDebouncing(true);
    }
    const timeout = setTimeout(() => {
      setIsSavingDebouncing(false);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [status]);

  const statusWithMask = isSavingDebouncing ? 'saving' : status;

  React.useEffect(() => {
    if (status === 'error') {
      const alertUser = (e: WindowEventMap['beforeunload']) => {
        e.returnValue = CONFIRM_LEAVE_MESSAGE;
        return CONFIRM_LEAVE_MESSAGE;
      };
      const unsubscribe = $rootScope.$on('$locationChangeStart', e => {
        const answer = window.confirm(CONFIRM_LEAVE_MESSAGE);
        if (!answer) {
          e.preventDefault();
        }
      });
      window.addEventListener('beforeunload', alertUser);
      return () => {
        unsubscribe();
        window.removeEventListener('beforeunload', alertUser);
      };
    }
  }, [$rootScope, status]);

  return statusWithMask;
};
