import { FieldType, MembersFieldValue, MembersFormFieldConfig } from '@process-street/subgrade/process';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { WidgetSettings } from 'pages/forms/_id/edit/components/form-fields/common/settings/widget-settings';

export const membersSettingsSchema: ObjectSchema<WidgetSettings<FieldType.Members>> = yup
  .object({
    config: yup
      .object<MembersFormFieldConfig>({
        groupId: yup.string().required(),
      })
      .required() as yup.Schema<MembersFormFieldConfig>,
  })
  .required();

type ValidationSchemaProps = {
  required: boolean;
};

export const makeMembersFormFieldValidationSchema = ({ required }: ValidationSchemaProps) => {
  return yup.object().shape({
    organizationMembershipIds: yup
      .array()
      .of(yup.string())
      .optional()
      .test('required select', 'This field is required', values => {
        if (required) {
          if (!values?.length) {
            return false;
          }
          const hasValue = values.some(value => value && value.trim() !== '');
          return hasValue;
        }
        return true;
      }),
  }) as yup.Schema<MembersFieldValue | undefined>;
};
