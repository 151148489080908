import { Button, HStack, Alert, AlertDescription, AlertIcon, Text, Link } from 'components/design/next';
import * as React from 'react';
import { CodeTaskActor } from 'pages/templates/_id/components/code-task-template-editor/machine/code-task-machine';
import { useActor } from '@xstate/react';
import { useDisclosure } from '@chakra-ui/react';
import { CodeTaskDetailedRunLogs } from 'pages/runs/_id/components/code-task-run-log-modal/code-task-detailed-run-logs';
import { NativeAutomation } from '@process-street/subgrade/process';

export type RunCodeButtonProps = {
  codeTaskActor: CodeTaskActor;
};

export const RunCodeButton: React.FC<RunCodeButtonProps> = ({ codeTaskActor }) => {
  const [current, send] = useActor(codeTaskActor);
  const { codeAction, testExecutionLog, codeTask } = current.context;
  const codeTaskLogsDisclosure = useDisclosure();

  const code = codeAction?.config.code ?? '';

  const hasEmptyCode = code.trim() === '';

  const handleRunClick = () => {
    send('EXECUTE_CODE');
  };

  const handleOpenCodeTaskLogs = () => {
    codeTaskLogsDisclosure.onOpen();
  };
  const isExecuting = current.matches('test.executingCode');

  const log: NativeAutomation.Log | undefined = testExecutionLog
    ? {
        id: '1',
        createdAt: Date.now(),
        executionId: '1',
        currentActionIndex: 0,
        currentActionType: 'ExecuteCode',
        automationStatus: testExecutionLog.error ? 'Failed' : 'Completed',
        currentActionStatus: testExecutionLog.error ? 'Failed' : 'Completed',
        errorMessage: testExecutionLog.error,
        logs: testExecutionLog.logs
          ? {
              logType: 'ExecuteCodeAction',
              logs: testExecutionLog,
            }
          : undefined,
      }
    : undefined;

  return (
    <HStack width="full" justifyContent="space-between" mt={2}>
      <Button onClick={handleRunClick} isDisabled={hasEmptyCode} isLoading={isExecuting}>
        Run code
      </Button>

      {testExecutionLog && (
        <Alert status={testExecutionLog.error ? 'error' : 'success'} width="unset" borderRadius="8px" paddingY={2}>
          <AlertIcon />
          <AlertDescription display="flex">
            <Text color="gray.600">{testExecutionLog.error ? 'Code test failed.' : 'The code ran successfully.'}</Text>
            <Link onClick={handleOpenCodeTaskLogs} textDecor="underline" ml={1}>
              View run log
            </Link>
            .
          </AlertDescription>
        </Alert>
      )}

      {log && <CodeTaskDetailedRunLogs taskTemplateName={codeTask.name} log={log} {...codeTaskLogsDisclosure} />}
    </HStack>
  );
};
