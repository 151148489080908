import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export enum SendEmailResponseType {
  AllSent = 'AllSent',
  NoneSent = 'NoneSent',
  PartiallyInvalid = 'PartiallyInvalid',
}

export namespace SendEmailMutation {
  export type Params = {
    widgetId: Muid;
    checklistRevisionId: Muid;
  };
  export type Response = {
    sentDate: number;
    senderUserId: Muid;
    responseType: SendEmailResponseType;
    summaryMessage: string;
    results: Array<{
      id: Muid;
      email: string;
      status: string;
      rejectReason?: string;
    }>;
  };
  export const key = ['checklist-revisions', 'widgets', 'send-email'];
  export const mutationFn = ({ checklistRevisionId, widgetId }: Params) =>
    axiosService
      .getAxios()
      .post(`/1/checklist-revisions/${checklistRevisionId}/widgets/${widgetId}/send`)
      .then(res => res.data);
}

export const useSendEmailMutation = (
  options: UseMutationOptions<SendEmailMutation.Response, AxiosError, SendEmailMutation.Params> = {},
) => {
  return useMutation(SendEmailMutation.mutationFn, options);
};
