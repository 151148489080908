import { User } from '@process-street/subgrade/core';
import { TaskAssignment, TaskAssignmentUtils } from '@process-street/subgrade/role-assignment';
import { ChakraAvatar } from 'app/components/design/next/chakra-avatar';
import { AssignmentsPopover } from 'app/pages/checklists/_id/components/task-list/components/task-list-item-assignee-indicator-popover';
import { NonEmptyArray } from 'app/utils/array-utils';
import { AvatarGroup, Text, Tooltip, VStack } from 'components/design/next';
import sortBy from 'lodash/sortBy';
import * as React from 'react';
import { getAvatar } from 'app/components/common/Avatar';

export type ApprovalAssigneesProps = {
  taskAssignments: NonEmptyArray<TaskAssignment>;
};

export const ApprovalAssignees = ({ taskAssignments }: ApprovalAssigneesProps) => {
  const count = taskAssignments.length - 1;

  const users = taskAssignments
    .map(TaskAssignmentUtils.getUserFromTaskAssignment)
    .filter((user): user is User => Boolean(user));

  const sortedUsers = React.useMemo(() => sortBy(users, user => user.username.toLowerCase()), [users]);

  const firstUser = sortedUsers[0];

  if (!firstUser) return null;

  const { title } = getAvatar(firstUser);
  const tooltipLabel =
    count > 0 ? `Assigned to ${title} and ${count} ${count > 1 ? 'others' : 'other'}` : `Assigned to ${title}`;

  return (
    <AssignmentsPopover assignments={taskAssignments}>
      <VStack alignItems="flex-start">
        <Text variant="-1u" color="gray.400">
          Assignees
        </Text>
        <Tooltip hasArrow shouldWrapChildren label={tooltipLabel}>
          <AvatarGroup aria-label="Approval assignees" size="xs" w="full" max={5} _hover={{ cursor: 'pointer' }}>
            {sortedUsers.map((user, index) => (
              <ChakraAvatar user={user} key={index} />
            ))}
          </AvatarGroup>
        </Tooltip>
      </VStack>
    </AssignmentsPopover>
  );
};
