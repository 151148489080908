import { Muid } from '@process-street/subgrade/core';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'features/checklist-revisions/query-builder';
import { useStateParam } from 'hooks/use-state-param';
import { useOutputWidgetAutomatedTasksMap } from 'features/native-automations/hooks/use-output-widget-automated-tasks-map';
import { useChecklistRevisionNativeAutomationStatus } from './use-checklist-revision-native-automation-status';
import React from 'react';

export const useAutomatedTaskOutputWidgetStatus = (widgetId: Muid) => {
  const checklistId = useStateParam({ key: 'id' });
  const checklistRevisionQuery = GetActiveChecklistRevisionByChecklistIdQuery.useQuery({ checklistId });
  const templateRevisionId = checklistRevisionQuery.data?.templateRevision.id;
  const outputWidgetAutomatedTasksMap = useOutputWidgetAutomatedTasksMap({ templateRevisionId });
  const nativeAutomations = React.useMemo(
    () => outputWidgetAutomatedTasksMap[widgetId]?.map(automatedTask => automatedTask.nativeAutomation),
    [outputWidgetAutomatedTasksMap, widgetId],
  );
  const { status } = useChecklistRevisionNativeAutomationStatus({ nativeAutomations });
  return { status };
};
