import * as React from 'react';
import { ablyService } from 'app/pusher/ably.service';
import { AblyEvent } from 'app/pusher/ably-event';
import { toResult } from '@process-street/subgrade/util';
import { NativeAutomation } from '@process-street/subgrade/process';
import { useStateParam } from 'hooks/use-state-param';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'features/checklist-revisions/query-builder';
import { GetAllNativeAutomationLogsByChecklistIdQuery } from 'features/native-automations/query-builder/get-all-native-automation-logs-by-checklist-id-query';
import { useInboxItemsSelectedRow } from 'app/pages/tasks/use-inbox-items-grid-context-store';
import sortBy from 'lodash/sortBy';
import { Option } from 'space-monad';
import { Types } from 'ably';
import { match, P } from 'ts-pattern';

interface Params {
  /** list of native automations - has to be stable reference */
  nativeAutomations?: NativeAutomation[];
  /** single native automation */
  nativeAutomation?: NativeAutomation;
}

/**
 * Compiles audit fields status for a form field's connected native automations.
 * @returns logs query and data associated with the latest native automation execution
 * */
export function useChecklistRevisionNativeAutomationStatus({ nativeAutomations, nativeAutomation }: Params) {
  const nativeAutomationsList = React.useMemo(
    () =>
      Option(nativeAutomation).fold(
        () => nativeAutomations ?? [],
        automation => [automation].concat(nativeAutomations ?? []),
      ),
    [nativeAutomation, nativeAutomations],
  );

  const selectedRow = useInboxItemsSelectedRow();
  const checklistId =
    // Get the checklist id when the user is in the WF run page
    useStateParam({ key: 'id' }) ??
    // When not in the WF run page, we use the My work's page currently selected row
    selectedRow?.checklist.id;
  const checklistRevisionQuery = GetActiveChecklistRevisionByChecklistIdQuery.useQuery({ checklistId });
  const checklistRevisionId = checklistRevisionQuery.data?.id;

  const logsQuery = GetAllNativeAutomationLogsByChecklistIdQuery.useQuery(
    { checklistId },
    {
      enabled: Boolean(nativeAutomationsList.length),
      select: logs =>
        Object.fromEntries(
          Object.entries(logs).filter(([automationStableId]) =>
            nativeAutomationsList.some(automation => automation.stableId === automationStableId),
          ),
        ) as GetAllNativeAutomationLogsByChecklistIdQuery.Response,
    },
  );

  // these have to be state, so that the Ably listeners can update them
  const [status, setStatus] = React.useState<NativeAutomation.EventStatus | undefined>();
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const [latestExecutionId, setLatestExecutionId] = React.useState<string | undefined>();
  const [latestNativeAutomation, setLatestNativeAutomation] = React.useState<NativeAutomation | undefined>();

  React.useEffect(
    function setLatestExecutionData() {
      // latest logs for each automation
      const latestLogs = nativeAutomationsList.map(automation => ({
        automationId: automation.stableId,
        data: logsQuery.data?.[automation.stableId]?.[0],
      }));
      // if there are multiple automations, find latest execution
      // having multiple automations connected to the same output field is an edge case
      // nevertheless, audit fields should always display the latest modification
      const latestLog = sortBy(latestLogs, 'data.createdAt').reverse()?.[0];

      if (!latestLog?.data) return;
      const latestLogData = latestLog.data;
      setLatestExecutionId(latestLogData.executionId);
      setErrorMessage(latestLogData.errorMessage);
      setLatestNativeAutomation(
        nativeAutomationsList.find(automation => automation.stableId === latestLog.automationId),
      );

      const status = match<typeof latestLogData, NativeAutomation.EventStatus | undefined>(latestLogData)
        .with({ errorMessage: P.string }, () => 'AutomationFailed')
        .with({ automationStatus: 'Failed' }, () => 'AutomationFailed')
        .with({ automationStatus: 'Running' }, () => 'AutomationRunning')
        .with({ automationStatus: 'Completed' }, () => 'AutomationComplete')
        .otherwise(() => undefined);

      setStatus(status);
    },
    [logsQuery.data, nativeAutomationsList],
  );

  React.useEffect(
    function subscribeToAbly() {
      if (!checklistRevisionId) return;
      const listeners = nativeAutomationsList.map(currentNativeAutomation => {
        const listener = (message: Types.Message) => {
          if (message.name !== AblyEvent.EventType.ChecklistRevisionNativeAutomationEvent) return;

          toResult<NativeAutomation.Event>(() => JSON.parse(message.data))
            .toOption()
            .forEach(event => {
              if (
                event.nativeAutomationId === currentNativeAutomation.id &&
                event.checklistRevisionId === checklistRevisionId
              ) {
                // if there is a newer automation event happening live, override the status
                setLatestExecutionId(event.executionId);
                setLatestNativeAutomation(currentNativeAutomation);
                setStatus(event.status);
                setErrorMessage(event.errorMessage);
              }
            });
        };

        return { nativeAutomation: currentNativeAutomation, listener };
      });

      listeners.forEach(({ nativeAutomation, listener }) => {
        const channel = getAblyChannel(nativeAutomation, checklistRevisionId);
        channel.subscribe(AblyEvent.EventType.ChecklistRevisionNativeAutomationEvent, listener);
      });

      return () => {
        listeners.forEach(({ nativeAutomation, listener }) => {
          const channel = getAblyChannel(nativeAutomation, checklistRevisionId);
          channel.unsubscribe(AblyEvent.EventType.ChecklistRevisionNativeAutomationEvent, listener);
        });
      };
    },
    [checklistRevisionId, nativeAutomationsList],
  );

  return { latestExecutionId, status, errorMessage, nativeAutomation: latestNativeAutomation, logsQuery };
}

function getAblyChannel(nativeAutomation: NativeAutomation, checklistRevisionId: string) {
  const channelName = ablyService.getChannelNameForNativeAutomation(nativeAutomation.id, checklistRevisionId);
  return ablyService.getChannel(channelName);
}
