import { GroupMembership, Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import {
  QueryKey,
  useQuery as useRQQuery,
  UseQueryOptions,
  QueryClient,
  QueryObserverOptions,
  QueryObserver,
} from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace GetGroupMembershipsQuery {
  export type Params = { userId?: Muid; groupId?: Muid };
  export type Response = GroupMembership[];

  export const key = ['group-memberships'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = (params: Params) => {
    return axiosService
      .getAxios()
      .get<Response>(`/1/group-memberships`, { params })
      .then(res => res.data);
  };

  export const useQuery = <Select = Response>(
    params: Params = {},
    options: UseQueryOptions<Response, Error, Select> = {},
  ) => {
    return useRQQuery<Response, Error, Select>(getKey(params), () => queryFn(params), options);
  };

  export const makeQueryObserver = ({
    queryClient,
    options,
    ...params
  }: Params & {
    queryClient: QueryClient;
    options: QueryObserverOptions<Response, AxiosError>;
  }) => {
    return new QueryObserver<Response, AxiosError>(queryClient, {
      queryKey: getKey(params),
      queryFn: () => queryFn(params),
      enabled: options.enabled !== false,
      ...options,
    });
  };
}

// exposed to not modify existing imports
export const useGroupMemberships = GetGroupMembershipsQuery.useQuery;
