import { GetAllNativeAutomationsQuery } from 'features/native-automations/query-builder';
import {
  AutomatedTaskTemplateId,
  FormFieldKeysContext,
  NativeAutomation,
  Widget,
} from '@process-street/subgrade/process';
import { match } from 'ts-pattern';
import React from 'react';
import { Muid } from '@process-street/subgrade/core';
import { useWidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import { WidgetServiceUtils } from 'services/widget-service.utils';
import { MergeTagMode, MergeTagStringReplacementUtils } from '@process-street/subgrade/merge-tags';
import isEmpty from 'lodash/isEmpty';

type InputWidgetAutomatedTasksMap = Record<Widget['id'], AutomatedTaskTemplateId[]>;

export function useInputWidgetAutomatedTasksMap({
  templateRevisionId,
}: {
  templateRevisionId?: Muid;
}): InputWidgetAutomatedTasksMap {
  const widgetsQuery = useWidgetsByTemplateRevisionIdQuery(templateRevisionId);
  const taskTemplateAutomations = GetAllNativeAutomationsQuery.useGetTaskTemplateAutomationsMapQuery({
    templateRevisionId,
  });

  return React.useMemo(
    () =>
      widgetsQuery.data && taskTemplateAutomations.data
        ? makeInputWidgetAutomatedTasksMap(widgetsQuery.data, taskTemplateAutomations.data)
        : {},
    [widgetsQuery.data, taskTemplateAutomations.data],
  );
}

/**
 * Extract connected input widgets from a list of automations.
 * @param widgets list of all widgets
 * @param automationsByTaskTemplateId automations by task template ID
 */
export const makeInputWidgetAutomatedTasksMap = (
  widgets: Widget[],
  automationsByTaskTemplateId: GetAllNativeAutomationsQuery.TaskTemplateAutomationMap,
): InputWidgetAutomatedTasksMap => {
  if (isEmpty(automationsByTaskTemplateId)) return {};
  const formFieldKeys = WidgetServiceUtils.getFormFieldWidgetKeyMap(widgets, FormFieldKeysContext.MERGE_TAG);

  const addInputWidgets = (
    value: string,
    acc: InputWidgetAutomatedTasksMap,
    taskTemplateId: string,
    nativeAutomation: NativeAutomation,
  ) => {
    const foundTags = MergeTagStringReplacementUtils.findTags(value ?? '', MergeTagMode.PLAINTEXT);
    foundTags.forEach(tag => {
      // find referenced widget based on {{form.variable}}
      const widget = formFieldKeys[tag.replace(/^form\./, '')];
      if (!widget) return;
      acc[widget.id] = (acc[widget.id] ?? []).concat([
        {
          taskTemplateId,
          nativeAutomation,
        } as AutomatedTaskTemplateId,
      ]);
    });
  };

  // uses reduce and accumulator mutation because there can be
  // - multiple automations per task template
  // - multiple automated action types
  // - each action can be connected to multiple input widgets
  return Object.entries(automationsByTaskTemplateId).reduce((acc, [taskTemplateId, taskTemplateAutomations]) => {
    taskTemplateAutomations.forEach(nativeAutomation => {
      nativeAutomation.actions.forEach(action => {
        match(action)
          .with({ actionType: 'ExecuteAiPrompt' }, ({ config: { prompt } }) => {
            addInputWidgets(prompt, acc, taskTemplateId, nativeAutomation);
          })
          .with({ actionType: 'ExecuteCode' }, ({ config: { inputData } }) => {
            Object.entries(inputData ?? {}).forEach(([_, value]) => {
              addInputWidgets(value, acc, taskTemplateId, nativeAutomation);
            });
          })
          .otherwise(() => {});
      });
    });
    return acc;
  }, {} as InputWidgetAutomatedTasksMap);
};
