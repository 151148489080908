import { Event } from '@process-street/subgrade/analytics';
import { getEnv } from 'components/common/env';
import { MasqueradeService } from 'features/masquerade/masquerade-service';

// This is based on this documentation:
// https://developers.hubspot.com/docs/api/events/tracking-code#tracking-in-single-page-apps

type MethodName = 'identify' | 'setPath' | 'trackPageView' | 'trackCustomBehavioralEvent';
type Method = [MethodName] | [MethodName, string | object];
type Properties = Record<string, string>;

declare const window: {
  _hsq: Method[];
};

const hsq = (window._hsq = window._hsq ?? []);

function isEnabled(): boolean {
  return !MasqueradeService.isMasquerading();
}

function execMethod(method: Method): void {
  if (isEnabled()) {
    hsq.push(method);
  }
}

// This doesn't set any session, it just tries to tie the HubSpot cookie to an email address or ID
// This means you don't need to worry about a corresponding `logout` function
function identify(email: string, id?: string) {
  execMethod(['identify', { email, id }]);
}

function setPath(path: string) {
  execMethod(['setPath', path]);
}

function trackPageView() {
  execMethod(['trackPageView']);
}

// HubSpot requires that you create the events before you can track them
const sandboxPrefix = 'pe45944903_';
const prodPrefix = 'pe45167024_';
const prefix = getEnv().APP_STAGE === 'prod' ? prodPrefix : sandboxPrefix;

const sandboxEventMap: Record<string, string> = {
  [Event.CHECKLIST_CREATED]: 'pe45944903_checklist_created_v2',
  [Event.TASKS_CHECKED_UNCHECKED]: 'pe45944903_task_checked_unchecked',
  [Event.TEMPLATE_CREATED]: 'pe45944903_template_created',
};
const prodEventMap: Record<string, string> = {
  [Event.CHECKLIST_CREATED]: 'pe45167024_checklist_created',
  [Event.TASKS_CHECKED_UNCHECKED]: 'pe45167024_task_checked_unchecked',
  [Event.TEMPLATE_CREATED]: 'pe45167024_template_created',
};

const eventNameMap = getEnv().APP_STAGE === 'prod' ? prodEventMap : sandboxEventMap;

function toHubSpotEvent(name: string): string {
  return eventNameMap[name] ?? prefix + name.replace(/\W/g, '_').toLowerCase();
}

// This is needed because the HubSpot API uses underscores for spaces in property names
function replaceSpacesInKeys(properties: { [key: string]: string }): { [key: string]: string } {
  return Object.fromEntries(Object.entries(properties).map(([key, value]) => [key.replace(/ /g, '_'), value]));
}

function trackCustomBehavioralEvent(name: string, properties: Properties) {
  const mappedEventName: string | undefined = eventNameMap[name] ?? toHubSpotEvent(name);
  const rewrittenProperties = replaceSpacesInKeys(properties);
  if (mappedEventName) {
    execMethod(['trackCustomBehavioralEvent', { name: mappedEventName, properties: rewrittenProperties }]);
  }
}

export const HubSpotService = {
  identify,
  setPath,
  trackPageView,
  trackCustomBehavioralEvent,
};
export type IHubSpotService = typeof HubSpotService;
