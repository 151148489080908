import { ActorRefFrom, assign, createMachine, send, sendTo, spawn } from 'xstate';
import { GroupMembership, Muid, OrganizationMembershipWithUser, User } from '@process-street/subgrade/core';
import {
  Attachment,
  Checklist,
  ChecklistMigrationStatus,
  ChecklistRevision,
  ChecklistStatus,
  Comment,
  ConsolidatedTaskPermit,
  FormFieldValue,
  FormFieldValueWithWidget,
  FormFieldWidgetWithValue,
  isFormFieldWidget,
  NativeAutomationWithLink,
  Task,
  TaskStatus,
  TaskWithTaskTemplate,
  Template,
  TemplateRevision,
  TemplateType,
  Widget,
  WidgetUtils,
} from '@process-street/subgrade/process';
import { makeTaskMachine, TaskMachine } from 'pages/responses/_id/components/task/task-machine';
import { FormMachineUtils } from './form-response-machine-utils';
import { WithSharedContext } from '../../types';
import { makeRulesEngineMachine, RulesEngineMachineActorRef } from './rules-engine-machine/rules-engine-machine';
import {
  FormResponseMachineRulesReceptionist,
  makeRulesEngineTargetTaskKey,
} from '../../form-response-machine-receptionist';
import { isAnonymousUser } from '@process-street/subgrade/util/user-type-utils';
import { OneOffTask } from '@process-street/subgrade/one-off-task';
import { Approval, ApprovalRuleSubject, ApprovalStatus } from '@process-street/subgrade/approval-rule';
import { makeMutation } from 'utils/query-builder/make-mutation';
import { CreateAllApprovalsMutation, GetApprovalsByChecklistRevisionIdQuery } from 'features/approvals/query-builder';
import { GetAllTasksByChecklistRevisionIdQuery } from 'features/task/query-builder';
import { makeErrorLoggerAction } from 'utils/machines';
import { ToastServiceImpl } from 'app/services/toast-service.impl';
import { GetChecklistQuery, UpdateChecklistStatusMutation } from 'app/features/checklists/query-builder';
import { ObjectUtils } from 'app/utils/object-utils';
import _groupBy from 'lodash/groupBy';
import _keyBy from 'lodash/keyBy';
import { match } from 'ts-pattern';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'app/features/checklist-revisions/query-builder';
import {
  GetFormFieldValuesByChecklistRevisionIdQuery,
  SendEmailMutation,
  SendEmailResponseType,
  UploadToS3MutationResponse,
} from 'app/features/widgets/query-builder';
import { GetAllNativeAutomationsQuery } from 'app/features/native-automations/query-builder';
import {
  ChecklistRealTimeManagerActorRef,
  makeChecklistRealTimeManagerMachine,
} from './checklist-real-time-manager-machine';
import { DeleteTemplateEmailAttachmentMutation } from 'app/features/widgets/components/send-rich-email/query-builder/delete-template-email-attachment';
import { AttachmentEventStatus, UploadEventParams } from '../form-fields/send-email-widget';
import { MergeTagsByChecklistRevisionIdQuery } from 'app/features/merge-tags/query-builder';
import {
  UploadUrlChecklistEmailAttachmentMutation,
  UploadUrlChecklistEmailAttachmentMutationResponse,
} from 'app/features/widgets/components/send-rich-email/query-builder/upload-url-checklist-email-attachment-mutation';
import {
  FinishEmailAttachmentChecklistUploadMutation,
  FinishEmailAttachmentChecklistUploadMutationResponse,
} from 'app/features/widgets/components/send-rich-email/query-builder/finish-email-attachment-checklist-upload-mutation';
import { UploadToS3Mutation } from 'app/features/cover-image/query-builder';

export type Context = {
  approvalRules: ApprovalRuleSubject[];
  approvals: Approval[];
  attachmentsMap: Record<Task['id'], Attachment[]>;
  checklist: Checklist;
  checklistRealTimeManagerActorRef?: ChecklistRealTimeManagerActorRef;
  checklistRevision: ChecklistRevision;
  comments: Comment[];
  commentsByTaskMap: Record<Task['id'], Comment[]>;
  currentTaskActor?: ActorRefFrom<TaskMachine>;
  currentUser: User;
  formFieldWidgetsWithValues: FormFieldWidgetWithValue[];
  groupMembershipsMap: Record<Muid, GroupMembership[]>;
  invalidTaskMap: Record<Muid, boolean>;
  nativeAutomationsMap: GetAllNativeAutomationsQuery.FormFieldWidgetAutomationMap;
  oneOffTasks: OneOffTask[];
  organizationMembershipsMap: Record<Muid, OrganizationMembershipWithUser>;
  organizationMembershipsByUserId: Record<Muid, OrganizationMembershipWithUser>;
  rulesActor: RulesEngineMachineActorRef;
  shouldHideCompletedTasks: boolean;
  shouldHideStoppedTasks: boolean;
  shouldSkipRedirectAfterUpdatingTaskStatus?: boolean;
  taskActorsMap: Record<Muid, ActorRefFrom<TaskMachine>>;
  template: Template;
  templateRevision: TemplateRevision;
  uploadParams: UploadEventParams;
  uploadKey: string;
  uploadUrl: string;
  widgets: Widget[];
  // We're storing this in the context to better support the migration modal
  // The migration modal should be visible only if the machine has started in the migrating state
  initialResponseState: 'active' | 'complete' | 'migrating' | 'inaccessible';
};

export type Event =
  | { type: 'ACTIVATE_RUN' }
  | { type: 'ARCHIVE_RUN' }
  | { type: 'COMPLETE_RUN' }
  | { type: 'COMPLETE_TASK'; taskId: Muid }
  | { type: 'CREATE_APPROVALS'; approvals: CreateAllApprovalsMutation.ApprovalDto[] }
  | { type: 'CREATE_COMMENT'; taskGroupId: Muid; comment: Comment }
  | { type: 'DELETE_COMMENT'; taskGroupId: Muid; commentId: Muid }
  | { type: 'DELETE_EMAIL_ATTACHMENT'; attachmentId: Muid }
  | {
      type: 'DELETE_ATTACHMENT_COMPLETE';
      data: DeleteTemplateEmailAttachmentMutation.Response;
      status: AttachmentEventStatus;
    }
  | { type: 'FORM_FIELD_VAUE_UPDATE'; formFieldValue: FormFieldValue }
  | { type: `${'INVALID' | 'VALID'}_TASK`; taskId: Muid }
  | { type: 'NEXT_TASK' | 'PREVIOUS_TASK' | 'SKIP_TASK' }
  | { type: 'SELECT_TASK'; taskId: Muid }
  | { type: 'SEND_EMAIL'; widgetId: Muid }
  | { type: 'TASK_STATUS_UPDATE_FAILED' | 'TASK_STATUS_UPDATE_SUCCESS'; skipRedirect?: boolean }
  | { type: 'TOGGLE_COMPLETED_TASKS' }
  | { type: 'TOGGLE_STOPPED_TASKS' }
  | { type: 'UNCOMPLETE_CURRENT_TASK' }
  | { type: 'UNCOMPLETE_TASK'; taskId: Muid }
  | { type: 'UPDATE_APPROVALS'; approvals?: Approval[] }
  | { type: 'UPDATE_CHECKLIST'; checklist?: Checklist }
  | { type: 'UPDATE_ONE_OFF_TASKS'; oneOffTasks?: OneOffTask[] }
  | { type: 'UPLOAD_EMAIL_ATTACHMENT'; uploadParams: UploadEventParams };

export type FormResponseMachineBuilderProps = {
  approvalRules: ApprovalRuleSubject[];
  approvals: Approval[];
  attachments: Attachment[];
  checklist: Checklist;
  checklistRevision: ChecklistRevision;
  comments: Comment[];
  consolidatedTaskPermits: ConsolidatedTaskPermit[];
  currentUser: User;
  formFieldValues: FormFieldValueWithWidget[];
  groupMemberships: GroupMembership[];
  nativeAutomations: NativeAutomationWithLink[];
  oneOffTasks: OneOffTask[];
  organizationMemberships: OrganizationMembershipWithUser[];
  tasks: TaskWithTaskTemplate[];
  template: Template;
  widgets: Widget[];
};

export type FormResponseMachineBuilderInternalProps = WithSharedContext<FormResponseMachineBuilderProps>;

export const makeFormResponseMachine = (props: FormResponseMachineBuilderInternalProps) => {
  const {
    approvalRules,
    approvals,
    attachments,
    checklist,
    checklistRevision,
    comments,
    consolidatedTaskPermits,
    currentUser,
    formFieldValues,
    groupMemberships,
    nativeAutomations,
    oneOffTasks,
    organizationMemberships,
    sharedContext,
    tasks,
    template,
    widgets,
  } = props;
  const { $state, queryClient } = sharedContext;

  const isChecklistComplete = checklist.status === ChecklistStatus.Completed;
  const isAnonymous = isAnonymousUser(currentUser);
  const isChecklistEditable = !isChecklistComplete;
  const isChecklist = template.templateType === TemplateType.Playbook;
  const isMigrating = checklist.migrationStatus !== ChecklistMigrationStatus.Inactive;
  const isArchived = checklist.status === ChecklistStatus.Archived;

  // completed forms are not accessible anonymously when opening them
  const isInaccessible = isChecklistComplete && isAnonymous;
  const initialResponseState = match({ isInaccessible, isChecklistComplete, isMigrating, isArchived })
    .with({ isInaccessible: true }, () => 'inaccessible')
    .with({ isChecklistComplete: true }, () => 'complete')
    .with({ isMigrating: true }, () => 'migrating')
    .with({ isArchived: true }, () => 'archived')
    .otherwise(() => 'active');

  return createMachine(
    {
      context: () => {
        const rulesActor = spawn(
          makeRulesEngineMachine({ checklist, checklistRevision, tasks, formFieldValues, widgets, sharedContext }),
          { name: 'rules-machine', sync: true },
        );
        const taskActorsMap = Object.fromEntries(
          tasks.map(task => {
            const taskWidgets = widgets.filter(w => w.header.taskTemplate.id === task.taskTemplate.id);
            const taskWidgetIdsSet = new Set(taskWidgets.map(widget => widget.id));
            const taskFormFieldValues = formFieldValues.filter(ffv => taskWidgetIdsSet.has(ffv.formFieldWidget.id));
            const taskPermits = consolidatedTaskPermits?.find(taskPermit => taskPermit.taskId === task.id);
            const isEditable = isChecklistEditable && !!taskPermits?.taskPermissionMap.canUpdate;

            const taskActor = spawn(
              makeTaskMachine({
                sharedContext,
                checklist,
                checklistRevision,
                task,
                widgets: taskWidgets,
                formFieldValues: taskFormFieldValues,
                isEditable,
                approvals,
                approvalRules,
              }),
              { name: `task-machine:${task.id}` },
            );
            FormResponseMachineRulesReceptionist.register({
              name: makeRulesEngineTargetTaskKey(task.taskTemplate.group.id),
              actorRef: taskActor.getSnapshot()!.context.rulesEngineTargetActor,
            });

            return [task.id, taskActor];
          }),
        ) as Record<Muid, ActorRefFrom<TaskMachine>>;

        const visibleTasksActorMap = Object.values(taskActorsMap).filter(
          taskActor => !taskActor.getSnapshot()?.context.task.hidden,
        );
        const currentTaskActor = visibleTasksActorMap[0] ?? undefined;
        FormResponseMachineRulesReceptionist.register({
          name: 'rules-engine-actor',
          actorRef: rulesActor,
        });

        const formFieldWidgetsWithValues: FormFieldWidgetWithValue[] = widgets
          .filter(isFormFieldWidget)
          .map(formFieldWidget => {
            const value = formFieldValues.find(
              formFieldValue => formFieldValue.formFieldWidget.id === formFieldWidget.id,
            );

            const formFieldValue = WidgetUtils.formFieldWidgetWithValueToFormFieldValue(value);

            return { ...formFieldWidget, formFieldValue };
          });

        const checklistRealTimeManagerActorRef = spawn(
          makeChecklistRealTimeManagerMachine({ checklistId: checklist.id, currentUser, queryClient }),
          { name: `form-response-real-time-actor:${checklist.id}` },
        );

        return {
          approvals,
          approvalRules,
          attachmentsMap: _groupBy(attachments, 'task.taskTemplate.group.id'),
          checklist,
          checklistRealTimeManagerActorRef,
          checklistRevision,
          comments,
          commentsByTaskMap: _groupBy(comments, 'task.taskTemplate.group.id'),
          currentTaskActor,
          currentUser,
          formFieldWidgetsWithValues,
          groupMembershipsMap: _groupBy(groupMemberships, 'group.id'),
          initialResponseState,
          invalidTaskMap: {},
          nativeAutomationsMap: GetAllNativeAutomationsQuery.makeWidgetAutomationMap(nativeAutomations ?? []),
          oneOffTasks,
          organizationMembershipsMap: _keyBy(organizationMemberships, 'id'),
          organizationMembershipsByUserId: _keyBy(organizationMemberships, 'user.id'),
          rulesActor,
          shouldHideCompletedTasks: false,
          shouldHideStoppedTasks: true,
          taskActorsMap,
          template,
          templateRevision: checklistRevision.templateRevision as TemplateRevision,
          widgets,
        } as Context;
      },
      tsTypes: {} as import('./form-response-machine.typegen').Typegen0,
      schema: {
        context: {} as Context,
        events: {} as Event,
        services: {} as {
          createApprovalsMutation: {
            data: CreateAllApprovalsMutation.Response;
          };
          completeChecklistStatusMutation: {
            data: UpdateChecklistStatusMutation.Response;
          };
          activateChecklistMutation: {
            data: UpdateChecklistStatusMutation.Response;
          };
          archiveChecklistMutation: {
            data: UpdateChecklistStatusMutation.Response;
          };
          sendEmailMutation: {
            data: SendEmailMutation.Response;
          };
          deleteEmailAttachmentMutation: {
            data: DeleteTemplateEmailAttachmentMutation.Response;
          };
          uploadUrlChecklistEmailAttachmentMutation: {
            data: UploadUrlChecklistEmailAttachmentMutationResponse;
          };
          uploadToS3Mutation: {
            data: UploadToS3MutationResponse;
          };
          finishEmailAttachmentChecklistUploadMutation: {
            data: FinishEmailAttachmentChecklistUploadMutationResponse;
          };
        },
      },
      predictableActionArguments: true,
      preserveActionOrder: true,
      id: `form-response-machine:${checklist.id}`,
      type: 'parallel',
      on: {
        FORM_FIELD_VAUE_UPDATE: {
          actions: ['assignFormFieldValues'],
        },
      },
      states: {
        response: {
          initial: initialResponseState,
          states: {
            active: {
              initial: 'idle',
              id: 'active',
              states: {
                idle: {
                  on: {
                    CREATE_APPROVALS: [{ target: 'creatingApprovals' }],
                    CREATE_COMMENT: { actions: 'updateCommentMap' },
                    COMPLETE_TASK: [
                      {
                        cond: 'taskHasInvalidFields',
                        actions: ['sendRevealInvalid'],
                        target: '#validation.invalid.visible',
                      },
                      { target: 'completingTask' },
                    ],
                    DELETE_COMMENT: { actions: 'deleteCommentFromMap' },
                    DELETE_EMAIL_ATTACHMENT: { target: 'deletingEmailAttachment' },
                    UNCOMPLETE_TASK: [
                      {
                        cond: 'taskHasInvalidFields',
                        actions: ['sendRevealInvalid'],
                        target: '#validation.invalid.visible',
                      },
                      { target: 'uncompletingTask' },
                    ],
                    UNCOMPLETE_CURRENT_TASK: [
                      {
                        cond: 'taskHasInvalidFields',
                        actions: ['sendRevealInvalid'],
                        target: '#validation.invalid.visible',
                      },
                      { target: 'uncompletingCurrentTask' },
                    ],
                    NEXT_TASK: [
                      {
                        cond: 'taskHasInvalidFields',
                        actions: ['sendRevealInvalid'],
                        target: '#validation.invalid.visible',
                      },
                      { target: 'completingCurrentTask' },
                    ],
                    PREVIOUS_TASK: {
                      target: 'uncompletingPreviousTask',
                    },
                    SELECT_TASK: {
                      target: 'idle',
                      actions: ['assignSelectedTaskAsCurrentTask', 'goToCurrentTask'],
                    },
                    SEND_EMAIL: { target: 'sendingEmails' },
                    SKIP_TASK: {
                      target: 'idle',
                      cond: 'hasMoreTasks',
                      actions: ['assignNextTaskAsCurrentTask', 'goToCurrentTask'],
                    },

                    UPDATE_CHECKLIST: [
                      { target: '#migrating', actions: ['assignChecklist'], cond: 'hasMigrationStarted' },
                      { target: 'idle', actions: ['assignChecklist'] },
                    ],
                    UPDATE_APPROVALS: [{ target: 'idle', actions: ['assignApprovals'] }],
                    UPDATE_ONE_OFF_TASKS: [{ target: 'idle', actions: ['assignOneOffTasks'] }],

                    TOGGLE_COMPLETED_TASKS: [
                      {
                        target: 'idle',
                        actions: [
                          'assignToggleCompletedTasks',
                          'assignNextIncompleteTaskAsCurrentTask',
                          'goToCurrentTask',
                        ],
                      },
                    ],
                    TOGGLE_STOPPED_TASKS: [{ target: 'idle', actions: ['assignToggleStoppedTasks'] }],
                    COMPLETE_RUN: [
                      {
                        actions: ['makeTaskErrorsVisible', 'notifyPendingFormFields'],
                        cond: 'isSomeInvalidTaskMissingRequiredFields',
                      },
                      { actions: ['makeTaskErrorsVisible', 'notifyPendingStopTask'], cond: 'isSomePendingStopTask' },
                      { actions: ['notifyPendingOneOffTasks'], cond: 'isSomePendingOneOffTask' },
                      { target: 'completingRun', cond: 'areAllTasksValid' },
                    ],
                    FORM_FIELD_VAUE_UPDATE: {
                      actions: ['assignFormFieldValues', 'invalidateMergeTags'],
                    },
                    ARCHIVE_RUN: {
                      target: '#archivingRun',
                    },
                    UPLOAD_EMAIL_ATTACHMENT: {
                      target: 'uploadingAttachment',
                    },
                  },
                },
                completingRun: {
                  invoke: {
                    src: 'completeChecklistStatusMutation',
                    onDone: {
                      target: '#complete',
                      actions: ['sendFormCompleteEventToTasks'],
                    },
                    onError: {
                      target: 'idle',
                      actions: ['logError'],
                    },
                  },
                },
                archivingRun: {
                  id: 'archivingRun',
                  invoke: {
                    src: 'archiveChecklistMutation',
                    onDone: {
                      target: '#archived',
                      actions: ['sendFormArchivedEventToTasks'],
                    },
                    onError: {
                      target: 'idle',
                      actions: ['logError'],
                    },
                  },
                },
                creatingApprovals: {
                  invoke: {
                    src: 'createApprovalsMutation',
                    onDone: {
                      target: 'idle',
                      actions: ['sendTaskRejectedToRejectedTasks', 'sendTaskApprovedToApprovedTasks'],
                    },
                    onError: {
                      target: 'idle',
                      actions: 'logError',
                    },
                  },
                },
                completingTask: {
                  entry: ['sendCompleteTask'],
                  on: {
                    TASK_STATUS_UPDATE_SUCCESS: [
                      {
                        target: 'idle',
                        cond: 'hasMoreTasks',
                      },
                      {
                        target: '#complete',
                        actions: ['sendFormCompleteEventToTasks'],
                        cond: 'isChecklist',
                      },
                      {
                        target: '#complete',
                        actions: ['sendFormCompleteEventToTasks'],
                      },
                    ],
                    TASK_STATUS_UPDATE_FAILED: [
                      {
                        target: 'idle',
                      },
                    ],
                    SELECT_TASK: {
                      target: 'idle',
                      actions: ['assignSelectedTaskAsCurrentTask', 'goToCurrentTask'],
                    },
                  },
                },

                completingCurrentTask: {
                  entry: ['sendCompleteCurrentTask'],
                  on: {
                    TASK_STATUS_UPDATE_SUCCESS: [
                      {
                        target: 'idle',
                        actions: ['assignNextTaskAsCurrentTask', 'goToCurrentTask'],
                        cond: 'hasMoreTasks',
                      },
                      {
                        target: '#complete',
                        actions: ['sendFormCompleteEventToTasks'],
                        cond: 'isChecklist',
                      },
                      {
                        target: '#complete',
                        actions: ['assignCurrentTaskToUndefined', 'sendFormCompleteEventToTasks'],
                      },
                    ],
                    TASK_STATUS_UPDATE_FAILED: [
                      {
                        target: 'idle',
                      },
                    ],
                    SELECT_TASK: {
                      target: 'idle',
                      actions: ['assignSelectedTaskAsCurrentTask', 'goToCurrentTask'],
                    },
                  },
                },
                sendingEmails: {
                  id: 'sendingEmails',
                  invoke: {
                    src: 'createSendEmailMutation',
                    onDone: {
                      target: 'idle',
                      actions: ['sendEmailSentToTask'],
                    },
                    onError: {
                      target: 'idle',
                      actions: 'logError',
                    },
                  },
                },
                deletingEmailAttachment: {
                  id: 'deletingEmailAttachment',
                  invoke: {
                    src: 'createDeleteEmailAttachmentMutation',
                    onDone: {
                      target: 'idle',
                      actions: ['sendDeleteAttachmentCompleteToTask'],
                    },
                    onError: {
                      target: 'idle',
                      actions: ['logError', 'sendDeleteAttachmentCompleteToTask'],
                    },
                  },
                },
                uncompletingCurrentTask: {
                  entry: ['sendUncompleteCurrentTask'],
                  on: {
                    TASK_STATUS_UPDATE_SUCCESS: [
                      {
                        target: 'idle',
                      },
                    ],
                    TASK_STATUS_UPDATE_FAILED: [
                      {
                        target: 'idle',
                      },
                    ],
                    SELECT_TASK: {
                      target: 'idle',
                      actions: ['assignSelectedTaskAsCurrentTask', 'goToCurrentTask'],
                    },
                  },
                },
                uncompletingPreviousTask: {
                  entry: ['sendUncompletePreviousTask'],
                  on: {
                    TASK_STATUS_UPDATE_SUCCESS: [
                      {
                        target: 'idle',
                        actions: ['assignPreviousTaskAsCurrentTask', 'goToCurrentTask'],
                      },
                    ],
                    TASK_STATUS_UPDATE_FAILED: [
                      {
                        target: 'idle',
                      },
                    ],
                    SELECT_TASK: {
                      target: 'idle',
                      actions: ['assignSelectedTaskAsCurrentTask', 'goToCurrentTask'],
                    },
                  },
                },
                uncompletingTask: {
                  entry: ['sendUncompleteTask'],
                  on: {
                    TASK_STATUS_UPDATE_SUCCESS: [
                      {
                        target: 'idle',
                      },
                    ],
                    TASK_STATUS_UPDATE_FAILED: [
                      {
                        target: 'idle',
                      },
                    ],
                    SELECT_TASK: {
                      target: 'idle',
                    },
                  },
                },
                uploadingAttachment: {
                  id: 'uploadingAttachment',
                  entry: 'assignUploadParams',
                  invoke: {
                    src: 'createUploadUrlMutation',
                    onDone: {
                      target: 'uploadingToS3',
                      actions: 'assignUploadUrl',
                    },
                    onError: {
                      target: 'idle',
                      actions: 'logError',
                    },
                  },
                },
                uploadingToS3: {
                  id: 'uploadingToS3',
                  invoke: {
                    src: 'createUploadToS3Mutation',
                    onDone: {
                      target: 'finishingUpload',
                    },
                    onError: {
                      target: 'idle',
                      actions: 'logError',
                    },
                  },
                },
                finishingUpload: {
                  id: 'finishingUpload',
                  invoke: {
                    src: 'createFinishEmailAttachmentChecklistUploadMutation',
                    onDone: {
                      target: 'idle',
                      actions: ['sendUploadCompleteToCurrentTask', 'clearUploadParams'],
                    },
                    onError: {
                      target: 'idle',
                      actions: 'logError',
                    },
                  },
                },
              },
            },
            complete: {
              id: 'complete',
              initial: 'idle',
              states: {
                idle: {
                  entry: ['goToFinishedPageIfNoMoreTasks'],
                  on: {
                    UPDATE_CHECKLIST: [{ target: 'idle', actions: ['assignChecklist'] }],
                    NEXT_TASK: [{ target: 'idle', actions: ['assignNextTaskAsCurrentTask', 'goToCurrentTask'] }],
                    PREVIOUS_TASK: [
                      { target: 'idle', actions: ['assignPreviousTaskAsCurrentTask', 'goToCurrentTask'] },
                    ],
                    SELECT_TASK: {
                      target: 'idle',
                      actions: ['assignSelectedTaskAsCurrentTask', 'goToCurrentTask'],
                    },
                    TOGGLE_COMPLETED_TASKS: [
                      {
                        target: 'idle',
                        actions: [
                          'assignToggleCompletedTasks',
                          'assignNextIncompleteTaskAsCurrentTask',
                          'goToCurrentTask',
                        ],
                      },
                    ],
                    ACTIVATE_RUN: [
                      {
                        target: 'activatingRun',
                      },
                    ],
                  },
                },
                activatingRun: {
                  invoke: {
                    src: 'activateChecklistMutation',
                    onDone: {
                      target: '#active',
                      actions: ['sendFormActivateEventToTasks'],
                    },
                    onError: {
                      target: 'idle',
                      actions: ['logError'],
                    },
                  },
                },
              },
            },
            archived: {
              id: 'archived',
              initial: 'idle',
              on: {
                UPDATE_CHECKLIST: [{ actions: ['assignChecklist'] }],
              },
              states: {
                idle: {
                  on: {
                    ACTIVATE_RUN: [
                      {
                        target: 'activatingRun',
                      },
                    ],
                  },
                },
                activatingRun: {
                  invoke: {
                    src: 'activateChecklistMutation',
                    onDone: {
                      target: '#active',
                      actions: ['sendFormActivateEventToTasks'],
                    },
                    onError: {
                      target: 'idle',
                      actions: ['logError'],
                    },
                  },
                },
              },
            },
            migrating: {
              id: 'migrating',
              entry: ['sendFormMigrationStartedEventToTasks'],
              exit: ['notifyMigrationFinished', 'refresh'],
              on: {
                UPDATE_CHECKLIST: [
                  { target: 'active', actions: ['assignChecklist'], cond: 'hasMigrationFinished' },
                  { actions: ['assignChecklist'] },
                ],
                SELECT_TASK: {
                  actions: ['assignSelectedTaskAsCurrentTask', 'goToCurrentTask'],
                },
              },
              initial: 'checking',
              states: {
                checking: {
                  invoke: {
                    src: 'invalidateChecklist',
                    onDone: { target: 'waiting' },
                    onError: { target: 'waiting', actions: 'logError' },
                  },
                },
                waiting: {
                  after: {
                    2000: { target: 'checking' },
                  },
                },
              },
            },
            inaccessible: {
              entry: () => $state.go('logout'),
            },
          },
        },

        validation: {
          id: 'validation',
          initial: 'valid',
          states: {
            valid: {
              on: {
                INVALID_TASK: { target: 'invalid', actions: 'addInvalidTask' },
              },
            },
            invalid: {
              on: {
                INVALID_TASK: { actions: 'addInvalidTask' },
                VALID_TASK: [
                  { cond: 'areAllTasksValid', target: 'valid', actions: 'removeInvalidTask' },
                  { actions: 'removeInvalidTask' },
                ],
              },
              initial: 'hidden',
              states: { hidden: {}, visible: {} },
            },
          },
        },
      },
    },
    {
      guards: {
        hasMoreTasks: (context, _) => {
          return FormMachineUtils.hasMoreTasks(context.taskActorsMap, context.currentTaskActor);
        },
        hasMigrationStarted: (context, evt) =>
          context.checklist.migrationStatus === ChecklistMigrationStatus.Inactive &&
          evt.checklist?.migrationStatus !== ChecklistMigrationStatus.Inactive,
        hasMigrationFinished: (_, evt) => {
          if (!evt.checklist) return false;

          return evt.checklist.migrationStatus === ChecklistMigrationStatus.Inactive;
        },
        isChecklist: () => isChecklist,
        taskHasInvalidFields: (context, _) => {
          const snapshot = context.currentTaskActor?.getSnapshot();
          // Fallback to true because if any of these actors are undefined, we've got bigger problems 🤠
          const isInvalid = snapshot?.matches('validation.invalid') ?? true;
          const hasInvalidFields = ObjectUtils.hasKeys(snapshot?.context.invalidWidgetMap ?? {});

          return isInvalid && hasInvalidFields;
        },
        areAllTasksValid: (context, evt) => {
          if (evt.type === 'COMPLETE_RUN') {
            return Object.keys(context.invalidTaskMap).length === 0;
          }
          const { [evt.taskId]: _, ...rest } = context.invalidTaskMap;
          return Object.keys(rest).length === 0;
        },
        isSomeInvalidTaskMissingRequiredFields: context => {
          const invalidTaskIds = Object.entries(context.invalidTaskMap)
            .map(([taskId, isInvalid]) => {
              return isInvalid ? taskId : undefined;
            })
            .filter(Boolean) as Muid[];

          const isSomeInvalidTaskMissingRequiredFields = invalidTaskIds.some(taskId => {
            const taskSnapshot = context.taskActorsMap[taskId]?.getSnapshot();

            if (!taskSnapshot) return false;
            const { invalidWidgetMap } = taskSnapshot.context;

            return Object.keys(invalidWidgetMap).length > 0;
          });

          return isSomeInvalidTaskMissingRequiredFields;
        },
        isSomePendingStopTask: context => {
          const incompleteStopTasks = Object.values(context.taskActorsMap).filter(actor => {
            const snapshot = actor.getSnapshot();

            if (!snapshot) return false;

            const isStopTask = snapshot?.context.task.taskTemplate.stop;
            const isComplete = snapshot?.matches('task.complete');

            return isStopTask && !isComplete;
          });

          return incompleteStopTasks.length > 0;
        },
        isSomePendingOneOffTask: context => {
          return context.oneOffTasks.some(task => task.status !== TaskStatus.Completed && task.required);
        },
      },
      actions: {
        updateCommentMap: assign({
          commentsByTaskMap: (ctx, evt) => {
            const { taskGroupId, comment } = evt;
            const existingComments = ctx.commentsByTaskMap[taskGroupId] ?? [];
            const updatedComments = [
              ...existingComments,
              // Adds currentUser since the response sends only the user id
              { ...comment, audit: { ...comment.audit, createdBy: ctx.currentUser } },
            ];

            return {
              ...ctx.commentsByTaskMap,
              [taskGroupId]: updatedComments,
            };
          },
        }),
        deleteCommentFromMap: assign({
          commentsByTaskMap: (ctx, evt) => {
            const { commentId, taskGroupId } = evt;
            if (!ctx.commentsByTaskMap[taskGroupId]) return ctx.commentsByTaskMap;
            return {
              ...ctx.commentsByTaskMap,
              [taskGroupId]: ctx.commentsByTaskMap[taskGroupId].filter(comment => comment.id !== commentId),
            };
          },
        }),
        notifyPendingFormFields: ctx => {
          const invalidFieldsCount = Object.keys(ctx.invalidTaskMap).reduce((sum, taskId) => {
            const snapshot = ctx.taskActorsMap[taskId]?.getSnapshot();

            if (!snapshot) return sum;

            return sum + Object.keys(snapshot.context.invalidWidgetMap).length;
          }, 0);

          ToastServiceImpl.openToast({
            status: 'warning',
            title: "We couldn't complete the workflow run",
            description: `${invalidFieldsCount} form field${
              invalidFieldsCount > 1 ? 's' : ''
            } still need to be completed.`,
          });
        },
        notifyPendingStopTask: () => {
          ToastServiceImpl.openToast({
            status: 'warning',
            title: "We couldn't complete the workflow run",
            description: 'All the stop tasks must be completed.',
          });
        },
        notifyPendingOneOffTasks: () => {
          ToastServiceImpl.openToast({
            status: 'warning',
            title: "We couldn't complete the workflow run",
            description: 'All the one-off tasks must be completed.',
          });
        },
        notifyMigrationFinished: () => {
          ToastServiceImpl.openToast({
            status: 'success',
            title: 'Update complete',
            description: 'This workflow run has been successfully updated.',
          });
        },
        sendCompleteCurrentTask: send(
          { type: 'COMPLETE_TASK' },
          { to: (ctx, __) => ctx.currentTaskActor as ActorRefFrom<TaskMachine> },
        ),
        sendUncompletePreviousTask: send(
          { type: 'UNCOMPLETE_TASK' },
          {
            to: (ctx, __) => FormMachineUtils.getPreviousTaskActor(ctx.taskActorsMap, ctx.currentTaskActor),
          },
        ),
        sendUncompleteCurrentTask: send(
          { type: 'UNCOMPLETE_TASK' },
          {
            to: ctx => ctx.currentTaskActor!,
          },
        ),
        sendEmailSentToTask: sendTo(
          (ctx, _) => ctx.currentTaskActor!,
          (_, evt) => ({ type: 'EMAIL_SENT', data: evt.data as SendEmailMutation.Response }),
        ),
        sendDeleteAttachmentCompleteToTask: sendTo(
          (ctx, _) => ctx.currentTaskActor!,
          (_, evt) => {
            const isSuccessfulEvent = evt.type === 'done.invoke.deletingEmailAttachment:invocation[0]';
            return {
              type: 'DELETE_ATTACHMENT_COMPLETE',
              data: evt.data as DeleteTemplateEmailAttachmentMutation.Response,
              status: isSuccessfulEvent ? AttachmentEventStatus.Success : AttachmentEventStatus.Error,
            };
          },
        ),

        sendFormMigrationStartedEventToTasks: ctx => {
          return Object.values(ctx.taskActorsMap).map(taskActor => taskActor.send({ type: 'FORM_MIGRATION_STARTED' }));
        },
        refresh: () => {
          void $state.go($state.current, {}, { reload: true });
        },
        sendFormCompleteEventToTasks: ctx => {
          return Object.values(ctx.taskActorsMap).map(taskActor => taskActor.send({ type: 'FORM_COMPLETE' }));
        },
        sendFormActivateEventToTasks: ctx => {
          return Object.values(ctx.taskActorsMap).map(taskActor => taskActor.send({ type: 'FORM_REACTIVATE' }));
        },
        sendFormArchivedEventToTasks: ctx => {
          return Object.values(ctx.taskActorsMap).map(taskActor => taskActor.send({ type: 'FORM_ARCHIVED' }));
        },
        sendTaskRejectedToRejectedTasks: (ctx, event) => {
          event.data.approvals
            .filter(approval => approval.status === ApprovalStatus.Rejected)
            .forEach(approval => {
              const taskActor = ctx.taskActorsMap[approval.subjectTaskId];
              taskActor.send({ type: 'TASK_REJECTED' });
            });
        },
        sendTaskApprovedToApprovedTasks: (ctx, event) => {
          event.data.approvals
            .filter(approval => approval.status === ApprovalStatus.Approved)
            .forEach(approval => {
              const taskActor = ctx.taskActorsMap[approval.subjectTaskId];
              taskActor.send({ type: 'TASK_APPROVED' });
            });
        },
        assignPreviousTaskAsCurrentTask: assign({
          currentTaskActor: (ctx, __) => FormMachineUtils.getPreviousTaskActor(ctx.taskActorsMap, ctx.currentTaskActor),
        }),
        assignNextTaskAsCurrentTask: assign({
          currentTaskActor: ctx => {
            if (ctx.shouldSkipRedirectAfterUpdatingTaskStatus) return ctx.currentTaskActor;

            return FormMachineUtils.getNextTaskActor(ctx.taskActorsMap, ctx.currentTaskActor);
          },
        }),
        assignNextIncompleteTaskAsCurrentTask: assign({
          currentTaskActor: ctx => {
            const currentTaskIndex = FormMachineUtils.getCurrentTaskIndex(ctx.taskActorsMap, ctx.currentTaskActor);
            const taskActors = FormMachineUtils.getVisibleTasks(ctx.taskActorsMap);
            const orderedTaskActors = [
              // first, try to find next incomplete task (or remain on current one)
              ...taskActors.slice(currentTaskIndex),
              // try to find nearest incomplete task before the current one if there are none after
              ...taskActors.slice(0, currentTaskIndex).reverse(),
            ];
            const nextIncompleteTaskActor = orderedTaskActors.find(taskActor =>
              taskActor.getSnapshot()?.matches('task.incomplete'),
            );
            return nextIncompleteTaskActor ?? ctx.currentTaskActor;
          },
        }),
        assignSelectedTaskAsCurrentTask: assign({
          currentTaskActor: (ctx, evt) => ctx.taskActorsMap[evt.taskId],
        }),
        assignCurrentTaskToUndefined: assign({
          currentTaskActor: (_, __) => {
            return undefined;
          },
        }),
        assignUploadParams: assign({ uploadParams: (_, evt) => evt.uploadParams }),
        assignUploadUrl: assign((_, evt) => ({
          uploadUrl: (evt.data as UploadUrlChecklistEmailAttachmentMutationResponse).url,
          uploadKey: (evt.data as UploadUrlChecklistEmailAttachmentMutationResponse).key,
        })),
        sendUploadCompleteToCurrentTask: sendTo(
          (ctx, _) => ctx.currentTaskActor!,
          (_, evt) => {
            const isSuccessfulEvent = evt.type === 'done.invoke.finishingUpload:invocation[0]';
            return {
              type: 'UPLOAD_EMAIL_ATTACHMENT_COMPLETE',
              data: evt.data as FinishEmailAttachmentChecklistUploadMutationResponse,
              status: isSuccessfulEvent ? AttachmentEventStatus.Success : AttachmentEventStatus.Error,
            };
          },
        ),

        clearUploadParams: assign(ctx => {
          ctx.uploadParams.onProgress(undefined);
          return { uploadUrl: '', uploadKey: '', uploadParams: undefined };
        }),
        goToFinishedPageIfNoMoreTasks: (ctx, __) => {
          if (ctx.currentTaskActor === undefined) {
            void $state.go('formResponseFinish', {
              id: checklist.id,
            });
          }
        },
        goToCurrentTask: ctx => {
          // Forms don't support multiple pages
          if (!$state.includes('checklistV2')) return;
          if (ctx.shouldSkipRedirectAfterUpdatingTaskStatus) return;

          void $state.go('checklistV2.task', {
            id: ctx.checklist.id,
            title: ctx.checklist.name,
            groupId: ctx.currentTaskActor?.getSnapshot()?.context.task.id,
          });
        },
        addInvalidTask: assign({
          invalidTaskMap: (ctx, event) => {
            ctx.invalidTaskMap[event.taskId] = true;
            return ctx.invalidTaskMap;
          },
        }),
        removeInvalidTask: assign({
          invalidTaskMap: (ctx, event) => {
            delete ctx.invalidTaskMap[event.taskId];
            return ctx.invalidTaskMap;
          },
        }),
        sendRevealInvalid: ctx => {
          ctx.currentTaskActor?.send({ type: 'REVEAL_INVALID' });
        },
        assignChecklist: assign((ctx, evt) => ({ checklist: evt.checklist ?? ctx.checklist })),
        assignApprovals: assign((ctx, evt) => ({ approvals: evt.approvals ?? ctx.approvals })),

        sendCompleteTask: send(
          { type: 'COMPLETE_TASK' },
          { to: (ctx, evt) => ctx.taskActorsMap[evt.taskId] as ActorRefFrom<TaskMachine> },
        ),
        sendUncompleteTask: send(
          { type: 'UNCOMPLETE_TASK' },
          { to: (ctx, evt) => ctx.taskActorsMap[evt.taskId] as ActorRefFrom<TaskMachine> },
        ),
        assignOneOffTasks: assign((ctx, evt) => ({ oneOffTasks: evt.oneOffTasks ?? ctx.oneOffTasks })),
        assignToggleCompletedTasks: assign(ctx => ({
          shouldHideCompletedTasks: !ctx.shouldHideCompletedTasks,
        })),
        assignToggleStoppedTasks: assign(ctx => ({
          shouldHideStoppedTasks: !ctx.shouldHideStoppedTasks,
        })),
        assignFormFieldValues: assign((ctx, evt) => {
          const ret = {
            formFieldWidgetsWithValues: ctx.formFieldWidgetsWithValues.map(ffwwv =>
              ffwwv.id === evt.formFieldValue.formFieldWidget.id
                ? { ...ffwwv, formFieldValue: evt.formFieldValue }
                : { ...ffwwv },
            ),
          };

          return ret;
        }),
        logError: makeErrorLoggerAction(`form-response-machine:${checklist.id}`),

        makeTaskErrorsVisible: ctx => {
          Object.values(ctx.taskActorsMap).forEach(taskActor => {
            taskActor.send({ type: 'REVEAL_INVALID' });
          });
        },
        invalidateMergeTags: async () => {
          await queryClient.invalidateQueries(
            GetFormFieldValuesByChecklistRevisionIdQuery.getKey({ checklistRevisionId: checklistRevision.id }),
          );

          await queryClient.invalidateQueries({ queryKey: MergeTagsByChecklistRevisionIdQuery.key });
        },
      },
      services: {
        createApprovalsMutation: async (_ctx, evt) => {
          const params = {
            checklistRevisionId: checklistRevision.id,
            approvals: evt.approvals,
          };

          return makeMutation(queryClient, {
            mutationKey: CreateAllApprovalsMutation.key,
            mutationFn: () => CreateAllApprovalsMutation.mutationFn(params),
            onSuccess: response => {
              void queryClient.invalidateQueries(
                GetApprovalsByChecklistRevisionIdQuery.getKey({ checklistRevisionId: checklistRevision.id }),
              );

              const wasRejection = response.approvals.some(approval => approval.status === ApprovalStatus.Rejected);
              if (wasRejection) {
                void queryClient.invalidateQueries(
                  GetAllTasksByChecklistRevisionIdQuery.getKey({
                    checklistRevisionId: checklistRevision.id,
                  }),
                );
              }
            },
          }).execute();
        },
        createSendEmailMutation: async (_, event) => {
          return makeMutation(queryClient, {
            mutationKey: SendEmailMutation.key,
            mutationFn: () =>
              SendEmailMutation.mutationFn({
                checklistRevisionId: checklistRevision.id,
                widgetId: event.widgetId,
              }),
            onSuccess: data =>
              void ToastServiceImpl.openToast({
                ...(data.responseType === SendEmailResponseType.AllSent
                  ? { status: 'success', title: 'Email sent' }
                  : {
                      status: 'warning',
                      title: 'Not all recipients will receive the email',
                      description: data.summaryMessage,
                    }),
              }),
            onError: () => {
              ToastServiceImpl.openToast({
                status: 'warning',
                title: "We couldn't send the email",
                description: 'Something went wrong. Please try again.',
              });
            },
          }).execute();
        },
        createDeleteEmailAttachmentMutation: async (_, event) => {
          return makeMutation(queryClient, {
            mutationKey: DeleteTemplateEmailAttachmentMutation.key,
            mutationFn: () =>
              DeleteTemplateEmailAttachmentMutation.mutationFn({
                attachmentId: event.attachmentId,
              }),
            onSuccess: () =>
              void ToastServiceImpl.openToast({
                status: 'success',
                title: 'Attachment removed successfully',
              }),
            onError: () =>
              void ToastServiceImpl.openToast({
                status: 'warning',
                title: "We couldn't remove the attachment",
                description: 'Something went wrong. Please try again.',
              }),
          }).execute();
        },
        createUploadUrlMutation: async (ctx, event) => {
          return makeMutation(queryClient, {
            mutationKey: UploadUrlChecklistEmailAttachmentMutation.key,
            mutationFn: () =>
              UploadUrlChecklistEmailAttachmentMutation.mutationFn({
                taskId: FormMachineUtils.getCurrentTaskId(ctx.taskActorsMap, ctx.currentTaskActor)?.toString() ?? '',
                fileName: event.uploadParams.fileName,
                mimeType: event.uploadParams.mimeType,
              }),
            onSuccess: () => ctx.uploadParams.onProgress(1),
            onError: () =>
              void ToastServiceImpl.openToast({
                status: 'warning',
                title: "We couldn't add the attachment",
                description: 'Something went wrong. Please try again.',
              }),
          }).execute();
        },
        createUploadToS3Mutation: async (ctx, _) =>
          makeMutation(queryClient, {
            mutationKey: UploadToS3Mutation.key,
            mutationFn: () =>
              UploadToS3Mutation.mutationFn({
                data: ctx.uploadParams.data,
                file: ctx.uploadParams.file,
                onProgress: ctx.uploadParams.onProgress,
                url: ctx.uploadUrl,
              }),
            onError: () =>
              void ToastServiceImpl.openToast({
                status: 'warning',
                title: "We couldn't add the attachment",
                description: 'Something went wrong. Please try again.',
              }),
          }).execute(),
        createFinishEmailAttachmentChecklistUploadMutation: async (ctx, _) =>
          makeMutation(queryClient, {
            mutationKey: FinishEmailAttachmentChecklistUploadMutation.key,
            mutationFn: () =>
              FinishEmailAttachmentChecklistUploadMutation.mutationFn({
                taskId: FormMachineUtils.getCurrentTaskId(ctx.taskActorsMap, ctx.currentTaskActor)?.toString() ?? '',
                key: ctx.uploadKey,
                contentType: ctx.uploadParams.file.type,
                formFieldValueId: ctx.uploadParams.formFieldValue.id,
                originalFilename: ctx.uploadParams.file.name,
                widgetId: ctx.uploadParams.formFieldValue.formFieldWidget.id,
              }),
            onError: () =>
              void ToastServiceImpl.openToast({
                status: 'warning',
                title: "We couldn't add the attachment",
                description: 'Something went wrong. Please try again.',
              }),
          }).execute(),

        invalidateChecklist: () => {
          return Promise.all([
            queryClient.invalidateQueries(GetChecklistQuery.getKey({ checklistId: checklist.id })),
            queryClient.invalidateQueries(
              GetActiveChecklistRevisionByChecklistIdQuery.getKey({
                checklistId: checklist.id,
              }),
            ),
          ]);
        },

        completeChecklistStatusMutation: async _ctx => {
          return makeMutation(queryClient, {
            mutationKey: UpdateChecklistStatusMutation.key,
            mutationFn: () =>
              UpdateChecklistStatusMutation.mutationFn({
                checklistId: checklist.id,
                status: ChecklistStatus.Completed,
              }),
            onSuccess: () => {
              void queryClient.invalidateQueries({
                queryKey: GetChecklistQuery.getKey({ checklistId: checklist.id }),
              });
            },
            onError: () => {
              ToastServiceImpl.openToast({
                status: 'warning',
                title: "We couldn't complete the workflow run",
                description: 'Something went wrong. Please try again.',
              });
            },
          }).execute();
        },

        archiveChecklistMutation: async _ctx => {
          return makeMutation(queryClient, {
            mutationKey: UpdateChecklistStatusMutation.key,
            mutationFn: () =>
              UpdateChecklistStatusMutation.mutationFn({
                checklistId: checklist.id,
                status: ChecklistStatus.Archived,
              }),
            onSuccess: () => {
              void queryClient.invalidateQueries({
                queryKey: GetChecklistQuery.getKey({ checklistId: checklist.id }),
              });
            },
            onError: () => {
              ToastServiceImpl.openToast({
                status: 'warning',
                title: "We couldn't complete the workflow run",
                description: 'Something went wrong. Please try again.',
              });
            },
          }).execute();
        },

        activateChecklistMutation: async _ctx => {
          return makeMutation(queryClient, {
            mutationKey: UpdateChecklistStatusMutation.key,
            mutationFn: () =>
              UpdateChecklistStatusMutation.mutationFn({
                checklistId: checklist.id,
                status: ChecklistStatus.Active,
              }),
            onSuccess: () => {
              void queryClient.invalidateQueries({
                queryKey: GetChecklistQuery.getKey({ checklistId: checklist.id }),
              });
            },
            onError: () => {
              ToastServiceImpl.openToast({
                status: 'warning',
                title: "We couldn't reactivate the workflow run",
                description: 'Something went wrong. Please try again.',
              });
            },
          }).execute();
        },
      },
    },
  );
};

export type FormResponseMachine = ReturnType<typeof makeFormResponseMachine>;
export type FormResponseActor = ActorRefFrom<FormResponseMachine>;
