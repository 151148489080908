import * as React from 'react';
import { memo } from 'react';
import { NativeAutomation, TaskTemplate } from '@process-street/subgrade/process';
import { Skeleton } from 'components/design/next';
import { WidgetSelector } from 'pages/templates/_id/components/code-task-template-editor/output-list/widget-selector';
import { KeyInput } from './key-input';
import * as yup from 'yup';
import {
  KeyValueList,
  KeyValueListProps,
  KeyValueMapping,
} from 'pages/templates/_id/components/code-task-template-editor/key-value-list/key-value-list';
import { CodeTaskTemplateEditorMode } from 'pages/templates/_id/components/code-task-template-editor/machine/code-task-machine';
import { TestValueOutput } from 'pages/templates/_id/components/code-task-template-editor/output-list/test-value-output';

export type OutputListProps = {
  taskTemplate: TaskTemplate;
  codeAction?: NativeAutomation.ExecuteCodeAction;
  onUpdate: (mappings: KeyValueMapping[]) => void;
  mode: CodeTaskTemplateEditorMode;
  mappings: KeyValueMapping[];
};

export const OutputList: React.FC<OutputListProps> = ({ codeAction, taskTemplate, onUpdate, mode, mappings }) => {
  const KeyComponent: KeyValueListProps['KeyComponent'] = memo(({ index, mode }) => (
    <KeyInput index={index} codeAction={codeAction} mode={mode} />
  ));
  const ValueComponent: KeyValueListProps['ValueComponent'] = memo(({ index, isReadOnly, mode }) =>
    mode === 'test' ? (
      <TestValueOutput index={index} />
    ) : (
      <WidgetSelector index={index} templateRevisionId={taskTemplate.templateRevision.id} isReadOnly={isReadOnly} />
    ),
  );

  return (
    <Skeleton w="full" isLoaded={mode !== 'loading'}>
      <KeyValueList
        mappings={mappings}
        onSubmit={onUpdate}
        validationSchema={schema}
        KeyComponent={KeyComponent}
        ValueComponent={ValueComponent}
        mode={mode}
      />
    </Skeleton>
  );
};

const schema = yup.object().shape({
  mappings: yup.array().of(
    yup.object().shape({
      key: yup.string().required('Key is required'),
      value: yup.string().required('Widget is required'),
    }),
  ),
});
