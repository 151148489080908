import { FormControl, FormErrorMessage, Textarea as ChakraTextarea, VStack } from 'components/design/next';
import Textarea from 'react-autosize-textarea';
import React from 'react';
import { useSelector } from '@xstate/react';
import { TextareaFormFieldActor, TextareaHooks } from './textarea-form-field-machine';
import { StringUtils } from '@process-street/subgrade/util';
import { FormResponseLabel } from '../common';
import { TextAreaRichEditor } from 'pages/runs/_id/components/checklist-textarea-form-field-widget/textarea-rich-editor';
import { ChecklistWidgetMachineSelectors } from '../../../utils/widget-machine-selectors';
import { FormFieldMachineHooks } from '../form-field-machine-hooks';
import { useMatch } from '@process-street/adapters/navigation';
import { MaskedInput, MaskedInputParsers } from 'app/features/widgets/components/masked-input';
import { WidgetUtils } from '@process-street/subgrade/process';
import { FormFieldValueHelperText } from 'app/pages/runs/_id/components/form-field-value-helper-text';
import { match } from 'ts-pattern';

export interface TextareaFormFieldProps {
  actor: TextareaFormFieldActor;
}

export const TextareaFormField: React.FC<React.PropsWithChildren<TextareaFormFieldProps>> = ({ actor }) => {
  const api = FormFieldMachineHooks.useApi(actor);

  const isInputDisabled = FormFieldMachineHooks.useIsInputDisabled(actor);
  const isAutofocused = FormFieldMachineHooks.useIsAutofocused(actor);
  const errorMessage = FormFieldMachineHooks.useValidationErrorMessage(actor);
  const isInvalidVisible = FormFieldMachineHooks.useIsInvalidVisible(actor);
  const inputNode = FormFieldMachineHooks.useInputNode(actor);
  const isHiddenByRule = useSelector(actor, ChecklistWidgetMachineSelectors.getIsHiddenByRule);
  const machineValue = TextareaHooks.useValue(actor);
  const widget = TextareaHooks.useWidget(actor);
  const formFieldValue = TextareaHooks.useFormFieldValue(actor);

  const isChecklist = useMatch('checklistV2');
  const setValue = (value: string) => {
    if (value !== machineValue) api.onChange(value);
  };

  const handleSetValue: React.ChangeEventHandler<HTMLTextAreaElement> = e => {
    setValue(e.target.value);
  };

  const handleReset = () => {
    const widgetDefaultValue = widget.config.defaultValue;
    if (widgetDefaultValue) {
      api.onChange(WidgetUtils.hasVariables(widgetDefaultValue) ? '' : widgetDefaultValue, true);
    }
  };

  const ref = React.useRef<HTMLDivElement | null>(null);

  const isInvalid = FormFieldMachineHooks.useIsInvalid(actor);

  const boxProps = isInputDisabled
    ? { bgColor: 'transparent', opacity: 0.4, fontSize: 'md', _hover: { cursor: 'text' }, maxW: 175 }
    : { fontSize: 'md', maxW: 175 };

  const hasDefaultValue = WidgetUtils.hasDefaultValue({ formFieldValue, widget });
  const hasVariables = WidgetUtils.hasVariables(widget.config.defaultValue);
  const maskedInputParser = React.useMemo(
    () =>
      hasDefaultValue && hasVariables
        ? MaskedInputParsers.makeParser('urls', 'merge-tags')
        : MaskedInputParsers.makeParser('urls'),
    [hasDefaultValue, hasVariables],
  );
  const html = React.useMemo(() => maskedInputParser(String(machineValue)), [maskedInputParser, machineValue]);

  const backgroundColor = match({ hasDefaultValue })
    .with({ hasDefaultValue: true }, () => 'yellow.100')
    .otherwise(() => undefined);

  return isHiddenByRule ? null : (
    <FormControl
      ref={node => {
        ref.current = node;
        if (node && !inputNode) {
          api.onSetNode(node);
        }
      }}
      as={VStack}
      alignItems="stretch"
      isRequired={widget.required}
      isInvalid={isInvalid}
      sx={{
        '&[data-invalid]': {
          '.rich-editor-wrapper': { borderColor: 'red.500', boxShadow: '0 0 0 1px var(--ps-colors-red-500)' },
        },
      }}
    >
      <FormResponseLabel>{widget.label || 'Untitled Long Text'}</FormResponseLabel>
      <MaskedInput
        html={html}
        fontSize="md"
        maskProps={match({ hasDefaultValue, format: widget.config.format, machineValue })
          .with({ hasDefaultValue: true, format: 'RichText' }, () => ({
            pt: '5.1px',
            px: '11px',
            bgColor: backgroundColor,
            maxW: 'calc(var(--ps-sizes-175) - 5px)',
          }))
          .with({ hasDefaultValue: true, format: 'PlainText' }, () => ({
            pt: '6.4px',
            px: '14.9px',
            bgColor: backgroundColor,
            maxW: 'calc(var(--ps-sizes-175) - 5px)',
            bottom: '1px',
          }))
          .otherwise(() => ({
            paddingTop: '5px',
            paddingLeft: '11px',
            maxW: 'calc(var(--ps-sizes-175) - 5px)',
          }))}
        px={0}
        p={0}
        sx={{ '> div': { p: 0, px: 0, py: 0 } }}
      >
        {widget.config.format === 'RichText' ? (
          <TextAreaRichEditor
            setValue={setValue}
            readOnly={isInputDisabled}
            editable={!isInputDisabled}
            placeholder={StringUtils.getNonEmpty(widget.config.placeholder, 'Type answer here...')}
            onBlur={() => api.onBlur()}
            onFocus={() => api.onFocus()}
            markdownValue={machineValue}
            editorWrapperProps={boxProps}
            hasDefaultValue={hasDefaultValue}
          />
        ) : (
          <ChakraTextarea
            as={Textarea}
            w="full"
            maxH="80"
            borderColor="gray.300"
            value={machineValue}
            placeholder={StringUtils.getNonEmpty(widget.config.placeholder, 'Type answer here...')}
            onFocus={() => api.onFocus()}
            onBlur={() => api.onBlur()}
            onChange={handleSetValue}
            isDisabled={isInputDisabled}
            autoFocus={isAutofocused}
            bgColor={backgroundColor}
            {...boxProps}
          />
        )}
      </MaskedInput>

      {isChecklist && (
        <FormFieldValueHelperText
          defaultValue={widget.config.defaultValue}
          formFieldValue={formFieldValue}
          onResetDefaultValue={handleReset}
        />
      )}

      {isInvalidVisible && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};
