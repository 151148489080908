import { Identifiable } from '@process-street/subgrade/core';
import { QueryClient, QueryKey } from 'react-query';

export const createRQArrayCacheUtils = <Data = Identifiable>(queryClient: QueryClient) => {
  const get = (key: QueryKey) => queryClient.getQueryData<Data | undefined>(key);

  const set = (key: QueryKey, data: Array<Data> | undefined) =>
    queryClient.setQueryData<Array<Data> | undefined>(key, data);

  const append = (key: QueryKey, item: Data) =>
    queryClient.setQueryData<Array<Data> | undefined>(key, (existing = []) => [...existing, item]);

  const remove = (key: QueryKey, predicate: (data: Data) => boolean) =>
    queryClient.setQueryData<Array<Data> | undefined>(key, (existing = []) =>
      existing.filter(data => !predicate(data)),
    );

  const update = (key: QueryKey, predicate: (data: Data) => boolean, updater: (data: Data) => Data) =>
    queryClient.setQueryData<Array<Data> | undefined>(key, (existing = []) =>
      existing.map(item => (predicate(item) ? updater(item) : item)),
    );

  return {
    append,
    get,
    remove,
    set,
    update,
  };
};

export const createRQObjectCacheUtils = <Data = Identifiable>(queryClient: QueryClient) => {
  const get = (key: QueryKey) => queryClient.getQueryData<Data | undefined>(key);

  const set = (key: QueryKey, data: Data | undefined) => queryClient.setQueryData<Data | undefined>(key, data);

  const update = (key: QueryKey, updater: (data: Data | undefined) => Data | undefined) =>
    queryClient.setQueryData<Data | undefined>(key, updater);

  return {
    get,
    set,
    update,
  };
};
