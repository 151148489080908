import { WithFormFieldMachineEvent } from 'pages/responses/_id/types';
import { actions, ActorRefFrom, createMachine, sendParent } from 'xstate';
import { Schema } from 'yup';
import { YupUtils } from 'pages/forms/_id/edit/components/form-fields/common/validation/yup-utils';

const { assign, choose } = actions;

export type Context = {
  errorMessage?: string;
};

type ParentEvent<Value = string> =
  | {
      type: 'UPDATE_VALUE';
      value: Value;
      hasDefaultValue?: boolean;
    }
  | {
      type: 'INVALID' | 'VALID';
    };

export type { ParentEvent as ValidationParentEvent };

export type Event<Value> = WithFormFieldMachineEvent<ParentEvent<Value>, Value> | { type: 'RESET' };

type CreateValidationMachineArgs<Value, S extends Schema<Value | undefined>> = {
  validationSchema: S;
  initialValue: Value | undefined;
  isEmpty?: (value: Value) => boolean;
};

export const makeValidationMachine = <Value, S extends Schema<Value | undefined> = Schema<Value | undefined>>({
  validationSchema,
  initialValue,
  isEmpty = value => value === '',
}: CreateValidationMachineArgs<Value | undefined, S>) => {
  const initialErrorMessage = YupUtils.validationErrorMessage(validationSchema, initialValue);
  const initialState = initialErrorMessage ? 'invalid' : 'valid';

  return createMachine(
    {
      tsTypes: {} as import('./validation-machine.typegen').Typegen0,
      schema: {
        context: {} as Context,
        events: {} as Event<Value | undefined>,
      },
      context: {
        errorMessage: initialErrorMessage,
      },
      predictableActionArguments: true,
      preserveActionOrder: true,
      id: 'validation',
      initial: initialState,
      on: { RESET: 'invalid' },
      states: {
        init: {},
        invalid: {
          initial: 'hidden',
          on: {
            CHANGE: [
              { cond: 'changeIsValid', target: 'valid', actions: ['sendParentUpdateValue'] },
              { actions: ['assignErrorMessage', 'sendParentUpdateValueIfEmpty'] },
            ],
          },
          entry: ['assignErrorMessage', 'sendParentInvalid'],
          states: {
            hidden: {
              on: { BLUR: 'visible', REVEAL_INVALID: 'visible' },
            },
            visible: {},
          },
        },
        valid: {
          entry: ['assignErrorMessageEmpty', 'sendParentValid'],
          on: {
            CHANGE: [
              { cond: 'changeIsInvalid', target: 'invalid', actions: ['sendParentUpdateValueIfEmpty'] },
              { actions: 'sendParentUpdateValue' },
            ],
          },
        },
      },
    },
    {
      actions: {
        assignErrorMessageEmpty: assign({
          errorMessage: undefined,
        }),
        assignErrorMessage: assign({
          errorMessage: (ctx, event) => {
            if (event.type === 'CHANGE') {
              return YupUtils.validationErrorMessage(validationSchema, event.value);
            } else {
              return ctx.errorMessage;
            }
          },
        }),
        sendParentInvalid: sendParent({ type: 'INVALID' }),
        sendParentValid: sendParent('VALID'),
        sendParentUpdateValue: sendParent((_, event) => ({
          type: 'UPDATE_VALUE',
          value: event.value,
          hasDefaultValue: event.hasDefaultValue,
        })),
        sendParentUpdateValueIfEmpty: choose([
          {
            // Empty values should always be persisted even if the field is invalid (due to being required)
            cond: 'changeIsEmpty',
            actions: sendParent((_, event) => ({ type: 'UPDATE_VALUE', value: event.value })),
          },
        ]),
      },
      guards: {
        changeIsEmpty: (_, event) => isEmpty(event.value),
        changeIsValid: (_, event) => validationSchema.isValidSync(event.value),
        changeIsInvalid: (_, event) => !validationSchema.isValidSync(event.value),
      },
    },
  );
};

export type ValidationMachine<Value, S extends Schema<Value | undefined> = Schema<Value | undefined>> = ReturnType<
  typeof makeValidationMachine<Value, S>
>;
export type ValidationActorRef<Value, S extends Schema<Value | undefined> = Schema<Value | undefined>> = ActorRefFrom<
  ValidationMachine<Value, S>
>;

export const ValidationSelectors = {
  errorMessage: <T>(actor: ValidationActorRef<T>) => actor.getSnapshot()?.context?.errorMessage,
  isActorValid: <T>(actor: ValidationActorRef<T>) => actor.getSnapshot()?.matches('valid') ?? false,
  isActorInvalidVisible: <T>(actor: ValidationActorRef<T>) => actor.getSnapshot()?.matches('invalid.visible') ?? true,
};
