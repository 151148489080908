import * as React from 'react';
import { FormLabel, HStack, Icon, Text, Tooltip } from 'components/design/next';

export const LabelWithTooltip: React.FC<{ label: string; tooltip: string }> = ({ label, tooltip }) => (
  <FormLabel margin={0}>
    <HStack>
      <Text color="gray.700">{label}</Text>
      <Tooltip label={<Text fontSize="xs">{tooltip}</Text>} hasArrow placement="top" shouldWrapChildren>
        <Icon size="4" icon="info-circle" variant="far" color="gray.500" />
      </Tooltip>
    </HStack>
  </FormLabel>
);
