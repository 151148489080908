import * as React from 'react';
import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { PriorityMigrateOneMutation } from 'app/features/checklist-revisions/query-builder/priority-migrate-one-mutation';
import { PostReportsSearchQuery } from 'app/features/checklist-grid/query-builder';
import { useQueryClient } from 'react-query';
import { Checklist, ChecklistMigrationStatus } from '@process-street/subgrade/process';
import { GetChecklistQuery } from 'app/features/checklists/query-builder';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'app/features/checklist-revisions/query-builder';
import { useGetNewestTemplateRevisionsByTemplateIdQuery } from 'app/features/template/query-builder';

export type MigrateWorkflowModalProps = {
  checklist: Checklist;
  isDisabled?: boolean;
};

export const MigrateWorkflowModal = ({ checklist, isDisabled }: MigrateWorkflowModalProps) => {
  const queryClient = useQueryClient();
  const disclosure = useDisclosure();

  const activeChecklistRevisionsQuery = GetActiveChecklistRevisionByChecklistIdQuery.useQuery({
    checklistId: checklist.id,
  });

  const newestChecklistRevisionsQuery = useGetNewestTemplateRevisionsByTemplateIdQuery({
    templateId: checklist.template.id,
  });

  const priorityMigrateOneMutation = PriorityMigrateOneMutation.useMutation({
    onSuccess: () => {
      void PostReportsSearchQuery.invalidate(queryClient);
      void queryClient.invalidateQueries(GetChecklistQuery.getKey({ checklistId: checklist.id }));

      disclosure.onClose();
    },
  });

  const handleMigrate = () => {
    if (!activeChecklistRevisionsQuery.data) {
      console.warn('`activeChecklistRevisionsQuery.data` is missing');
      return;
    }

    priorityMigrateOneMutation.mutate({ checklistRevisionId: activeChecklistRevisionsQuery.data.id });
  };

  React.useEffect(
    function showModalWhenRevisionsDoNotMatches() {
      if (isDisabled) return;
      if (!newestChecklistRevisionsQuery.data || !activeChecklistRevisionsQuery.data) {
        console.warn('`newestChecklistRevisionsQuery.data` or `activeChecklistRevisionsQuery.data` is missing');
        return;
      }

      const [newestTemplateRevision] = newestChecklistRevisionsQuery.data ?? [];
      // We can't check if there is a new revision if there are no revisions
      if (!newestTemplateRevision) return;
      // Do nothing if the migration is in progress or schedules
      if (checklist.migrationStatus !== ChecklistMigrationStatus.Scheduled) return;

      // Do nothing if the current revision is the newest
      if (newestTemplateRevision.id === activeChecklistRevisionsQuery.data.templateRevision.id) return;

      disclosure.onOpen();
    },
    // We only want to run this effect once these queries changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeChecklistRevisionsQuery.data, newestChecklistRevisionsQuery.data, isDisabled],
  );

  return (
    <Modal size="xl" {...disclosure}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p={8}>
          <ThemeProvider2024>
            <VStack spacing={4}>
              <Image src={require('../../../../../images/update-modal-icon.svg')} />
              <Text textAlign="center" fontWeight="semibold">
                This workflow run is being updated to the latest version of the workflow.
              </Text>
              <Text textAlign="center">
                This workflow run is scheduled to be updated to the latest version of the workflow soon.
                <br />
                Would you like to update this workflow run now?
              </Text>

              <Button
                isLoading={priorityMigrateOneMutation.isLoading}
                loadingText="Updating to latest"
                variant="solid"
                colorScheme="brand"
                onClick={handleMigrate}
              >
                Update to latest now
              </Button>
            </VStack>
          </ThemeProvider2024>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
