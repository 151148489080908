import { FileFieldValue, FormFieldWidgetWithValue } from '@process-street/subgrade/process';
import { Link } from 'components/design/next';
import * as React from 'react';

export type ApprovalWidgetFileRendererProps = {
  widget: FormFieldWidgetWithValue;
};

export const ApprovalWidgetFileRenderer = ({ widget }: ApprovalWidgetFileRendererProps) => {
  const value = widget.formFieldValue?.fieldValue && (widget.formFieldValue?.fieldValue as FileFieldValue);

  if (!value) return;

  return (
    <Link fontSize="md" href={value.url} color="brand.500" target="_blank" rel="noopener noreferrer">
      {value.name}
    </Link>
  );
};
