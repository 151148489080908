import { Muid } from '@process-street/subgrade/core';
import { RichEmailWidgetAttachment } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace DeleteTemplateEmailAttachmentMutation {
  export type Params = {
    attachmentId: Muid;
  };
  export type Response = RichEmailWidgetAttachment;
  export const key = ['email-attachment'];
  export const mutationFn = ({ attachmentId }: Params) =>
    axiosService
      .getAxios()
      .delete<Response>(`/1/email-attachments/${attachmentId}`)
      .then(res => res.data);
  export const useMutation = (options: UseMutationOptions<Response, AxiosError, Params> = {}) => {
    return useRQMutation(mutationFn, { ...options, mutationKey: key });
  };
}
