import { useWorkflowState } from 'components/focus-bar/workflow/use-workflow-state';
import { useStateParam } from 'hooks/use-state-param';
import { useNewestTemplateRevisionQuery } from 'pages/pages/_id/edit/page/query';
import { useTaskTemplatesByTemplateRevisionIdQuery } from 'features/task-templates/query-builder';
import React from 'react';
import {
  AiTask,
  AutomatedTaskTemplate,
  DEFAULT_AI_NAME,
  ExecuteAiPrompt,
  NativeAutomation,
  TaskTemplate,
  TemplateRevision,
  Widget,
} from '@process-street/subgrade/process';
import { createUsableContext } from '@process-street/subgrade/util';
import { useQueryClient } from 'react-query';
import { match, P } from 'ts-pattern';
import { useInjector } from 'components/injection-provider';
import { TaskListEvent } from 'directives/task-list/task-list-event';
import { useWidgetsByTemplateRevisionIdQuery } from 'features/widgets/query-builder';
import {
  GetAllNativeAutomationsQuery,
  UpdateNativeAutomationActionsMutation,
} from 'features/native-automations/query-builder';

export type AiTaskTemplateFormContext = {
  aiTaskTemplate: AutomatedTaskTemplate;
  isDisabled: boolean;
  templateRevision: TemplateRevision;
  taskTemplates: TaskTemplate[];
  widgets: Widget[];
  isLoading: boolean;
  promptType: ExecuteAiPrompt.PromptType | undefined;
  handlePromptTypeChange: (promptType: ExecuteAiPrompt.PromptType) => void;
  isPristine: boolean;
  setIsPristine: (isPristine: boolean) => void;
};

type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
type UseAiTaskTemplateFormResult = WithOptional<AiTaskTemplateFormContext, 'aiTaskTemplate' | 'templateRevision'>;

const FIRST_MATH_ACTION_KEY = 'executeMathFormula0';
const FIRST_AI_PROMPT_ACTION_KEY = 'executeAiPrompt0';

export const useAiTaskTemplateForm = ({
  onEditTaskTemplateName,
}: {
  onEditTaskTemplateName?: (newName: string) => void;
}): UseAiTaskTemplateFormResult => {
  // Queries
  const templateId = useStateParam({ key: 'id' });
  const isEditable = useWorkflowState() === 'edit';
  const newestTemplateRevisionQuery = useNewestTemplateRevisionQuery({ templateId, editable: isEditable });
  const templateRevision = newestTemplateRevisionQuery.data;
  const templateRevisionId = newestTemplateRevisionQuery.data?.id;

  const taskTemplateGroupId = useStateParam({ key: 'groupId' });
  const taskTemplatesQuery = useTaskTemplatesByTemplateRevisionIdQuery({ templateRevisionId });
  const taskTemplate = React.useMemo(
    () => taskTemplatesQuery.data?.find(t => t.group.id === taskTemplateGroupId),
    [taskTemplateGroupId, taskTemplatesQuery.data],
  );

  const widgetsQuery = useWidgetsByTemplateRevisionIdQuery(templateRevisionId);
  const widgets = React.useMemo(() => widgetsQuery.data ?? [], [widgetsQuery.data]);

  const taskAutomationQuery = GetAllNativeAutomationsQuery.useGetTaskTemplateAutomationQuery({
    templateRevisionId: taskTemplate?.templateRevision.id,
    taskTemplateId: taskTemplate?.id,
  });

  const aiTaskTemplate = React.useMemo(
    () =>
      taskTemplate && taskAutomationQuery.data
        ? ({ taskTemplate, nativeAutomation: taskAutomationQuery.data } as AutomatedTaskTemplate)
        : undefined,
    [taskAutomationQuery.data, taskTemplate],
  );

  // State
  const [promptType, setPromptType] = React.useState<ExecuteAiPrompt.PromptType | undefined>();
  const savedPromptType = React.useMemo(
    () => AiTask.getPromptConfig(taskAutomationQuery.data)?.promptType,
    [taskAutomationQuery.data],
  );
  // No use effect to prevent back-to-back render (taskAutomationQuery -> _setPromptType)
  if (savedPromptType && !promptType) {
    setPromptType(savedPromptType);
  }
  if (!savedPromptType && promptType) {
    setPromptType(undefined);
  }

  const [isPristine, setIsPristine] = React.useState(true);

  // Mutations
  const queryClient = useQueryClient();
  const { $rootScope } = useInjector('$rootScope');

  const updateNativeAutomationActions = UpdateNativeAutomationActionsMutation.useMutation({
    onSuccess: data => {
      queryClient.setQueryData<GetAllNativeAutomationsQuery.Response>(
        GetAllNativeAutomationsQuery.getKey({ templateRevisionId: taskTemplate!.templateRevision.id }),
        GetAllNativeAutomationsQuery.taskTemplateAutomationUpdater(taskTemplate!.id, data),
      );
    },
  });

  const updateTaskTmplNameIfRequired = React.useCallback(
    ({
      taskTemplate,
      previousPromptType,
      newPromptType,
    }: {
      taskTemplate: TaskTemplate;
      previousPromptType: ExecuteAiPrompt.PromptType | undefined;
      newPromptType: ExecuteAiPrompt.PromptType;
    }) => {
      const previousPromptConfig = previousPromptType
        ? ExecuteAiPrompt.PROMPT_CONFIGS_BY_TYPE[previousPromptType]
        : undefined;
      if (
        taskTemplate.name?.trim() === DEFAULT_AI_NAME.trim() ||
        taskTemplate.name === `AI Task: ${previousPromptConfig?.promptLabel ?? previousPromptConfig?.promptType}`
      ) {
        const config = ExecuteAiPrompt.PROMPT_CONFIGS_BY_TYPE[newPromptType];
        const newName = `AI Task: ${config.promptLabel ?? config.promptType}`;

        // We have to update the task via Angular as the task list contains some state which needs updating
        $rootScope.$broadcast(TaskListEvent.UPDATE_TASK_TEMPLATE_NAME, { ...taskTemplate, name: newName });
        onEditTaskTemplateName?.(newName);
      }
    },
    [$rootScope, onEditTaskTemplateName],
  );

  const handlePromptTypeChange = React.useCallback(
    (newPromptType: ExecuteAiPrompt.PromptType) => {
      if (!aiTaskTemplate) return;

      const previousPromptType = promptType;
      setPromptType(newPromptType);

      const { nativeAutomation, taskTemplate } = aiTaskTemplate;

      // HACK - we are shoe-horning the checklistRevisionIdPath into the ExecuteMathFormula action config.
      // Math does not use AI so we have to supply the path ourselves so we're just getting it off the UpdateFormFields action.
      const checklistRevisionIdPath =
        nativeAutomation.actions.find(NativeAutomation.isUpdateFormFieldsAction)?.config.checklistRevisionIdPath ?? '';

      updateNativeAutomationActions
        .mutateAsync({
          nativeAutomationId: aiTaskTemplate.nativeAutomation.id,

          actions: nativeAutomation.actions.map(action => {
            return match<NativeAutomation.Action, NativeAutomation.Action>(action)
              .with({ actionType: P.union('ExecuteAiPrompt', 'ExecuteMathFormula') }, () => {
                return (
                  match<ExecuteAiPrompt.PromptType, NativeAutomation.Action>(newPromptType)
                    // As Math is a particular case because is not using chat gpt at the moment, we need to update the first Action: ExecuteAiPrompt -> ExecuteMathFormula.
                    .with('Math', () => ({
                      actionType: 'ExecuteMathFormula',
                      key: FIRST_MATH_ACTION_KEY,
                      config: { mapping: {}, checklistRevisionIdPath },
                    }))
                    .otherwise(() => ({
                      actionType: 'ExecuteAiPrompt',
                      key: FIRST_AI_PROMPT_ACTION_KEY,
                      config: { ...ExecuteAiPrompt.PROMPT_CONFIGS_BY_TYPE[newPromptType] },
                    }))
                );
              })
              .with({ actionType: 'UpdateFormFields' }, action => ({
                ...action,
                config: { ...action.config, mapping: {} },
              }))
              .otherwise(() => action);
          }),
        })
        .then(() => {
          updateTaskTmplNameIfRequired({ taskTemplate, previousPromptType, newPromptType });
        });
    },
    [aiTaskTemplate, promptType, updateNativeAutomationActions, updateTaskTmplNameIfRequired],
  );

  const isLoading = React.useMemo(() => {
    return (
      newestTemplateRevisionQuery.isLoading ||
      taskTemplatesQuery.isLoading ||
      taskAutomationQuery.isLoading ||
      widgetsQuery.isLoading
    );
  }, [
    newestTemplateRevisionQuery.isLoading,
    taskAutomationQuery.isLoading,
    taskTemplatesQuery.isLoading,
    widgetsQuery.isLoading,
  ]);

  // Skip intermediate renders when resetting this state using a naked if statement
  if (!isLoading && !promptType && !isPristine) {
    setIsPristine(true);
  }

  return React.useMemo<UseAiTaskTemplateFormResult>(
    () => ({
      isDisabled: !isEditable || !taskAutomationQuery.isSuccess,
      templateRevision,
      aiTaskTemplate,
      taskTemplates: taskTemplatesQuery.data ?? [],
      isLoading,
      promptType,
      handlePromptTypeChange,
      widgets,
      isPristine,
      setIsPristine,
    }),
    [
      isEditable,
      taskAutomationQuery.isSuccess,
      templateRevision,
      aiTaskTemplate,
      taskTemplatesQuery.data,
      isLoading,
      promptType,
      handlePromptTypeChange,
      widgets,
      isPristine,
    ],
  );
};

const [hook, { Provider }] = createUsableContext<AiTaskTemplateFormContext>({
  hookName: 'useAiTaskTemplateFormContext',
  providerName: 'AiTaskTemplateFormProvider',
});
export const useAiTaskTemplateFormContext = hook;

export const AiTaskTemplateFormProvider: React.FC<React.PropsWithChildren<AiTaskTemplateFormContext>> = ({
  children,
  ...value
}) => {
  return <Provider value={value}>{children}</Provider>;
};
