import * as React from 'react';
import { Box, Center, Checkbox, HStack, Progress, Text, useToken, VStack } from 'components/design/next';
import { isApproval, TaskStatus, TaskTemplateTaskType, TaskWithTaskTemplate } from '@process-street/subgrade/process';
import { TaskListItemAssigneeIndicator } from './task-list-item-assignee-indicator';
import { match } from 'ts-pattern';
import { Icon } from 'app/components/design/next';
import { TaskListItemDueDateIndicator } from './task-list-item-due-date-indicator';
import { TaskListItemDynamicDueDateIndicator } from './task-list-item-dynamic-due-date-indicator';
import { TaskListItemAutomationIndicator } from './task-list-item-automation-indicator';
import { FormResponsePageMachineHooks } from 'pages/responses/_id/form-response-page-hooks';
import { ApprovalStatus } from '@process-street/subgrade/approval-rule';
import { TaskListItemWidgetErrorCounter } from './task-list-item-widget-error-counter';
import { TaskMachine } from 'app/pages/responses/_id/components/task/task-machine';
import { ActorRefFrom } from 'xstate';
import { TaskMachineSelectors } from 'app/pages/responses/_id/components/task/task-machine-selectors';
import { useSelector } from '@xstate/react';
import { motion } from 'framer-motion';
import { TaskMachineHooks } from 'app/pages/responses/_id/components/task/task-machine-hooks';
import { FormResponseMachineHooks } from 'app/pages/responses/_id/components/form-response-body/form-response-machine-hooks';

export type TaskListItemProps = {
  task: TaskWithTaskTemplate;
  isLast: boolean;
  isFirst: boolean;
  isSelected: boolean;
  stepNumber: number;
  onSelect: (task: TaskWithTaskTemplate) => void;
  onTaskStatusChange: (task: TaskWithTaskTemplate) => void;
  selectedTask: TaskWithTaskTemplate;
  taskActor: ActorRefFrom<TaskMachine>;
};

const MotionBox = motion(Box);

export const TaskListItem = ({
  task,
  stepNumber,
  onSelect,
  onTaskStatusChange,
  isLast,
  isFirst,
  isSelected,
  selectedTask,
  taskActor,
}: TaskListItemProps) => {
  const showInvalid = useSelector(taskActor, TaskMachineSelectors.shouldShowErrors);
  const invalidWidgetCount = useSelector(taskActor, TaskMachineSelectors.getInvalidWidgetCount);
  const progress = TaskMachineHooks.useTaskSubtasksCompletionPercentage(taskActor);

  const subjectGroupIdToApprovalGroupIdMap = FormResponsePageMachineHooks.useSubjectGroupIdToApprovalGroupIdMap();
  const subjectIdToApprovalMap = FormResponsePageMachineHooks.useSubjectIdToApprovalMap();
  const approval = subjectIdToApprovalMap.get(task.id);
  const isLocked = approval?.status === ApprovalStatus.Approved;

  const isCompleted = task.status === TaskStatus.Completed;
  const isApprovalTaskSelected = !isSelected && selectedTask?.taskTemplate.taskType === TaskTemplateTaskType.Approval;
  const isMigrating = FormResponseMachineHooks.useIsMigrating();
  const shouldHighlight =
    isApprovalTaskSelected &&
    subjectGroupIdToApprovalGroupIdMap.get(task.taskTemplate.group.id) === selectedTask?.taskTemplate.group.id;

  const handleSelect: React.MouseEventHandler = React.useCallback(() => {
    onSelect(task);
  }, [onSelect, task]);

  const handleTaskStatusChange = React.useCallback(() => {
    onTaskStatusChange(task);
  }, [onTaskStatusChange, task]);

  const preventSelectingTask: React.MouseEventHandler = e => {
    e.stopPropagation();
  };

  const hasErrors = showInvalid && invalidWidgetCount > 0;
  const hasStopTaskError = showInvalid && task.taskTemplate.stop && !isCompleted;

  const bgColor = match({ isStopped: task.stopped, shouldHighlight, hasErrors, isSelected, hasStopTaskError })
    .with({ shouldHighlight: true }, () => 'brand.200')
    .with({ hasErrors: true, isSelected: true }, () => 'red.400')
    .with({ hasStopTaskError: true }, () => 'red.100')
    .with({ isStopped: true }, () => 'gray.50')
    .otherwise(() => 'white');

  const fontColor = match({ hasErrors, isCompleted, isSelected })
    .with({ hasErrors: true, isSelected: true }, () => 'white')
    .with({ isCompleted: true }, () => 'gray.500')
    .otherwise(() => 'gray.600');

  const borderColor = match({ isSelected, hasErrors })
    .with({ hasErrors: true, isSelected: true }, () => 'red.600')
    .with({ hasErrors: false, isSelected: true }, () => 'brand.500')
    .otherwise(() => 'gray.200');

  const stopIndicatorColor = match({ hasStopTaskError, isSelected })
    .with({ hasStopTaskError: true }, () => 'red.500')
    .with({ isSelected: true }, () => 'purple.500')
    .otherwise(() => 'gray.400');

  const initialBgColorCompiled = useToken('colors', 'yellow.100');
  const bgColorCompiled = useToken('colors', bgColor);

  return (
    <Box w="full">
      <HStack w="full" spacing="0">
        <Box p={2} pl={0} pr={3}>
          <VStack position="relative">
            <Center
              minW={5}
              w={5}
              h={5}
              bgColor={isSelected ? 'brand.500' : 'transparent'}
              borderRadius="full"
              role="group"
              position="relative"
            >
              {match(task.taskTemplate)
                .when(isApproval, () => (
                  <Icon
                    aria-label="Approval task"
                    color={isSelected ? 'white' : 'gray.400'}
                    size="3"
                    icon="thumbs-up"
                  />
                ))
                .otherwise(() => (
                  <Text
                    fontSize="sm"
                    fontWeight={isSelected ? '700' : '400'}
                    color={isSelected ? 'white' : 'gray.400'}
                    position="absolute"
                    cursor="default"
                  >
                    {stepNumber}
                  </Text>
                ))}
            </Center>
            {task.taskTemplate.stop && (
              <Box position="absolute" bottom="-4">
                <Icon aria-label="Stop task" color={stopIndicatorColor} size="3" icon="hand" />
              </Box>
            )}
          </VStack>
        </Box>
        <Box
          position="relative"
          w="full"
          borderBottomLeftRadius={isLast ? '8px' : undefined}
          borderBottomRightRadius={isLast ? '8px' : undefined}
          overflow="hidden"
        >
          <MotionBox
            className="task-list-item__task-name-wrapper"
            w="full"
            justifyContent="flex-start"
            fontWeight={isSelected ? '600' : task.taskTemplate.name?.endsWith(':') ? '700' : '400'}
            noOfLines={1}
            borderStyle="solid"
            borderWidth="thin"
            borderColor={borderColor}
            borderRadius={0}
            h={10}
            color={isSelected ? 'brand.600' : 'gray.600'}
            borderTopLeftRadius={isFirst ? '8px' : undefined}
            borderTopRightRadius={isFirst ? '8px' : undefined}
            borderBottomLeftRadius={isLast ? '8px' : undefined}
            borderBottomRightRadius={isLast ? '8px' : undefined}
            borderTopColor={isSelected || isFirst ? undefined : 'transparent'}
            aria-selected={isSelected}
            bgColor={bgColor}
            onClick={handleSelect}
            initial={{ backgroundColor: initialBgColorCompiled }}
            animate={{ backgroundColor: bgColorCompiled }}
            transition={{ backgroundColor: { duration: 2 } }}
            position="relative"
            zIndex="1"
          >
            <HStack spacing={2} px={3} alignItems="center">
              {task.taskTemplate.taskType === TaskTemplateTaskType.Standard && (
                <Box w="4" h="4" onClick={preventSelectingTask}>
                  <Checkbox
                    isChecked={isCompleted}
                    onChange={handleTaskStatusChange}
                    isDisabled={isLocked || isMigrating}
                    mt="-px"
                    sx={{
                      '.chakra-checkbox__control': {
                        borderWidth: '1px',
                        borderRadius: '4px',
                        backgroundColor: 'white',
                      },
                    }}
                  />
                </Box>
              )}

              <Text
                role="button"
                aria-label={task.taskTemplate.name}
                flex="1"
                w="full"
                h={10}
                lineHeight="40px"
                mt="-1px"
                textAlign="left"
                fontSize="md"
                noOfLines={1}
                textDecoration={isCompleted ? 'line-through' : undefined}
                color={fontColor}
                fontStyle={isCompleted ? 'italic' : undefined}
              >
                {task.taskTemplate.name}
              </Text>

              <HStack>
                <TaskListItemAssigneeIndicator task={task} />
                <TaskListItemDueDateIndicator task={task} />
                <TaskListItemDynamicDueDateIndicator task={task} />
                <TaskListItemAutomationIndicator task={task} />
                <TaskListItemWidgetErrorCounter
                  hasErrors={showInvalid}
                  invalidWidgetCount={invalidWidgetCount}
                  isSelected={isSelected}
                />
              </HStack>
            </HStack>
          </MotionBox>
          {typeof progress === 'number' && progress > 0 && (
            <Progress
              zIndex="1"
              sx={{
                '> div': {
                  transition: '100ms ease width',
                },
              }}
              className="task-list-item__progress-bar"
              position="absolute"
              bottom="1px"
              left={0}
              right="1px"
              size="sm"
              h="4px"
              w={progress < 100 ? 'calc(100% - 2px)' : '100%'}
              value={progress}
              borderBottomLeftRadius={isLast ? '8px' : undefined}
              borderBottomRightRadius={isLast ? '8px' : undefined}
              bgColor="white"
              aria-label="workflow run progress bar"
            />
          )}
        </Box>
      </HStack>
    </Box>
  );
};
