import * as React from 'react';
import { Checklist } from '@process-street/subgrade/process';
import { ChecklistCommentsOverviewPopover } from 'app/features/comments/components/checklist/comments-overview-popover';
import { Icon } from 'app/components/design/next';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';

export type CommentsButtonProps = {
  checklist: Checklist;
};

export const CommentsButton = ({ checklist }: CommentsButtonProps) => {
  return (
    <ChecklistCommentsOverviewPopover
      checklistId={checklist.id}
      triggerProps={{
        leftIcon: <Icon icon="comment" size="4" color="gray.400" />,
        colorScheme: 'brand',
        variant: 'ghost',
        rounded: 'md',
        fontWeight: 'normal',
        color: 'gray.600',
        _hover: { bgColor: 'brand.50' },
        px: 4,
        py: 0,
      }}
      ContentContainer={ThemeProvider2024}
    />
  );
};
