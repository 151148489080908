import { Button, HStack, Icon, IconButton, VStack } from 'components/design/next';
import { FieldArray, FieldArrayRenderProps, Formik } from 'formik';
import * as React from 'react';
import * as yup from 'yup';
import { CodeTaskTemplateEditorMode } from 'pages/templates/_id/components/code-task-template-editor/machine/code-task-machine';

export type KeyValueMapping = {
  key: string;
  value: string;
};

export type KeyValueListProps = {
  mappings: KeyValueMapping[];
  onSubmit: (mappings: KeyValueMapping[]) => void;
  validationSchema: yup.ObjectSchema;
  KeyComponent: React.FC<{ index: number; isReadOnly: boolean; mode: CodeTaskTemplateEditorMode }>;
  ValueComponent: React.FC<{ index: number; isReadOnly: boolean; mode: CodeTaskTemplateEditorMode }>;
  mode: CodeTaskTemplateEditorMode;
};

export const KeyValueList: React.FC<KeyValueListProps> = ({
  validationSchema,
  onSubmit,
  mappings,
  KeyComponent,
  ValueComponent,
  mode,
}) => {
  const handleRemove = (arrayHelpers: FieldArrayRenderProps, index: number) => {
    arrayHelpers.remove(index);
    void arrayHelpers.form.submitForm();
  };

  const handleSubmit = ({ mappings }: { mappings: KeyValueMapping[] }) => {
    const trimmedMappings = mappings.map(({ key, value }) => ({ key: key.trim(), value: value.trim() }));
    onSubmit(trimmedMappings);
  };

  return (
    <Formik
      initialValues={{ mappings }}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <>
          <FieldArray
            name="mappings"
            render={arrayHelpers => (
              <VStack width="full" alignItems="flex-start">
                {values.mappings.map((_row, index) => (
                  <HStack key={index} alignSelf="stretch" alignItems="flex-start">
                    <KeyComponent index={index} isReadOnly={mode === 'view'} mode={mode} />
                    <ValueComponent index={index} isReadOnly={mode === 'view'} mode={mode} />

                    {mode === 'edit' && (
                      <IconButton
                        aria-label="Remove mapping"
                        icon={<Icon icon="trash-alt" variant="far" size="4" />}
                        variant="outline"
                        colorScheme="gray"
                        onClick={() => handleRemove(arrayHelpers, index)}
                      />
                    )}
                  </HStack>
                ))}

                {mode === 'edit' && (
                  <Button
                    variant="outline"
                    colorScheme="gray"
                    onClick={() => arrayHelpers.push({ key: '', value: '' })}
                    leftIcon={<Icon icon="plus" variant="far" size="4" />}
                  >
                    Add value
                  </Button>
                )}
              </VStack>
            )}
          />
        </>
      )}
    </Formik>
  );
};
