import * as React from 'react';
import { RightSidebarButton } from '../right-sidebar-button';
import { Checklist, ChecklistMigrationStatus } from '@process-street/subgrade/process';
import { useGetNewestTemplateRevisionsByTemplateIdQuery } from 'app/features/template/query-builder';
import {
  GetActiveChecklistRevisionByChecklistIdQuery,
  ScheduleMigrationMutation,
} from 'app/features/checklist-revisions/query-builder';
import { Box, Icon } from 'app/components/design/next';
import { motion } from 'framer-motion';
import { useQueryClient } from 'react-query';
import { GetChecklistQuery } from 'app/features/checklists/query-builder';

export type MigrateRunButtonProps = {
  checklist: Checklist;
};

const MotionBox = motion(Box);

export const MigrateRunButton = ({ checklist }: MigrateRunButtonProps) => {
  const queryClient = useQueryClient();
  const activeChecklistRevisionsQuery = GetActiveChecklistRevisionByChecklistIdQuery.useQuery({
    checklistId: checklist.id,
  });

  const newestChecklistRevisionsQuery = useGetNewestTemplateRevisionsByTemplateIdQuery({
    templateId: checklist.template.id,
  });

  const scheduleMigrationMutation = ScheduleMigrationMutation.useMutation({
    onSuccess: () => {
      void queryClient.invalidateQueries(GetChecklistQuery.getKey({ checklistId: checklist.id }));
    },
  });

  const shouldShowButton = React.useMemo(() => {
    if (!activeChecklistRevisionsQuery.data || !newestChecklistRevisionsQuery.data) return false;

    const [newestChecklistRevision] = newestChecklistRevisionsQuery.data;
    const activeTemplateRevision = activeChecklistRevisionsQuery.data.templateRevision;

    return activeTemplateRevision.id !== newestChecklistRevision.id;
  }, [activeChecklistRevisionsQuery.data, newestChecklistRevisionsQuery.data]);

  const handleClick = React.useCallback(() => {
    if (!activeChecklistRevisionsQuery.data) {
      console.error('`activeChecklistRevisionsQuery.data` is missing');
      return;
    }

    scheduleMigrationMutation.mutate({ checklistRevisionId: activeChecklistRevisionsQuery.data?.id });
  }, [activeChecklistRevisionsQuery.data, scheduleMigrationMutation]);

  const isMigrating = checklist.migrationStatus !== ChecklistMigrationStatus.Inactive;
  const isLoading = scheduleMigrationMutation.isLoading || isMigrating;

  if (!shouldShowButton) return null;

  const icon = <Icon color="gray.400" icon="refresh" size="4" />;
  const spinningIcon = (
    <MotionBox
      w="5"
      display="flex"
      alignItems="center"
      justifyContent="center"
      h="5"
      animate={{ rotate: 360 }}
      transition={{ repeat: Infinity, duration: 1, ease: 'linear' }}
    >
      {icon}
    </MotionBox>
  );

  return (
    <RightSidebarButton
      onClick={handleClick}
      iconName="refresh"
      leftIcon={isLoading ? spinningIcon : icon}
      isDisabled={isLoading}
    >
      {isLoading ? 'Updating to Latest' : 'Update to Latest'}
    </RightSidebarButton>
  );
};
