import * as React from 'react';
import { AspectRatio, VStack, Text } from 'components/design/next';
import { VideoContentWidgetMachineActorRef } from './video-content-widget-machine';
import { ChecklistWidgetMachineSelectors } from '../../../utils/widget-machine-selectors';
import { useSelector } from '@xstate/react';
import { match, P } from 'ts-pattern';
import { Embed } from 'app/components/design/next';
import { ContentWidgetMachineHooks } from '../content-widget-machine-hooks';

export type VideoContentWidgetProps = {
  actor: VideoContentWidgetMachineActorRef;
};

export const VideoContentWidget = ({ actor }: VideoContentWidgetProps) => {
  const widget = ContentWidgetMachineHooks.useWidget(actor);
  const isHiddenByRule = useSelector(actor, ChecklistWidgetMachineSelectors.getIsHiddenByRule);

  const hasContent = match(widget)
    .with({ service: P.not(P.nullish), serviceCode: P.not(P.nullish) }, () => true)
    .with({ service: P.not(P.nullish), file: { url: P.not(P.nullish) } }, () => true)
    .otherwise(() => false);

  if (isHiddenByRule || !hasContent) return null;

  return (
    <VStack w="full" gap={2} alignItems="flex-start">
      <AspectRatio ratio={16 / 9} w="full">
        {widget.service && (
          <Embed embed={{ service: widget.service, serviceCode: widget.serviceCode, fileUrl: widget.file?.url }} />
        )}
      </AspectRatio>
      {widget.description && (
        <Text color="gray.600" fontStyle="italic">
          {widget.description}
        </Text>
      )}
    </VStack>
  );
};
