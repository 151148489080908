import * as React from 'react';
import { RightSidebarButton } from '../right-sidebar-button';
import { GetChecklistPermissionsQuery } from 'app/features/permissions/query-builder/get-consolidated-checklist-permissions-query';
import { Checklist } from '@process-street/subgrade/process';

export type ArchiveRunButtonProps = {
  isArchived?: boolean;
  isArchiving: boolean;
  isUnarchiving: boolean;
  onArchive: () => void;
  onUnarchive: () => void;
  checklist: Checklist;
};

export const ArchiveRunButton = ({
  isArchived,
  isArchiving,
  isUnarchiving,
  onArchive,
  onUnarchive,
  checklist,
}: ArchiveRunButtonProps) => {
  const checklistPermissionsQuery = GetChecklistPermissionsQuery.useQuery({ checklistId: checklist.id });

  if (!checklistPermissionsQuery.data?.permissionMap.checklistArchive) return null;

  return isArchived ? (
    <RightSidebarButton
      iconName="undo"
      isLoading={isUnarchiving}
      loadingText="Unarchiving"
      colorScheme="brand"
      variant="solid"
      color="white"
      onClick={onUnarchive}
      iconProps={{ color: 'whiteAlpha.800' }}
      fontWeight="semibold"
    >
      Unarchive
    </RightSidebarButton>
  ) : (
    <RightSidebarButton iconName="archive" isLoading={isArchiving} loadingText="Archiving" onClick={onArchive}>
      Archive
    </RightSidebarButton>
  );
};
