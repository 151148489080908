const LINK_REGEXP =
  /((ftp|https?):\/\/|(www\.)|(mailto:)?[A-Za-z0-9._%+-]+@)[A-Za-z0-9\-._~:/?#[\]@!$&'()*+,;=\u00A0-\uFFFF]*[A-Za-z0-9\-_~:/#[\]@$*+\u00A0-\uFFFF]/gi;

export const wrapLinks = (input: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, 'text/html');

  const walkNodes = (node: Node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      const matches = node.nodeValue?.match(LINK_REGEXP);
      if (matches) {
        const fragment = document.createDocumentFragment();
        let lastIndex = 0;
        matches.forEach(match => {
          const index = node.nodeValue?.indexOf(match, lastIndex) ?? -1;
          if (index > -1) {
            fragment.appendChild(document.createTextNode(node.nodeValue!.substring(lastIndex, index)));
            const a = document.createElement('a');
            a.href = match.startsWith('http') ? match : `https://${match}`;
            a.target = '_blank';
            a.textContent = match;
            fragment.appendChild(a);
            lastIndex = index + match.length;
          }
        });
        fragment.appendChild(document.createTextNode(node.nodeValue!.substring(lastIndex)));
        (node as ChildNode).replaceWith(fragment);
      }
    } else if (node.nodeType === Node.ELEMENT_NODE && node.nodeName !== 'A') {
      Array.from(node.childNodes).forEach(walkNodes);
    }
  };

  Array.from(doc.body.childNodes).forEach(walkNodes);
  return doc.body.innerHTML;
};
