import * as React from 'react';
import {
  forwardRef,
  Icon as ChakraIcon,
  IconProps as ChakraIconProps,
  Button as ChakraButton,
  IconButton as ChakraIconButton,
  useTheme as useDefaultTheme,
  WithCSSVar,
  ButtonProps as ChakraButtonProps,
  IconButtonProps as ChakraIconButtonProps,
} from '@chakra-ui/react';
import { motion, MotionProps } from 'framer-motion';
import { Theme } from './theme';

export { Hide, Show } from '@chakra-ui/media-query';
export * from './provider';
export * from '@chakra-ui/react';
export { Icon } from './icon';
export type { IconProps } from './icon';
export { ChakraIcon };
export type { ChakraIconProps };

export * from './embed';
export * from './tagged-input';
export * from './tooltip-with-touch';

export * from './use-breakpoint-px-map';
export * from './use-breakpoint-px';

export { useToast } from './toast';

export const useTheme = (): WithCSSVar<Theme> => useDefaultTheme<Theme>();

/**
 * See https://www.framer.com/motion/component/#custom-components
 * Must have a single child that it wraps.
 * It merges its Motion props with that of its child.
 * */
export const MotionWrapper = motion<MotionProps & { children: React.ReactElement }>(
  forwardRef((props, ref) => React.cloneElement(props.children, { ref })),
);

type ButtonProps<As extends React.ElementType> = Omit<ChakraButtonProps, 'as'> & {
  as?: As;
} & React.ComponentPropsWithoutRef<As>;

export const Button = React.forwardRef(
  <As extends React.ElementType = 'button'>(props: ButtonProps<As>, ref: React.ForwardedRef<HTMLButtonElement>) => {
    return <ChakraButton {...props} isDisabled={props.isDisabled ?? props.disabled} ref={ref} />;
  },
) as <As extends React.ElementType = 'button'>(
  props: ButtonProps<As> & { ref?: React.Ref<HTMLButtonElement> },
) => JSX.Element;

type IconButtonProps<As extends React.ElementType> = Omit<ChakraIconButtonProps, 'as'> & {
  as?: As;
} & React.ComponentPropsWithoutRef<As>;

export const IconButton = React.forwardRef(
  <As extends React.ElementType>(props: IconButtonProps<As>, ref: React.ForwardedRef<HTMLButtonElement>) => {
    return <ChakraIconButton {...props} isDisabled={props.isDisabled ?? props.disabled} ref={ref} />;
  },
) as <As extends React.ElementType = 'button'>(
  props: IconButtonProps<As> & { ref?: React.Ref<HTMLButtonElement> },
) => JSX.Element;
