import * as React from 'react';
import { Image, Text, VStack } from '@chakra-ui/react';
import { ImageContentWidgetMachineActorRef } from './image-content-widget-machine';
import { useSelector } from '@xstate/react';
import { ChecklistWidgetMachineSelectors } from '../../../utils/widget-machine-selectors';
import { ContentWidgetMachineHooks } from '../content-widget-machine-hooks';

export type ImageContentWidgetProps = {
  actor: ImageContentWidgetMachineActorRef;
};

export const ImageContentWidget: React.FC<React.PropsWithChildren<ImageContentWidgetProps>> = ({ actor }) => {
  const widget = ContentWidgetMachineHooks.useWidget(actor);
  const isHiddenByRule = useSelector(actor, ChecklistWidgetMachineSelectors.getIsHiddenByRule);

  if (isHiddenByRule || !widget.file) return null;

  return (
    <VStack w="full">
      <Image mb="4" src={widget.file.url} alt={widget.caption} maxW="full" h="auto" />;
      {widget.caption && (
        <Text fontStyle="italic" color="gray.500">
          {widget.caption}
        </Text>
      )}
    </VStack>
  );
};
