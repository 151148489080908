import { QueryActorSelectors } from 'app/utils/query-builder';
import { FormResponsePageMachineContext } from '../form-response-page-machine';

export namespace FormResponsePageMachineUtils {
  export function getChecklistRevisionId(context: FormResponsePageMachineContext) {
    return QueryActorSelectors.getQueryData(context.checklistRevisionQuery)?.id;
  }

  export function getChecklistId(context: FormResponsePageMachineContext) {
    return QueryActorSelectors.getQueryData(context.checklistRevisionQuery)?.checklist.id;
  }

  export function getTemplateRevisionId(context: FormResponsePageMachineContext) {
    return QueryActorSelectors.getQueryData(context.checklistRevisionQuery)?.templateRevision.id;
  }

  export function getTemplateId(context: FormResponsePageMachineContext) {
    return QueryActorSelectors.getQueryData(context.checklistQuery)?.template.id;
  }
  export function getOrganizationId(context: FormResponsePageMachineContext) {
    return QueryActorSelectors.getQueryData(context.checklistQuery)?.organization.id;
  }
  export function getChecklistRevision(context: FormResponsePageMachineContext) {
    return QueryActorSelectors.getQueryData(context.checklistRevisionQuery);
  }
}
