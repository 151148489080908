import { FormControl, FormErrorMessage, VStack, Box, useDisclosure } from 'components/design/next';
import React from 'react';
import { DateFormFieldActor, DateFormFieldHooks } from './date-form-field-machine';
import { useSelector } from '@xstate/react';
import { StringUtils } from '@process-street/subgrade/util';
import { useGetCurrentUserQuery } from 'features/user/query-builder';
import { BlvdDatePicker, BlvdDatePickerProps } from 'components/design/BlvdDatePicker';
import { useSelector as useReduxSelector } from 'react-redux';
import { SessionSelector } from 'reducers/session/session.selectors';
import { DateContextUtils } from '@process-street/subgrade/core/date-context';
import { FormResponseLabel } from '../common';
import { ChecklistWidgetMachineSelectors } from '../../../utils/widget-machine-selectors';
import { useMatch } from '@process-street/adapters/navigation';
import { FormFieldAudit } from '../common/form-field-audit';

export interface DateFormFieldProps {
  actor: DateFormFieldActor;
}

export const DateFormField = ({ actor }: DateFormFieldProps) => {
  const api = DateFormFieldHooks.useApi(actor);

  const widget = DateFormFieldHooks.useWidget(actor);
  const formFieldValue = DateFormFieldHooks.useFormFieldValue(actor);
  const value = DateFormFieldHooks.useValue(actor);
  const inputNode = DateFormFieldHooks.useInputNode(actor);
  const timeStatus = DateFormFieldHooks.useTimeStatus(actor);
  const isInvalid = DateFormFieldHooks.useIsInvalid(actor);
  const isDisabled = DateFormFieldHooks.useIsInputDisabled(actor);
  const isAutofocused = DateFormFieldHooks.useIsAutofocused(actor);
  const errorMessage = DateFormFieldHooks.useValidationErrorMessage(actor);

  const isHiddenByRule = useSelector(actor, ChecklistWidgetMachineSelectors.getIsHiddenByRule);

  const isChecklist = useMatch('checklistV2');

  const handleSave: BlvdDatePickerProps['onSave'] = (date, timeStatus) => {
    if (date) {
      api.onChange(date.getTime(), timeStatus !== 'visible');

      return;
    }
    api.onChange(undefined, true);
  };

  const user = useGetCurrentUserQuery().data;
  const org = useReduxSelector(SessionSelector.getSelectedOrganization)!;
  const dateContext = DateContextUtils.getDateContext(org, user);
  const disclosure = useDisclosure({
    onClose: () => api.onBlur(),
  });

  const ref = React.useRef<HTMLDivElement | null>(null);

  const id = `date-form-field-${widget.id}`;
  return isHiddenByRule ? null : (
    <FormControl
      ref={node => {
        ref.current = node;
        if (node && !inputNode) {
          api.onSetNode(node);
        }
      }}
      as={VStack}
      alignItems="stretch"
      isInvalid={isInvalid}
      isRequired={widget.required}
    >
      <FormResponseLabel htmlFor={id}>{StringUtils.getNonEmpty(widget.label, 'Untitled Date')}</FormResponseLabel>
      <Box w={{ base: 'full', lg: '88' }}>
        <BlvdDatePicker
          disclosure={disclosure}
          isDisabled={isDisabled}
          value={value ? new Date(value) : undefined}
          onSave={handleSave}
          dateContext={dateContext}
          timeStatus={timeStatus}
          isInvalid={isInvalid}
          id={id}
          triggerProps={{
            autoFocus: isAutofocused,
            _hover: { cursor: isDisabled ? 'text' : 'inherit' },
            _disabled: {
              pointerEvents: 'auto',
              userSelect: 'text',
              opacity: 0.4,
              bgColor: 'transparent',
            },
          }}
        />
      </Box>
      {isChecklist && formFieldValue && <FormFieldAudit isDisabled={isDisabled} audit={formFieldValue.audit} />}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
