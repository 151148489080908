import { SelectFormFieldConfigItem } from '@process-street/subgrade/process';
import { match } from 'ts-pattern';
import { ActorRefFrom, assign, createMachine, sendParent, StateFrom } from 'xstate';

type SelectItemMachineArgs = {
  item: SelectFormFieldConfigItem;
};

type Context = {
  item: SelectFormFieldConfigItem;
  inputRef?: React.RefObject<HTMLTextAreaElement>;
};

export type ParentEvent =
  | { type: 'ITEM_INSERT'; item: SelectFormFieldConfigItem }
  | { type: 'ITEM_REMOVE'; item: SelectFormFieldConfigItem }
  | { type: 'ITEM_UPDATE'; item: SelectFormFieldConfigItem }
  | { type: 'ITEM_MOVE'; item: SelectFormFieldConfigItem; direction: 'up' | 'down' };

type Event =
  | ParentEvent
  | { type: 'FOCUS' | 'BLUR' | 'REMOVE' | 'AUTO_FOCUS' }
  | { type: 'CHANGE'; value: string }
  | { type: 'CHANGE_INTERNAL'; value: string }
  | { type: 'KEY_DOWN'; event: React.KeyboardEvent<HTMLTextAreaElement> }
  | { type: 'SET_INPUT_REF'; ref: React.RefObject<HTMLTextAreaElement> };

export const makeSelectItemMachine = ({ item }: SelectItemMachineArgs) => {
  return createMachine(
    {
      id: `select-item-machine:${item.id}`,
      predictableActionArguments: true,
      type: 'parallel',
      schema: { context: {} as Context, events: {} as Event },
      tsTypes: {} as import('./select-item-machine.typegen').Typegen0,
      context: {
        item,
      },
      states: {
        input: {
          initial: 'blurred',
          on: {
            REMOVE: { actions: 'sendParentRemove' },
            CHANGE_INTERNAL: { actions: 'assignValue' },
          },
          states: {
            blurred: {
              on: { FOCUS: 'focused' },
            },
            focused: {
              on: {
                BLUR: 'blurred',
                CHANGE: [
                  { actions: ['sendParentItemInsertMultiple'], cond: 'isMultiline' },
                  {
                    actions: ['assignValue', 'sendParentUpdate'],
                  },
                ],
                CHANGE_INTERNAL: { actions: 'assignValue' },
                KEY_DOWN: [
                  {
                    cond: 'isBackspaceOnEmptyName',
                    actions: ['preventDefault', 'sendParentRemove'],
                  },
                  {
                    cond: 'isCtrlMetaArrowUpOrDown',
                    actions: 'sendParentUpOrDown',
                  },
                  {
                    cond: 'pressedEnter',
                    actions: ['sendParentInsert'],
                  },
                ],
              },
            },
          },
        },
        autoFocus: {
          initial: 'disabled',
          states: {
            disabled: {
              on: {
                AUTO_FOCUS: { target: 'enabled', actions: 'autoFocus' },
                SET_INPUT_REF: { actions: ['assignInputRef'] },
              },
            },
            enabled: {
              on: {
                BLUR: 'disabled',
                SET_INPUT_REF: { actions: ['assignInputRef', 'autoFocus'] },
              },
            },
          },
        },
      },
    },
    {
      actions: {
        autoFocus: (context, _event) => {
          context.inputRef?.current?.focus();
        },
        assignValue: assign({
          item: (context, event) => {
            return { ...context.item, name: event.value };
          },
        }),
        sendParentUpdate: sendParent((context, _event) => ({ type: 'ITEM_UPDATE', item: context.item })),
        assignInputRef: assign({
          inputRef: (_context, event) => event.ref,
        }),
        sendParentRemove: sendParent(ctx => ({ type: 'ITEM_REMOVE', item: ctx.item })),
        sendParentInsert: sendParent(ctx => ({ type: 'ITEM_INSERT', item: ctx.item })),
        sendParentUpOrDown: sendParent((context, event) => {
          return match(event.event)
            .with({ key: 'ArrowUp' }, () => ({ type: 'ITEM_MOVE', item: context.item, direction: 'up' }))
            .with({ key: 'ArrowDown' }, () => ({ type: 'ITEM_MOVE', item: context.item, direction: 'down' }))
            .otherwise(() => ({ type: 'NOOP' }));
        }),
        sendParentItemInsertMultiple: sendParent((context, event) => {
          const items = event.value.split('\n');
          return { type: 'ITEM_INSERT_MULTIPLE', newItems: items, after: context.item };
        }),
        preventDefault: (_context, event) => {
          event.event.preventDefault();
        },
      },
      guards: {
        pressedEnter: (_, event) => {
          return event.event.key === 'Enter';
        },
        isBackspaceOnEmptyName: (ctx, { event }) => {
          return event.key === 'Backspace' && ctx.item.name === '';
        },
        isCtrlMetaArrowUpOrDown: (_ctx, { event }) => {
          return event.metaKey && (event.key === 'ArrowUp' || event.key === 'ArrowDown');
        },
        isMultiline: (_, event) => {
          return event.value.includes('\n');
        },
      },
    },
  );
};

export type SelectItemMachine = ReturnType<typeof makeSelectItemMachine>;
export type SelectItemActorRef = ActorRefFrom<SelectItemMachine>;

type State = StateFrom<SelectItemMachine>;
export const Selectors = {
  getItem: (state: State) => state.context.item,
};
