import { FormControl, FormErrorMessage, VStack } from 'components/design/next';
import React from 'react';
import { useSelector } from '@xstate/react';
import { TextFormFieldActor, TextFormFieldHooks } from './text-form-field-machine';
import { StringUtils } from '@process-street/subgrade/util';
import { FormResponseLabel } from '../common';
import { ChecklistWidgetMachineSelectors } from '../../../utils/widget-machine-selectors';
import { FormFieldMachineHooks } from '../form-field-machine-hooks';
import { SelectableDisabledInput } from 'app/pages/responses/_id/components/form-fields/common/ui';
import { useMatch } from '@process-street/adapters/navigation';
import { MaskedInput, MaskedInputParsers } from 'app/features/widgets/components/masked-input';
import { WidgetUtils } from '@process-street/subgrade/process';
import { FormFieldValueHelperText } from 'app/pages/runs/_id/components/form-field-value-helper-text';
import { match } from 'ts-pattern';

export interface TextFormFieldProps {
  actor: TextFormFieldActor;
}

export const TextFormField: React.FC<React.PropsWithChildren<TextFormFieldProps>> = ({ actor }) => {
  const api = FormFieldMachineHooks.useApi(actor);

  const isHiddenByRule = useSelector(actor, ChecklistWidgetMachineSelectors.getIsHiddenByRule);
  const inputNode = FormFieldMachineHooks.useInputNode(actor);
  const isInputDisabled = FormFieldMachineHooks.useIsInputDisabled(actor);
  const isAutofocused = FormFieldMachineHooks.useIsAutofocused(actor);
  const errorMessage = FormFieldMachineHooks.useValidationErrorMessage(actor);
  const isInvalid = FormFieldMachineHooks.useIsInvalid(actor);

  const machineValue = TextFormFieldHooks.useValue(actor);
  const widget = TextFormFieldHooks.useWidget(actor);
  const formFieldValue = TextFormFieldHooks.useFormFieldValue(actor);

  const isChecklist = useMatch('checklistV2');

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => api.onChange(e.target.value);
  const handleReset = () => {
    const widgetDefaultValue = widget.config.defaultValue;
    if (widgetDefaultValue) {
      api.onChange(WidgetUtils.hasVariables(widgetDefaultValue) ? '' : widgetDefaultValue, true);
    }
  };

  const hasDefaultValue = WidgetUtils.hasDefaultValue({ formFieldValue, widget });
  const hasVariables = WidgetUtils.hasVariables(widget.config.defaultValue);
  const maskedInputParser = React.useMemo(
    () =>
      hasDefaultValue && hasVariables
        ? MaskedInputParsers.makeParser('urls', 'merge-tags')
        : MaskedInputParsers.makeParser('urls'),
    [hasDefaultValue, hasVariables],
  );
  const html = React.useMemo(() => maskedInputParser(String(machineValue)), [maskedInputParser, machineValue]);

  const ref = React.useRef<HTMLDivElement | null>(null);

  return isHiddenByRule ? null : (
    <FormControl
      ref={node => {
        ref.current = node;
        if (node && !inputNode) {
          api.onSetNode(node);
        }
      }}
      as={VStack}
      alignItems="stretch"
      isRequired={widget.required}
      isInvalid={isInvalid}
    >
      <FormResponseLabel>{widget.label || 'Untitled Short Text'}</FormResponseLabel>
      <MaskedInput html={html} fontSize="md" maskProps={{ pt: 2 }}>
        <SelectableDisabledInput
          w={{ base: '100%', lg: '88' }}
          borderColor="gray.300"
          value={machineValue}
          placeholder={StringUtils.getNonEmpty(widget.config.placeholder, 'Type answer here...')}
          onFocus={() => api.onFocus()}
          onBlur={() => api.onBlur()}
          onChange={handleChange}
          isDisabled={isInputDisabled}
          autoFocus={isAutofocused}
          bgColor={match({ hasDefaultValue })
            .with({ hasDefaultValue: true }, () => 'yellow.100')
            .otherwise(() => undefined)}
        />
      </MaskedInput>
      {isChecklist && (
        <FormFieldValueHelperText
          defaultValue={widget.config.defaultValue}
          formFieldValue={formFieldValue}
          onResetDefaultValue={handleReset}
        />
      )}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
