import React from 'react';
import { CommentItem, NewCommentBox } from '../common';
import {
  AttachmentType,
  Checklist,
  ChecklistRevision,
  Comment,
  CommentMethod,
  Task,
  TaskTemplate,
  TaskTemplateGroup,
} from '@process-street/subgrade/process';
import {
  GetAttachmentsQuery,
  GetCommentsByChecklistRevisionIdQuery,
  CreateCommentMutation,
} from 'features/comments/query-builder';
import { isNotIdRef } from '@process-street/subgrade/core';
import { Divider, List, StackProps, useToast, VStack } from 'components/design/next';

import { isChecklistActionable } from 'utils/checklist';
import { CommentEvent } from 'services/events/comment-event';
import { useGetCurrentUserInfoQuery } from 'features/user/query-builder';
import { match, P } from 'ts-pattern';
import { AttachmentItem } from '../common/attachment/attachment-item';
import { GetAllActivitiesByTypeQuery } from 'features/activities/query-builder';
import { ActivityObjectType } from '@process-street/subgrade/activity';
import { useQueryClient } from 'react-query';
import { useGetTemplateQuery } from 'features/template/query-builder';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { useOneOffTaskDrawerStore } from 'app/features/one-off-tasks/components/shared/one-off-task-drawer-store';
import { EventEmitterService } from '@process-street/subgrade/util/event-emitter-service';

export type TaskCommentsProps = {
  checklistRevision: ChecklistRevision;
  taskId: Task['id'];
  activeStepId: TaskTemplateGroup['id'];
  fontSize?: StackProps['fontSize'];
  onlyComments?: boolean;
};

export const TaskComments: React.FC<React.PropsWithChildren<TaskCommentsProps>> = ({
  taskId,
  activeStepId,
  checklistRevision,
  fontSize = 'base',
  onlyComments = false,
}) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { options } = useOneOffTaskDrawerStore();
  const containerRef = React.useRef<HTMLDivElement>(null);

  const templateId = isNotIdRef(checklistRevision.templateRevision)
    ? checklistRevision.templateRevision.template.id
    : '';

  const templateQuery = useGetTemplateQuery({ templateId });
  const checklistCommentsEnabled = templateQuery.data?.checklistCommentsEnabled ?? false;
  const checklist = isNotIdRef(checklistRevision.checklist) ? checklistRevision.checklist : ({} as Checklist);

  const commentsQuery = GetCommentsByChecklistRevisionIdQuery.useQuery(
    {
      checklistRevisionId: checklistRevision.id,
      templateId,
    },
    {
      select: data =>
        data.filter(comment => {
          const task = isNotIdRef(comment.task) ? comment.task : ({} as Task);
          const taskTemplate = isNotIdRef(task.taskTemplate) ? task.taskTemplate : ({} as TaskTemplate);
          return taskTemplate.group.id === activeStepId;
        }),
    },
  );

  const attachmentsQuery = GetAttachmentsQuery.useQuery(
    {
      checklistRevisionId: checklistRevision?.id,
      templateId,
    },
    {
      select: data =>
        data.filter(attachment => {
          const task = isNotIdRef(attachment.task) ? attachment.task : ({} as Task);
          const taskTemplate = isNotIdRef(task.taskTemplate) ? task.taskTemplate : ({} as TaskTemplate);
          return taskTemplate.group?.id === activeStepId && attachment.type === AttachmentType.Comment;
        }),
    },
  );

  const currentUserQuery = useGetCurrentUserInfoQuery({ select: data => data.user });

  const createCommentMutation = CreateCommentMutation.useMutation({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Comment created',
      });
      EventEmitterService.broadcast(CommentEvent.COMMENT_CREATE_OK, taskId, checklist.id);
      queryClient.invalidateQueries(
        GetCommentsByChecklistRevisionIdQuery.getKey({ checklistRevisionId: checklistRevision.id, templateId }),
      );
      queryClient.invalidateQueries(
        GetAllActivitiesByTypeQuery.getKey({
          checklistId: checklist.id,
          type: 'checklist',
          objectType: ActivityObjectType.Comment,
        }),
      );
    },
    onError: () => {
      EventEmitterService.broadcast(CommentEvent.COMMENT_CREATE_FAILED, taskId, checklist.id);

      toast({
        status: 'error',
        title: "We're having problems creating the comment",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const onSendComment = (content: Comment['content']) => {
    createCommentMutation.mutate({
      content,
      audit: {
        createdBy: currentUserQuery.data,
        createdDate: new Date().getTime(),
      },
      taskId,
      method: CommentMethod.Task,
    });
  };

  const onAddAttachment = () => {
    toast({
      title: 'Attachment created',
      status: 'success',
    });
    queryClient.invalidateQueries(
      GetAttachmentsQuery.getKey({ checklistRevisionId: checklistRevision.id, templateId }),
    );
    queryClient.invalidateQueries(
      GetAllActivitiesByTypeQuery.getKey({
        checklistId: checklist.id,
        type: 'checklist',
        objectType: ActivityObjectType.Attachment,
      }),
    );
  };

  const allItems = match([commentsQuery, attachmentsQuery])
    .with([{ status: 'success' }, { status: 'success' }], ([{ data: comments }, { data: attachments }]) => {
      return [...(comments ?? []), ...(attachments ?? [])].sort((a, b) => a.audit.createdDate - b.audit.createdDate);
    })
    .otherwise(() => []);

  React.useEffect(
    function scrollToComments() {
      if (commentsQuery.data && options?.shouldScrollToComments) {
        setTimeout(() => {
          containerRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 500);
      }
    },
    [commentsQuery.data, options?.shouldScrollToComments],
  );

  return checklistCommentsEnabled ? (
    <VStack ref={containerRef} alignItems="flex-start" spacing="4" mb="4" w="full">
      <Divider mb="1" />
      <List spacing="1" w="full">
        {allItems.map(item => {
          if ('file' in item) {
            return (
              <AttachmentItem
                {...{
                  key: item.id,
                  attachment: item,
                  checklistId: checklist.id,
                  templateId,
                  isFromOneOffTask: false,
                }}
              />
            );
          }
          return <CommentItem {...{ key: item.id, checklistId: checklist.id, comment: item, templateId }} />;
        })}
      </List>
      {!onlyComments &&
        match(currentUserQuery)
          .with(
            { status: 'success', data: P.not(P.nullish) },
            ({ data: currentUser }) =>
              isChecklistActionable(checklist) && (
                <NewCommentBox
                  {...{
                    onSendComment,
                    onAddAttachment,
                    checklistId: checklist.id,
                    currentUser,
                    taskId,
                    fontSize,
                  }}
                />
              ),
          )
          .otherwise(() => null)}
    </VStack>
  ) : (
    <></>
  );
};
