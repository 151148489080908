import { PrintConditionalLogicButton } from './print-conditional-logic-button';
import { EditWorkflowButton } from './edit-workflow-button';
import { MigrateRunButton } from './migrate-run-button';
import { DeleteRunButton } from './delete-run-button';
import { ArchiveRunButton } from './archive-run-button';
import { CommentsButton } from './comments-button';
import { SubscribeButton } from './subscribe-button';

export const RightSidebarMenuItems = {
  PrintConditionalLogicButton,
  EditWorkflowButton,
  MigrateRunButton,
  DeleteRunButton,
  ArchiveRunButton,
  CommentsButton,
  SubscribeButton,
};
