import { useSelector } from '@xstate/react';
import { ContentWidgetActorRef, ContentWidgetMachineState } from './content-widget-machine-types';

export namespace ContentWidgetMachineHooks {
  export type ActorRef = ContentWidgetActorRef;
  export type State = ContentWidgetMachineState;

  export namespace Selectors {
    export function getWidget<S extends State>(state: S): S['context']['widget'] {
      return state.context.widget;
    }
  }
  export const useWidget = <T extends ActorRef>(actorRef: T) => {
    return useSelector(actorRef, Selectors.getWidget);
  };
}
