import { Muid } from '@process-street/subgrade/core';
import { ChecklistSubscription } from '@process-street/subgrade/process';
import { axiosService } from 'app/services/axios-service';
import { AxiosError } from 'axios';
import { useQuery as useRQ, UseQueryOptions, QueryClient } from 'react-query';

export namespace GetChecklistSubscriptionsQuery {
  export type Params = {
    checklistId?: Muid;
    userId?: Muid;
  };

  export type Response = Array<ChecklistSubscription>;

  export const key = ['checklist', 'subscriptions'];

  export const getKey = (params: Params) => [...key, params];

  export const queryFn = (params: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/checklist-subscriptions`, {
        params: {
          where: JSON.stringify({
            'checklistId': { _eq: params.checklistId },
            'organizationMembership.userId': { _eq: params.userId },
          }),
          include: 'organizationMembership',
        },
      })
      .then(res => res.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ({
      queryKey: getKey(params),
      queryFn: () => queryFn(params),
      ...options,
      enabled: Boolean(params.checklistId) && Boolean(params.userId) && options.enabled !== false,
    });
  };

  export const invalidate = (queryClient: QueryClient, params: Params) => queryClient.invalidateQueries(getKey(params));
}
