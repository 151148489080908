import * as React from 'react';
import {
  Box,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Portal,
  Tooltip,
} from 'components/design/next';
import { FormFieldWidget, TaskTemplate } from '@process-street/subgrade/process';
import { match, P } from 'ts-pattern';
import { useAssociatedAutomatedTaskTemplates } from './use-associated-automated-task-templates';
import { ThemeProvider2024 } from 'components/design/next/theme-provider-2024';
import { IconButtonProps } from '@chakra-ui/react';

export interface WidgetTaskAutomationIndicatorProps {
  widget: FormFieldWidget;
  onSelectTaskTemplate: (taskTemplate: TaskTemplate) => void;
}

export const WidgetTaskAutomationIndicator: React.FC<React.PropsWithChildren<WidgetTaskAutomationIndicatorProps>> = ({
  widget,
  onSelectTaskTemplate,
}) => {
  const associatedAiTaskTemplates = useAssociatedAutomatedTaskTemplates({ widget, automationType: 'AiTask' });
  const associatedCodeTaskTemplates = useAssociatedAutomatedTaskTemplates({ widget, automationType: 'CodeTask' });

  return (
    <HStack spacing={2}>
      <InputOutputIndicator
        automationType="AiTask"
        associatedTaskTemplates={associatedAiTaskTemplates}
        onSelect={onSelectTaskTemplate}
      />
      <InputOutputIndicator
        automationType="CodeTask"
        associatedTaskTemplates={associatedCodeTaskTemplates}
        onSelect={onSelectTaskTemplate}
      />
    </HStack>
  );
};

const InputOutputIndicator = ({
  associatedTaskTemplates,
  onSelect,
  automationType,
}: {
  associatedTaskTemplates: ReturnType<typeof useAssociatedAutomatedTaskTemplates>;
  onSelect: (taskTemplate: TaskTemplate) => void;
  automationType: 'AiTask' | 'CodeTask';
}) => {
  const isAiTask = automationType === 'AiTask';
  const taskTypeCopy = isAiTask ? 'AI Task' : 'Code Task';

  const icon = <Icon icon={isAiTask ? 'sparkles' : 'code'} size="4" color="brand.500" />;

  const iconButtonProps: IconButtonProps = {
    'isRound': true,
    'size': 'xs',
    icon,
    'variant': 'ghost',
    'aria-label': `${taskTypeCopy} input/output indicator`,
  };

  return match(associatedTaskTemplates)
    .with({ inputsTo: [], outputsFrom: [] }, () => null)
    .with({ outputsFrom: [P.select('taskTemplate')], inputsTo: [] }, ({ taskTemplate }) => (
      <Tooltip
        label={`This form field is ${isAiTask ? 'the output of an AI Task' : 'an output of a Code Task'}`}
        hasArrow
      >
        <Box>
          <IconButton {...iconButtonProps} onClick={() => onSelect(taskTemplate)} />
        </Box>
      </Tooltip>
    ))
    .with({ inputsTo: [P.select('taskTemplate')], outputsFrom: [] }, ({ taskTemplate }) => (
      <Tooltip label={`This form field is an input of ${isAiTask ? 'an AI Task' : 'a Code Task'}`} hasArrow>
        <Box>
          <IconButton {...iconButtonProps} onClick={() => onSelect(taskTemplate)} />
        </Box>
      </Tooltip>
    ))
    .otherwise(({ inputsTo, outputsFrom }) => (
      <Menu>
        <Tooltip label={`This form field is connected to multiple ${isAiTask ? 'AI Tasks' : 'Code Tasks'}`} hasArrow>
          <MenuButton as={IconButton} {...iconButtonProps} />
        </Tooltip>

        <Portal>
          <ThemeProvider2024>
            <MenuList>
              {inputsTo.length > 0 && (
                <MenuGroup title="Input to">
                  {inputsTo.map(taskTemplate => (
                    <MenuItem fontSize="md" key={taskTemplate.id} onClick={() => onSelect(taskTemplate)}>
                      {taskTemplate.name}
                    </MenuItem>
                  ))}
                </MenuGroup>
              )}
              {outputsFrom.length > 0 && (
                <MenuGroup title="Output from">
                  {outputsFrom.map(taskTemplate => (
                    <MenuItem fontSize="md" key={taskTemplate.id} onClick={() => onSelect(taskTemplate)}>
                      {taskTemplate.name}
                    </MenuItem>
                  ))}
                </MenuGroup>
              )}
            </MenuList>
          </ThemeProvider2024>
        </Portal>
      </Menu>
    ));
};
