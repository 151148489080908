import * as React from 'react';
import { EmailFields, EmailFieldValues } from 'features/widgets/components/send-rich-email/common/email-fields';
import {
  BodyEditorTab,
  Field,
  Label,
  sanitizeEmailBody,
} from 'features/widgets/components/send-rich-email/common/common';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  HStack,
  Icon,
  InputGroup,
  InputRightElement,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useBoolean,
  useDisclosure,
  VStack,
} from 'components/design/next';
import {
  FormFieldValue,
  RichEmailWidgetAttachmentWithS3File,
  SendRichEmailFieldValue,
  SendRichEmailFormFieldValue,
  SendRichEmailFormFieldWidget,
  Task,
  TemplateRevision,
} from '@process-street/subgrade/process';
import { SendRichEmailEditorV1 } from 'features/widgets/components/send-rich-email/template/editor';
import { MergeTagsMenu, MergeTagsMenuButton } from 'features/merge-tags/components/merge-tags-menu';
import { MergeTagsConstants } from '@process-street/subgrade/form';
import { useDebouncedCallback } from 'use-debounce';
import { useUnmount } from 'react-use';
import { useMergeTaggableInput } from 'hooks/use-merge-taggable-input';
import { EmailAttachmentItem } from 'features/widgets/components/send-rich-email/email_attachment/email-attachment-item';
import { UploadProgress } from 'features/upload/components';
import { UploadChecklistEmailAttachment } from 'features/widgets/components/send-rich-email/email_attachment/upload-checklist-email-attachment';
import { Option } from '@process-street/subgrade/core';
import { DeleteTemplateEmailAttachmentMutation } from 'features/widgets/components/send-rich-email/query-builder/delete-template-email-attachment';
import { calculateTotalSizeOfAttachments } from 'features/widgets/components/send-rich-email/util';
import { useFeatureFlag } from 'app/features/feature-flags';
import { SendRichEmailTemplateEditor } from '../template/editor/send-rich-email-template-editor-v2';

export type SendRichEmailChecklistEditorV1Props = {
  templateRevisionId: TemplateRevision['id'];
  taskId: Task['id'];
  formFieldValueId?: SendRichEmailFormFieldValue['id'];
  widget: SendRichEmailFormFieldWidget;
  fieldValue?: SendRichEmailFieldValue;
  onCancel: () => void;
  onCreateFormFieldValue: (
    fieldValues: EmailFieldValues,
    body: string,
    attachments: RichEmailWidgetAttachmentWithS3File[],
  ) => Promise<FormFieldValue>;
  onSave: (fieldValues: EmailFieldValues, body: string, attachments: RichEmailWidgetAttachmentWithS3File[]) => void;
};

const SANITIZE_BLUR_TIMEOUT = 500;

export const SendRichEmailChecklistEditorV1: React.FC<React.PropsWithChildren<SendRichEmailChecklistEditorV1Props>> = ({
  fieldValue,
  onCancel,
  onSave,
  onCreateFormFieldValue,
  templateRevisionId,
  widget,
  taskId,
  formFieldValueId,
}) => {
  const { editor } = widget.config;

  const [fieldValues, setFieldValues] = React.useState<EmailFieldValues>({
    to: fieldValue?.to ?? widget.config.to,
    cc: fieldValue?.cc ?? widget.config.cc,
    bcc: fieldValue?.bcc ?? widget.config.bcc,
    subject: fieldValue?.subject ?? widget.config.subject,
  });

  // upload
  const [isUploading, setIsUploading] = useBoolean(false);
  const [progress, setProgress] = React.useState<number | undefined>(undefined);

  const [rawHTMLBody, setRawHTMLBody] = React.useState(
    sanitizeEmailBody(
      fieldValue?.body ?? (editor === 'RichEditor' ? widget.config.richEditorBody : widget.config.rawHTMLBody) ?? '',
    ),
  );

  const sanitizeHTMLBody = React.useCallback(() => setRawHTMLBody(sanitizeEmailBody), []);
  const debouncedSanitizeHtmlBody = useDebouncedCallback(() => {
    sanitizeHTMLBody();
  }, SANITIZE_BLUR_TIMEOUT);

  const { ref: rawHTMLBodyRef, insertMergeTag: insertRawHTMLBody } = useMergeTaggableInput<HTMLTextAreaElement>({
    get: () => rawHTMLBody,
    set: setRawHTMLBody,
  });

  useUnmount(() => {
    debouncedSanitizeHtmlBody.cancel();
  });

  const handleSave = () => {
    return onSave(fieldValues, rawHTMLBody, attachments);
  };

  const handleCreateFormFieldValue = () => {
    return onCreateFormFieldValue(fieldValues, rawHTMLBody, attachments);
  };

  const [attachments, setAttachments] = React.useState<RichEmailWidgetAttachmentWithS3File[]>(
    fieldValue?.attachments ?? [],
  );

  const deleteAlertDiclosure = useDisclosure();
  const deleteAlertCancelRef = React.useRef<HTMLButtonElement>(null);

  const [attachmentToDelete, setAttachmentToDelete] = React.useState<Option<RichEmailWidgetAttachmentWithS3File>>();
  const handleDeleteAttachment = (attachment: RichEmailWidgetAttachmentWithS3File) => {
    setAttachmentToDelete(attachment);
    deleteAlertDiclosure.onOpen();
  };
  const attachmentAdded = (attachment: RichEmailWidgetAttachmentWithS3File) => {
    setIsUploading.off();
    setProgress(undefined);

    const updatedAttachments = [...attachments, attachment];
    setAttachments(updatedAttachments);
  };

  const deleteEmailAttachmentMutation = DeleteTemplateEmailAttachmentMutation.useMutation();
  const doDeleteAttachment = async () => {
    deleteAlertDiclosure.onClose();
    if (attachmentToDelete) {
      const updatedAttachments = attachments.filter(a => a.attachment.id !== attachmentToDelete.attachment.id);
      setAttachments(updatedAttachments);

      await deleteEmailAttachmentMutation.mutateAsync({ attachmentId: attachmentToDelete.attachment.id });
    }
  };

  const allAttachments = (widget.config.attachments ?? []).concat(attachments);
  const totalSize = calculateTotalSizeOfAttachments(allAttachments);
  const isMCEEditorEnabled = useFeatureFlag('sendEmailMCE');

  return (
    <>
      <EmailFields
        fieldValues={fieldValues}
        templateRevisionId={templateRevisionId}
        onChange={setFieldValues}
        widget={widget}
      />

      <Field alignItems="start">
        <Label>Body</Label>
        <Tabs
          w="full"
          {...{
            variant: 'enclosed',
            cursor: 'auto',
            index: 0,
            isLazy: true,
          }}
        >
          <TabList borderBottom="none">
            {editor === 'RichEditor' && (
              <BodyEditorTab py="2" px="3">
                <Icon icon="text" variant="far" size="4" />
                <Text>Rich Text</Text>
              </BodyEditorTab>
            )}

            {editor === 'RawHTMLEditor' && (
              <BodyEditorTab py="2" px="3">
                <Icon icon="code" variant="far" size="4" />
                <Text>HTML</Text>
              </BodyEditorTab>
            )}
          </TabList>

          <TabPanels borderStyle="solid" borderColor="gray.300" borderWidth="px" h="2xs" bg="white">
            {editor === 'RichEditor' && (
              <TabPanel h="full" p="0" tabIndex={-1}>
                {isMCEEditorEnabled ? (
                  <SendRichEmailTemplateEditor
                    widget={widget}
                    templateRevisionId={templateRevisionId}
                    body={rawHTMLBody}
                    onUpdate={({ richEditorBody }) => setRawHTMLBody(richEditorBody ?? '')}
                  />
                ) : (
                  <SendRichEmailEditorV1
                    mode="Checklist"
                    id={widget.header.id}
                    templateRevisionId={templateRevisionId}
                    widget={widget}
                    body={rawHTMLBody}
                    onUpdate={({ richEditorBody }) => setRawHTMLBody(richEditorBody ?? '')}
                  />
                )}
              </TabPanel>
            )}

            {editor === 'RawHTMLEditor' && (
              <TabPanel p="0" h="full">
                <InputGroup h="full">
                  <Textarea
                    h="full"
                    borderRadius="none"
                    border="none"
                    fontFamily="mono"
                    resize="none"
                    ref={rawHTMLBodyRef}
                    placeholder="TODO"
                    value={rawHTMLBody}
                    onChange={e => setRawHTMLBody(e.target.value)}
                    onBlur={debouncedSanitizeHtmlBody}
                  />
                  <InputRightElement top="-px" transform="translateY(-100%)" bg="transparent" w="8" h="8">
                    <MergeTagsMenu
                      {...{
                        templateRevisionId,
                        onSelect: (key, _fieldId, fallback) => insertRawHTMLBody(key, fallback),
                        mergeTagTarget: MergeTagsConstants.Target.RICH_CONTENT,
                        menuButton: (
                          <MergeTagsMenuButton
                            size="sm"
                            onClick={debouncedSanitizeHtmlBody.cancel}
                            onFocus={debouncedSanitizeHtmlBody.cancel}
                          />
                        ),
                      }}
                    />
                  </InputRightElement>
                </InputGroup>
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Field>

      <VStack paddingTop={attachments.length > 0 ? 4 : 0} paddingLeft={19} alignItems="start">
        {attachments.map(attachment => (
          <EmailAttachmentItem
            key={attachment.attachment.id}
            attachment={attachment}
            onDelete={handleDeleteAttachment}
          />
        ))}

        {!isUploading && (
          <UploadChecklistEmailAttachment
            {...{
              taskId,
              widgetId: widget.id,
              formFieldValueId,
              setIsUploading,
              onCreateFormFieldValue: handleCreateFormFieldValue,
              onFinish: attachmentAdded,
              progress,
              setProgress,
              totalSize,
            }}
          />
        )}
        {isUploading && <UploadProgress progress={progress as number} message="Uploading attachment..." />}
      </VStack>

      <HStack justifyContent="end">
        <Button variant="tertiary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </HStack>

      <AlertDialog
        {...{
          ...deleteAlertDiclosure,
          leastDestructiveRef: deleteAlertCancelRef,
        }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Confirmation
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text variant="inherit">Are you sure to delete this attachment?</Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <ButtonGroup>
                <Button variant="ghost" ref={deleteAlertCancelRef} onClick={deleteAlertDiclosure.onClose}>
                  Cancel
                </Button>

                <Button variant="danger" onClick={doDeleteAttachment} ml={3}>
                  Delete
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
