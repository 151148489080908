import { FieldType, TemplateType, WidgetType } from '@process-street/subgrade/process';
import React from 'react';
import { TaskMachine } from 'pages/responses/_id/components/task/task-machine';
import { TextFormField } from 'pages/responses/_id/components/form-fields/text-form-field';
import { ActorRefFrom } from 'xstate';
import { match, P } from 'ts-pattern';
import { TextareaFormField } from '../form-fields/textarea-form-field';
import { EmailFormField } from '../form-fields/email-form-field';
import { UrlFormField } from '../form-fields/url-form-field';
import { SelectFormField } from '../form-fields/select-form-field';
import { FormFieldWidgetActorLookup as ActorLookup } from '../form-fields/form-field-machine';
import { FileFormField } from '../form-fields/file-form-field';
import { ImageContentWidget } from '../content/image-content-widget/image-content-widget';
import { TextContentWidgetMachineActorRef } from '../content/text-content-widget';
import { ImageContentWidgetMachineActorRef } from '../content/image-content-widget/image-content-widget-machine';
import { FileContentWidget } from '../content/file-content-widget';
import { FileContentWidgetMachineActorRef } from '../content/file-content-widget/file-content-widget-machine';
import { DateFormField } from '../form-fields/date-form-field';
import { NumberFormField } from '../form-fields/number-form-field';
import { TableFormField } from '../form-fields/table-form-field';
import { MembersFormField } from '../form-fields/members-form-field';
import { VideoContentWidget, VideoContentWidgetMachineActorRef } from '../content/video-content-widget';
import { SendEmailWidget } from '../form-fields/send-email-widget';
import { SubtasksFormField } from '../form-fields/subtasks-form-field';
import { TaskMachineHooks } from './task-machine-hooks';
import { EmbedContentWidget, EmbedContentWidgetMachineActorRef } from '../content/embed-content-widget';
import { FormResponseMachineHooks } from '../form-response-body/form-response-machine-hooks';
import { WidgetProvider } from 'app/pages/runs/_id/hooks/use-widget-context';
import { CrossLinkContentWidget, CrossLinkContentWidgetMachineActorRef } from '../content/cross-link-content-widget';

const TextContentResponseWidget = React.lazy(() =>
  import('pages/responses/_id/components/content/text-content-widget').then(({ TextContentWidget }) => ({
    default: TextContentWidget,
  })),
);

export interface TaskProps {
  taskMachine: ActorRefFrom<TaskMachine>;
}

export const Task: React.FC<React.PropsWithChildren<TaskProps>> = ({ taskMachine }) => {
  const checklistRevision = FormResponseMachineHooks.useChecklistRevision();
  const currentTaskActorRef = FormResponseMachineHooks.useCurrentTaskActorRef();
  const widgetActors = TaskMachineHooks.useWidgetActors(taskMachine);
  const currentTask = TaskMachineHooks.useTask(currentTaskActorRef);

  return (
    <React.Suspense fallback="">
      {widgetActors.map(widgetActor => {
        const widget = widgetActor.getSnapshot()?.context.widget;
        if (!widget) return null;

        return (
          <WidgetProvider key={widget.id} widget={widget}>
            {match({ widget, actor: widgetActor })
              .with({ widget: { fieldType: FieldType.Text }, actor: P.not(undefined) }, ({ widget: w, actor }) => (
                <TextFormField key={w.id} actor={actor as ActorLookup<typeof w>} />
              ))
              .with({ widget: { fieldType: FieldType.Textarea }, actor: P.not(undefined) }, ({ widget: w, actor }) => (
                <TextareaFormField key={w.id} actor={actor as ActorLookup<typeof w>} />
              ))
              .with({ widget: { fieldType: FieldType.Email }, actor: P.not(undefined) }, ({ widget: w, actor }) => (
                <EmailFormField key={w.id} actor={actor as ActorLookup<typeof w>} />
              ))
              .with({ widget: { fieldType: FieldType.Url }, actor: P.not(undefined) }, ({ widget: w, actor }) => (
                <UrlFormField key={w.id} actor={actor as ActorLookup<typeof w>} />
              ))
              .with(
                { widget: { fieldType: P.union(FieldType.Select, FieldType.MultiChoice) }, actor: P.not(undefined) },
                ({ widget: w, actor }) => <SelectFormField key={w.id} actor={actor as ActorLookup<typeof w>} />,
              )
              .with({ widget: { fieldType: FieldType.File }, actor: P.not(undefined) }, ({ widget: w, actor }) => (
                <FileFormField key={w.id} actor={actor as ActorLookup<typeof w>} />
              ))
              .with({ widget: { fieldType: FieldType.Date }, actor: P.not(undefined) }, ({ widget: w, actor }) => (
                <DateFormField key={w.id} actor={actor as ActorLookup<typeof w>} />
              ))
              .with({ widget: { fieldType: FieldType.Number }, actor: P.not(undefined) }, ({ widget: w, actor }) => (
                <NumberFormField key={w.id} actor={actor as ActorLookup<typeof w>} />
              ))
              .with({ widget: { fieldType: FieldType.Members }, actor: P.not(undefined) }, ({ widget: w, actor }) => (
                <MembersFormField key={w.id} actor={actor as ActorLookup<typeof w>} />
              ))
              .with(
                { widget: { fieldType: FieldType.SendRichEmail }, actor: P.not(undefined) },
                ({ widget: w, actor }) => <SendEmailWidget key={w.id} actor={actor as ActorLookup<typeof w>} />,
              )
              .with({ widget: { fieldType: FieldType.Table }, actor: P.not(undefined) }, ({ widget: w, actor }) => (
                <TableFormField key={w.id} actor={actor as ActorLookup<typeof w>} templateType={TemplateType.Form} />
              ))
              .with(
                { widget: { fieldType: FieldType.MultiSelect }, actor: P.not(undefined) },
                ({ widget: w, actor }) => (
                  <SubtasksFormField
                    key={w.id}
                    actor={actor as ActorLookup<typeof w>}
                    checklistRevisionId={checklistRevision.id}
                    taskId={currentTask.id}
                  />
                ),
              )
              .with({ widget: { header: { type: WidgetType.Text } } }, ({ widget: w, actor }) => (
                <TextContentResponseWidget
                  key={w.id}
                  actor={actor as TextContentWidgetMachineActorRef}
                  checklistRevisionId={checklistRevision.id}
                  taskId={currentTask.id}
                />
              ))
              .with({ widget: { header: { type: WidgetType.CrossLink } } }, ({ widget: w, actor }) => (
                <CrossLinkContentWidget key={w.id} actor={actor as CrossLinkContentWidgetMachineActorRef} />
              ))
              .with({ widget: { header: { type: WidgetType.Image } } }, ({ widget: w, actor }) => (
                <ImageContentWidget key={w.id} actor={actor as ImageContentWidgetMachineActorRef} />
              ))
              .with({ widget: { header: { type: WidgetType.File } } }, ({ widget: w, actor }) => (
                <FileContentWidget key={w.id} actor={actor as FileContentWidgetMachineActorRef} />
              ))
              .with({ widget: { header: { type: WidgetType.Video } } }, ({ widget: w, actor }) => (
                <VideoContentWidget key={w.id} actor={actor as VideoContentWidgetMachineActorRef} />
              ))
              .with({ widget: { header: { type: WidgetType.Embed } } }, ({ widget: w, actor }) => (
                <EmbedContentWidget key={w.id} actor={actor as EmbedContentWidgetMachineActorRef} />
              ))
              .otherwise(() => null)}
          </WidgetProvider>
        );
      })}
    </React.Suspense>
  );
};
