import React from 'react';
import {
  NativeAutomation,
  TEXT_MAX_LENGTH,
  TextFormFieldValue,
  TextFormFieldWidget,
  WidgetUtils,
  WidgetValidation,
} from '@process-street/subgrade/process';
import { match } from 'ts-pattern';
import { ChecklistFormFieldWidgetProps } from 'components/widgets/form-field/common/types';
import { InputProps } from 'components/design/next';
import { Option } from 'space-monad';
import { useMachine } from '@xstate/react';
import { validatedInputMachine } from 'utils/widget/validated-input-machine/validated-input-machine';
import { assign } from 'xstate';
import { useSyncedAndMaskedFormFieldValues } from 'components/widgets/form-field/common/use-synced-and-masked-form-field-values';
import { useAutomatedTaskOutputWidgetStatus } from '../../hooks/use-automated-task-output-widget-status';
import { MergeTagStringReplacementUtils } from '@process-street/subgrade/merge-tags';
import { useFeatureFlag } from 'features/feature-flags';

export interface Props
  extends Pick<
    ChecklistFormFieldWidgetProps<TextFormFieldWidget, TextFormFieldValue>,
    'widget' | 'editable' | 'formFieldValue' | 'onUpdateValue' | 'onInteractionEnd'
  > {
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const DEFAULT_PLACEHOLDER = 'Type answer here…';

export function useChecklistTextFormFieldWidget({
  widget,
  editable,
  formFieldValue,
  onUpdateValue,
  onInteractionEnd,
  onKeyUp,
}: Props): {
  inputProps: Partial<InputProps>;
  errorMessage: string;
  setValue: (value: string) => void;
  handleReset: () => void;
  nativeAutomationStatus: NativeAutomation.EventStatus | undefined;
} {
  const isMergeTagImprovementsEnabled = useFeatureFlag('mergeTagImprovements');
  const { status: nativeAutomationStatus } = useAutomatedTaskOutputWidgetStatus(widget.id);
  const isReadOnly = !editable;
  const { constraints } = widget;

  const stringValue = Option(formFieldValue)
    .map(ffv => ffv.fieldValue.value)
    .map(String)
    .getOrElse('');
  const initiallyValid = !WidgetValidation.getTextFormFieldWidgetErrorType(constraints, stringValue);
  const widgetDefaultValue = Option<string>(widget.config?.defaultValue)
    .map(defaultValue =>
      isMergeTagImprovementsEnabled
        ? MergeTagStringReplacementUtils.replaceUnknownTagsValues(defaultValue)
        : defaultValue,
    )
    .get();

  const [state, send] = useMachine(validatedInputMachine, {
    context: { value: stringValue, isValid: initiallyValid, shouldShowError: !initiallyValid },
    actions: {
      validate: assign({
        isValid: ({ value }) => {
          const error = WidgetValidation.getTextFormFieldWidgetErrorType(constraints, value);
          return !error;
        },
      }),
      onInteractionEnd: (ctx, _event) => {
        onInteractionEnd(widget, { value: ctx.value });
      },
      onReset: () => {
        onUpdateValue(widget, {
          value: WidgetUtils.hasVariables(widgetDefaultValue) ? '' : widgetDefaultValue,
          hasDefaultValue: true,
        });
      },
      onChange: (ctx, _event) => {
        onUpdateValue(widget, {
          value: ctx.value,
          hasDefaultValue: ctx.value === widgetDefaultValue,
        });
      },
    },
  });

  useSyncedAndMaskedFormFieldValues({ send, widget, formFieldValue });

  const errorType = WidgetValidation.getTextFormFieldWidgetErrorType(constraints, state.context.value);

  const setValue = (newValue: string) => {
    send({ type: 'CHANGE', value: newValue });
  };

  const errorMessage = state.context.shouldShowError
    ? match(errorType)
        .with('not-in-between', () => `The character limit is between ${constraints.min} and ${constraints.max}`)
        .with('max-chars', () => `Maximum ${constraints?.max} characters`)
        .with('min-chars', () => `Minimum ${constraints?.min} characters`)
        .otherwise(() => '')
    : '';

  const onBlur = () => {
    send({ type: 'BLUR' });
  };

  const onFocus = () => {
    send({ type: 'FOCUS' });
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setValue(e.target.value);
  };

  const handleReset = () => {
    send({ type: 'RESET', value: widgetDefaultValue ?? '' });
  };

  return {
    errorMessage,
    setValue,
    handleReset,
    inputProps: {
      isReadOnly,
      value: state.context.value,
      onChange,
      onBlur,
      maxLength: TEXT_MAX_LENGTH,
      onFocus,
      placeholder: widget.config.placeholder || DEFAULT_PLACEHOLDER,
      onKeyUp,
      resize: 'none',
      overflowY: 'hidden',
    },
    nativeAutomationStatus,
  };
}
