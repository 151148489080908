import { Comment } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { useMutation as useRQMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace DeleteCommentMutation {
  export type Params = { commentId: Comment['id'] };
  export type Response = Comment;
  export const key = ['delete', 'comment'];
  export const mutationFn = (params: Params) =>
    axiosService
      .getAxios()
      .delete<Response>(`/1/comments/${params.commentId}`)
      .then(res => res.data);
  export const useMutation = (
    options: UseMutationOptions<DeleteCommentMutation.Response, AxiosError, DeleteCommentMutation.Params> = {},
  ) => {
    return useRQMutation(DeleteCommentMutation.mutationFn, options);
  };
}
