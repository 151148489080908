import { MergeTagMode, MergeTagTarget } from '@process-street/subgrade/form';
import { EmbedWidget, WidgetUtils } from '@process-street/subgrade/process';
import { Box, Text } from 'components/design/next';
import { SandboxedIframe } from 'components/widgets/embed/SandboxedIframe';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'features/checklist-revisions/query-builder';
import { useGetAllTasksByChecklistRevisionIdQuery } from 'features/task/query-builder';
import { useParseChecklistMergeTagsByTarget } from 'features/widgets/components/send-rich-email/checklist/use-parse-checklist-merge-tags-by-target';
import { useStateParam } from 'hooks/use-state-param';
import * as React from 'react';
import { EmbedWidgetPlaceholder } from '../embed-widget-placeholder';
import { EmbedUtils } from '@process-street/subgrade/util';
import { match } from 'ts-pattern';

export interface ChecklistEmbedWidgetProps {
  widget: EmbedWidget;
}

export const ChecklistEmbedWidget: React.FunctionComponent<React.PropsWithChildren<ChecklistEmbedWidgetProps>> = ({
  widget,
}) => {
  const checklistId = useStateParam({ key: 'id' });
  const taskTemplateGroupId = useStateParam({ key: 'groupId' });
  const checklistRevisionQuery = GetActiveChecklistRevisionByChecklistIdQuery.useQuery(
    { checklistId },
    {
      staleTime: 1000 * 60,
    },
  );
  const checklistRevisionId = checklistRevisionQuery.data?.id;
  const taskQuery = useGetAllTasksByChecklistRevisionIdQuery(
    { checklistRevisionId },
    {
      staleTime: 1000 * 60,
      select: tasks => tasks.find(task => task.taskTemplate.group.id === taskTemplateGroupId),
    },
  );

  const parseUrl = useParseChecklistMergeTagsByTarget({
    checklistRevisionId,
    taskId: taskQuery.data?.id,
    mergeTagTarget: MergeTagTarget.GENERAL,
  });

  const url = parseUrl({ content: widget.url ?? '', mode: MergeTagMode.PLAINTEXT });

  const hasVariables = WidgetUtils.hasVariables(url);
  const encodedUrl = encodeURI(url);
  const isValidURL = EmbedUtils.isUrlValid(encodedUrl ?? '');

  return (
    <Box width="full">
      {match({ hasVariables, isValidURL })
        .with({ hasVariables: true }, () => (
          <EmbedWidgetPlaceholder>
            <Text variant="-2" color="gray.500" textAlign="center" px="4">
              {url}
            </Text>
          </EmbedWidgetPlaceholder>
        ))
        .with({ isValidURL: true }, () => <SandboxedIframe url={encodedUrl} />)
        .otherwise(() => (
          <EmbedWidgetPlaceholder>
            <Text variant="-2" color="red.500">
              Invalid url: {url}
            </Text>
          </EmbedWidgetPlaceholder>
        ))}
    </Box>
  );
};
