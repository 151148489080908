import {
  MultiOptionFieldValue,
  MultiSelectFormFieldValue,
  MultiSelectFormFieldWidget,
  MultiSelectItemValueStatus,
} from '@process-street/subgrade/process';
import {
  makeValidationMachine,
  ValidationActorRef,
  ValidationParentEvent,
  ValidationSelectors,
} from '../validation-machine';
import {
  ActionObject,
  ActorRefFrom,
  assign,
  createMachine,
  forwardTo,
  sendParent,
  spawn,
  StateFrom,
  send,
} from 'xstate';
import {
  makeRulesEngineTargetMachine,
  sendRulesActorFormFieldValueUpdate,
} from '../../form-response-body/rules-engine-machine';
import { FormFieldMachineBuilderProps, WithFormFieldMachineEvent } from '../../../types';
import { makeSubtasksValidationSchema } from 'app/pages/forms/_id/edit/components/content/subtasks-content/subtasks-content-schema';
import { UpdateFormFieldValueMutationResponse } from 'app/features/widgets/query-builder';
import { makeUpdateFormFieldValueMutation } from '../make-update-form-field-value-mutation';
import { makeErrorLoggerAction } from 'app/utils/machines';
import { AxiosError } from 'axios';
import { FormResponseErrorToasts } from '../../../utils/form-response-error-toasts';
import { useSelector } from '@xstate/react';
import { useCallback, useMemo } from 'react';
import { identitySelector } from '../form-field-machine-hooks';

export type Context = {
  value: MultiOptionFieldValue | undefined;
  widget: MultiSelectFormFieldWidget;
  formFieldValue: MultiSelectFormFieldValue | undefined;
  validationActor: ValidationActorRef<MultiSelectFormFieldValue | undefined>;
  rulesEngineTargetActor: ActorRefFrom<typeof makeRulesEngineTargetMachine>;
  inputNode: HTMLElement | null;
  id: string;
};

export type Event =
  | Exclude<
      WithFormFieldMachineEvent<
        ValidationParentEvent,
        MultiOptionFieldValue | undefined,
        MultiSelectFormFieldValue | undefined
      >,
      { type: 'CHANGE' | 'UPDATE_VALUE' }
    >
  | { type: 'CHANGE' | 'UPDATE_VALUE'; value: MultiOptionFieldValue | undefined };

export const makeSubtasksFormFieldMachine = ({
  formFieldWidget,
  formFieldValue,
  autoFocus,
  checklistRevisionId,
  sharedContext,
  isEditable,
  inputNode,
}: FormFieldMachineBuilderProps<MultiSelectFormFieldWidget>) => {
  const validationSchema = makeSubtasksValidationSchema({ required: formFieldWidget.required });

  const initialState = isEditable ? 'enabled' : 'disabled';

  const value = formFieldValue?.fieldValue as MultiOptionFieldValue | undefined;

  const id = `subtasks-form-field-machine:${formFieldWidget.id}`;

  return createMachine(
    {
      context: () => ({
        id,
        inputNode,
        value,
        widget: formFieldWidget,
        formFieldValue,
        validationActor: spawn(
          makeValidationMachine({
            validationSchema,
            initialValue: formFieldValue,
          }),
          {
            name: `subtasks-form-field-validation-actor:${formFieldWidget.id}`,
          },
        ),
        rulesEngineTargetActor: spawn(
          makeRulesEngineTargetMachine({ type: 'widget', widgetHeaderGroupId: formFieldWidget.header.group.id }),
          { name: 'hidden-by-rule-actor', sync: true },
        ),
      }),
      schema: {
        context: {} as Context,
        events: {} as Event,
        services: {} as {
          updateFormFieldValue: {
            data: UpdateFormFieldValueMutationResponse;
          };
        },
      },
      tsTypes: {} as import('./subtasks-form-field-machine.typegen').Typegen0,
      id,
      predictableActionArguments: true,
      preserveActionOrder: true,
      type: 'parallel',
      on: {
        SET_NODE: { actions: ['assignNode'] },
        SCROLL_INTO_VIEW: { actions: ['scrollIntoView'] },
      },
      states: {
        input: {
          initial: initialState,
          states: {
            disabled: {
              on: {
                ENABLE: {
                  target: 'enabled',
                },
              },
            },
            enabled: {
              initial: 'idle',
              on: { DISABLE: 'disabled' },
              states: {
                idle: { on: { FOCUS: 'focused' } },
                focused: {
                  on: {
                    BLUR: 'idle',
                    CHANGE: { actions: ['updateValue', 'sendRulesActorFormFieldValueUpdate'] },
                  },
                },
              },
            },
          },
        },

        autoFocus: {
          initial: autoFocus ? 'enabled' : 'disabled',
          states: { disabled: {}, enabled: {} },
        },

        mutation: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                UPDATE_VALUE: 'updating',
              },
            },
            updating: {
              invoke: {
                src: 'updateFormFieldValue',
                onDone: {
                  target: 'idle',
                  actions: ['sendParentFormFieldValueUpdate', 'assignFormFieldValue'],
                },
                onError: {
                  target: 'idle',
                  actions: ['logError', 'showErrorToast'],
                },
              },
            },
          },
        },
        validation: {
          initial: 'enabled',
          states: {
            enabled: {
              on: {
                CHANGE: [
                  {
                    cond: 'shouldForwardToValidation',
                    actions: ['updateValue', 'autoSendUpdateValue', 'forwardToValidation'],
                  },
                  {
                    cond: 'shouldNotForwardToValidation',
                    actions: ['updateValue', 'autoSendUpdateValue'],
                  },
                ],
                REVEAL_INVALID: { actions: 'forwardToValidation' },
                VALID: { actions: 'sendParentValid' },
                INVALID: { actions: 'sendParentInvalid' },
                HIDE: { actions: 'sendParentValid', target: 'disabled' },
              },
            },
            disabled: {
              on: {
                REVEAL: { target: 'enabled', actions: 'restoreValidationWithParent' },
              },
            },
          },
        },
      },
    },
    {
      guards: {
        shouldForwardToValidation: (ctx, event) => {
          // All items completed, so we can validate the widget
          const allCompleted = ((event.value as MultiOptionFieldValue).itemValues ?? []).every(
            item => item.status === MultiSelectItemValueStatus.Completed,
          );

          // All itemes were completed (widget valid) and now is uncompleting, so we trigger validation now (since before the Complete button was enbaled)
          const allWereCompletedNowNot =
            (ctx.value?.itemValues ?? []).every(item => item.status === MultiSelectItemValueStatus.Completed) &&
            ((event.value as MultiOptionFieldValue).itemValues ?? []).some(
              item => item.status === MultiSelectItemValueStatus.NotCompleted,
            );

          return allCompleted || allWereCompletedNowNot;
        },

        shouldNotForwardToValidation: (_, event) =>
          // Not all items completed, the user is completing them, so no validation yet
          ((event.value as MultiOptionFieldValue).itemValues ?? []).some(
            item => item.status === MultiSelectItemValueStatus.NotCompleted,
          ),
      },
      services: {
        updateFormFieldValue: async (_, event) =>
          makeUpdateFormFieldValueMutation({
            queryClient: sharedContext.queryClient,
            body: {
              itemValues: event.value?.itemValues,
              checklistRevisionId,
              widgetId: formFieldWidget.id,
            },
          }).execute(),
      },
      actions: {
        logError: makeErrorLoggerAction(id),
        assignNode: assign({ inputNode: (_, event) => event.node }),
        updateValue: assign({
          value: (_, event) => {
            return event.value as MultiOptionFieldValue;
          },
        }),
        showErrorToast: (_, event) => FormResponseErrorToasts.showFormFieldUpdateErrorToast(event.data as AxiosError),
        sendParentInvalid: sendParent({ type: 'INVALID_WIDGET', widgetId: formFieldWidget.id }),
        sendParentValid: sendParent({ type: 'VALID_WIDGET', widgetId: formFieldWidget.id }),
        forwardToValidation: forwardTo(ctx => ctx.validationActor) as ActionObject<Context, Event>,
        autoSendUpdateValue: send((_, event) => {
          return {
            type: 'UPDATE_VALUE',
            value: event.value,
          };
        }),
        restoreValidationWithParent: sendParent((ctx, _evt) => {
          if (ctx.validationActor.getSnapshot()?.matches('valid')) {
            return { type: 'VALID_WIDGET', widgetId: formFieldWidget.id };
          }
          return { type: 'INVALID_WIDGET', widgetId: formFieldWidget.id };
        }),

        sendRulesActorFormFieldValueUpdate: (_ctx, event) =>
          sendRulesActorFormFieldValueUpdate({
            formFieldValue: {
              ...formFieldValue!,
              fieldValue: event.value as MultiOptionFieldValue,
            },
            formFieldWidget,
          }),
        scrollIntoView: ctx => {
          ctx.inputNode?.scrollIntoView();
        },
        sendParentFormFieldValueUpdate: sendParent((_ctx, evt) => ({
          type: 'FORM_FIELD_VAUE_UPDATE',
          formFieldValue: evt.data.formFieldValue,
        })),
        assignFormFieldValue: assign({
          formFieldValue: (ctx, evt) => ({
            ...ctx.formFieldValue,
            ...(evt.data.formFieldValue as MultiSelectFormFieldValue),
          }),
        }),
      },
    },
  );
};

export type SubtasksFormFieldMachine = ReturnType<typeof makeSubtasksFormFieldMachine>;
export type SubtasksFormFieldMachineState = StateFrom<SubtasksFormFieldMachine>;
export type SubtasksFormFieldActor = ActorRefFrom<SubtasksFormFieldMachine>;

export namespace SubtasksFormFieldSelectors {
  export function getValidationActor(state: SubtasksFormFieldMachineState) {
    return state.context.validationActor;
  }

  export function getIsInputDisabled(state: SubtasksFormFieldMachineState) {
    return state.matches('input.disabled');
  }

  export const getInputNode = (state: SubtasksFormFieldMachineState) => state.context.inputNode;

  export const getIsInvallid = (state: SubtasksFormFieldMachineState) => state.context.inputNode;

  export const getFormFieldValue = (state: SubtasksFormFieldMachineState) => state.context.formFieldValue;

  export const getValue = (state: SubtasksFormFieldMachineState) => state.context.value;

  export const getWidget = (state: SubtasksFormFieldMachineState) => state.context.widget;
}

export namespace SubtasksFormFieldHooks {
  export function useValidationActor(actorRef: SubtasksFormFieldActor) {
    return useSelector(actorRef, SubtasksFormFieldSelectors.getValidationActor);
  }

  export function useIsInvalid(actorRef: SubtasksFormFieldActor) {
    const validationActor = useValidationActor(actorRef);
    return ValidationSelectors.isActorInvalidVisible(validationActor);
  }

  export const useInputNode = (actorRef: SubtasksFormFieldActor) => {
    return useSelector(actorRef, SubtasksFormFieldSelectors.getInputNode);
  };

  export function useIsInputDisabled(actorRef: SubtasksFormFieldActor) {
    return useSelector(actorRef, SubtasksFormFieldSelectors.getIsInputDisabled);
  }

  const validationMachineSelector = (state: SubtasksFormFieldMachineState) => state.context.validationActor;
  export function useValidationSnapshot(parentRef: SubtasksFormFieldActor) {
    const validationActor = useSelector(parentRef, validationMachineSelector) as ValidationActorRef<any>;
    const validationSnapshot = useSelector(validationActor, identitySelector);
    return validationSnapshot;
  }

  export function useValidationErrorMessage(parentRef: SubtasksFormFieldActor) {
    const validationSnapshot = useValidationSnapshot(parentRef);
    return validationSnapshot.context.errorMessage;
  }

  export const useFormFieldValue = (actorRef: SubtasksFormFieldActor) => {
    return useSelector(actorRef, SubtasksFormFieldSelectors.getFormFieldValue);
  };

  export const useValue = (actorRef: SubtasksFormFieldActor) => {
    return useSelector(actorRef, SubtasksFormFieldSelectors.getValue);
  };

  export const useWidget = (actorRef: SubtasksFormFieldActor) => {
    return useSelector(actorRef, SubtasksFormFieldSelectors.getWidget);
  };

  export function useApi(actorRef: SubtasksFormFieldActor) {
    const onChange = useCallback(
      (value?: MultiOptionFieldValue) => {
        actorRef.send({ type: 'CHANGE', value });
      },
      [actorRef],
    );

    const onBlur = useCallback(() => {
      actorRef.send({ type: 'BLUR' });
    }, [actorRef]);

    const onFocus = useCallback(() => {
      actorRef.send({ type: 'FOCUS' });
    }, [actorRef]);

    const onSetNode = useCallback(
      (node: HTMLDivElement | null) => {
        actorRef.send({ type: 'SET_NODE', node });
      },
      [actorRef],
    );

    return useMemo(() => ({ onChange, onBlur, onFocus, onSetNode }), [onChange, onBlur, onFocus, onSetNode]);
  }
}
