import { Muid, Ref, User } from '@process-street/subgrade/core';
import { FormFieldValue } from '@process-street/subgrade/process';
import { TaskAssignment } from '@process-street/subgrade/role-assignment';
import { FORM_FIELD_VALUE_SET } from 'app/components/form-field-value/store/form-field-value.actions';
import { useInjector } from 'app/components/injection-provider';
import { trace } from 'app/components/trace';
import { MergeTagsByChecklistRevisionIdQuery } from 'app/features/merge-tags/query-builder';
import { UpdateFormFieldValueMutation } from 'app/features/widgets/query-builder';
import { AblyEvent } from 'app/pusher/ably-event';
import { ablyService } from 'app/pusher/ably.service';
import { FormFieldEvent } from 'services/form-field-event';
import { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { match, P } from 'ts-pattern';
import { useCurrentUser } from './use-current-user';

const logger = trace({ name: 'FormFieldValueListeners' });

export type ValueUpdateMessage = {
  updatedBy: Ref<User>;
  source: AblyEvent.FormFieldValueUpdateSource;
  updatedFormFieldValue?: FormFieldValue;
  checklistTaskAssignments: {
    created: Array<TaskAssignment>;
    deleted: Array<TaskAssignment>;
  };
};

export const useFormFieldValueListeners = () => {
  const dispatch = useDispatch();
  const { $rootScope } = useInjector('$rootScope');
  const currentUser = useCurrentUser();
  const queryClient = useQueryClient();

  const formFieldValueUpdateListener = useCallback(
    async (message: { data: string }) => {
      const data: ValueUpdateMessage = JSON.parse(message.data);

      logger.debug('live form field value update ', data);

      const isUpdatedByDifferentUser = currentUser?.id !== data.updatedBy.id;

      // e.g. AI task update
      const isNativeAutomationUpdate = data.source === AblyEvent.FormFieldValueUpdateSource.NativeAutomation;
      const isDefaultValueUpdated = match(data.updatedFormFieldValue?.fieldValue)
        .with({ hasDefaultValue: P.boolean }, ({ hasDefaultValue }) => hasDefaultValue)
        .otherwise(() => false);

      const shouldUpdateFormFieldValue =
        (isUpdatedByDifferentUser || isDefaultValueUpdated || isNativeAutomationUpdate) && data.updatedFormFieldValue;

      if (!shouldUpdateFormFieldValue) {
        return;
      }

      if (data.updatedFormFieldValue) {
        $rootScope.$broadcast(FormFieldEvent.FORM_FIELD_VALUE_LIVE_UPDATED, data.updatedFormFieldValue);
        dispatch({
          type: FORM_FIELD_VALUE_SET,
          payload: {
            formFieldValue: data.updatedFormFieldValue,
            checklistTaskAssignments: data.checklistTaskAssignments,
          },
        });
        await UpdateFormFieldValueMutation.updateFormFieldValuesOnSuccess(queryClient)({
          formFieldValue: data.updatedFormFieldValue,
          checklistTaskAssignments: data.checklistTaskAssignments,
        });
      }

      await queryClient.invalidateQueries({ queryKey: MergeTagsByChecklistRevisionIdQuery.key });
    },
    [$rootScope, currentUser?.id, dispatch, queryClient],
  );

  const listenToFormFieldValueUpdate = useCallback(
    (checklistId: Muid) => {
      logger.info('live update is enabled - connecting to ably');

      const channelName = ablyService.getChannelNameForChecklist(checklistId);
      const channel = ablyService.getChannel(channelName);
      channel.subscribe(AblyEvent.EventType.FormFieldValueUpdated, formFieldValueUpdateListener);

      return () => {
        channel.unsubscribe(AblyEvent.EventType.FormFieldValueUpdated);
      };
    },
    [formFieldValueUpdateListener],
  );

  return useMemo(
    () => ({
      listenToFormFieldValueUpdate,
    }),
    [listenToFormFieldValueUpdate],
  );
};
