import { Muid } from '@process-street/subgrade/core';
import { FieldType, TemplateRevision } from '@process-street/subgrade/process';
import * as React from 'react';
import { BlvdSelect, BlvdSelectHelpers } from 'components/design/BlvdSelect';
import { AiTaskWidgetSelectOption } from 'pages/templates/_id/components/ai-task-template-form/components/ai-task-widget-select/ai-task-widget-select-option';
import { AiTaskWidgetSelectValue } from 'pages/templates/_id/components/ai-task-template-form/components/ai-task-widget-select/ai-task-widget-select-value';
import { useCodeEditorWidgetOptions } from 'pages/templates/_id/components/code-task-template-editor/use-code-editor-widget-options';
import { useField, useFormikContext } from 'formik';
import { FormControl } from '@chakra-ui/react';
import { FormErrorMessage } from 'components/design/next';

export type WidgetOption = { label: string; value: Muid; fieldType?: FieldType };

export type WidgetSelectorProps = {
  templateRevisionId: TemplateRevision['id'];
  index: number;
  isReadOnly: boolean;
};

export const WidgetSelector: React.FC<WidgetSelectorProps> = ({ index, templateRevisionId, isReadOnly }) => {
  const { taskWidgetOptions } = useCodeEditorWidgetOptions({ templateRevisionId });
  const { handleSubmit } = useFormikContext();
  const [field, meta, { setValue }] = useField(`mappings[${index}].value`);

  const selectedOption = field.value
    ? taskWidgetOptions?.flatMap(option => option.options).find(option => option.value === field.value)
    : undefined;

  const handleChange = async (option: WidgetOption) => {
    await setValue(option.value);
    handleSubmit();
  };

  return (
    <FormControl isInvalid={!!meta.error && meta.touched}>
      <BlvdSelect<WidgetOption>
        {...field}
        isSearchable
        enableSearchAutoFocus
        options={taskWidgetOptions ?? []}
        value={selectedOption}
        onChange={async option => {
          if (BlvdSelectHelpers.isOptionType<WidgetOption>(option)) {
            await handleChange(option);
          }
        }}
        isDisabled={isReadOnly}
        components={components}
      />
      {meta.error ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
    </FormControl>
  );
};

const components = {
  Option: AiTaskWidgetSelectOption,
  SingleValue: AiTaskWidgetSelectValue,
};
