import { Button } from 'components/design/next';
import { ButtonGroup } from '@chakra-ui/react';
import * as React from 'react';
import { CodeTaskTemplateEditorMode } from 'pages/templates/_id/components/code-task-template-editor/machine/code-task-machine';

export type SwitchModeButtonsProps = {
  mode: CodeTaskTemplateEditorMode;
  onSwitchMode: (mode: CodeTaskTemplateEditorMode) => void;
  isTestModeEnabled: boolean;
};

export const SwitchModeButtons: React.FC<SwitchModeButtonsProps> = ({ mode, onSwitchMode, isTestModeEnabled }) => {
  return (
    <ButtonGroup
      width="full"
      variant="outline"
      justifyContent="flex-end"
      isAttached={true}
      spacing={0}
      colorScheme="gray"
      mt={4}
    >
      <Button
        color={mode === 'edit' ? 'blue.500' : 'gray.500'}
        borderColor={mode === 'edit' ? 'blue.500' : 'gray.500'}
        borderWidth="1px"
        onClick={() => onSwitchMode('edit')}
      >
        Setup
      </Button>
      <Button
        color={mode === 'test' ? 'blue.500' : 'gray.500'}
        borderColor={mode === 'test' ? 'blue.500' : 'gray.500'}
        borderLeftColor={'blue.500'}
        borderWidth="1px"
        onClick={() => onSwitchMode('test')}
        isDisabled={!isTestModeEnabled}
      >
        Test
      </Button>
    </ButtonGroup>
  );
};
