import {
  SendRichEmailFormFieldWidget,
  SendRichEmailFormFieldValue,
  SendRichEmailFieldValue,
  FormFieldValue,
} from '@process-street/subgrade/process';
import { Muid, User } from '@process-street/subgrade/core';
import { UpdateFormFieldValueMutationResponse } from 'features/widgets/query-builder';
import { ActorRefFrom, assign, createMachine, forwardTo, sendParent, spawn, StateFrom } from 'xstate';
import { FormFieldMachineBuilderProps, WithFormFieldMachineEvent } from '../../../types';
import { makeValidationMachine, ValidationActorRef, ValidationParentEvent } from '../validation-machine';
import {
  makeRulesEngineTargetMachine,
  sendRulesActorFormFieldValueUpdate,
} from '../../form-response-body/rules-engine-machine';
import { makeUpdateFormFieldValueMutation } from '../make-update-form-field-value-mutation';
import { FormResponseErrorToasts } from 'pages/responses/_id/utils/form-response-error-toasts';
import { AxiosError } from 'axios';
import { makeErrorLoggerAction } from 'app/utils/machines';
import _isEmpty from 'lodash/isEmpty';
import {
  makeSendEmailValidationSchema,
  makeSendRichEmailFieldValidationSchema,
} from 'app/pages/forms/_id/edit/components/content/email-content/email-content-schema';
import { DeleteTemplateEmailAttachmentMutation } from 'app/features/widgets/components/send-rich-email/query-builder/delete-template-email-attachment';
import { FinishEmailAttachmentChecklistUploadMutationResponse } from 'app/features/widgets/components/send-rich-email/query-builder/finish-email-attachment-checklist-upload-mutation';
import { match } from 'ts-pattern';

export type ValueType = SendRichEmailFieldValue;

export type Context = {
  currentUser?: User;
  value: SendRichEmailFieldValue;
  widget: SendRichEmailFormFieldWidget;
  formFieldValue: SendRichEmailFormFieldValue | undefined;
  formFieldValidationActor: ValidationActorRef<SendRichEmailFieldValue | undefined>;
  sendEmailValidationActor: ValidationActorRef<SendRichEmailFieldValue | undefined>;
  rulesEngineTargetActor: ActorRefFrom<typeof makeRulesEngineTargetMachine>;
  inputNode: HTMLElement | null;
  checklistRevisionId: Muid;
  id: string;
  isReadOnly: boolean;
};

export type UploadEventParams = {
  fileName: string;
  mimeType: string;
  file: File;
  data: string | ArrayBuffer;
  onProgress: (value: number | undefined) => void;
  formFieldValue: FormFieldValue;
};

export type Event =
  | Exclude<
      WithFormFieldMachineEvent<ValidationParentEvent, SendRichEmailFieldValue, SendRichEmailFieldValue | undefined>,
      { type: 'CHANGE' | 'UPDATE_VALUE' }
    >
  | { type: 'CHANGE' | 'UPDATE_VALUE'; value: SendRichEmailFieldValue | undefined }
  | { type: 'DELETE_EMAIL_ATTACHMENT'; attachmentId: Muid }
  | {
      type: 'DELETE_ATTACHMENT_COMPLETE';
      status: AttachmentEventStatus;
      data: DeleteTemplateEmailAttachmentMutation.Response;
    }
  | { type: 'SET_CURRENT_USER'; currentUser: User }
  | { type: 'SEND_EMAIL'; widgetId: Muid }
  | { type: 'SEND_PLAIN_TEXT_EMAIL'; value: SendRichEmailFieldValue }
  | { type: 'EMAIL_SENT'; value: SendRichEmailFieldValue }
  | { type: 'UPLOAD_EMAIL_ATTACHMENT'; uploadParams: UploadEventParams }
  | {
      type: 'UPLOAD_ATTACHMENT_COMPLETE';
      status: AttachmentEventStatus;
      data: FinishEmailAttachmentChecklistUploadMutationResponse;
    };

export enum AttachmentEventStatus {
  Success = 'Success',
  Error = 'Error',
}

const parseConfigToValue = (formFieldValue: SendRichEmailFormFieldWidget): SendRichEmailFieldValue => ({
  subject: formFieldValue.config.subject,
  to: formFieldValue.config.to,
  cc: formFieldValue.config.cc,
  bcc: formFieldValue.config.bcc,
  body:
    formFieldValue.config.emailFormat === 'PlainText'
      ? formFieldValue.config.plainTextBody
      : (formFieldValue.config.body as string | undefined),
  attachments: formFieldValue.config.attachments,
});

export const makeSendEmailWidgetMachine = ({
  formFieldWidget,
  formFieldValue,
  autoFocus,
  checklistRevisionId,
  sharedContext,
  isEditable,
  inputNode,
}: FormFieldMachineBuilderProps<SendRichEmailFormFieldWidget>) => {
  const formFieldValidationSchema = makeSendRichEmailFieldValidationSchema({ required: formFieldWidget.required });
  const sendEmailValidationSchema = makeSendEmailValidationSchema({ required: formFieldWidget.required });
  const value: SendRichEmailFieldValue =
    !formFieldValue || _isEmpty(formFieldValue?.fieldValue)
      ? parseConfigToValue(formFieldWidget)
      : formFieldValue.fieldValue;
  const initialState = isEditable ? 'enabled' : 'disabled';

  const id = `send-email-widget-machine:${formFieldWidget.id}`;

  const isEmpty = (value: SendRichEmailFieldValue | undefined) => {
    if (!value) return true;
    return false;
  };

  return createMachine(
    {
      context: (): Context => ({
        id,
        checklistRevisionId,
        currentUser: undefined,
        inputNode,
        value,
        widget: formFieldWidget,
        formFieldValue,
        formFieldValidationActor: spawn(
          makeValidationMachine({
            validationSchema: formFieldValidationSchema,
            initialValue: value,
            isEmpty,
          }),
          {
            name: `form-field-validation-actor:${formFieldWidget.id}`,
          },
        ),
        sendEmailValidationActor: spawn(
          makeValidationMachine({
            validationSchema: sendEmailValidationSchema,
            initialValue: value,
            isEmpty,
          }),
          {
            name: `send-email-widget-validation-actor:${formFieldWidget.id}`,
          },
        ),
        rulesEngineTargetActor: spawn(
          makeRulesEngineTargetMachine({ type: 'widget', widgetHeaderGroupId: formFieldWidget.header.group.id }),
          { name: 'hidden-by-rule-actor', sync: true },
        ),
        isReadOnly: !isEditable,
      }),
      schema: {
        context: {} as Context,
        events: {} as Event,
        services: {} as {
          updateFormFieldValue: {
            data: UpdateFormFieldValueMutationResponse;
          };
        },
      },
      tsTypes: {} as import('./send-email-widget-machine.typegen').Typegen0,
      id,
      predictableActionArguments: true,
      preserveActionOrder: true,
      type: 'parallel',
      on: {
        SET_NODE: { actions: ['assignNode'] },
        SCROLL_INTO_VIEW: { actions: ['scrollIntoView'] },
      },
      states: {
        input: {
          initial: initialState,
          states: {
            disabled: {
              on: {
                ENABLE: {
                  target: 'enabled',
                },
              },
            },
            enabled: {
              initial: 'focused',
              on: { DISABLE: 'disabled' },
              states: {
                focused: {
                  on: {
                    CHANGE: { actions: ['updateValue', 'sendRulesActorFormFieldValueUpdate'] },
                    SET_CURRENT_USER: { actions: ['assignCurrentUser'] },
                    SEND_PLAIN_TEXT_EMAIL: { actions: ['updateSendValues', 'sendRulesActorFormFieldValueUpdate'] },
                  },
                },
              },
            },
          },
        },

        emailSending: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                SEND_EMAIL: { target: 'sending', actions: ['sendParentSendEmailAction'] },
              },
            },
            sending: {
              on: {
                EMAIL_SENT: {
                  target: 'idle',
                  actions: ['updateSendValues', 'sendRulesActorFormFieldValueUpdate'],
                },
              },
            },
          },
        },
        deletingEmailAttachment: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                DELETE_EMAIL_ATTACHMENT: { target: 'deleting', actions: ['sendParentDeleteAttachment'] },
              },
            },
            deleting: {
              on: {
                DELETE_ATTACHMENT_COMPLETE: [
                  {
                    cond: 'isSuccessfulAttachmentEvent',
                    target: 'idle',
                    actions: ['updateAttachmentValues', 'sendRulesActorFormFieldValueUpdate'],
                  },
                  {
                    target: 'idle',
                  },
                ],
              },
            },
          },
        },
        uploadingEmailAttachment: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                UPLOAD_EMAIL_ATTACHMENT: { target: 'uploading', actions: ['sendParentUploadAttachment'] },
              },
            },
            uploading: {
              on: {
                UPLOAD_ATTACHMENT_COMPLETE: [
                  {
                    cond: 'isSuccessfulAttachmentEvent',
                    target: 'idle',
                    actions: ['updateAttachmentValues', 'sendRulesActorFormFieldValueUpdate'],
                  },
                  {
                    target: 'idle',
                  },
                ],
              },
            },
          },
        },
        autoFocus: {
          initial: autoFocus ? 'enabled' : 'disabled',
          states: { disabled: {}, enabled: {} },
        },

        mutation: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                UPDATE_VALUE: 'updating',
              },
            },
            updating: {
              invoke: {
                src: 'updateFormFieldValue',
                onDone: {
                  target: 'idle',
                  actions: ['sendParentFormFieldValueUpdate'],
                },
                onError: {
                  target: 'idle',
                  actions: ['logError', 'showErrorToast'],
                },
              },
            },
          },
        },
        // This state is a kind of controller to forward events up and down
        // Since it is a parallel state, it can listen for events without blocking or getting blocked by nested states
        validation: {
          initial: 'enabled',
          states: {
            enabled: {
              on: {
                UPLOAD_ATTACHMENT_COMPLETE: { actions: 'forwardAttachmentToValidation' },
                CHANGE: { actions: 'forwardToValidation' },
                DELETE_ATTACHMENT_COMPLETE: { actions: 'forwardAttachmentToValidation' },

                REVEAL_INVALID: { actions: 'forwardToValidation' },
                BLUR: { actions: 'forwardToValidation' },
                VALID: { actions: 'restoreValidationWithParent' },
                INVALID: { actions: 'restoreValidationWithParent' },
                HIDE: { actions: 'restoreValidationWithParent', target: 'disabled' },
              },
            },
            disabled: {
              on: {
                REVEAL: { target: 'enabled', actions: 'restoreValidationWithParent' },
              },
            },
          },
        },
        sendValidation: {
          initial: 'enabled',
          states: {
            enabled: {
              on: {
                EMAIL_SENT: { actions: 'forwardToSendValidation' },
                SEND_PLAIN_TEXT_EMAIL: { actions: 'forwardToSendValidation' },
                REVEAL_INVALID: { actions: 'forwardToSendValidation' },
                BLUR: { actions: 'forwardToSendValidation' },
                VALID: { actions: 'restoreValidationWithParent' },
                INVALID: { actions: 'restoreValidationWithParent' },
                HIDE: { actions: 'restoreValidationWithParent', target: 'disabled' },
              },
            },
            disabled: {
              on: {
                REVEAL: { target: 'enabled', actions: 'restoreValidationWithParent' },
              },
            },
          },
        },
      },
    },
    {
      services: {
        updateFormFieldValue: async (_, event) =>
          makeUpdateFormFieldValueMutation({
            queryClient: sharedContext.queryClient,
            body: {
              checklistRevisionId,
              widgetId: formFieldWidget.id,
              ...event.value,
            },
          }).execute(),
      },
      actions: {
        assignCurrentUser: assign({ currentUser: (_, event) => event.currentUser }),
        logError: makeErrorLoggerAction(id),
        assignNode: assign({ inputNode: (_, event) => event.node }),
        updateValue: assign({
          value: (_, event) => event.value as SendRichEmailFieldValue,
        }),
        updateAttachmentValues: assign({
          value: (ctx, event) => {
            if (event.status === AttachmentEventStatus.Success) {
              return match(event)
                .with({ type: 'UPLOAD_ATTACHMENT_COMPLETE' }, ({ data }) => ({
                  ...ctx.value,
                  attachments: ctx.value.attachments ? [...ctx.value.attachments, data] : [data],
                }))
                .with({ type: 'DELETE_ATTACHMENT_COMPLETE' }, ({ data }) => ({
                  ...ctx.value,
                  attachments: ctx.value?.attachments?.filter(attachment => attachment.attachment.id !== data.id),
                }))
                .otherwise(() => ctx.value);
            }
            return ctx.value;
          },
        }),
        updateSendValues: assign({
          value: (ctx, _) =>
            ({
              ...ctx.value,
              lastSentByUserId: ctx?.currentUser?.id,
              lastSentDate: Date.now(),
            } as SendRichEmailFieldValue),
        }),
        showErrorToast: (_, event) => FormResponseErrorToasts.showFormFieldUpdateErrorToast(event.data as AxiosError),
        forwardToValidation: forwardTo(ctx => ctx.formFieldValidationActor),
        forwardAttachmentToValidation: (ctx, evt) => {
          if ('data' in evt) {
            return ctx.formFieldValidationActor.send({ type: 'CHANGE', value: ctx.value });
          }
        },
        forwardToSendValidation: (ctx, evt) => {
          if ('value' in evt) {
            return ctx.sendEmailValidationActor.send({ type: 'CHANGE', value: evt.value });
          }
        },
        restoreValidationWithParent: sendParent((ctx, _evt) => {
          if (
            ctx.formFieldValidationActor.getSnapshot()?.matches('valid') &&
            ctx.sendEmailValidationActor.getSnapshot()?.matches('valid')
          ) {
            return { type: 'VALID_WIDGET', widgetId: formFieldWidget.id };
          }
          return { type: 'INVALID_WIDGET', widgetId: formFieldWidget.id };
        }),

        sendRulesActorFormFieldValueUpdate: (ctx, event) =>
          sendRulesActorFormFieldValueUpdate({
            formFieldValue: {
              ...formFieldValue!,
              fieldValue: 'value' in event ? (event.value as SendRichEmailFieldValue) : ctx.value,
            },
            formFieldWidget,
          }),
        scrollIntoView: ctx => {
          ctx.inputNode?.scrollIntoView();
        },
        sendParentFormFieldValueUpdate: sendParent((_ctx, evt) => ({
          type: 'FORM_FIELD_VAUE_UPDATE',
          formFieldValue: evt.data.formFieldValue,
        })),
        sendParentSendEmailAction: sendParent((ctx, _) => ({
          type: 'SEND_EMAIL',
          widgetId: ctx.widget.id,
        })),
        sendParentDeleteAttachment: sendParent((ctx, evt) => ({
          type: 'DELETE_EMAIL_ATTACHMENT',
          attachmentId: evt.attachmentId,
          widgetId: ctx.widget.id,
        })),
        sendParentUploadAttachment: sendParent((_, evt) => ({
          type: 'UPLOAD_EMAIL_ATTACHMENT',
          uploadParams: evt.uploadParams,
        })),
      },
      guards: {
        isSuccessfulAttachmentEvent: (_, event) => event.status === AttachmentEventStatus.Success,
      },
    },
  );
};

export type SendEmailWidgetMachine = ReturnType<typeof makeSendEmailWidgetMachine>;
export type SendEmailWidgetMachineState = StateFrom<SendEmailWidgetMachine>;
export type SendEmailWidgetActor = ActorRefFrom<SendEmailWidgetMachine>;

export namespace SendEmailSelectors {
  export const getChecklistRevisionId = (state: SendEmailWidgetMachineState) => state.context.checklistRevisionId;
  export const getFormFieldValue = (state: SendEmailWidgetMachineState) => state.context.formFieldValue;
  export const getValue = (state: SendEmailWidgetMachineState) => state.context.value;
  export const getWidget = (state: SendEmailWidgetMachineState) => state.context.widget;
  export const getIsReadOnly = (state: SendEmailWidgetMachineState) => state.context.isReadOnly;
  export const getValidationActor = (state: SendEmailWidgetMachineState) => state.context.formFieldValidationActor;
  export const getSendEmailValidationActor = (state: SendEmailWidgetMachineState) =>
    state.context.sendEmailValidationActor;
  export const getCurrentNode = (state: SendEmailWidgetMachineState) => state.context.inputNode;
  export const getIsUploading = (state: SendEmailWidgetMachineState) =>
    state.matches('uploadingEmailAttachment.uploading');
}
