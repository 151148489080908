import { Muid, User } from '@process-street/subgrade/core';
import { FieldType, FormFieldValue } from '@process-street/subgrade/process';
import { ValueUpdateMessage } from 'app/hooks/use-form-field-value-listeners';
import { AblyEvent } from 'app/pusher/ably-event';
import { ablyService } from 'app/pusher/ably.service';
import { ActorRefFrom, createMachine, StateFrom } from 'xstate';
import { FormResponseMachineWidgetReceptionist, makeWidgetKey } from '../../../form-response-machine-receptionist';
import { makeErrorLoggerAction } from 'app/utils/machines';
import { QueryClient } from 'react-query';
import { GetFormFieldValuesByChecklistRevisionIdQuery } from 'app/features/widgets/query-builder';
import { MergeTagsByChecklistRevisionIdQuery } from 'app/features/merge-tags/query-builder';
import { match } from 'ts-pattern';

type Context = {
  checklistId: Muid;
  currentUser: User;
};

type Event = { type: 'ABLY_EVENT_FORM_FIELD_UPDATED_RECEIVED'; data: FormFieldValue } | { type: 'STOP_LISTENING' };

export type ChecklistRealTimeManagerMachineBuilderProps = {
  checklistId: Muid;
  currentUser: User;
  queryClient: QueryClient;
};

export const makeChecklistRealTimeManagerMachine = ({
  checklistId,
  currentUser,
  queryClient,
}: ChecklistRealTimeManagerMachineBuilderProps) => {
  const id = `form-field-real-time-manager-machine:${checklistId}`;

  return createMachine(
    {
      id,
      initial: 'listening',
      context: {
        checklistId,
        currentUser,
      },
      schema: {
        context: {} as Context,
        events: {} as Event,
      },
      tsTypes: {} as import('./checklist-real-time-manager-machine.typegen').Typegen0,
      predictableActionArguments: true,
      preserveActionOrder: true,
      type: 'parallel',
      states: {
        listening: {
          invoke: {
            src: 'listenToAblyMessages',
            onError: {
              actions: 'logError',
            },
          },
          on: {
            ABLY_EVENT_FORM_FIELD_UPDATED_RECEIVED: {
              actions: ['sendSyncWidget'],
            },
            STOP_LISTENING: {
              target: 'stopped',
            },
          },
        },
        stopped: {
          type: 'final',
        },
      },
    },
    {
      actions: {
        sendSyncWidget: (_, event) => {
          const widgetId = event.data.formFieldWidget.id;
          const actor = FormResponseMachineWidgetReceptionist.get(makeWidgetKey(widgetId));
          actor?.send({ type: 'SYNC_FORM_FIELD_VALUE', value: event.data });
        },

        logError: makeErrorLoggerAction(id),
      },
      services: {
        listenToAblyMessages: ctx => (sendBack, _onReceive) => {
          const channelName = ablyService.getChannelNameForChecklist(ctx.checklistId);
          const channel = ablyService.getChannel(channelName);

          const formFieldValueUpdatedHandler = async (message: { data: string }) => {
            const data: ValueUpdateMessage = JSON.parse(message.data);

            const updatedFormFieldValue = data?.updatedFormFieldValue;

            if (!updatedFormFieldValue) return;

            const hasDefaultValue =
              'hasDefaultValue' in updatedFormFieldValue.fieldValue
                ? updatedFormFieldValue.fieldValue.hasDefaultValue
                : false;

            // Don't send back the event if the update was made by the current user unless it has a default value
            if (updatedFormFieldValue.audit.updatedBy.id === ctx.currentUser.id && !hasDefaultValue) return;

            sendBack({ type: 'ABLY_EVENT_FORM_FIELD_UPDATED_RECEIVED', data: updatedFormFieldValue });

            await queryClient.invalidateQueries(
              GetFormFieldValuesByChecklistRevisionIdQuery.getKey({
                checklistRevisionId: updatedFormFieldValue.checklistRevision.id,
              }),
            );
            await queryClient.invalidateQueries({ queryKey: MergeTagsByChecklistRevisionIdQuery.key });
          };

          const formFieldValueDeletedHandler = async (message: { data: string }) => {
            const data: ValueUpdateMessage = JSON.parse(message.data);

            const updatedFormFieldValue = data?.updatedFormFieldValue;

            if (!updatedFormFieldValue) return;

            const hasDefaultValue =
              'hasDefaultValue' in updatedFormFieldValue.fieldValue
                ? updatedFormFieldValue.fieldValue.hasDefaultValue
                : false;

            // Don't send back the event if the update was made by the current user unless it has a default value
            if (updatedFormFieldValue.audit.updatedBy.id === ctx.currentUser.id && !hasDefaultValue) return;
            const newFieldValue = match(updatedFormFieldValue)
              .with({ formFieldWidget: { fieldType: FieldType.File } }, () => ({}))
              .otherwise(() => ({ ...updatedFormFieldValue.fieldValue, value: '' }));

            sendBack({
              type: 'ABLY_EVENT_FORM_FIELD_UPDATED_RECEIVED',
              data: {
                ...updatedFormFieldValue,
                fieldValue: newFieldValue,
              },
            });

            await queryClient.invalidateQueries(
              GetFormFieldValuesByChecklistRevisionIdQuery.getKey({
                checklistRevisionId: updatedFormFieldValue.checklistRevision.id,
              }),
            );
            await queryClient.invalidateQueries({ queryKey: MergeTagsByChecklistRevisionIdQuery.key });
          };

          channel.subscribe(AblyEvent.EventType.FormFieldValueUpdated, formFieldValueUpdatedHandler);
          channel.subscribe(AblyEvent.EventType.FormFieldValueDeleted, formFieldValueDeletedHandler);

          // Return cleanup logic for when this state (or machine) exits
          return () => {
            channel.unsubscribe(AblyEvent.EventType.FormFieldValueUpdated, formFieldValueUpdatedHandler);
            channel.unsubscribe(AblyEvent.EventType.FormFieldValueDeleted, formFieldValueDeletedHandler);
          };
        },
      },
    },
  );
};

export type ChecklistRealTimeManagerMachine = ReturnType<typeof makeChecklistRealTimeManagerMachine>;
export type ChecklistRealTimeManagerState = StateFrom<ChecklistRealTimeManagerMachine>;
export type ChecklistRealTimeManagerActorRef = ActorRefFrom<ChecklistRealTimeManagerMachine>;
