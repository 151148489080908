import * as React from 'react';
import { EmbedContentWidgetMachineActorRef, EmbedContentWidgetmachineHooks } from './embed-content-widget-machine';
import { useSelector } from '@xstate/react';
import { ChecklistWidgetMachineSelectors } from '../../../utils/widget-machine-selectors';
import { ChecklistEmbedWidget } from 'app/components/widgets/embed/checklist-embed-widget';

export type EmbedContentWidgetProps = {
  actor: EmbedContentWidgetMachineActorRef;
};

export const EmbedContentWidget = ({ actor }: EmbedContentWidgetProps) => {
  const widget = EmbedContentWidgetmachineHooks.useWidget(actor);
  const isHiddenByRule = useSelector(actor, ChecklistWidgetMachineSelectors.getIsHiddenByRule);

  return !isHiddenByRule && <ChecklistEmbedWidget widget={widget} />;
};
