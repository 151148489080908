import { StateFrom } from 'xstate';
import { FormResponsePageMachine } from './form-response-page-machine';
import { QueryActorSelectors } from 'app/utils/query-builder';
import { Muid } from '@process-street/subgrade/core';
import { isDateFormFieldWidget, TaskTemplate } from '@process-street/subgrade/process';
import { DueDateHelper } from 'app/features/dynamic-due-dates/services/due-date-helper';
import _groupBy from 'lodash/groupBy';

export type State = StateFrom<FormResponsePageMachine>;

export const FormResponsePageMachineSelectors = {
  getValue: (state: State) => state.value,
  getFormResponseActor: (state: State) => state.context.formResponseActor,
  getTasks: (state: State) => QueryActorSelectors.getQueryData(state.context.tasksQuery),
  getApprovalRules: (state: State) => QueryActorSelectors.getQueryData(state.context.approvalRulesQuery),
  getApprovals: (state: State) => QueryActorSelectors.getQueryData(state.context.approvalsQuery),
  getOneOffTasks: (state: State) => QueryActorSelectors.getQueryData(state.context.oneOffTasksQuery),
  getChecklist: (state: State) => QueryActorSelectors.getQueryData(state.context.checklistQuery),
  getChecklistRevision: (state: State) => QueryActorSelectors.getQueryData(state.context.checklistRevisionQuery),
  getTaskAssignments: (taskId: Muid) => (state: State) => {
    const taskAssignments = QueryActorSelectors.getQueryData(state.context.taskAssignmentsQuery) ?? [];
    return taskAssignments.filter(taskAssignment => taskAssignment.task.id === taskId);
  },
  getTaskAssignmentsByTaskMap: (state: State) => {
    const taskAssignments = QueryActorSelectors.getQueryData(state.context.taskAssignmentsQuery) ?? [];
    return _groupBy(taskAssignments, taskAssignment => taskAssignment.task.id);
  },
  getDueDateRules: (state: State) => QueryActorSelectors.getQueryData(state.context.dueDateRulesQuery),
  getDueDateRuleByTaskTemplate: (taskTemplate: TaskTemplate) => (state: State) => {
    const dueDateRules = QueryActorSelectors.getQueryData(state.context.dueDateRulesQuery) ?? [];
    return DueDateHelper.findRuleForTask(dueDateRules, taskTemplate);
  },
  getTaskTemplates: (state: State) => QueryActorSelectors.getQueryData(state.context.taskTemplatesQuery) ?? [],
  getDateWidgets: (state: State) => {
    const widgets = QueryActorSelectors.getQueryData(state.context.widgetsQuery) ?? [];
    return widgets.filter(isDateFormFieldWidget);
  },
  getChecklistTaskById: (taskTemplateId: Muid) => (state: State) => {
    const taskTemplates = QueryActorSelectors.getQueryData(state.context.tasksQuery) ?? [];
    return taskTemplates.find(taskTemplate => taskTemplate.id === taskTemplateId);
  },
};
