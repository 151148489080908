import angular from 'angular';
import { reactToAngular } from 'components/react-root';
import { WidgetsApi } from './api/widgets.api';
import { Widgets } from './api/widgets.resource';
import { CrossLinkWidget } from './cross-link';
import { ChecklistEmbedWidget } from './embed/checklist-embed-widget';
import { TemplateEmbedWidget } from './embed/template-embed-widget';
import { ChecklistFileWidget } from './file/ChecklistFileWidget/ChecklistFileWidget';
import { ChecklistMembersFieldComponent } from './form-field/members/checklist/checklist-members-field.component';
import { TemplateMembersFieldComponent } from './form-field/members/template/template-members-field.component';
import { ChecklistImageWidget } from './image/ChecklistImageWidget';
import { WidgetActionsImpl } from './store/widget.actions';
import { ChecklistTextWidget } from './text/ChecklistTextWidget';
import { ChecklistVideoWidget } from './video/ChecklistVideoWidget';
import { EmbeddedVideo } from './video/EmbeddedVideo';
import { TemplateNumberFormField } from './form-field/number/template';
import {
  SendRichEmailChecklistWidgetV1 as SendRichEmailChecklistWidget,
  SendRichEmailTemplateWidget,
} from 'features/widgets/components/send-rich-email';
import { ApprovalSubjectSendRichEmail } from 'features/widgets/components/approval-subject-send-rich-email';
import { EmailTemplateWidget } from 'features/widgets/components/email';
import { UnsavedChangesIcon } from 'components/widgets/form-field/common/unsaved-changes-icon/unsaved-changes-icons';
import { AiGenerateTextWidgetContentMenuModule } from 'features/widgets/components/ai/ai-generate-text-widget-content-menu/ai-generate-text-widget-content-menu.module';

export const WidgetsModule = angular
  .module('widgets', [AiGenerateTextWidgetContentMenuModule])
  .component('psTemplateEmbedWidget', reactToAngular(TemplateEmbedWidget, ['widget', 'disabled', 'onUpdate']))
  .component('psChecklistEmbedWidget', reactToAngular(ChecklistEmbedWidget, ['widget']))
  .component('psChecklistImageWidget', reactToAngular(ChecklistImageWidget, ['widget']))
  .component('psTemplateMembersFormField', TemplateMembersFieldComponent)
  .component('psChecklistMembersFormField', ChecklistMembersFieldComponent)
  .component('psChecklistVideoWidget', reactToAngular(ChecklistVideoWidget, ['widget']))
  .component('psEmbeddedVideo', reactToAngular(EmbeddedVideo, ['code', 'service']))
  .component('psChecklistTextWidget', reactToAngular(ChecklistTextWidget, ['widget', 'checklistRevision', 'task']))
  .component('psChecklistFileWidget', reactToAngular(ChecklistFileWidget, ['user', 'widget']))
  .component(
    'psCrossLinkWidget',
    reactToAngular(CrossLinkWidget, ['widget', 'editable', 'onUpdate', 'searchParams', 'isLoading']),
  )
  .component('psTemplateNumberFormField', reactToAngular(TemplateNumberFormField, ['isEditable', 'widget', 'onUpdate']))
  .component(
    'psSendRichEmailTemplateWidget',
    reactToAngular(SendRichEmailTemplateWidget, ['widget', 'isDisabled', 'onUpdate', 'templateRevision']),
  )
  .component(
    'psSendRichEmailChecklistWidget',
    reactToAngular(SendRichEmailChecklistWidget, [
      'widget',
      'checklistRevisionId',
      'formFieldValueId',
      'onUpdate',
      'taskId',
      'templateRevisionId',
      'readOnly',
    ]),
  )
  .component(
    'psEmailTemplateWidget',
    reactToAngular(EmailTemplateWidget, ['widget', 'isDisabled', 'onUpdate', 'templateRevision']),
  )
  .component(
    'psApprovalSubjectTaskWidgetSendRichEmailRenderer',
    reactToAngular(ApprovalSubjectSendRichEmail, ['formFieldValue', 'widget']),
  )
  .component('psUnsavedChangesIcon', reactToAngular(UnsavedChangesIcon))

  .factory('Widgets', Widgets)
  .service('WidgetsApi', WidgetsApi)
  .factory('WidgetActions', WidgetActionsImpl).name;
