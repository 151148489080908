import { Checklist, ChecklistMigrationStatus } from '@process-street/subgrade/process';
import { useToast } from 'app/components/design/next';
import { GetActiveChecklistRevisionByChecklistIdQuery } from 'app/features/checklist-revisions/query-builder';
import { useGetNewestTemplateRevisionsByTemplateIdQuery } from 'app/features/template/query-builder';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

export const useNotifyPendingMigration = (checklist: Checklist) => {
  const toast = useToast();
  const activeChecklistRevisionsQuery = GetActiveChecklistRevisionByChecklistIdQuery.useQuery({
    checklistId: checklist.id,
  });

  const newestChecklistRevisionsQuery = useGetNewestTemplateRevisionsByTemplateIdQuery({
    templateId: checklist.template.id,
  });

  const debounceNotifyPendingMigration = useDebouncedCallback(() => {
    if (!newestChecklistRevisionsQuery.data || !activeChecklistRevisionsQuery.data) {
      console.warn('`newestChecklistRevisionsQuery.data` or `activeChecklistRevisionsQuery.data` is missing');
      return;
    }

    const [newestTemplateRevision] = newestChecklistRevisionsQuery.data ?? [];
    // We can't check if there is a new revision if there are no revisions
    if (!newestTemplateRevision) return;
    // Do nothing if the migration is in progress or schedules
    if (checklist.migrationStatus !== ChecklistMigrationStatus.Inactive) return;

    // Do nothing if the current revision is the newest
    if (newestTemplateRevision.id === activeChecklistRevisionsQuery.data.templateRevision.id) return;

    toast({
      status: 'warning',
      title: 'This workflow run is based on an old version of the workflow',
      description: 'Click the Update to Latest button in the menu to update this workflow to the latest version.',
    });
  }, 100);

  React.useEffect(debounceNotifyPendingMigration, [debounceNotifyPendingMigration]);
};
