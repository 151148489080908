import * as React from 'react';
import { useActor } from '@xstate/react';
import { HiddenFormFieldActor } from './hidden-form-field-machine';
import { HStack, InputGroup, Text, VStack } from 'components/design/next';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { FormFieldLabel } from '../common/form-field-label';
import { SettingsModalFields } from 'pages/forms/_id/edit/components/form-fields/common/settings/fields';
import { WidgetActorProvider } from '../../../../shared/widget-context';
import { hiddenSettingsSchema } from './hidden-form-field-schema';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { FormFieldRecentlyMovedIndicator } from '../common/form-field-recently-moved-indicator';
import { InlineSettings } from '../common/settings/inline-settings';
import { ViewModeInteractionWrapper } from '../../view-mode-interaction-wrapper/view-mode-interaction-wrapper';
import { TaskTemplate, TemplateType } from '@process-street/subgrade/process';
import { WidgetTaskAutomationIndicator } from 'app/pages/templates/_id/components/ai-task-template-widget-indicator';
import { PreventClickOverlay } from '../common/prevent-click-overlay';

export type HiddenFormFieldWidgetProps = {
  isFirst: boolean;
  isLast: boolean;
  actor: HiddenFormFieldActor;
};

export const HiddenFormFieldWidget: React.FC<React.PropsWithChildren<HiddenFormFieldWidgetProps>> = ({
  actor,
  isFirst,
  isLast,
}) => {
  const [state, send] = useActor(actor);
  const { widget, template, labelActor, recentlyMovedFrom, isReadOnly } = state.context;
  const ref = React.useRef<HTMLDivElement | null>(null);

  const isWorkflow = template.templateType === TemplateType.Playbook;

  const handleSelectTaskTemplate = (taskTemplate: TaskTemplate) => send({ type: 'SELECT_TASK_TEMPLATE', taskTemplate });

  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer>
        <VStack alignItems="flex-start" flex="1">
          {recentlyMovedFrom && <FormFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}

          <HStack justifyContent="space-between" w="full">
            {labelActor && <FormFieldLabel isReadOnly={isReadOnly} actor={labelActor} />}
            {isWorkflow && (
              <HStack justifyContent="flex-end" w="auto">
                <WidgetTaskAutomationIndicator widget={widget} onSelectTaskTemplate={handleSelectTaskTemplate} />
              </HStack>
            )}
          </HStack>
          <ViewModeInteractionWrapper templateId={template.id}>
            <InputGroup
              w="full"
              ref={node => {
                ref.current = node;
                if (node && !state.context.inputNode) {
                  send({ type: 'SET_NODE', node });
                }
              }}
              scrollMarginBottom={17}
            >
              <InlineSettings widget={widget} schema={hiddenSettingsSchema}>
                <SettingsModalFields.InlineDefaultValue
                  id={`form-field-widget-${widget.id}`}
                  widget={widget}
                  templateRevisionId={widget.templateRevision.id}
                  isDisabled={isReadOnly}
                />
              </InlineSettings>

              {isReadOnly ? (
                <PreventClickOverlay />
              ) : (
                <>
                  <WidgetListItemDragIcon />
                  <FormsWidgetMenu>
                    <FormsWidgetMenuItems.Required widget={widget} />
                    <FormsWidgetMenuItems.ConditionalLogic
                      widget={widget}
                      templateRevisionId={widget.templateRevision.id}
                      templateType={template.templateType}
                    />
                    <FormsWidgetMenuItems.Divider />
                    <FormsWidgetMenuItems.Duplicate />
                    <FormsWidgetMenuItems.MoveToStep widget={widget} />
                    <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
                    <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
                    <FormsWidgetMenuItems.Delete />
                  </FormsWidgetMenu>
                </>
              )}
            </InputGroup>
          </ViewModeInteractionWrapper>

          <Text fontSize="sm" color="gray.500" mt="1" alignSelf="flex-end" fontStyle="italic">
            This field will not be visible in workflow runs.
          </Text>
        </VStack>
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};
