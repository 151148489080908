import { FormFieldWidgetWithValue, TaskWithTaskTemplate } from '@process-street/subgrade/process';
import { VStack, Text } from 'components/design/next';
import * as React from 'react';
import { ApprovalStatus } from '@process-street/subgrade/approval-rule';
import { ApprovalButtons } from '../common/approval-buttons';
import { Muid } from '@process-street/subgrade/core';
import { ArrayUtils, NonEmptyArray } from 'app/utils/array-utils';
import { ApprovalWidgetsSection } from '../approval-widgets-section';
import { TaskNameButton } from '../common/task-name-button';
import { TaskAssignment } from '@process-street/subgrade/role-assignment';
import { ApprovalAssignees } from '../common/approval-assignees';

export type WaitingForApprovalTasksSectionProps = {
  tasks: NonEmptyArray<TaskWithTaskTemplate>;
  taskAssignmentsByTaskMap: Record<Muid, TaskAssignment[]>;
  widgetsByTaskMap: Record<Muid, FormFieldWidgetWithValue[]>;
  onUpsertApproval: (task: TaskWithTaskTemplate, status: ApprovalStatus, comment?: string) => void;
  onSelectTask: (taskId: Muid) => void;
  isMigrating?: boolean;
};

export const WaitingForApprovalTasksSection = ({
  tasks,
  taskAssignmentsByTaskMap,
  widgetsByTaskMap,
  isMigrating,
  onUpsertApproval,
  onSelectTask,
}: WaitingForApprovalTasksSectionProps) => {
  return (
    <VStack alignItems="stretch" gap={4} width="full">
      <Text variant="1" color="gray.400" fontWeight="bold">
        Waiting for approval
      </Text>

      {tasks.map(task => {
        const widgets = widgetsByTaskMap[task.taskTemplate.id] ?? [];
        const taskAssignments = taskAssignmentsByTaskMap[task.id] ?? [];
        return (
          <VStack spacing="3" key={task.id} alignItems="flex-start" backgroundColor="gray.50" padding={4}>
            <TaskNameButton
              taskName={task.taskTemplate.name || '<unnamed task>'}
              onClick={() => onSelectTask(task.id)}
            />
            {ArrayUtils.isNonEmptyArray(taskAssignments) && <ApprovalAssignees taskAssignments={taskAssignments} />}
            {ArrayUtils.isNonEmptyArray(widgets) && <ApprovalWidgetsSection widgets={widgets} />}

            <ApprovalButtons subjectTask={task} isMigrating={isMigrating} onUpsertApproval={onUpsertApproval} />
          </VStack>
        );
      })}
    </VStack>
  );
};
