import { FormFieldWidgetWithValue, TaskWithTaskTemplate } from '@process-street/subgrade/process';
import { Button, HStack, VStack } from 'components/design/next';
import * as React from 'react';
import { RejectTaskButton } from '../common/reject-task-button';
import { ApprovalStatus } from '@process-street/subgrade/approval-rule';
import { ArrayUtils } from 'app/utils/array-utils';
import { ApprovalWidgetsSection } from '../approval-widgets-section';
import { Muid } from '@process-street/subgrade/core';
import { TaskNameButton } from '../common/task-name-button';
import { TaskAssignment } from '@process-street/subgrade/role-assignment';
import { ApprovalAssignees } from '../common/approval-assignees';

export type ApprovedTaskItemProps = {
  task: TaskWithTaskTemplate;
  taskAssignments: TaskAssignment[];
  widgets: FormFieldWidgetWithValue[];
  onUpsertApproval: (task: TaskWithTaskTemplate, status: ApprovalStatus, comment?: string) => void;
  onSelectTask: (taskId: Muid) => void;
};

export const ApprovedTaskItem = ({
  task,
  taskAssignments,
  widgets,
  onUpsertApproval,
  onSelectTask,
}: ApprovedTaskItemProps) => {
  const [isEditingStatus, setIsEditingStatus] = React.useState(false);
  const [isRejectingWithComment, setIsRejectingWithComment] = React.useState(false);

  return (
    <VStack
      alignItems="flex-start"
      spacing="3"
      w="full"
      key={task.id}
      role="group"
      backgroundColor="gray.50"
      p="4"
      borderLeft="solid 2px"
      borderColor="green.500"
    >
      <HStack w="full" justifyContent="space-between">
        <TaskNameButton
          taskName={task.taskTemplate.name || '<unnamed task>'}
          onClick={() => onSelectTask(task.id)}
          w="auto"
        />
        {!isEditingStatus && (
          <Button
            variant="unstyled"
            color="gray.300"
            fontSize="md"
            display="none"
            w="auto"
            onClick={() => setIsEditingStatus(true)}
            _groupHover={{ display: 'block' }}
            _hover={{ color: 'gray.400', cursor: 'pointer' }}
          >
            Edit status
          </Button>
        )}
      </HStack>

      {isEditingStatus && (
        <>
          {ArrayUtils.isNonEmptyArray(taskAssignments) && <ApprovalAssignees taskAssignments={taskAssignments} />}
          {ArrayUtils.isNonEmptyArray(widgets) && <ApprovalWidgetsSection widgets={widgets} />}
          <HStack w="full" pt="2" justifyContent="flex-end">
            <Button
              display={isRejectingWithComment ? 'none' : 'block'}
              variant="ghost"
              color="gray.500"
              fontWeight="normal"
              onClick={() => setIsEditingStatus(false)}
            >
              Cancel
            </Button>

            <RejectTaskButton
              task={task}
              onUpsertApproval={onUpsertApproval}
              onRejecting={() => setIsRejectingWithComment(true)}
              onCancel={() => setIsRejectingWithComment(false)}
            />
          </HStack>
        </>
      )}
    </VStack>
  );
};
