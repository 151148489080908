import * as React from 'react';
import { useField, useFormikContext } from 'formik';
import { FormControl, FormErrorMessage, Input, InputGroup, InputRightElement } from 'components/design/next';
import { MergeTagsMenu, MergeTagsMenuButton } from 'features/merge-tags/components/merge-tags-menu';
import { MergeTagsConstants } from '@process-street/subgrade/form';
import { TemplateRevision } from '@process-street/subgrade/process';
import { useMergeTaggableInput } from 'hooks/use-merge-taggable-input';
import { CodeTaskTemplateEditorMode } from 'pages/templates/_id/components/code-task-template-editor/machine/code-task-machine';

export type ValueInputProps = {
  index: number;
  templateRevisionId: TemplateRevision['id'];
  mode: CodeTaskTemplateEditorMode;
};

export const ValueInput: React.FC<ValueInputProps> = ({ index, templateRevisionId, mode }) => {
  const { handleSubmit } = useFormikContext();
  const [field, meta, { setValue }] = useField(`mappings[${index}].value`);

  const { ref: inputRef, insertMergeTag: insertVariable } = useMergeTaggableInput({
    get: () => field.value,
    set: setValue,
  });

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = async event => {
    await setValue(event.target.value);
  };

  return (
    <FormControl isInvalid={!!meta.error && meta.touched}>
      <InputGroup w="full">
        <Input
          {...field}
          ref={node => {
            inputRef.current = node;
          }}
          placeholder="Enter value"
          name={field.name}
          value={field.value}
          onChange={handleChange}
          onBlur={() => handleSubmit()}
          isDisabled={mode === 'view'}
        />
        {mode === 'edit' && (
          <InputRightElement _focusWithin={{ zIndex: 3 }}>
            <MergeTagsMenu
              {...{
                templateRevisionId,
                onSelect: (key, _fieldId, fallback) => insertVariable(key, fallback),
                mergeTagTarget: MergeTagsConstants.Target.AI_TASK_PROMPT,
                menuButton: <MergeTagsMenuButton size="sm" bg="white" />,
              }}
            />
          </InputRightElement>
        )}
      </InputGroup>
      {meta.error ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
    </FormControl>
  );
};
