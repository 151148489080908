import * as React from 'react';
import { UrlFormFieldValue, UrlFormFieldWidget, WidgetUtils } from '@process-street/subgrade/process';
import { UnsavedChangesIcon } from 'components/widgets/form-field/common/unsaved-changes-icon/unsaved-changes-icons';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Spinner,
  Text,
  VStack,
} from 'components/design/next';
import { ChecklistFormFieldWidgetProps } from 'components/widgets/form-field/common/types';
import { FormFieldValueHelperText } from 'pages/runs/_id/components/form-field-value-helper-text';
import { useChecklistUrlFormField } from './use-checklist-url-form-field';
import { MaskedInput, MaskedInputParsers } from 'features/widgets/components/masked-input';
import identity from 'lodash/identity';
import { WidgetProvider } from '../../hooks/use-widget-context';
import { useWorkflowRunStore } from '../../workflow-run-store';
import { match } from 'ts-pattern';

export interface ChecklistUrlFormFieldWidgetProps
  extends ChecklistFormFieldWidgetProps<UrlFormFieldWidget, UrlFormFieldValue> {}

export const ChecklistUrlFormFieldWidget: React.FC<
  React.PropsWithChildren<ChecklistUrlFormFieldWidgetProps>
> = props => {
  const { widget, formFieldValue, isInvalid, readOnly } = props;
  const { errorMessage, inputProps, onReset, urlIsOpenable, onOpen, nativeAutomationStatus } =
    useChecklistUrlFormField(props);

  const hasDefaultValue = WidgetUtils.hasDefaultValue({ formFieldValue, widget });
  const hasVariables = WidgetUtils.hasVariables(widget.config.defaultValue);
  const maskedInputParser = React.useMemo(
    () => (hasDefaultValue && hasVariables ? MaskedInputParsers.makeParser('merge-tags') : identity),
    [hasDefaultValue, hasVariables],
  );
  const html = React.useMemo(() => maskedInputParser(String(inputProps.value)), [maskedInputParser, inputProps.value]);

  const addOutputWidgetClickedDuringAiTask = useWorkflowRunStore(state => state.addOutputWidgetClickedDuringAiTask);

  return (
    <WidgetProvider widget={widget}>
      <FormControl
        as={VStack}
        alignItems="stretch"
        isRequired={widget.required}
        isInvalid={isInvalid || Boolean(errorMessage)}
        isDisabled={nativeAutomationStatus === 'AutomationRunning' || readOnly}
      >
        <HStack lineHeight="normal">
          {errorMessage ? <UnsavedChangesIcon mr={1} /> : null}
          <FormLabel m="0">{widget.label || 'Untitled Website'}</FormLabel>
          {nativeAutomationStatus === 'AutomationRunning' && (
            <Spinner color="gray.300" data-testid="native-automation-running-spinner" />
          )}
        </HStack>

        <InputGroup>
          <InputLeftAddon bg="gray.100">
            <Icon icon="globe" variant="far" size="4" />
          </InputLeftAddon>

          <MaskedInput
            onClick={() => {
              if (nativeAutomationStatus === 'AutomationRunning') {
                addOutputWidgetClickedDuringAiTask(widget.id);
              }
            }}
            w="full"
            html={html}
            color={readOnly ? 'gray.400' : undefined}
            sx={{
              // InputGroup styling fails on this wrapped input
              '& input': {
                borderLeftRadius: 0,
                borderColor: readOnly ? 'gray.400' : 'inherit',
              },
            }}
          >
            <Input
              {...{ ...inputProps, 'data-testid': `url-field-${widget.header.id}` }}
              bgColor={match({ hasDefaultValue, readOnly })
                .with({ readOnly: true }, () => 'gray.100')
                .with({ hasDefaultValue: true }, () => 'yellow.100')
                .otherwise(() => undefined)}
            />
          </MaskedInput>

          <InputRightElement className={`input-group-btn`}>
            <IconButton
              variant="tertiary"
              borderColor="gray.200"
              borderLeftRadius={0}
              aria-label="Open URL"
              isDisabled={!urlIsOpenable}
              onClick={onOpen}
              data-testid={`open-button-${widget.header.id}`}
              icon={<Icon icon="arrow-right" size="4" variant="far" color="gray.600" />}
            />
          </InputRightElement>
        </InputGroup>

        <FormFieldValueHelperText
          defaultValue={widget.config.defaultValue}
          formFieldValue={formFieldValue}
          onResetDefaultValue={onReset}
        />

        {errorMessage ? (
          <FormErrorMessage>
            <Text variant="-2">{errorMessage}</Text>
          </FormErrorMessage>
        ) : null}
      </FormControl>
    </WidgetProvider>
  );
};
