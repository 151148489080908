import {
  FormFieldWidgetWithValue,
  MultiOptionFieldValue,
  MultiSelectFormFieldWidget,
  MultiSelectItemValueStatus,
} from '@process-street/subgrade/process';
import { Checkbox, HStack, Text } from 'components/design/next';
import * as React from 'react';

export type ApprovalWidgetMultiSelectRendererProps = {
  widget: FormFieldWidgetWithValue;
};
export const ApprovalWidgetMultiSelectRenderer = ({ widget }: ApprovalWidgetMultiSelectRendererProps) => {
  const fieldValue = React.useMemo(() => {
    return widget.formFieldValue?.fieldValue && (widget.formFieldValue.fieldValue as MultiOptionFieldValue);
  }, [widget.formFieldValue?.fieldValue]);

  if (!fieldValue) return null;

  return (
    <>
      {fieldValue.itemValues.map(itemValue => {
        const configItem = (widget as MultiSelectFormFieldWidget).config.items.find(item => item.id === itemValue.id);
        if (configItem) {
          return (
            <HStack>
              <Checkbox isDisabled isChecked={itemValue.status === MultiSelectItemValueStatus.Completed} />
              <Text>{configItem.name}</Text>
            </HStack>
          );
        }
      })}
    </>
  );
};
