import { NativeAutomation } from '@process-street/subgrade/process';
import { KeyValueMapping } from 'pages/templates/_id/components/code-task-template-editor/key-value-list/key-value-list';
import { StateValue } from 'xstate';
import { CodeTaskTemplateEditorMode } from 'pages/templates/_id/components/code-task-template-editor/machine/code-task-machine';

export namespace CodeTaskUtils {
  export const convertKeyToJsonPath = (codeAction: NativeAutomation.ExecuteCodeAction, key: string): string =>
    key === '' ? '' : `$.actions.${codeAction.key}.output.outputData['${key.replaceAll("'", "\\'")}']`;

  export const extractKeyFromJsonPath = (jsonPath: string): string => {
    const pattern = `.outputData['`;
    const i = jsonPath.indexOf(pattern);

    return jsonPath.substring(i + pattern.length, jsonPath.length - 2).replaceAll("\\'", "'");
  };

  export const getTestInputData = (inputData: KeyValueMapping[], testInputData: KeyValueMapping[]) => {
    const testMappings = getObjectFromMappings(testInputData);

    return inputData.map(row => ({
      key: row.key,
      value: testMappings[row.key] ?? removeMergeTags(row.value),
    }));
  };

  export const getTestOutputData = (outputMapping: KeyValueMapping[]) =>
    outputMapping.map(({ key }) => ({ key, value: '' }));

  const MERGE_TAG_REGEX = /\{\{.*}}/;

  const removeMergeTags = (value: string) => value.replace(MERGE_TAG_REGEX, '');

  export const getObjectFromMappings = (mappings: KeyValueMapping[]) =>
    Object.fromEntries(mappings.map(({ key, value }) => [key, value]));

  const createKeyValueMapping = (object: Record<string, string>): KeyValueMapping[] =>
    Object.entries(object).map(([key, value]) => ({
      key,
      value,
    }));

  export const getInputMappingsFromCodeAction = (codeAction?: NativeAutomation.ExecuteCodeAction): KeyValueMapping[] =>
    createKeyValueMapping(codeAction?.config.inputData ?? {});

  export const getOutputMappingsFromUpdateFormFieldsAction = (
    updateFormFieldsAction?: NativeAutomation.UpdateFormFieldsAction,
  ): KeyValueMapping[] => createKeyValueMapping(updateFormFieldsAction?.config.mapping ?? {});

  export const getUpdatedTestOutputMappings = (
    testOutputMappings: KeyValueMapping[],
    outputData: Record<string, string>,
  ) =>
    testOutputMappings.map(mapping => {
      const { key } = mapping;
      const fieldKey = extractKeyFromJsonPath(key);
      const outputValue = outputData[fieldKey];

      return outputValue ? { key, value: outputValue } : mapping;
    });

  export function findActionByType<T>(
    actions: NativeAutomation.Action[],
    actionType: NativeAutomation.Action['actionType'],
  ) {
    return actions.find(action => action.actionType === actionType) as T;
  }

  export const getModeFromState = (value: StateValue): CodeTaskTemplateEditorMode =>
    (typeof value === 'string' ? value : Object.keys(value)[0]) as CodeTaskTemplateEditorMode;
}
