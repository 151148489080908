import * as React from 'react';
import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Skeleton,
  Spacer,
  Tooltip,
  useDisclosure,
} from 'components/design/next';
import { UseAiTaskTemplateFormArgs, useBasicAiTaskPromptForm } from './use-basic-ai-task-prompt-form';
import { MergeTagsMenu } from 'features/merge-tags/components/merge-tags-menu';
import { useMergeTaggableInput } from 'hooks/use-merge-taggable-input';
import { MergeTagsConstants } from '@process-street/subgrade/form';
import { TestAiTaskModal } from '../../../test-ai-task-modal';
import { ProcessPeteTextarea, ProcessPeteTextareaHeader, ProcessPeteTextareaHeaderTitle } from '../../../common';
import { MaskedInput, MaskedInputParsers } from 'features/widgets/components/masked-input';
import { AiTaskWidgetSelect } from '../ai-task-widget-select';
import { ProcessPeteIcon } from 'pages/runs/_id/components/automated-task-audit/common';

export interface BasicAiTaskPromptFormProps {
  promptConfig: UseAiTaskTemplateFormArgs['promptConfig'];
}

const maskedInputParser = MaskedInputParsers.makeParser('urls', 'merge-tags');

export const BasicAiTaskPromptForm: React.FC<React.PropsWithChildren<BasicAiTaskPromptFormProps>> = ({
  promptConfig,
}) => {
  const {
    prompt,
    onPromptChanged,
    outputWidgetId,
    onOutputWidgetIdChanged,
    isDisabled,
    templateRevision,
    isLoading,
    aiTaskTemplate,
    setIsFocused,
  } = useBasicAiTaskPromptForm({ promptConfig });
  const templateRevisionId = templateRevision.id;

  const { ref: promptRef, insertMergeTag: insertVariable } = useMergeTaggableInput<HTMLTextAreaElement>({
    get: () => prompt,
    set: onPromptChanged,
  });

  const testDisclosure = useDisclosure();

  const promptPlaceholder = 'Type your own prompt';

  const html = React.useMemo(() => maskedInputParser(String(prompt)), [prompt]);

  return (
    <>
      <FormControl isDisabled={isDisabled}>
        <ProcessPeteTextareaHeader isDisabled={isDisabled}>
          <HStack spacing="1">
            <ProcessPeteIcon size="4" color="gray.500" mr={1} />
            <ProcessPeteTextareaHeaderTitle>
              You can edit and add variables to your prompt
            </ProcessPeteTextareaHeaderTitle>
            <Tooltip label="Make sure linked form fields are required" hasArrow shouldWrapChildren>
              <Icon icon="info-circle" variant="far" size="4" color="gray.500" />
            </Tooltip>
          </HStack>
          <Spacer />

          <ButtonGroup>
            <MergeTagsMenu
              {...{
                templateRevisionId,
                onSelect: (key, _fieldId, fallback) => insertVariable(key, fallback),
                isDisabled,
                mergeTagTarget: MergeTagsConstants.Target.AI_TASK_PROMPT,
              }}
            />
            {!isDisabled && (
              <>
                <Button variant="secondary" onClick={testDisclosure.onOpen}>
                  Test
                </Button>
                {aiTaskTemplate && <TestAiTaskModal {...{ ...testDisclosure, aiTaskTemplate, templateRevisionId }} />}
              </>
            )}
          </ButtonGroup>
        </ProcessPeteTextareaHeader>

        <Skeleton isLoaded={!isLoading}>
          <MaskedInput html={html}>
            <ProcessPeteTextarea
              ref={promptRef}
              autoFocus
              placeholder={promptPlaceholder}
              value={prompt}
              onChange={e => onPromptChanged(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              isDisabled={isDisabled}
            />
          </MaskedInput>
        </Skeleton>
      </FormControl>

      <FormControl isDisabled={isDisabled}>
        <FormLabel htmlFor="output">Output</FormLabel>
        <Skeleton isLoaded={!isLoading}>
          <AiTaskWidgetSelect
            isDisabled={isDisabled}
            value={outputWidgetId}
            onChange={option => {
              onOutputWidgetIdChanged(option.value);
            }}
          />
        </Skeleton>
      </FormControl>
    </>
  );
};
