import * as React from 'react';
import { EmailFormFieldValue, EmailFormFieldWidget, WidgetUtils } from '@process-street/subgrade/process';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  Spinner,
  Text,
  VStack,
} from 'components/design/next';
import { UnsavedChangesIcon } from 'components/widgets/form-field/common/unsaved-changes-icon/unsaved-changes-icons';
import { ChecklistFormFieldWidgetProps } from 'components/widgets/form-field/common/types';
import { useChecklistEmailFormField } from './use-checklist-email-form-field';
import { FormFieldValueHelperText } from 'pages/runs/_id/components/form-field-value-helper-text';
import { MaskedInput, MaskedInputParsers } from 'features/widgets/components/masked-input';
import identity from 'lodash/identity';
import { WidgetProvider } from '../../hooks/use-widget-context';
import { useWorkflowRunStore } from '../../workflow-run-store';
import { match } from 'ts-pattern';

export interface ChecklistEmailFormFieldWidgetProps
  extends ChecklistFormFieldWidgetProps<EmailFormFieldWidget, EmailFormFieldValue> {}

export const ChecklistEmailFormFieldWidget: React.FC<
  React.PropsWithChildren<ChecklistEmailFormFieldWidgetProps>
> = props => {
  const { widget, formFieldValue, isInvalid, readOnly } = props;
  const { errorMessage, inputProps, handleReset, nativeAutomationStatus } = useChecklistEmailFormField(props);

  const hasValidationError = Boolean(errorMessage);
  const shouldShowErrorState = hasValidationError || isInvalid;

  const hasDefaultValue = WidgetUtils.hasDefaultValue({ formFieldValue, widget });
  const hasVariables = WidgetUtils.hasVariables(widget.config.defaultValue);
  const maskedInputParser = React.useMemo(
    () => (hasDefaultValue && hasVariables ? MaskedInputParsers.makeParser('urls', 'merge-tags') : identity),
    [hasDefaultValue, hasVariables],
  );
  const html = React.useMemo(() => maskedInputParser(String(inputProps.value)), [maskedInputParser, inputProps.value]);

  const addOutputWidgetClickedDuringAiTask = useWorkflowRunStore(state => state.addOutputWidgetClickedDuringAiTask);
  return (
    <WidgetProvider widget={widget}>
      <FormControl
        as={VStack}
        alignItems="stretch"
        isInvalid={shouldShowErrorState}
        isRequired={widget.required}
        isDisabled={nativeAutomationStatus === 'AutomationRunning' || readOnly}
      >
        <HStack lineHeight="normal">
          {hasValidationError ? <UnsavedChangesIcon /> : null}
          <FormLabel m={0}>{widget.label || 'Untitled Email Address'}</FormLabel>
          {nativeAutomationStatus === 'AutomationRunning' && (
            <Spinner color="gray.300" data-testid="native-automation-running-spinner" />
          )}
        </HStack>
        <InputGroup>
          <InputLeftAddon bgColor="gray.100">
            <Icon size="4" icon="envelope" variant="far" color={'gray.500'} />
          </InputLeftAddon>
          <MaskedInput
            onClick={() => {
              if (nativeAutomationStatus === 'AutomationRunning') {
                addOutputWidgetClickedDuringAiTask(widget.id);
              }
            }}
            w="full"
            html={html}
            color={readOnly ? 'gray.400' : undefined}
            sx={{
              // InputGroup styling fails on this wrapped input
              '& input': {
                borderLeftRadius: 0,
                borderColor: readOnly ? 'gray.400' : 'inherit',
              },
            }}
          >
            <Input
              type="email"
              aria-label="email"
              {...inputProps}
              onBlur={e => {
                inputProps.onChange?.(e);
                inputProps.onBlur?.(e);
              }}
              bgColor={match({ hasDefaultValue, readOnly })
                .with({ readOnly: true }, () => 'gray.100')
                .with({ hasDefaultValue: true }, () => 'yellow.100')
                .otherwise(() => undefined)}
            />
          </MaskedInput>
        </InputGroup>

        <FormFieldValueHelperText
          defaultValue={widget.config.defaultValue}
          formFieldValue={formFieldValue}
          onResetDefaultValue={handleReset}
        />

        {hasValidationError && (
          <FormErrorMessage>
            <Text variant="-2">{errorMessage}</Text>
          </FormErrorMessage>
        )}
      </FormControl>
    </WidgetProvider>
  );
};
