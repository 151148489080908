import { ApprovalStatus } from '@process-street/subgrade/approval-rule';
import { FormFieldWidgetWithValue, TaskWithTaskTemplate } from '@process-street/subgrade/process';
import { Text, VStack } from 'components/design/next';
import * as React from 'react';
import { ApprovedTaskItem } from './approved-task-item';
import { NonEmptyArray } from 'app/utils/array-utils';
import { Muid } from '@process-street/subgrade/core';
import { TaskAssignment } from '@process-street/subgrade/role-assignment';

export type ApprovedTasksSectionProps = {
  tasks: NonEmptyArray<TaskWithTaskTemplate>;
  taskAssignmentsByTaskMap: Record<Muid, TaskAssignment[]>;
  widgetsByTaskMap: Record<Muid, FormFieldWidgetWithValue[]>;
  onUpsertApproval: (task: TaskWithTaskTemplate, status: ApprovalStatus, comment?: string) => void;
  onSelectTask: (taskId: Muid) => void;
};

export const ApprovedTasksSection = ({
  tasks,
  taskAssignmentsByTaskMap,
  widgetsByTaskMap,
  onUpsertApproval,
  onSelectTask,
}: ApprovedTasksSectionProps) => {
  return (
    <VStack alignItems="stretch" gap={4} width="full">
      <Text
        alignSelf="flex-start"
        padding={1}
        variant="1"
        color="green.500"
        backgroundColor="green.50"
        fontWeight="bold"
      >
        Approved
      </Text>

      {tasks.map(task => (
        <ApprovedTaskItem
          key={task.id}
          task={task}
          taskAssignments={taskAssignmentsByTaskMap[task.id] ?? []}
          widgets={widgetsByTaskMap[task.taskTemplate.id] ?? []}
          onUpsertApproval={onUpsertApproval}
          onSelectTask={onSelectTask}
        />
      ))}
    </VStack>
  );
};
