import { Muid } from '@process-street/subgrade/core';
import { AxiosError } from 'axios';
import { useQuery as useRQ, UseQueryOptions, QueryKey } from 'react-query';
import { axiosService } from 'services/axios-service';
import { Checklist } from '@process-street/subgrade/process';

export namespace GetChecklistPermissionsQuery {
  export type Params = {
    checklistId?: Checklist['id'];
  };

  export type Response = {
    checklistId: Muid;
    userId: Muid;
    permissionMap: {
      checklistArchive: boolean;
      checklistDelete: boolean;
      checklistRead: boolean;
      checklistExport: boolean;
    };
  };

  export const key = ['checklists', 'permissions', 'consolidated'];

  export const getKey = (params: Params): QueryKey => [...key, params];

  export const queryFn = (params: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/checklists/${params.checklistId}/permissions/consolidated`)
      .then(res => res.data);

  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQ(getKey(params), () => queryFn(params), {
      ...options,
      enabled: Boolean(params.checklistId) && options.enabled !== false,
    });
  };
}
