export type NonEmptyArray<T> = [T, ...T[]];
export type EmptyArray = [];

export namespace ArrayUtils {
  export function isNonEmptyArray<T>(array: T[] | undefined): array is NonEmptyArray<T> {
    return !!array && array.length > 0;
  }
  export function hasEmptyValues<T>(array: T[] | undefined): boolean {
    return isEmptyArray(array) || (isNonEmptyArray(array) && array.some(value => !value));
  }
  export function isEmptyArray<T>(array: T[] | undefined): array is EmptyArray {
    return !isNonEmptyArray(array);
  }
}
