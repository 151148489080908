import * as React from 'react';
import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  Portal,
  Text,
} from 'components/design/next';
import { NativeAutomation, TaskTemplate } from '@process-street/subgrade/process';
import { PopoverProps, PopoverTrigger, Table, TableContainer, Tbody, Tr, useDisclosure } from '@chakra-ui/react';
import { ThemeProvider2024 } from 'components/design/next/theme-provider-2024';
import { CodeTaskRunLogItem } from 'pages/runs/_id/components/code-task-run-log-modal/code-task-run-log-item';
import groupBy from 'lodash/groupBy';
import { CodeTaskDetailedRunLogs } from 'pages/runs/_id/components/code-task-run-log-modal/code-task-detailed-run-logs';

export interface CodeTaskRunLogProps extends PopoverProps {
  /** Rendered content that will trigger the modal */
  children: React.ReactNode;
  nativeAutomationLogs?: NativeAutomation.Log[];
  taskTemplate: TaskTemplate;
}

export const CodeTaskRunLog: React.FC<CodeTaskRunLogProps> = ({
  taskTemplate,
  children,
  nativeAutomationLogs,
  ...props
}) => {
  // only display one (the latest) log item per execution
  const latestLogDataPerExecution = React.useMemo(() => {
    const codeTaskActionLogs = nativeAutomationLogs?.filter(log => log.currentActionType === 'ExecuteCode');
    return (
      Object.values(groupBy(codeTaskActionLogs, 'executionId'))
        // logs are received in sorted order from server, so the 1st item is the latest
        .map(logs => logs[0])
        .flat()
    );
  }, [nativeAutomationLogs]);

  const logDetailsDisclosure = useDisclosure();
  const [selectedLog, setSelectedLog] = React.useState<NativeAutomation.Log | undefined>(undefined);
  const handleSelectLog = (log: NativeAutomation.Log) => {
    const hasLogs = Boolean(log.logs?.logs);
    if (!hasLogs) return;

    setSelectedLog(log);
    logDetailsDisclosure.onOpen();
  };

  return (
    <>
      <Popover placement="auto" isLazy lazyBehavior="unmount" size="lg" {...props}>
        <PopoverTrigger>{children}</PopoverTrigger>
        <Portal>
          <ThemeProvider2024>
            <PopoverContent zIndex="popover" w="sm" maxW="100vw">
              <PopoverArrow />
              <PopoverCloseButton mt={1} fontSize="xs" color="gray.500" />
              <PopoverBody>
                <Box>
                  <Flex py={1} pl={1} alignItems="center" flexGrow={1}>
                    <Text fontWeight="medium">Code task run log</Text>
                  </Flex>
                  <TableContainer maxH="50vh" mt={1} overflowY="auto">
                    <Table variant="striped">
                      <Tbody>
                        {/*start with white striped color*/}
                        <Tr aria-hidden />
                        {latestLogDataPerExecution?.length ? (
                          latestLogDataPerExecution.map(log => (
                            <CodeTaskRunLogItem
                              log={log}
                              taskTemplate={taskTemplate}
                              key={log.id}
                              onClick={() => handleSelectLog(log)}
                            />
                          ))
                        ) : (
                          <Text>Code task has not run yet.</Text>
                        )}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              </PopoverBody>
            </PopoverContent>
          </ThemeProvider2024>
        </Portal>
      </Popover>
      <CodeTaskDetailedRunLogs taskTemplateName={taskTemplate.name} log={selectedLog} {...logDetailsDisclosure} />
    </>
  );
};
