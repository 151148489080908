import React, { useEffect } from 'react';
import { useMentionableUsers } from 'features/comments/use-mentionable-users';
import { Avatar, Button, HStack, StackProps, Text, useToast, VStack } from 'components/design/next';
import { Checklist, Comment, Task } from '@process-street/subgrade/process';

import { CommentEditor } from 'components/comments/CommentEditor';
import { getUserProfilePicUrl } from 'features/comments/utils';
import { User } from '@process-street/subgrade/core';
import { UploadAttachmentComment } from './upload-attachment';
import { UploadProgress } from 'features/upload/components';
import { CommentHelpBlock } from 'features/comments/components/common/comment-help-block';
import { SIZE_TOO_LARGE, useUploadAttachmentComment } from './use-upload-attachment-comment';
import last from 'lodash/last';
import { DefaultErrorMessages } from 'components/utils/error-messages';

type NewCommentBoxProps = {
  currentUser: User;
  checklistId: Checklist['id'];
  onSendComment: (content: string) => void;
  onAddAttachment?: () => void;
  content?: Comment['content'];
  onCancel?: () => void;
  taskId?: Task['id'];
  fontSize?: StackProps['fontSize'];
};

export const NewCommentBox: React.FC<React.PropsWithChildren<NewCommentBoxProps>> = ({
  currentUser,
  checklistId,
  onSendComment,
  onAddAttachment,
  content: initialContent,
  onCancel,
  taskId,
  fontSize = 'base',
}) => {
  const { users: mentionableUsers } = useMentionableUsers({ checklistId });
  const [content, setContent] = React.useState(initialContent ?? '');
  const [uploadingCount, setUploadingCount] = React.useState(0);
  const [progress, setProgress] = React.useState<number | undefined>(undefined);
  const hasContent = content.length > 0;
  const toast = useToast();

  const onFinishUploadAttachment = () => {
    setUploadingCount(0);
    setProgress(undefined);
    onAddAttachment?.();
  };

  const { dropzoneState, uploadError } = useUploadAttachmentComment({
    taskId: taskId!,
    checklistId,
    setUploadingCount,
    onFinish: () => onFinishUploadAttachment(),
    setProgress,
    isDropDisabled: false,
    shouldBroadcastEvents: true,
  });

  const fileTooLarge =
    dropzoneState.fileRejections.length > 0 && last(dropzoneState.fileRejections[0].errors)?.code === SIZE_TOO_LARGE;

  useEffect(() => {
    if (uploadError) {
      toast({
        status: 'error',
        title: "We're having problems uploading the attachment",
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    }
  }, [uploadError, toast]);

  const handleOnSendCommentClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onSendComment(content);
      setContent('');
    },
    [content, onSendComment],
  );

  if (!currentUser) {
    return null;
  }

  const isReplyBox = Boolean(onCancel);

  const uploadMessage = uploadingCount > 1 ? 'Uploading attachments...' : 'Uploading attachment...';

  return (
    <HStack alignItems="flex-start" spacing={[0, 3]} width="full" {...dropzoneState.getRootProps()}>
      <Avatar
        display={['none', 'block']}
        name={currentUser?.username}
        src={getUserProfilePicUrl(currentUser)}
        size="xs"
        border="none"
      ></Avatar>{' '}
      <VStack bg="brand.50" width="full" alignItems="start" p="3" borderRadius="sm">
        {uploadingCount ? (
          <UploadProgress progress={progress as number} message={uploadMessage} />
        ) : (
          <CommentEditor
            borderWidth="px"
            borderStyle="solid"
            borderColor={dropzoneState.isDragActive ? 'brand.500' : 'transparent'}
            {...{
              'w': 'full',
              'bg': 'brand.50',
              'user': currentUser as User,
              mentionableUsers,
              content,
              'onUpdate': setContent,
              'aria-label': 'add comment',
              fontSize,
            }}
          />
        )}
        <HStack w="full" justifyContent="flex-end" alignItems="center">
          {hasContent && <CommentHelpBlock {...{ fontSize: 'sm' }} />}
          <HStack justifyContent="flex-end" w={hasContent ? 'auto' : 'full'}>
            {isReplyBox && (
              <Button
                aria-label="cancel comment"
                variant="ghost"
                color="gray.600"
                size="xs"
                onClick={() => {
                  setContent('');
                  onCancel?.();
                }}
              >
                Cancel
              </Button>
            )}
            {!uploadingCount && (
              <UploadAttachmentComment
                {...{
                  taskId: taskId!,
                  checklistId,
                  setUploadingCount,
                  onFinish: () => onFinishUploadAttachment(),
                  progress,
                  setProgress,
                  disabled: hasContent,
                  shouldBroadcastEvents: true,
                }}
              />
            )}
            {fileTooLarge && (
              <Text color="red.500" align="center" fontWeight="medium">
                {last(dropzoneState.fileRejections[0].errors)?.message}
              </Text>
            )}
            <Button
              aria-label="send comment"
              variant="tertiary"
              size={isReplyBox ? 'xs' : 'sm'}
              isDisabled={content === ''}
              onClick={handleOnSendCommentClick}
              placeSelf="flex-end"
            >
              Send
            </Button>
          </HStack>
        </HStack>
      </VStack>
    </HStack>
  );
};
