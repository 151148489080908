import { FieldType, FormFieldWidget, NativeAutomation } from '@process-street/subgrade/process';
import { MuidUtils, StdMergeTagKey, User } from '@process-street/subgrade/core';
import { match } from 'ts-pattern';
import { CustomIconName } from 'components/design/next/icon/icon-name';
import { StyleProps } from 'components/design/next';
import { getAvatarUrl } from 'components/common/Avatar';

export type Option = {
  key: string;
  primaryLabel: string;
  secondaryLabel?: string;
  avatarUrl?: string;
  avatarIcon?: CustomIconName;
  avatarBg?: StyleProps['color'];
} & (
  | { type: 'user'; user: User }
  | { type: 'formField'; widget: FormFieldWidget }
  | { type: 'role'; role: 'runner' }
  | { type: 'unknown' }
);

export enum OptionLabel {
  SendTo = 'Send to',
  Dynamic = 'Dynamic assignees',
  Users = 'Users',
}

export type OptionGroup = { label: OptionLabel; options: Option[] };

export const getBaseGroups: () => OptionGroup[] = () => [
  { label: OptionLabel.SendTo, options: [] },
  { label: OptionLabel.Dynamic, options: [] },
  { label: OptionLabel.Users, options: [] },
];

export const RUNNER_OPTION: Option = {
  type: 'role',
  role: 'runner',
  key: 'runner',
  avatarIcon: 'play',
  avatarBg: 'indigo.500',
  primaryLabel: 'Workflow Runner',
  secondaryLabel: 'The user assigned to this workflow when it is run',
};

export const getUnknownOption: () => Option = () => ({
  type: 'unknown',
  key: `unknown-${MuidUtils.randomMuid()}`,
  avatarIcon: 'question',
  primaryLabel: 'Unknown',
});

export const DEFAULT_ROLES: Option[] = [RUNNER_OPTION];

export const userToOption = (user: User): Option => ({
  type: 'user',
  key: user.id,
  user,
  primaryLabel: user.username,
  secondaryLabel: user.email,
  avatarUrl: getAvatarUrl(user),
});

export const widgetToOption = (widget: FormFieldWidget): Option => ({
  type: 'formField',
  key: widget.id,
  widget,
  primaryLabel: widget.label ?? widget.key,
  secondaryLabel: widget.fieldType,
  ...(widget.fieldType === FieldType.Members
    ? { avatarIcon: 'users', avatarBg: 'green.500' }
    : { avatarIcon: 'envelope', avatarBg: 'blue.500' }),
});

const FORM_FIELD_VALUE_KEY_PREFIX = 'form';
const REMOVE_TAGS_REGEX = /{{|}}/g;

export const optionToRecipient = (option: Option): NativeAutomation.SendEmailActionRecipient => {
  return match<Option, NativeAutomation.SendEmailActionRecipient>(option)
    .with({ type: 'user' }, ({ user }) => ({
      recipientType: 'User',
      userId: user.id,
    }))
    .with({ type: 'formField' }, ({ widget }) => ({
      recipientType: 'MergeTag',
      key: `{{${FORM_FIELD_VALUE_KEY_PREFIX}.${
        widget.fieldType === FieldType.Members ? `${widget.key}.email` : widget.key
      }}}`,
    }))
    .with({ type: 'role', role: 'runner' }, () => ({
      recipientType: 'MergeTag',
      key: `{{${StdMergeTagKey.RunCreatedByEmail}}}`,
    }))
    .with({ type: 'unknown' }, () => {
      throw new Error('Unknown option type');
    })
    .exhaustive();
};

export const getRecipientToOption = ({
  widgetsByKey,
  usersMap,
}: {
  widgetsByKey: Map<string, FormFieldWidget>;
  usersMap: Map<string, User>;
}) => {
  const widgetKeysSet = new Set(widgetsByKey.keys());
  return (recipient: NativeAutomation.SendEmailActionRecipient): Option => {
    return match<typeof recipient, Option>(recipient)
      .with({ recipientType: 'User' }, ({ userId }) => {
        const user = usersMap.get(userId);
        return user ? userToOption(user) : getUnknownOption();
      })
      .with({ recipientType: 'MergeTag' }, ({ key }) => {
        const formFieldKey = key.replace(`${FORM_FIELD_VALUE_KEY_PREFIX}.`, '').replace(REMOVE_TAGS_REGEX, '');

        return (
          match<string, Option>(formFieldKey)
            .with(`${StdMergeTagKey.RunCreatedByEmail}`, () => RUNNER_OPTION)
            .when(
              leaf => widgetKeysSet.has(leaf),
              () => widgetToOption(widgetsByKey.get(formFieldKey)!),
            )
            // Support for members field e.g., 'form.membersFields.email'
            .when(
              leaf => {
                const [key, property] = leaf.split('.');
                return widgetKeysSet.has(key) && property === 'email';
              },
              leaf => {
                const [key] = leaf.split('.');
                const widget = widgetsByKey.get(key)!;
                return widgetToOption(widget);
              },
            )
            .otherwise(() => getUnknownOption())
        );
      })
      .otherwise(() => getUnknownOption());
  };
};
