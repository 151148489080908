import { MuidUtils } from '../../core';
import { Checklist } from '../../process';
import { AssignmentStatus, ChecklistAssignment } from '../../role-assignment';
import { generateAudit } from './audit-generator';
import { generateOrganizationRef } from './organization-generator';
import { generateOrganizationMembership } from './organization-membership-generator';
import { generateUser } from './user-generator';

export const generateChecklistAssignment = (
  checklistAssignment?: Partial<ChecklistAssignment>,
): ChecklistAssignment => ({
  id: MuidUtils.randomMuid(),
  audit: generateAudit(),
  checklist: { id: MuidUtils.randomMuid() },
  organization: generateOrganizationRef(),
  organizationMembership: { id: MuidUtils.randomMuid() },
  status: AssignmentStatus.Active,
  ...checklistAssignment,
});

export const generateChecklistAssignmentsForChecklists = (
  checklists: Checklist[],
  size: number,
): ChecklistAssignment[] =>
  checklists.reduce((agg: ChecklistAssignment[], checklist: Checklist) => {
    const assignmentsInChecklist: ChecklistAssignment[] = [...Array(size)].map(() => {
      const user = generateUser();
      const organizationMembership = generateOrganizationMembership({
        user: { id: user.id },
        organization: { id: checklist.organization.id },
      });
      return generateChecklistAssignment({ checklist, organizationMembership });
    });
    return agg.concat(assignmentsInChecklist);
  }, []);
