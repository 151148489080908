import {
  isAutomatedTask,
  MapUtils,
  TaskStatus,
  TaskTemplateTaskType,
  TemplateType,
  WidgetType,
  WidgetUtils,
} from '@process-street/subgrade/process';
import { ProcessingErrorUtils } from '@process-street/subgrade/core';
import { TaskListConstants } from '@process-street/subgrade/process/task-list-constants';
import { BaseWidgetSelector } from '@process-street/subgrade/redux/selector';
import angular from 'angular';
import { ApprovalSelector } from 'components/approvals/store/approval.selectors';
import { DynamicDueDatesSelector } from 'components/dynamic-due-dates/store/dynamic-due-dates.selectors';
import { ChecklistWidgetSelector } from 'components/widgets/store/checklist-widget.selector';
import { WidgetSelector } from 'components/widgets/store/widget.selector';
import { TaskStatsSelector } from 'reducers/task-stats/task-stats.selectors';
import { TaskTemplateSelector } from 'reducers/task-template/task-template.selectors';
import { TaskSelector } from 'reducers/task/task.selectors';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { Key } from 'services/key';
import templateUrl from './task-list.component.html';
import './task-list.scss';
import { TaskStatusToggleSource } from './TaskStatusToggleSource';
import { DefaultErrorMessages } from 'components/utils/error-messages';
import { EventName } from 'services/event-name';
import ResponsiveBootstrapToolkit from 'responsive-toolkit';
import { trace } from 'components/trace';
import { ChecklistRulesEngineService } from 'directives/rules/checklist';
import { queryClient } from 'components/react-root';
import { GetAllTasksByChecklistRevisionIdQuery } from 'features/task/query-builder';
import { GetAllNativeAutomationLogsByChecklistIdQuery } from 'features/native-automations/query-builder/get-all-native-automation-logs-by-checklist-id-query';
import { StopTaskEvent } from 'services/stop-task-event';
import { ChecklistEvent } from 'services/checklists/checklist-event';
import { OneOffTaskAngularHelper } from 'features/one-off-tasks/components/shared/angular/one-off-task-angular-helper';
import { OneOffTaskHelper } from 'features/one-off-tasks/components/shared/one-off-task-helper';
import { TaskListHelper } from 'directives/task-list/task-list-helper';
import { AttachmentEvent } from 'services/events/attachment-event';
import { CommentEvent } from 'services/events/comment-event';
import { TaskListEvent } from 'directives/task-list/task-list-event';
import { FormFieldEvent } from 'services/form-field-event';

angular.module('frontStreetApp.directives').component('psTaskList', {
  bindings: {
    mode: '<',

    templateRevision: '<',
    activeTaskTemplateGroupId: '<',
    userCanManageTasks: '<',
    userCanUpdateTemplate: '<',
    widgetsMap: '<',
    formFieldValueMap: '<',

    checklistRevision: '<',

    onTaskTemplatesLoaded: '&',
    onInitWidgets: '&',

    onSelectTaskTemplate: '&',
    onActiveTaskTemplateSet: '&',

    onSetChecklistProgress: '&',
    onTaskStatusChanged: '&',
    onAllTasksStatusChanged: '&',

    onToggleWidgetsVisibility: '&',
    onSetWidgetsVisibility: '&',
    userAnonymous: '<',
    hideCompletedTasks: '<',
  },
  require: {
    inboxChecklistDetailsCtrl: '^?psChecklistInboxItemDetails',
  },
  templateUrl,
  controller(
    $anchorScroll,
    $ngRedux,
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $timeout,
    $q,
    ChecklistService,
    ChecklistTaskAssignmentService,
    DueDateUpdateListener,
    DynamicDueDateActions,
    FeatureFlagService,
    focusById,
    OrderTreeService,
    RequiredFieldEvent,
    FormFieldService,
    FormFieldValueService,
    FormFieldValueEvent,
    RequiredFieldService,
    RuleService,
    SessionService,
    StopTaskService,
    TaskActions,
    TaskDueDateListenerService,
    TaskListService,
    TaskTemplateListService,
    TaskService,
    TaskStatsActions,
    TaskStatsService,
    TaskTemplateService,
    ToastService,
    util,
    WidgetActions,
    WidgetService,
  ) {
    const logger = trace({ name: 'psTaskList' });
    logger.info('loading ctrl');

    const ctrl = this;
    ctrl.stoppedTasksShown = false;
    ctrl.dueDateRules = [];
    ctrl.taskStatsMap = {};
    ctrl.taskMap = {};
    ctrl.taskTemplateMap = {};
    ctrl.isSandboxMode = $state.includes('sandboxChecklist') ?? false;

    ctrl.mapStateToThis = (checklistRevision, activeTaskTemplateGroupId) =>
      createSelector(
        [
          DynamicDueDatesSelector.getAllByChecklistRevisionId(checklistRevision.id),
          TaskSelector.getTaskMapWithTasksByChecklistIdGroupedByTaskTemplateGroupId(checklistRevision.checklist.id),
          TaskTemplateSelector.getAllByChecklistRevisionId(checklistRevision.id),
          WidgetSelector.getAllByChecklistRevisionId(checklistRevision.id),
          ApprovalSelector.getApprovalSubjectTasks(
            checklistRevision.id,
            checklistRevision.templateRevision.id,
            activeTaskTemplateGroupId,
          ),
          TaskStatsSelector.getEntityMapForCurrentChecklistRevisionId,
          ChecklistWidgetSelector.getAllByChecklistRevisionId(checklistRevision.id),
        ],
        (
          dueDateRules,
          { tasks, taskMap },
          taskTemplates,
          widgets,
          approvalSubjectTasks,
          taskStatsMap,
          checklistWidgets,
        ) => {
          const taskTemplateMap = TaskTemplateListService.getTaskTemplateMap(taskTemplates);

          const allChecklistTasksCompleted =
            tasks.length !== 0 &&
            tasks.length === taskTemplates.length &&
            TaskListService.areAllTasksCompleted(taskTemplates, taskMap);

          $timeout(ctrl.initializeDisabledTaskTemplates);

          return {
            allChecklistTasksCompleted,
            dueDateRules,
            tasks,
            taskMap,
            taskTemplates,
            widgets,
            taskTemplateMap,
            approvalSubjectTaskIds: approvalSubjectTasks.map(t => t.id),
            taskStatsMap,
            checklistWidgets,
          };
        },
      );

    const bindActionsToCtrl = {
      getAllFilteredDddRulesByChecklistRevisionId: DynamicDueDateActions.getAllFilteredByChecklistRevisionId,
      updateTaskInternal: TaskActions.updateInternal,
      updateAllChecklistWidgetsInternal: WidgetActions.updateAllChecklistWidgetsInternal,
      getTaskStatsByChecklistRevisionId: TaskStatsActions.getAllByChecklistRevisionId,
      updateTaskStatsInternal: TaskStatsActions.updateInternal,
      updateAllTaskStatsInternal: TaskStatsActions.updateAllInternal,
    };

    const mapDispatchToProps = dispatch => ({
      actions: bindActionCreators(bindActionsToCtrl, dispatch),
    });

    ctrl.unsubscribe = $ngRedux.connect(null, mapDispatchToProps)(ctrl);

    ctrl.connectToRedux = (checklistRevision, activeTaskTemplateGroupId) => {
      if (ctrl.unsubscribe) {
        ctrl.unsubscribe();
      }
      ctrl.unsubscribe = $ngRedux.connect(
        ctrl.mapStateToThis(checklistRevision, activeTaskTemplateGroupId),
        mapDispatchToProps,
      )(ctrl);
    };

    ctrl.$onDestroy = () => {
      $scope.unsubscribeOneOffTasks?.();
      ctrl.unsubscribe();
    };

    ctrl.$onChanges = changes => {
      if (
        changes.activeTaskTemplateGroupId &&
        changes.activeTaskTemplateGroupId.currentValue &&
        ctrl.taskTemplates &&
        !changes.templateRevision
      ) {
        ctrl.activeTaskTemplateGroupId = changes.activeTaskTemplateGroupId.currentValue;
        setActiveTaskTemplateByGroupId(ctrl.activeTaskTemplateGroupId);

        if (Object.keys(ctrl.formFieldWidgetsMap).length > 0) {
          ctrl.showErrorsForInvalidFormFields();
        }
        ctrl.connectToRedux(ctrl.checklistRevision, ctrl.activeTaskTemplateGroupId);
      }

      if (changes.checklistRevision && changes.checklistRevision.currentValue) {
        ctrl.connectToRedux(ctrl.checklistRevision, ctrl.activeTaskTemplateGroupId);

        // subscribe to query updates
        $scope.unsubscribeOneOffTasks?.();
        $scope.unsubscribeOneOffTasks = OneOffTaskAngularHelper.subscribeToOneOffTasksForChecklist(
          queryClient,
          ctrl.checklistRevision.checklist.id,
          $scope.initOneOffTasks,
        );
      }

      if ((changes.checklistRevision || changes.templateRevision) && ctrl.checklistRevision && ctrl.templateRevision) {
        ctrl.assigneesMap = {};
        ctrl.taskStatsMap = {};
        ctrl.disabledTaskTemplateGroupIds = [];

        const shouldFlushCache = ctrl.isSandboxMode;
        TaskService.getAllByChecklistRevisionId(ctrl.checklistRevision.id, shouldFlushCache).then(
          tasks => {
            if (ctrl.checklistRevision?.checklist?.template?.templateType === TemplateType.Task) {
              const oneOffTask = tasks.find(task => task.taskTemplate.group.id === ctrl.activeTaskTemplateGroupId);
              if (oneOffTask) {
                $state.go('openOneOffTask', { id: oneOffTask.id });
                return;
              }
            }

            $scope.initOneOffTasks();

            const taskTemplates = tasks.map(task => task.taskTemplate);
            ctrl.initializeTaskTemplates(taskTemplates);
            ctrl.initializeChecklistTasks(tasks);

            ctrl.onTaskTemplatesLoaded({ taskTemplates: ctrl.taskTemplates });
            ctrl.completeChecklistInitializing();
            ctrl.initializeRulesEngine()?.then(_ => {
              ctrl.showErrorsForInvalidFormFields();
            });

            ctrl.actions.getAllFilteredDddRulesByChecklistRevisionId(ctrl.checklistRevision.id);
          },
          () => {
            logger.error('Failed to load tasks for ChecklistRevision %s', ctrl.checklistRevision.id);
            ToastService.openToast({
              status: 'error',
              title: `We're having problems loading the tasks`,
              description: DefaultErrorMessages.unexpectedErrorDescription,
            });
          },
        );

        if (ctrl.isInbox()) {
          logger.info('loading loadFormFieldValues, revision ', ctrl.checklistRevision.id);
          loadFormFieldValues(ctrl.checklistRevision);
        }
      }

      if (changes.formFieldValueMap && ctrl.taskTemplates) {
        ctrl.initializeDisabledTaskTemplates();
      }
    };

    ctrl.showErrorsForInvalidFormFields = () => {
      ChecklistService.validateNonEmptyValues({
        taskTemplates: ctrl.taskTemplates,
        taskMap: ctrl.taskMap,
        widgetsMap: ctrl.formFieldWidgetsMap,
        formFieldValueMap: ctrl.formFieldValueMap,
      });
    };

    ctrl.$onInit = function () {
      if (ctrl.inboxChecklistDetailsCtrl) {
        ctrl.inboxChecklistDetailsCtrl.registerTaskListCtrl(ctrl);
      }

      DueDateUpdateListener.listen($scope);
    };

    ctrl.updateFocus = function () {
      if ($stateParams.modal) return;
      if (!ctrl.checklistRevision.checklist.name || $state.params.new) {
        focusById('title', {
          selectionStart() {
            return 0;
          },
        });
      } else if (ctrl.activeTaskTemplate) {
        const options = {};
        if (ctrl.activeTaskTemplate.name === 'Heading:') {
          options.selectionStart = function () {
            return 0;
          };
          options.selectionEnd = function (length) {
            return length - 1;
          };
        }

        focusById(`step-${ctrl.activeTaskTemplate.group.id}`, options);
      }
    };

    ctrl.isChecklistEditor = () => ctrl.mode === TaskListConstants.Mode.CHECKLIST_EDITOR;

    ctrl.isInbox = () => ctrl.mode === TaskListConstants.Mode.INBOX;

    // We want these to be cached locally so we can reuse it to rebuild the rules
    // without invalidating the cache completely and pulling new data

    // The two variable below are needed for rules engine
    ctrl._formFieldWidgets = [];
    ctrl._formFieldValues = [];

    // The two below are needed for a proper functioning of required fields and stopped tasks validation
    // FIXME Why do we have formFieldWidgetsMap and _formFieldWidgets? Shouldn't one be enough?
    ctrl.formFieldWidgetsMap = {};
    if (ctrl.isInbox()) {
      // FIXME Why do we have formFieldValueMap and _formFieldValues? Shouldn't one be enough?
      ctrl.formFieldValueMap = {};
    }

    ctrl.rules = [];
    ctrl.initializeRulesEngine = function () {
      const widgetsRequest = WidgetService.getAllByChecklistRevisionId(ctrl.checklistRevision.id);
      const formFieldValuesRequest = FormFieldValueService.getAllByChecklistRevisionId(ctrl.checklistRevision.id);

      return $q
        .all({
          widgets: widgetsRequest,
          formFieldValues: formFieldValuesRequest,
        })
        .then(result => {
          ctrl._formFieldWidgets = result.widgets.filter(widget => widget.header.type === WidgetType.FormField);

          ctrl._formFieldValues = result.formFieldValues;

          ctrl._rebuildFormFieldWidgetAndValueMaps();

          return ctrl.rebuildRulesEngine(true /* flushCache */);
        });
    };

    ctrl._rebuildFormFieldWidgetAndValueMaps = () => {
      ctrl.formFieldWidgetsMap = MapUtils.toFormFieldWidgetMapByTaskTemplateGroupId(ctrl._formFieldWidgets);
    };

    ctrl.rebuildRulesEngine = (flushCache = false) => {
      return RuleService.getAllByChecklistRevisionId(ctrl.checklistRevision.id, flushCache)
        .then(ruleDefinitions =>
          ChecklistRulesEngineService.buildTaskVisibilityRules({
            ruleDefinitions,
            formFieldWidgets: ctrl._formFieldWidgets,
            formFieldValues: ctrl._formFieldValues,
            tasks: ctrl.tasks,
            checklist: ctrl.checklistRevision.checklist,
          }),
        )
        .then(rls => {
          ctrl.rules = rls;
        });
    };

    ctrl.applyRules = () => {
      if (!ctrl.rules.length) {
        return;
      }

      let checklistState = ChecklistRulesEngineService.buildChecklistStateObject(
        ctrl.checklistRevision.checklist,
        ctrl.tasks,
        ctrl.checklistWidgets,
      );

      // We need to reset the visibility status of the tasks
      checklistState = ChecklistRulesEngineService.resetChecklistState(
        checklistState,
        ctrl.taskTemplates,
        ctrl.widgets,
      );

      const updatedChecklistState = ChecklistRulesEngineService.execute(ctrl.rules, checklistState);

      const updatedTaskStateMap = {};
      updatedChecklistState.taskStates.forEach(ts => {
        updatedTaskStateMap[ts.taskTemplateGroupId] = ts;
      });

      ctrl.tasks.forEach(task => {
        const updatedTaskState = updatedTaskStateMap[task.taskTemplate.group.id];
        if (updatedTaskState && updatedTaskState.task && task.hidden !== updatedTaskState.task.hidden) {
          task.hidden = updatedTaskState.task.hidden;

          this.actions.updateTaskInternal({ ...task });

          const data = { updatedTask: task };
          $rootScope.$broadcast(EventName.TASK_HIDDEN_UPDATED, data);
        }
      });

      if (updatedChecklistState.checklistWidgetStates.length > 0) {
        const checklistWidgets = updatedChecklistState.checklistWidgetStates.map(cws => cws.checklistWidget);
        this.actions.updateAllChecklistWidgetsInternal(checklistWidgets);
      }

      ctrl.initializeDisabledTaskTemplates();

      updateChecklistProgress();
    };

    ctrl.initializeTaskTemplates = function (retrievedTaskTemplates) {
      TaskTemplateListService.initializeAssigneesMap(retrievedTaskTemplates, ctrl.assigneesMap);

      retrievedTaskTemplates.forEach(taskTemplate => {
        ctrl.onInitWidgets({ taskTemplateId: taskTemplate.group.id });
      });
    };

    function updateChecklistProgress() {
      const progress = TaskService.calculatePercentageComplete(ctrl.taskTemplates, ctrl.taskMap, $scope.oneOffTasks);
      ctrl.onSetChecklistProgress({ progress });
    }

    ctrl.initializeChecklistTasks = function (tasks) {
      const { checklistAssigneesMap } = ctrl;

      tasks.forEach(task => {
        checklistAssigneesMap[task.id] = checklistAssigneesMap[task.id] || [];
      });

      updateChecklistProgress();
    };

    ctrl.getTaskByTaskTemplateGroupId = function (taskTemplateGroupId) {
      return ctrl.taskMap[taskTemplateGroupId];
    };

    ctrl.initializeDisabledTaskTemplates = function () {
      const valuesReady = ctrl.formFieldWidgetsMap && ctrl.formFieldValueMap && ctrl.taskMap && ctrl.taskTemplates;
      if (ctrl.isChecklistActionable(ctrl.checklistRevision.checklist) && valuesReady) {
        const countBefore = ctrl.disabledTaskTemplateGroupIds && ctrl.disabledTaskTemplateGroupIds.length;

        const { disabledTaskTemplateGroupIds, firstStopGroupId } =
          StopTaskService.getDisabledTaskTemplateGroupIdsByFormFieldValue(
            ctrl.taskTemplates,
            ctrl.formFieldWidgetsMap,
            ctrl.formFieldValueMap,
            ctrl.taskMap,
          );

        const firstNotPermittedTaskStats = TaskStatsService.getStatsOfFirstNotPermittedNotCompletedStopTask(
          Object.values(ctrl.taskMap),
          ctrl.taskStatsMap,
        );

        const firstStopTaskTemplate =
          firstStopGroupId && ctrl.taskTemplates.find(tt => tt.group.id === firstStopGroupId);

        if (
          firstNotPermittedTaskStats &&
          (!firstStopTaskTemplate ||
            OrderTreeService.compare(firstNotPermittedTaskStats.orderTree, firstStopTaskTemplate.orderTree) < 0)
        ) {
          ctrl.disabledTaskTemplateGroupIds = ctrl.taskTemplates
            .filter(tt => OrderTreeService.compare(firstNotPermittedTaskStats.orderTree, tt.orderTree) < 0)
            .map(tt => tt.group.id)
            .filter(groupId => ctrl.taskMap[groupId] && !ctrl.taskMap[groupId].hidden);
        } else {
          ctrl.disabledTaskTemplateGroupIds = disabledTaskTemplateGroupIds;
        }

        ctrl.firstStopGroupId = firstStopGroupId;

        const countAfter = ctrl.disabledTaskTemplateGroupIds && ctrl.disabledTaskTemplateGroupIds.length;

        if (countBefore !== countAfter) {
          $rootScope.$broadcast(TaskListEvent.STOPPED_TASKS_COUNT_UPDATED);
        }
      }
    };

    ctrl.completeChecklistInitializing = function () {
      ctrl.initializeDisabledTaskTemplates();
      initializeChecklistAssignments(ctrl.checklistRevision.id).then(() => {
        ctrl.resolveActiveTaskTemplate();
      });
    };

    ctrl.checkIfAllTasksCompleted = function () {
      const allTasksCompleted =
        ctrl.allChecklistTasksCompleted &&
        !TaskStatsService.doesChecklistHaveNotPermittedNotCompletedStopTasks(
          Object.values(ctrl.taskMap),
          ctrl.taskStatsMap,
        );

      if (ctrl.allTasksCompleted !== allTasksCompleted) {
        const empty = ctrl.taskTemplates.length === 0;
        ctrl.onAllTasksStatusChanged({ allTasksCompleted, checklistHasTasks: !empty });
      }
      ctrl.allTasksCompleted = allTasksCompleted;
    };

    ctrl.getActiveOrSavedTaskTemplate = () => {
      const activeTaskTemplate = ctrl.taskTemplateMap[ctrl.activeTaskTemplateGroupId];

      if (ctrl.activeTaskTemplateGroupId && (!activeTaskTemplate || ctrl.isTaskHiddenByCL(activeTaskTemplate))) {
        // task should be there but is either not found or hidden by CL
        ToastService.openToast("Oops! This task may not exist or you don't have permission to view it.");
      } else if (activeTaskTemplate) {
        // task found

        if (ctrl.isStoppedTaskHidden(activeTaskTemplate)) {
          // we need to unfold hidden stopped tasks
          ctrl.stoppedTasksShown = true;
        }

        return activeTaskTemplate;
      }

      // task or not selected or not found - let's try with saved one
      const savedTaskTemplateGroupId = SessionService.getChecklistEditorActiveStep($state.params.id);

      const savedTaskTemplate =
        ctrl.taskTemplateMap[ctrl.activeTaskTemplateGroupId] || ctrl.taskTemplateMap[savedTaskTemplateGroupId];

      // check if hidden by CL
      if (savedTaskTemplate && ctrl.shouldHideTask(savedTaskTemplate)) {
        return undefined;
      }

      return savedTaskTemplate;
    };

    ctrl.resolveActiveTaskTemplate = () => {
      const visibleTaskTemplates = ctrl.getVisibleTaskTemplates();
      const activeTaskTemplate =
        ctrl.getActiveOrSavedTaskTemplate() ||
        (ctrl.isChecklistEditor() &&
          visibleTaskTemplates.length > 0 &&
          visibleTaskTemplates.find(({ taskType }) => taskType !== TaskTemplateTaskType.AI));

      ctrl.selectTaskTemplate(activeTaskTemplate, true /* replace */);

      if (activeTaskTemplate) {
        // This is necessary because the $stateChangeSuccess event will already have fired,
        // but since there were no $scope.tasks, it couldn't task it
        setActiveTaskTemplateByGroupId(activeTaskTemplate.group.id);
      } else {
        hideWidgets();
      }
    };

    ctrl.checklistAssigneesMap = {};

    function initializeChecklistAssignments(checklistRevisionId) {
      return ChecklistTaskAssignmentService.getAllByChecklistRevisionId(checklistRevisionId).then(assignments => {
        angular.extend(ctrl.checklistAssigneesMap, ChecklistTaskAssignmentService.toAssigneesMap(assignments));
      });
    }

    ctrl.getTaskAssignees = function (taskTemplate) {
      const task = ctrl.taskMap[taskTemplate.group.id];
      return task && ctrl.checklistAssigneesMap[task.id];
    };

    // Tasks

    /**
     * Selects the task if the event has not been prevented.
     *
     * @param event
     * @param taskTemplate
     */
    ctrl.clickTaskTemplate = function (event, taskTemplate) {
      // TODO instead of doing this, we can achieve the same through stop propagation on assignments component
      if (!event.originalEvent.fromAssignmentClick) {
        ctrl.selectTaskTemplate(taskTemplate);
      }
    };

    ctrl.selectTaskTemplate = function (taskTemplate, replace) {
      if (
        !taskTemplate ||
        (taskTemplate && ctrl.activeTaskTemplate && taskTemplate.group.id === ctrl.activeTaskTemplate.group.id) ||
        isAutomatedTask(taskTemplate)
      ) {
        // Ignore, it's the same task or the task is not selectable
        return;
      }

      ctrl.onSelectTaskTemplate({ taskTemplate, replace });
    };

    // Handle key events

    ctrl.handleTaskTemplateKeydown = function (event, taskTemplate) {
      // This doesn't work well on mobile
      if (util.isMobile()) {
        return;
      }

      switch (event.keyCode) {
        case Key.UP_ARROW:
          selectTaskTemplateAbove(taskTemplate);
          event.preventDefault();
          break;
        case Key.DOWN_ARROW:
          ctrl.selectTaskTemplateBelow(taskTemplate, false /*skipEmptyHeading*/);
          event.preventDefault();
          break;
        case Key.SPACE:
          ctrl.toggleTaskStatus(taskTemplate);
          event.stopPropagation();
          event.preventDefault();
          break;
        default: // We don't care about other keys
      }
    };

    /**
     * Makes a task template (taskTemplate) above <b>taskTemplate</b> to be selected (active).
     * Looks for a taskTemplate that is not in <b>_deleting</b> state.
     *
     * @param taskTemplate will be used as a lower bound and it cannot be selected
     * @returns {boolean} <b>true</b> if a task above was selected (exists and not in deleting state),
     *                    <b>false</b> - otherwise
     */
    function selectTaskTemplateAbove(taskTemplate) {
      const taskTemplateToSelect = TaskListService.getTaskTemplateAbove(taskTemplate, ctrl.taskTemplates, ctrl.tasks);

      if (taskTemplateToSelect.group.id !== taskTemplate) {
        ctrl.selectTaskTemplate(taskTemplateToSelect);
        return true;
      } else {
        return false;
      }
    }

    /**
     * Makes a task template (taskTemplate) below <b>taskTemplate</b> to be selected (active).
     * Looks for a taskTemplate that is not in <b>_deleting</b> state.
     *
     * @param taskTemplate will be used as an upper bound and it cannot be selected
     * @param skipEmptyHeading
     * @returns {boolean} <b>true</b> if a task below was selected (exists and not in deleting state),
     *                    <b>false</b> - otherwise
     */
    ctrl.selectTaskTemplateBelow = function (taskTemplate, skipEmptyHeading = true) {
      const taskTemplateToSelect = resolveTaskTemplateBelow(taskTemplate, skipEmptyHeading);

      if (taskTemplateToSelect.group.id !== taskTemplate.group.id) {
        ctrl.selectTaskTemplate(taskTemplateToSelect);
        return true;
      } else {
        return false;
      }
    };

    function resolveTaskTemplateBelow(taskTemplate, skipEmptyHeading = true) {
      const taskTemplateBelow = TaskListService.getTaskTemplateBelow(taskTemplate, ctrl.taskTemplates, ctrl.tasks);

      if (!skipEmptyHeading) {
        return taskTemplateBelow;
      }

      if (!taskTemplateBelow) {
        return taskTemplate;
      }

      const hasWidgets = BaseWidgetSelector.existsByTaskTemplateId(taskTemplateBelow.id)($ngRedux.getState());
      if (
        taskTemplateBelow.group.id !== taskTemplate.group.id &&
        TaskTemplateService.isHeading(taskTemplateBelow) &&
        !hasWidgets
      ) {
        return resolveTaskTemplateBelow(taskTemplateBelow);
      }

      return taskTemplateBelow;
    }

    ctrl.getTaskClasses = function (task) {
      let classes = {};
      if (task) {
        const active = ctrl.isActive(task.taskTemplate);
        classes = TaskTemplateListService.getTaskTemplateClasses(task.taskTemplate, active /* selected */);
        const disabled = ctrl.isTaskTemplateDisabled(task.taskTemplate);
        const hasInvalidFields = ctrl.taskStatsMap[task.id] && ctrl.taskStatsMap[task.id].invalidFieldsCount > 0;

        classes['task-disabled'] = disabled;
        classes['not-completable'] = !ctrl.isTaskTemplateCompletable(task.taskTemplate);
        classes['has-error'] =
          (!disabled && task._statusToggleRequested && task._updateFailed && hasInvalidFields) || false;
        classes['completed'] = task.status === TaskStatus.Completed;
        classes['approval-subject'] = ctrl.approvalSubjectTaskIds.includes(task.id);
        classes['required'] = ctrl.nonCompletedStopTaskIds.includes(task.id);
        const taskType = isAutomatedTask(task.taskTemplate) ? 'Automated' : 'Standard';
        classes[`task-type-${taskType}`] = true;
      }
      return classes;
    };

    ctrl.isHeading = TaskTemplateService.isHeading;

    ctrl.isApproval = TaskTemplateService.isApproval;

    ctrl.isActive = function (taskTemplate) {
      return TaskTemplateService.hasSameGroupId(taskTemplate, ctrl.activeTaskTemplate);
    };

    ctrl.fireOnTaskStatusChanged = function (taskTemplate, status) {
      updateChecklistProgress();
      ctrl.onTaskStatusChanged({ taskTemplate, status });
    };

    ctrl.getVisibleTaskTemplates = function () {
      const visibleTaskTypes = [
        TaskTemplateTaskType.Standard,
        TaskTemplateTaskType.Approval,
        TaskTemplateTaskType.AI,
        TaskTemplateTaskType.Code,
      ];
      // We must assume that the taskMap is in place and full,
      // otherwise the data is not yet ready to be presented
      const taskTemplates =
        angular.isArray(ctrl.taskTemplates) && ctrl.taskMap
          ? ctrl.taskTemplates.filter(
              taskTemplate =>
                ctrl.taskMap[taskTemplate.group.id] &&
                !ctrl.taskMap[taskTemplate.group.id].hidden &&
                visibleTaskTypes.includes(taskTemplate.taskType),
            )
          : [];

      // side effect for get but it's optimisation
      ctrl.updateStepNumbersMap(taskTemplates);

      return taskTemplates;
    };

    ctrl.stepNumbersMap = {};
    ctrl.updateStepNumbersMap = taskTemplates => {
      const shouldSkipAiTasksInNumbering = FeatureFlagService.getFeatureFlags().reactWorkflowEditor;
      const filteredTaskTemplates = shouldSkipAiTasksInNumbering
        ? taskTemplates.filter(tt => tt.taskType !== TaskTemplateTaskType.AI)
        : taskTemplates;
      ctrl.stepNumbersMap = Object.fromEntries(
        filteredTaskTemplates.map((taskTemplate, index) => [taskTemplate.id, index + 1]),
      );
    };

    // hidden by CL
    ctrl.isTaskHiddenByCL = taskTemplate => {
      const groupId = taskTemplate.group.id;
      return ctrl.taskMap[groupId] && ctrl.taskMap[groupId].hidden;
    };

    // hidden by stop task
    ctrl.isStoppedTaskHidden = taskTemplate => {
      return !ctrl.isTaskTemplateCompletable(taskTemplate) && !ctrl.stoppedTasksShown;
    };

    //  hidden by UI action
    ctrl.isCompletedTaskHidden = taskTemplate => {
      const task = ctrl.taskMap[taskTemplate.group.id];
      const hasInvalidFields = ctrl.taskStatsMap[task.id] && ctrl.taskStatsMap[task.id].invalidFieldsCount > 0;
      return ctrl.hideCompletedTasks && !hasInvalidFields && task.status === TaskStatus.Completed;
    };

    ctrl.shouldHideTask = taskTemplate =>
      ctrl.isStoppedTaskHidden(taskTemplate) ||
      ctrl.isTaskHiddenByCL(taskTemplate) ||
      ctrl.isCompletedTaskHidden(taskTemplate);

    ctrl.canUpdateTask = function (task) {
      return TaskListService.canUpdateTask(
        ctrl.isInbox(),
        task,
        ctrl.taskStatsMap[task.id],
        ctrl.widgetsMap,
        ctrl.formFieldValueMap,
        ctrl.taskMap,
      );
    };

    ctrl._validateIfTaskStatusUpdatable = (task, newStatus) => {
      const visibleFFWidgets = WidgetUtils.filterVisibleWidgets(ctrl._formFieldWidgets, ctrl.checklistWidgets);
      const formFieldWidgetsMap = MapUtils.toFormFieldWidgetMapByTaskTemplateGroupId(visibleFFWidgets);

      return TaskService.validateIfTaskStatusUpdatable(
        task,
        newStatus,
        formFieldWidgetsMap,
        ctrl.formFieldValueMap,
        ctrl.taskMap,
        ctrl.taskTemplates,
        ctrl.taskStatsMap,
      );
    };

    ctrl._revalidateAllFailedToUpdateTasksStatusUpdatability = ignoreRequiredFields => {
      const updatedStats = ctrl.tasks.map(task => validateTaskStatusUpdatability({ task, ignoreRequiredFields }));
      ctrl.actions.updateAllTaskStatsInternal(updatedStats);
    };

    ctrl._revalidateTaskStatusUpdatabilityByTaskTemplateGroupId = taskTemplateGroupId => {
      const updatedStats = ctrl.tasks
        .filter(task => task.taskTemplate.group.id === taskTemplateGroupId)
        .map(task => validateTaskStatusUpdatability({ task }));

      ctrl.actions.updateAllTaskStatsInternal(updatedStats);
    };

    function validateTaskStatusUpdatability({ task, ignoreRequiredFields }) {
      const newStatus = TaskStatus.Completed;
      const result = ctrl._validateIfTaskStatusUpdatable(task, newStatus);

      let invalidFieldsCount = 0;
      if (result.updatable) {
        delete task._updateFailed;
      } else {
        task._updateFailed = true;

        invalidFieldsCount = ignoreRequiredFields
          ? result.errors.failedConstraintsFormFields?.length || 0
          : result.errors.invalidFieldCount;
      }

      return { ...ctrl.taskStatsMap[task.id], invalidFieldsCount };
    }

    ctrl.updateFormFieldValueMap = function (formFieldValueMap) {
      ctrl.formFieldValueMap = formFieldValueMap;
    };

    ctrl.toggleChecklistTaskStatus = taskTemplate => {
      const task = ctrl.taskMap[taskTemplate.group.id];
      task._statusToggleRequested = true;

      const originalStatus = task.status;
      const newStatus = TaskService.getReversedTaskStatus(originalStatus);

      const result = ctrl._validateIfTaskStatusUpdatable(task, newStatus);

      if (result.updatable) {
        delete task._updateFailed;
        task.status = newStatus;
        task.completedDate = newStatus === TaskStatus.Completed ? Date.now() : null;

        this.actions.updateTaskInternal({ ...task });

        ctrl.initializeDisabledTaskTemplates();
        ctrl.fireOnTaskStatusChanged(taskTemplate, newStatus);
        ctrl.validateDynamicDueDatesOnTaskStatusChange(task, originalStatus, newStatus);

        const promise = isAutomatedTask(taskTemplate)
          ? TaskService.autoCompleteChecklistIfAllTasksCompleted({
              checklistRevision: ctrl.checklistRevision,
              taskTemplates: ctrl.taskTemplates,
              taskMap: ctrl.taskMap,
              taskStatsMap: ctrl.taskStatsMap,
              oneOffTasks: $scope.oneOffTasks,
            })
          : TaskListService.updateTaskStatus({
              task,
              newStatus,
              checklistRevision: ctrl.checklistRevision,
              taskTemplates: ctrl.taskTemplates,
              taskMap: ctrl.taskMap,
              taskStatsMap: ctrl.taskStatsMap,
              oneOffTasks: $scope.oneOffTasks,
            });

        return promise
          .then(async () => {
            // in new inbox, attempt autocompletion only after waiting for the last task to complete
            // this way, by the time inbox query is invalidated, we have already completed the last task & the checklist
            ctrl.checkIfAllTasksCompleted();
            ctrl.initializeDisabledTaskTemplates();

            delete task._statusToggleRequested;

            // Refetch task list after checking-unchecking a task, which causes issues with approvals.
            await queryClient.invalidateQueries(
              GetAllTasksByChecklistRevisionIdQuery.getKey({ checklistRevisionId: ctrl.checklistRevision.id }),
            );
            await queryClient.invalidateQueries({
              queryKey: GetAllNativeAutomationLogsByChecklistIdQuery.getKey({
                checklistId: ctrl.checklistRevision.checklist.id,
              }),
            });

            return newStatus;
          })
          .catch(() => {
            // Rollback
            task.status = originalStatus;
            task._updateFailed = true;
            this.actions.updateTaskInternal({ ...task });

            ctrl.initializeDisabledTaskTemplates();
            ctrl.fireOnTaskStatusChanged(taskTemplate, originalStatus);
          });
      } else {
        task._updateFailed = true;
        this.actions.updateTaskInternal({ ...task });
        const invalidFieldCount = result.errors && result.errors.invalidFieldCount;

        //TODO refactor to treat all errors with the same service
        if (result.errors) {
          if (result.errors.invalidFormFields) {
            RequiredFieldService.broadcastTaskHasInvalidFormFields(taskTemplate, result.errors.invalidFormFields);
          }
          if (result.errors.failedConstraintsFormFields) {
            FormFieldValueService.broadcastTaskHasFailedConstraintsFormFields(
              taskTemplate,
              result.errors.failedConstraintsFormFields,
            );
          }
        }

        const stats = { ...ctrl.taskStatsMap[task.id], invalidFieldsCount: invalidFieldCount };
        ctrl.actions.updateTaskStatsInternal(stats);

        if (result.errors && result.errors.stop) {
          ChecklistService.validateAndComplete({
            checklist: ctrl.checklistRevision.checklist,
            taskTemplates: ctrl.taskTemplates,
            taskMap: ctrl.taskMap,
            widgetsMap: ctrl.formFieldWidgetsMap,
            formFieldValueMap: ctrl.formFieldValueMap,
            complete: false,
          });
        } else if (result.errors && result.errors.stoppedByHiddenTask) {
          ToastService.openToast({
            status: 'warning',
            title: `We couldn't complete the task`,
            description: `Some fields somewhere else in this workflow run still need to be completed.`,
          });
        } else if (invalidFieldCount) {
          const errorMessage = ProcessingErrorUtils.makeRequiredOrFailedMessage({
            requiredCount: result.errors.invalidFormFields?.length,
            failedCount: result.errors.failedConstraintsFormFields?.length,
          });
          ToastService.openToast({
            status: 'warning',
            title: `We couldn't complete the task`,
            description: errorMessage,
          });
        }
      }
    };

    ctrl.toggleTaskStatus = async (taskTemplate, source) => {
      if (
        ctrl.isChecklistActionable(ctrl.checklistRevision.checklist) &&
        ctrl.isTaskTemplateCompletable(taskTemplate) &&
        !ctrl.isApproval(taskTemplate)
      ) {
        ctrl._setNonCompletedStopTaskIds([]);

        const goToNextTask = source !== TaskStatusToggleSource.TaskListItem;

        await ctrl.toggleChecklistTaskStatus(taskTemplate, goToNextTask);

        return ctrl.rebuildRulesEngine().then(() => {
          ctrl.applyRules();

          const task = ctrl.taskMap[taskTemplate.group.id];

          if (goToNextTask && task && task.status === TaskStatus.Completed && !task._updateFailed) {
            ctrl.selectTaskTemplateBelow(taskTemplate);
          }
        });
      }
    };

    ctrl.handleApprovalTaskUpdate = async task => {
      ctrl.tasks = ctrl.tasks.map(t => (t.id === task.id ? task : t));

      ctrl.actions.updateTaskInternal(task);

      ctrl.completeWorkflowIfApprovalTaskIsLastTask(task);

      ctrl.rebuildRulesEngine().then(() => {
        ctrl.applyRules();
      });
    };

    ctrl.completeWorkflowIfApprovalTaskIsLastTask = approvalTask => {
      const areAllTasksCompleted = ctrl.tasks.every(task => task.status === TaskStatus.Completed);
      const requiredOneOffTasks = OneOffTaskHelper.getRequiredNotCompletedTasks($scope.oneOffTasks);
      const areAllRequiredOneOffTasksCompleted = requiredOneOffTasks.length === 0;

      if (areAllTasksCompleted && areAllRequiredOneOffTasksCompleted) {
        $rootScope.$broadcast(ChecklistEvent.COMPLETED_FROM_APPROVAL_TASK, {
          approvalTask,
        });
      }
    };

    let userHasSelectedTaskBefore = false;

    function setActiveTaskTemplateByGroupId(groupId) {
      const activeTaskTemplate = ctrl.taskTemplates && ctrl.taskTemplateMap[groupId];

      if (activeTaskTemplate) {
        if (!userHasSelectedTaskBefore || ResponsiveBootstrapToolkit.is('<=sm')) {
          userHasSelectedTaskBefore = true;
          showWidgets();
        }

        SessionService.setChecklistEditorActiveStep(ctrl.checklistRevision.checklist.id, activeTaskTemplate.group.id);

        // Go to top
        $anchorScroll('widgets-top');

        ctrl.activeTaskTemplate = activeTaskTemplate;
        const task = ctrl.taskMap[activeTaskTemplate.group.id];

        ctrl.updateFocus();

        ctrl.onActiveTaskTemplateSet({
          taskTemplate: activeTaskTemplate,
          task,
          completable: ctrl.isTaskTemplateCompletable(activeTaskTemplate),
          assignees: ctrl.checklistAssigneesMap[task.id],
          canMoveUp: TaskTemplateListService.canMoveTaskTemplateUp(ctrl.activeTaskTemplate, ctrl.taskTemplates),
          canMoveDown: ctrl.canMoveToNextTaskTemplate(ctrl.activeTaskTemplate),
        });
      }
    }

    ctrl.canMoveToNextTaskTemplate = function (activeTaskTemplate) {
      return TaskTemplateListService.canMoveTaskTemplateDown(activeTaskTemplate, ctrl.taskTemplates);
    };

    ctrl.isChecklistActionable = ChecklistService.isChecklistActionable;

    ctrl.nonCompletedStopTaskIds = [];
    ctrl._setNonCompletedStopTaskIds = function (notCompletedStopTasks) {
      ctrl.nonCompletedStopTaskIds = notCompletedStopTasks.map(t => t.id);
    };

    $scope.$on(StopTaskEvent.CHECKLIST_HAS_NOT_COMPLETED_STOP_TASKS, (__event, data) => {
      ctrl._setNonCompletedStopTaskIds(data.notCompletedStopTasks);
    });

    $scope.$on(StopTaskEvent.RESET_NON_COMPLETED_STOP_TASKS, () => {
      ctrl._setNonCompletedStopTaskIds([]);
    });

    $scope.$on(TaskListEvent.SUBJECT_TASK_REJECTED, (__event, subjectTask) => {
      ctrl.validateDynamicDueDatesOnTaskStatusChange(subjectTask, TaskStatus.Completed);
    });

    ctrl.isTaskTemplateDisabled = function (taskTemplate) {
      return ctrl.disabledTaskTemplateGroupIds.indexOf(taskTemplate.group.id) > -1;
    };

    /**
     * A task is completable if it is not disabled, or if it's the first uncompleted stop task
     * This way the user can get feedback on what needs to be completed for their stop task.
     */
    ctrl.isTaskTemplateCompletable = function (taskTemplate) {
      const firstStopTask = ctrl.firstStopGroupId === taskTemplate.group.id;
      const disabled = ctrl.isTaskTemplateDisabled(taskTemplate);
      return !disabled || firstStopTask;
    };

    ctrl.isNextTaskSelectable = function (taskTemplate, taskTemplates) {
      const index = taskTemplates.indexOf(taskTemplate);
      return index !== taskTemplates.length - 1 && !ctrl.isTaskTemplateDisabled(taskTemplates[index + 1]);
    };

    ctrl.toggleStoppedTasksShown = function () {
      ctrl.stoppedTasksShown = !ctrl.stoppedTasksShown;

      $rootScope.$broadcast(TaskListEvent.STOPPED_TASKS_SHOWN_TOGGLED, ctrl.stoppedTasksShown);
    };

    ctrl.doStoppedTasksExist = function () {
      if (ctrl.disabledTaskTemplateGroupIds) {
        if (ctrl.disabledTaskTemplateGroupIds.length === 1) {
          return ctrl.firstStopGroupId !== ctrl.disabledTaskTemplateGroupIds[0];
        } else {
          return ctrl.disabledTaskTemplateGroupIds.length > 0;
        }
      }
      return false;
    };

    ctrl.taskProgressMap = {};

    function updateTaskProgress(groupId, taskProgress) {
      ctrl.taskProgressMap[groupId] = taskProgress;
    }

    // Widgets

    ctrl.toggleWidgetsVisibility = function (task) {
      ctrl.onToggleWidgetsVisibility({ task });
    };

    function showWidgets(instantly) {
      ctrl.onSetWidgetsVisibility({ visible: true, instantly });
    }

    function hideWidgets() {
      ctrl.onSetWidgetsVisibility({ visible: false });
    }

    function loadFormFieldValues(checklistRevision) {
      return FormFieldValueService.getAllByChecklistRevisionId(checklistRevision.id).then(ffValues => {
        ctrl.formFieldValueMap = {};
        FormFieldValueService.initializeFormFieldValueMap(ctrl.formFieldValueMap, ffValues, ctrl.widgetsMap);
      });
    }

    ctrl.updateStatsOnInvalidChecklistFields = function (ignoreRequiredFields = false) {
      ctrl._revalidateAllFailedToUpdateTasksStatusUpdatability(ignoreRequiredFields);
      ctrl.tasks
        .filter(t => ctrl.taskStatsMap[t.id] && ctrl.taskStatsMap[t.id].invalidFieldsCount > 0)
        .forEach(task => {
          task._statusToggleRequested = true;
          task._updateFailed = true;
          this.actions.updateTaskInternal({ ...task });
        });
    };

    // Events

    $scope.$on(RequiredFieldEvent.CHECKLIST_HAS_INVALID_FORM_FIELDS, () => {
      ctrl.updateStatsOnInvalidChecklistFields();
    });

    //TODO we should unify required + constraint events
    $scope.$on(
      FormFieldValueEvent.CHECKLIST_HAS_FAILED_CONSTRAINTS_FORM_FIELDS,
      (__event, { ignoreRequiredFields }) => {
        ctrl.updateStatsOnInvalidChecklistFields(ignoreRequiredFields);
      },
    );

    $scope.$on(RequiredFieldEvent.TASK_HAS_INVALID_FORM_FIELDS, (__event, data) => {
      const task = ctrl.getTaskByTaskTemplateGroupId(data.taskTemplate.group.id);
      task._statusToggleRequested = true;
      task._updateFailed = true;
    });

    $scope.$on(FormFieldValueEvent.TASK_HAS_FAILED_CONSTRAINTS_FORM_FIELDS, (__event, data) => {
      const task = ctrl.getTaskByTaskTemplateGroupId(data.taskTemplate.group.id);
      task._statusToggleRequested = true;
      task._updateFailed = true;
    });

    $scope.$on(TaskListEvent.SELECT_TASK_BELOW_REQUEST, (__event, taskTemplate) => {
      ctrl.selectTaskTemplateBelow(taskTemplate);
    });

    $scope.$on(TaskListEvent.TOGGLE_TASK_STATUS_REQUEST, (__event, taskTemplate) => {
      ctrl.toggleTaskStatus(taskTemplate);
    });

    $scope.$on(TaskListEvent.TASK_APPROVAL_STATUS_CHANGED, (__event, task) => {
      ctrl.handleApprovalTaskUpdate(task);
    });

    $scope.$on(TaskListEvent.AI_TASK_COMPLETED, (__event, taskTemplate) => {
      const task = ctrl.taskMap[taskTemplate.group.id];
      if (task?.status === TaskStatus.NotCompleted) {
        ctrl.toggleTaskStatus(taskTemplate);
      }
    });

    $scope.$on(TaskListEvent.TASK_PROGRESS_UPDATED, (__event, taskTemplateGroupId, taskProgress) => {
      updateTaskProgress(taskTemplateGroupId, taskProgress);
    });

    $scope.$on(AttachmentEvent.ATTACHMENT_CREATE_OK, (__event, taskId) => {
      const stats = ctrl.taskStatsMap[taskId];
      const newStats = { ...stats, attachmentsCount: stats.attachmentsCount + 1 };
      ctrl.actions.updateTaskStatsInternal(newStats);
    });

    $scope.$on(AttachmentEvent.ATTACHMENT_DELETE_OK, (__event, taskId) => {
      const stats = ctrl.taskStatsMap[taskId];
      const newStats = { ...stats, attachmentsCount: stats.attachmentsCount - 1 };
      ctrl.actions.updateTaskStatsInternal(newStats);
    });

    $scope.$on(CommentEvent.COMMENT_CREATE_OK, (__event, taskId) => {
      const stats = ctrl.taskStatsMap[taskId];
      const newStats = { ...stats, commentsCount: stats.commentsCount + 1 };
      ctrl.actions.updateTaskStatsInternal(newStats);
    });

    $scope.$on(CommentEvent.COMMENT_DELETE_OK, (__event, taskId) => {
      const stats = ctrl.taskStatsMap[taskId];
      const newStats = { ...stats, commentsCount: stats.commentsCount - 1 };
      ctrl.actions.updateTaskStatsInternal(newStats);
    });

    ctrl._onFormFieldUpdated = formFieldValue => {
      const formFieldWidget = ctrl._formFieldWidgets.find(widget => widget.id === formFieldValue.formFieldWidget.id);

      ctrl._updateFormFieldValues(formFieldValue);

      ctrl.rebuildRulesEngine().then(() => {
        ctrl.applyRules();
        ctrl.initializeDisabledTaskTemplates();

        ctrl._rebuildFormFieldWidgetAndValueMaps();
        ctrl._revalidateTaskStatusUpdatabilityByTaskTemplateGroupId(formFieldWidget.header.taskTemplate.group.id);
      });
    };

    $scope.$on(FormFieldEvent.FORM_FIELD_INTERACTION_ENDED, (__event, widget, formFieldValue) => {
      // THIS IS S TERRIBLE HACK!!!
      // When you see it, pretend like you didn't and simply ignore...
      // This is required in order to make sure that calculation of rules is taking place
      // before anything else can happen and break the flow
      const [failedWidget] = FormFieldValueService.getFailedFormFieldWidgets({
        taskWidgets: [widget],
        formFieldValueMap: { [widget.header.id]: formFieldValue },
      });
      if (failedWidget) return;
      ctrl._onFormFieldUpdated(formFieldValue);
    });

    $scope.$on(FormFieldEvent.FORM_FIELD_VALUE_UPDATED, (__event, formFieldValue) => {
      ctrl._onFormFieldUpdated(formFieldValue);
    });

    $scope.$on(FormFieldEvent.FORM_FIELD_VALUE_UPDATE_STARTED, (__event, formFieldValue, originalFormFieldValue) => {
      ctrl.validateVarDueDatesOnFormFieldValueChange(formFieldValue, originalFormFieldValue);
    });

    $scope.$on(FormFieldEvent.FORM_FIELD_VALUE_LIVE_UPDATED, (__event, formFieldValue) => {
      const { originalFormFieldValue, formFieldValueWithWidget, formFieldWidget } =
        TaskListHelper.getOriginalFormFieldValue(ctrl._formFieldWidgets, ctrl._formFieldValues, formFieldValue);

      if (!formFieldWidget) {
        // This check is needed as we might run into a situation that the checklist is on an old revision
        // and the field does not exists in the local copy of the checklist (has not been refreshed yet)
        logger.warn(`widget not found by id ${formFieldValue.formFieldWidget.id}`);
        return;
      }

      ctrl._updateFormFieldValues(formFieldValueWithWidget);
      ctrl._rebuildFormFieldWidgetAndValueMaps();

      ctrl.rebuildRulesEngine().then(() => {
        ctrl.applyRules();
      });

      ctrl.validateVarDueDatesOnFormFieldValueChange(formFieldValueWithWidget, originalFormFieldValue);

      ctrl._revalidateTaskStatusUpdatabilityByTaskTemplateGroupId(formFieldWidget.header.taskTemplate.group.id);
    });

    $scope.$on(FormFieldEvent.FORM_FIELD_VALUE_LIVE_DELETED, (__event, formFieldValue) => {
      const formFieldWidget = ctrl._formFieldWidgets.find(widget => widget.id === formFieldValue.formFieldWidget.id);

      if (!formFieldWidget) {
        // This check is needed as we might run into a situation that the checklist is on an old revision
        // and the field does not exists in the local copy of the checklist (has not been refreshed yet)
        logger.warn(`widget not found by id ${formFieldValue.formFieldWidget.id}`);
        return;
      }

      const formFieldValueWithWidget = { ...formFieldValue, formFieldWidget };

      ctrl._deleteFormFieldValues(formFieldValueWithWidget);
      ctrl._rebuildFormFieldWidgetAndValueMaps();

      ctrl.rebuildRulesEngine().then(() => {
        ctrl.applyRules();
      });

      ctrl._revalidateTaskStatusUpdatabilityByTaskTemplateGroupId(formFieldWidget.header.taskTemplate.group.id);
    });

    $scope.$on(FormFieldEvent.FORM_FIELD_VALUE_UPDATE_OK, (__event, formFieldValue) => {
      ctrl._updateFormFieldValues(formFieldValue);
    });

    $scope.$on(FormFieldEvent.FORM_FIELD_VALUE_UPDATE_FAILED, (__event, formFieldValue, originalFormFieldValue) => {
      const formFieldWidget = ctrl._formFieldWidgets.find(widget => widget.id === formFieldValue.formFieldWidget.id);

      if (formFieldWidget && FormFieldService.isRevertible(formFieldWidget)) {
        ctrl._updateFormFieldValues(originalFormFieldValue);

        ctrl.rebuildRulesEngine().then(() => {
          ctrl.applyRules();
        });

        ctrl._rebuildFormFieldWidgetAndValueMaps();
      }
    });

    ctrl._deleteFormFieldValuesInternal = (formFieldValues, formFieldValue) => {
      const formFieldWidgetId = formFieldValue.formFieldWidget.id;

      // We search via the widget id because the form field value id might not exist (for new values)
      const existingValueIndex = formFieldValues.findIndex(v => v.formFieldWidget.id === formFieldWidgetId);
      if (existingValueIndex !== -1) {
        formFieldValues.splice(existingValueIndex, 1);
      }
    };

    ctrl._updateFormFieldValues = newFormFieldValue => {
      ctrl._formFieldValues = TaskListHelper.getUpdatedFormFieldValues(ctrl._formFieldValues, newFormFieldValue);
    };

    ctrl._deleteFormFieldValues = formFieldValue => {
      ctrl._deleteFormFieldValuesInternal(ctrl._formFieldValues, formFieldValue);
    };

    $scope.$on(EventName.TASK_DUE_DATE_UPDATE_OK, (__event, data) => {
      logger.info('TASK_DUE_DATE_UPDATE_OK', data);
      const { updatedTask } = data;
      if (updatedTask) {
        const task = ctrl.tasks.find(t => t.id === updatedTask.id);
        if (task) {
          task.dueDate = updatedTask.dueDate;
          task.dueDateOverridden = updatedTask.dueDateOverridden;
        }
      }
    });

    ctrl.validateDynamicDueDatesOnTaskStatusChange = (updatedTask, originalStatus, __newStatus) => {
      const originalTask = Object.assign({}, updatedTask, { status: originalStatus });

      TaskDueDateListenerService.updateTaskDueDatesByTaskCompletedDateSource(
        ctrl.dueDateRules,
        ctrl.tasks,
        ctrl.checklistRevision.checklist,
        updatedTask,
        originalTask,
      );
      TaskDueDateListenerService.updateTaskDueDatesByPreviousTaskCompletedDateSource(
        ctrl.dueDateRules,
        ctrl.tasks,
        updatedTask,
        originalTask,
        ctrl.taskStatsMap,
      );
    };

    ctrl.validateVarDueDatesOnFormFieldValueChange = (updatedFormFieldValue, originalFormFieldValue) => {
      if (!updatedFormFieldValue) return;

      const formFieldValues = TaskListHelper.getUpdatedFormFieldValues(ctrl._formFieldValues, updatedFormFieldValue);

      TaskDueDateListenerService.updateTaskDueDatesByFormFieldValueSource(
        ctrl.dueDateRules,
        ctrl.tasks,
        ctrl.checklistRevision.checklist,
        formFieldValues,
        updatedFormFieldValue,
        originalFormFieldValue,
      );
    };

    $scope.$on(ChecklistEvent.UPDATE_DUE_DATE_STARTED, (__event, data) => {
      TaskDueDateListenerService.updateTaskDueDatesByChecklistDueDateSource(
        ctrl.dueDateRules,
        ctrl.tasks,
        data.updatedChecklist,
      );
    });

    ctrl.getAriaLabel = function (index) {
      return `task run ${index} ${ctrl.checklistRevision.checklist.template.name}`;
    };

    $scope.oneOffTasks = [];
    $scope.initOneOffTasks = () => {
      OneOffTaskAngularHelper.fetchOneOffTasksForChecklist(queryClient, ctrl.checklistRevision.checklist.id).then(
        data => {
          $scope.oneOffTasks = data;

          $timeout(() => {
            updateChecklistProgress();
          });
        },
      );
    };
  },
});
