import angular from 'angular';
import { getEnv } from 'components/common/env';

angular.module('frontStreetApp.services').factory('FacebookService', $window => {
  function boot() {
    $window.fbq?.('init', getEnv().FACEBOOK_PIXEL_ID);
  }

  function trackViewContent() {
    $window.fbq?.('track', 'ViewContent');
  }

  function trackCompleteRegistration() {
    $window.fbq?.('track', 'CompleteRegistration');
  }

  function trackMakePurchase(value, currency) {
    const val = value ? value.toString() : '0.00';
    const curr = currency || 'USD';

    $window.fbq?.('track', 'Purchase', { value: val, currency: curr });
  }

  return {
    boot,
    trackViewContent,
    trackCompleteRegistration,
    trackMakePurchase,
  };
});
