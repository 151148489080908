import * as React from 'react';
import { ActorRefFrom } from 'xstate';
import { Muid } from '@process-street/subgrade/core';
import { TaskActor, TaskMachine } from './task-machine';
import { useSelector } from '@xstate/react';
import { TaskMachineSelectors } from './task-machine-selectors';
import { useSafeActor } from 'app/hooks/use-safe-actor';
import { TaskWithTaskTemplate } from '@process-street/subgrade/process';
import { CreateCommentMutation } from 'app/features/comments/query-builder';

export namespace TaskMachineHooks {
  export const useIsTaskComplete = (actor: ActorRefFrom<TaskMachine> | undefined) => {
    const safeActor = useSafeActor<TaskMachine>(actor);
    return useSelector(safeActor, TaskMachineSelectors.getIsCompleted);
  };

  export const useTask = (actor: ActorRefFrom<TaskMachine> | undefined) => {
    const safeActor = useSafeActor<TaskMachine>(actor);
    return useSelector(safeActor, TaskMachineSelectors.getTask);
  };

  export const useRulesEngineTargetActor = (actor: ActorRefFrom<TaskMachine> | undefined) => {
    const safeActor = useSafeActor<TaskMachine>(actor);
    return useSelector(safeActor, TaskMachineSelectors.getRulesEngineTargetActor);
  };

  export const useIsTaskApproval = (actor: ActorRefFrom<TaskMachine> | undefined) => {
    const safeActor = useSafeActor<TaskMachine>(actor);
    return useSelector(safeActor, TaskMachineSelectors.getIsApproval);
  };

  export const useShouldShowErrors = (actor: ActorRefFrom<TaskMachine> | undefined) => {
    const safeActor = useSafeActor<TaskMachine>(actor);
    return useSelector(safeActor, TaskMachineSelectors.shouldShowErrors);
  };

  export const useInvalidWidgetCount = (actor: ActorRefFrom<TaskMachine> | undefined) => {
    const safeActor = useSafeActor<TaskMachine>(actor);
    return useSelector(safeActor, TaskMachineSelectors.getInvalidWidgetCount);
  };

  export const useWidgetActorsMap = (actor: ActorRefFrom<TaskMachine> | undefined) => {
    const safeActor = useSafeActor<TaskMachine>(actor);
    return useSelector(safeActor, TaskMachineSelectors.getWidgetActorsMap);
  };

  export const useWidgetActors = (actor: ActorRefFrom<TaskMachine> | undefined) => {
    const safeActor = useSafeActor<TaskMachine>(actor);
    return useSelector(safeActor, TaskMachineSelectors.getWidgetActors);
  };

  export const useTaskSubtasksCompletionPercentage = (actor: ActorRefFrom<TaskMachine> | undefined) => {
    const safeActor = useSafeActor<TaskMachine>(actor);
    return useSelector(safeActor, TaskMachineSelectors.getTaskSubtasksCompletionPercentage);
  };

  export const useApi = (actorRef: TaskActor) => {
    const onUpdateTaskDueDate = React.useCallback(
      ({ task, date, dateOverridden }: { task: TaskWithTaskTemplate; date: number; dateOverridden: boolean }) =>
        actorRef.send({
          type: 'UPDATE_TASK_DUE_DATE',
          data: { task: { ...task, dueDate: date }, dateOverridden },
        }),
      [actorRef],
    );

    const onAddComment = React.useCallback(
      (comment: CreateCommentMutation.Params) => actorRef.send({ type: 'CREATE_COMMENT', comment }),
      [actorRef],
    );

    const onDeleteComment = React.useCallback(
      (commentId: Muid) => actorRef.send({ type: 'DELETE_COMMENT', commentId }),
      [actorRef],
    );
    return React.useMemo(
      () => ({
        onAddComment,
        onDeleteComment,
        onUpdateTaskDueDate,
      }),
      [onAddComment, onDeleteComment, onUpdateTaskDueDate],
    );
  };
}
