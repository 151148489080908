import {
  FormControl,
  FormErrorMessage,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  VStack,
} from 'components/design/next';
import React from 'react';
import { NumberFormFieldActor } from './number-form-field-machine';
import { StringUtils } from '@process-street/subgrade/util';
import { FormResponseLabel } from '../common';
import { SelectableDisabledInput } from 'app/pages/responses/_id/components/form-fields/common/ui';
import { useMatch } from '@process-street/adapters/navigation';
import { MaskedInput } from 'app/features/widgets/components/masked-input';
import { useNumberFormField } from './use-number-form-field';
import { FormFieldValueHelperText } from 'app/pages/runs/_id/components/form-field-value-helper-text';

export interface NumberFormFieldProps {
  actor: NumberFormFieldActor;
}

export const NumberFormField: React.FC<React.PropsWithChildren<NumberFormFieldProps>> = ({ actor }) => {
  const isChecklist = useMatch('checklistV2');
  const ref = React.useRef<HTMLDivElement | null>(null);

  const {
    bgColor,
    errorMessage,
    formFieldValue,
    html,
    inputNode,
    isAutofocused,
    isHiddenByRule,
    isInputDisabled,
    isInvalid,
    machineValue,
    widget,
    onBlur,
    onChange,
    onFocus,
    onReset,
    onSetNode,
  } = useNumberFormField({ actor });

  return isHiddenByRule ? null : (
    <FormControl
      ref={node => {
        ref.current = node;
        if (node && !inputNode) {
          onSetNode(node);
        }
      }}
      as={VStack}
      alignItems="stretch"
      isInvalid={isInvalid}
      isRequired={widget.required}
    >
      <FormResponseLabel>{widget.label || 'Untitled Number'}</FormResponseLabel>
      <InputGroup>
        {widget.config.unitLocation === 'prefix' && widget.config.unit ? (
          <InputLeftAddon>{widget.config.unit}</InputLeftAddon>
        ) : null}

        <MaskedInput html={html} fontSize="md" maskProps={{ pt: 2 }} w={{ base: '100%', lg: '78' }}>
          <SelectableDisabledInput
            type="number"
            w={{ base: '100%', lg: '78' }}
            borderColor="gray.300"
            value={machineValue ?? ''}
            placeholder={StringUtils.getNonEmpty(widget.config.placeholder, 'Type answer here...')}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            isDisabled={isInputDisabled}
            autoFocus={isAutofocused}
            bgColor={bgColor}
          />
        </MaskedInput>

        {widget.config.unitLocation === 'suffix' && widget.config.unit ? (
          <InputRightAddon>{widget.config.unit}</InputRightAddon>
        ) : null}
      </InputGroup>
      {isChecklist && (
        <FormFieldValueHelperText
          defaultValue={widget.config.defaultValue}
          formFieldValue={formFieldValue}
          onResetDefaultValue={onReset}
        />
      )}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
