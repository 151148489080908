import * as React from 'react';
import { SubtasksFormFieldActor, SubtasksFormFieldHooks } from './subtasks-form-field-machine';
import { useSelector } from '@xstate/react';
import { Box, Checkbox, FormControl, FormErrorMessage, HStack, Text, VStack } from 'components/design/next';
import { ChecklistWidgetMachineSelectors } from '../../../utils/widget-machine-selectors';
import { FormResponseLabel } from '../common';
import {
  MultiOptionFieldValue,
  MultiSelectItemValueStatus,
  SelectFormFieldConfigItem,
} from '@process-street/subgrade/process';
import { wrapLinks } from 'app/utils/wrap-links';
import { useMatch } from '@process-street/adapters/navigation';
import { FormFieldAudit } from '../common/form-field-audit';
import { Muid } from '@process-street/subgrade/core';
import { MergeTagMode, MergeTagTarget } from '@process-street/subgrade/form';
import { useParseChecklistMergeTagsByTarget } from 'app/features/widgets/components/send-rich-email/checklist/use-parse-checklist-merge-tags-by-target';

export type SubtasksFormFieldProps = {
  actor: SubtasksFormFieldActor;
  checklistRevisionId: Muid;
  taskId: Muid;
};

export const SubtasksFormField = ({ actor, checklistRevisionId, taskId }: SubtasksFormFieldProps) => {
  const api = SubtasksFormFieldHooks.useApi(actor);
  const inputNode = SubtasksFormFieldHooks.useInputNode(actor);
  const isInvalid = SubtasksFormFieldHooks.useIsInvalid(actor);
  const isDisabled = SubtasksFormFieldHooks.useIsInputDisabled(actor);
  const widget = SubtasksFormFieldHooks.useWidget(actor);
  const formFieldValue = SubtasksFormFieldHooks.useFormFieldValue(actor);
  const value = SubtasksFormFieldHooks.useValue(actor);
  const isHiddenByRule = useSelector(actor, ChecklistWidgetMachineSelectors.getIsHiddenByRule);
  const errorMessage = SubtasksFormFieldHooks.useValidationErrorMessage(actor);

  const isChecklist = useMatch('checklistV2');
  const ref = React.useRef<HTMLDivElement | null>(null);

  const parseGeneral = useParseChecklistMergeTagsByTarget({
    checklistRevisionId,
    taskId,
    mergeTagTarget: MergeTagTarget.GENERAL,
  });

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, configItem: SelectFormFieldConfigItem) => {
    const status = e.target.checked ? MultiSelectItemValueStatus.Completed : MultiSelectItemValueStatus.NotCompleted;

    const updatedValue: MultiOptionFieldValue = {
      itemValues: widget.config.items.map(item => {
        const matchingValue = value?.itemValues.find(i => i.id === item.id);
        if (matchingValue) {
          return matchingValue.id === configItem.id ? { ...matchingValue, status } : { ...matchingValue };
        }

        return {
          id: item.id,
          status: configItem.id === item.id ? status : MultiSelectItemValueStatus.NotCompleted,
          itemType: 'Static',
          name: configItem.name,
        };
      }),
    };
    api.onChange(updatedValue);
  };

  return isHiddenByRule ? null : (
    <FormControl
      ref={node => {
        ref.current = node;
        if (node && !inputNode) {
          api.onSetNode(node);
        }
      }}
      as={VStack}
      alignItems="stretch"
      isInvalid={isInvalid}
      isRequired={widget.required}
    >
      {widget.label && <FormResponseLabel>{widget.label}</FormResponseLabel>}
      {widget.config.items.map((item, index) => {
        const fieldValue = value?.itemValues.find(itemValue => itemValue.id === item.id);
        const isChecked = fieldValue?.status === MultiSelectItemValueStatus.Completed;

        return (
          <HStack key={item.id} spacing="2" opacity={isDisabled ? '0.4' : 'inherit'}>
            <Box
              minW={2}
              sx={{
                '@media print': {
                  p: { margin: '0 !important' }, // fix print overrides
                },
              }}
            >
              <Text fontSize="sm" color="gray.400">
                {index + 1}
              </Text>
            </Box>
            <Checkbox
              isDisabled={isDisabled}
              onBlur={() => api.onBlur()}
              onFocus={() => api.onFocus()}
              isChecked={isChecked}
              onChange={e => handleCheckboxChange(e, item)}
              sx={{
                '.chakra-checkbox__control': {
                  borderWidth: 'thin',
                  borderColor: isInvalid && !isChecked ? 'red' : 'gray.300',
                  borderRadius: '4px',
                  w: 5,
                  h: 5,
                },
              }}
            />
            <Text
              color={isChecked ? 'gray.400' : 'gray.600'}
              lineHeight="24px"
              textDecoration={isChecked ? 'line-through' : undefined}
              fontStyle={isChecked ? 'italic' : undefined}
              dangerouslySetInnerHTML={{
                __html: wrapLinks(parseGeneral({ content: item.name ?? '<unnamed subtask>', mode: MergeTagMode.HTML })),
              }}
            />
            {widget.required && (
              <Text as="span" color="red" mt="-2px" ml="-1">
                *
              </Text>
            )}
          </HStack>
        );
      })}

      {isChecklist && formFieldValue && <FormFieldAudit isDisabled={isDisabled} audit={formFieldValue.audit} />}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
