import * as React from 'react';
import { Text, VStack } from 'components/design/next';
import { ActorRefFrom } from 'xstate';
import { TaskMachine } from 'pages/responses/_id/components/task/task-machine';
import { FormResponseActor } from 'pages/responses/_id/components/form-response-body/form-response-machine';
import { ApprovalStatus } from '@process-street/subgrade/approval-rule';
import { ApprovedTasksSection } from './approved-tasks-section';
import { ArrayUtils } from 'app/utils/array-utils';
import { WaitingForApprovalTasksSection } from './waiting-for-approval-tasks-section';
import { isTextWidget, TaskWithTaskTemplate, Widget } from '@process-street/subgrade/process';
import { CreateAllApprovalsMutation } from 'app/features/approvals/query-builder';
import { Muid, MuidUtils } from '@process-street/subgrade/core';
import { useTaskApprovalTaskGroupsMap } from './hooks/use-approval-task-groups-map';
import { TaskMachineHooks } from '../task/task-machine-hooks';
import { FormResponseMachineHooks } from '../form-response-body/form-response-machine-hooks';
import { TaskNameButton } from './common/task-name-button';
import { TextContentWidgetMachineActorRef } from '../content/text-content-widget/text-content-widget-machine';
import { FormResponsePageMachineHooks } from '../../form-response-page-hooks';
import { WaitingForSubmissionTasksSection } from './waiting-for-submission-tasks-section';

const TextContentResponseWidget = React.lazy(() =>
  import('pages/responses/_id/components/content/text-content-widget').then(({ TextContentWidget }) => ({
    default: TextContentWidget,
  })),
);

export type ApprovalTaskProps = {
  taskMachine: ActorRefFrom<TaskMachine>;
  formResponseActor: FormResponseActor;
};

export const ApprovalTask = ({ taskMachine, formResponseActor }: ApprovalTaskProps) => {
  const api = FormResponseMachineHooks.useApi(formResponseActor);
  const approvals = FormResponseMachineHooks.useApprovals();
  const approvalTask = TaskMachineHooks.useTask(taskMachine);
  const widgetActorsMap = TaskMachineHooks.useWidgetActorsMap(taskMachine);
  const formFieldWidgetsByTaskMap = FormResponseMachineHooks.useFormFieldWidgetsWithValuesByTaskMap();
  const widgetsByTaskMap = FormResponseMachineHooks.useWidgetsByTaskMap();
  const taskAssignmentsByTaskMap = FormResponsePageMachineHooks.useTaskAssignmentsByTaskMap();
  const isMigrating = FormResponseMachineHooks.useIsMigrating();
  const checklistRevision = FormResponseMachineHooks.useChecklistRevision();
  const currentTaskActorRef = FormResponseMachineHooks.useCurrentTaskActorRef();
  const currentTask = TaskMachineHooks.useTask(currentTaskActorRef);

  /** Approvals task can have 0 or 1 widgets. If has 1 widget, is a text widget with approval instructions. */
  const approvalInstructionsWidget: Widget | undefined =
    widgetsByTaskMap[approvalTask.taskTemplate.id]?.filter(isTextWidget)?.[0];
  const approvalInstructionsWidgetActorRef =
    approvalInstructionsWidget && widgetActorsMap[approvalInstructionsWidget.id];

  const taskGroupsMap = useTaskApprovalTaskGroupsMap({ taskMachine });

  const handleOnUpsertApproval = (task: TaskWithTaskTemplate, status: ApprovalStatus, comment?: string) => {
    if (!approvalTask) return;

    const existingApproval = approvals.find(
      approval => approval.subjectTaskId === task.id && approval.approvalTaskId === approvalTask.id,
    );
    const id = existingApproval?.id ?? MuidUtils.randomMuid();

    const approval: CreateAllApprovalsMutation.ApprovalDto = {
      id,
      subjectTaskId: task.id,
      approvalTaskId: approvalTask.id,
      organizationId: approvalTask.organization.id,
      status,
      comment,
    };

    api.onCreateApprovals([approval]);
  };

  const handleOnSelectTask = (taskId: Muid) => api.onSelectTask(taskId);

  if (!approvalTask) return;

  return (
    <React.Suspense fallback="">
      <VStack width="full" gap={6}>
        {approvalInstructionsWidget && approvalInstructionsWidgetActorRef && (
          <TextContentResponseWidget
            key={approvalInstructionsWidget.id}
            actor={approvalInstructionsWidgetActorRef as TextContentWidgetMachineActorRef}
            checklistRevisionId={checklistRevision.id}
            taskId={currentTask.id}
          />
        )}
        {ArrayUtils.isNonEmptyArray(taskGroupsMap.awaitingTasks) && (
          <WaitingForApprovalTasksSection
            tasks={taskGroupsMap.awaitingTasks}
            taskAssignmentsByTaskMap={taskAssignmentsByTaskMap}
            widgetsByTaskMap={formFieldWidgetsByTaskMap}
            onUpsertApproval={handleOnUpsertApproval}
            onSelectTask={handleOnSelectTask}
            isMigrating={isMigrating}
          />
        )}

        {ArrayUtils.isNonEmptyArray(taskGroupsMap.notSubmittedTasks) && (
          <WaitingForSubmissionTasksSection
            tasks={taskGroupsMap.notSubmittedTasks}
            taskAssignmentsByTaskMap={taskAssignmentsByTaskMap}
            onSelectTask={handleOnSelectTask}
          />
        )}

        {taskGroupsMap.rejectedTasks.length > 0 && (
          <VStack alignItems="stretch" gap={4} width="full">
            <Text
              alignSelf="flex-start"
              padding={1}
              variant="1"
              color="red.500"
              backgroundColor="red.50"
              fontWeight="bold"
            >
              Rejected
            </Text>

            {taskGroupsMap.rejectedTasks.map(task => (
              <VStack
                key={task.id}
                alignItems="flex-start"
                backgroundColor="gray.50"
                padding={4}
                borderLeft="solid 2px"
                borderColor="red.500"
              >
                <TaskNameButton
                  taskName={task.taskTemplate.name || '<unnamed task>'}
                  onClick={() => handleOnSelectTask(task.id)}
                />
              </VStack>
            ))}
          </VStack>
        )}

        {ArrayUtils.isNonEmptyArray(taskGroupsMap.approvedTasks) && (
          <ApprovedTasksSection
            tasks={taskGroupsMap.approvedTasks}
            taskAssignmentsByTaskMap={taskAssignmentsByTaskMap}
            widgetsByTaskMap={formFieldWidgetsByTaskMap}
            onUpsertApproval={handleOnUpsertApproval}
            onSelectTask={handleOnSelectTask}
          />
        )}
      </VStack>
    </React.Suspense>
  );
};
