import React from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  HStack,
  useToast,
  ButtonProps,
  IconProps,
} from 'app/components/design/next';
import { RightSidebarButton } from '../right-sidebar-button';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { Checklist } from '@process-street/subgrade/process';
import { useNavigate } from '@process-street/adapters/navigation';
import { DefaultErrorMessages } from 'app/components/utils/error-messages';
import { AxiosError } from 'axios';
import { GetChecklistPermissionsQuery } from 'app/features/permissions/query-builder/get-consolidated-checklist-permissions-query';
import { DeleteChecklistMutation } from 'app/features/checklist/query-builder';

export type DeleteRunButtonProps = {
  checklist: Checklist;
  buttonProps?: Partial<ButtonProps>;
  iconProps?: Partial<IconProps>;
};

export const DeleteRunButton = ({ checklist, buttonProps, iconProps }: DeleteRunButtonProps) => {
  const checklistPermissionsQuery = GetChecklistPermissionsQuery.useQuery({ checklistId: checklist.id });
  const disclosure = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast();

  const deleteChecklistMutation = DeleteChecklistMutation.useMutation({
    onSuccess: () => {
      disclosure.onClose();
      navigate('dashboard');

      toast({
        status: 'success',
        title: 'Workflow run deleted',
        description: 'You can restore the workflow run from the right bar.',
      });
    },
    onError: (error: AxiosError) => {
      console.error('Error deleting checklist', error.response);

      toast({
        status: 'error',
        title: `We're having problems deleting the workflow run`,
        description: DefaultErrorMessages.unexpectedErrorDescription,
      });
    },
  });

  const handleDelete = () => {
    deleteChecklistMutation.mutate({ id: checklist.id });
  };

  if (!checklistPermissionsQuery.data?.permissionMap.checklistDelete) return null;

  return (
    <>
      <RightSidebarButton iconName="trash" iconProps={iconProps} {...buttonProps} onClick={disclosure.onOpen}>
        Delete
      </RightSidebarButton>

      <Modal {...disclosure}>
        <ModalOverlay />
        <ModalContent>
          <ThemeProvider2024>
            <ModalHeader fontSize="lg">Delete this workflow run?</ModalHeader>
            <ModalBody fontSize="md">Your workflow run will be deleted and irrecoverable!</ModalBody>
            <ModalFooter>
              <HStack spacing={3}>
                <Button
                  variant="ghost"
                  colorScheme="gray"
                  onClick={disclosure.onClose}
                  isDisabled={deleteChecklistMutation.isLoading}
                >
                  Cancel
                </Button>
                <Button
                  variant="solid"
                  colorScheme="red"
                  onClick={handleDelete}
                  isLoading={deleteChecklistMutation.isLoading}
                  loadingText="Delete"
                >
                  Delete
                </Button>
              </HStack>
            </ModalFooter>
          </ThemeProvider2024>
        </ModalContent>
      </Modal>
    </>
  );
};
