import {
  FieldType,
  SendRichEmailFieldValue,
  SendRichEmailFormFieldConfig,
  SendRichEmailFormFieldWidget,
} from '@process-street/subgrade/process';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { WidgetSettings } from 'pages/forms/_id/edit/components/form-fields/common/settings/widget-settings';

type Option<T> = T | undefined;

type ValidationSchemaProps = {
  required: boolean;
  constraints?: SendRichEmailFormFieldWidget['constraints'];
};

// Helper function for Option<string>
const optionalStringSchema = () =>
  yup
    .mixed<Option<string>>()
    .test(
      'is-optional-string',
      'Invalid optional string',
      (value): value is Option<string> => value === undefined || typeof value === 'string',
    );

// Helper function for emailFormat
const emailFormatSchema = () =>
  yup
    .mixed<Option<'RichTextOrHtml' | 'PlainText'>>()
    .test(
      'is-valid-email-format',
      'Invalid email format',
      (value): value is Option<'RichTextOrHtml' | 'PlainText'> =>
        value === undefined || value === 'RichTextOrHtml' || value === 'PlainText',
    );

// Helper function for Option<string[]>
const optionalStringArraySchema = () =>
  yup
    .mixed<Option<string[]>>()
    .test(
      'is-optional-string-array',
      'Invalid optional string array',
      (value): value is Option<string[]> =>
        value === undefined || (Array.isArray(value) && value.every(item => typeof item === 'string')),
    );
// Helper function for constraints
const constraintsSchema = () =>
  yup
    .mixed<Record<string, unknown> | undefined>()
    .test(
      'is-record-or-undefined',
      'Invalid constraints',
      (value): value is Record<string, unknown> | undefined =>
        value === undefined || (typeof value === 'object' && value !== null),
    );

export const emailContentSettingsSchema: ObjectSchema<WidgetSettings<FieldType.SendRichEmail>> = yup
  .object({
    config: yup
      .object<SendRichEmailFormFieldConfig>({
        editor: yup.string().oneOf(['RichEditor', 'RawHTMLEditor', 'PlainTextEditor']).required(),
        emailFormat: emailFormatSchema(),
        editAllowed: yup.boolean().optional(),
        // Use custom schemas for Option<string> and Option<string[]> fields
        to: optionalStringArraySchema(),
        cc: optionalStringArraySchema(),
        bcc: optionalStringArraySchema(),
        subject: optionalStringSchema(),
        richEditorBody: optionalStringSchema(),
        rawHTMLBody: optionalStringSchema(),
        plainTextBody: optionalStringSchema(),
      })
      .required() as yup.Schema<SendRichEmailFormFieldConfig>,
    // Add other required fields from WidgetSettings
    key: yup.string(),
    templateRevision: yup.mixed(),
    fieldType: yup.mixed<FieldType.SendRichEmail>().oneOf([FieldType.SendRichEmail]),
    helpText: yup.string().optional(),
    constraints: constraintsSchema(),
    deleted: yup.boolean(),
    required: yup.boolean(),
  })
  .required();

const mergeTagRegex = /^{{[^{}]+}}$/;

const validateEmailOrMergeTag = (value: string) => {
  if (yup.string().email().isValidSync(value)) return true;
  return mergeTagRegex.test(value);
};

export const makeSendRichEmailFieldValidationSchema = ({ required }: ValidationSchemaProps) => {
  return yup.object().shape({
    to: yup
      .array()
      .of(
        yup.string().test('email-or-merge-tag', 'Must be a valid email or merge tag (e.g., {{any_string}})', value => {
          if (!value) return true;
          return validateEmailOrMergeTag(value);
        }),
      )
      .optional()
      .test('required to', 'At least one recipient is required', values => {
        if (required) {
          return !!values && values.length > 0;
        }
        return true;
      }),
    cc: yup
      .array()
      .of(
        yup.string().test('email-or-merge-tag', 'Must be a valid email or merge tag (e.g., {{any_string}})', value => {
          if (!value) return true;
          return validateEmailOrMergeTag(value);
        }),
      )
      .optional(),
    bcc: yup
      .array()
      .of(
        yup.string().test('email-or-merge-tag', 'Must be a valid email or merge tag (e.g., {{any_string}})', value => {
          if (!value) return true;
          return validateEmailOrMergeTag(value);
        }),
      )
      .optional(),
    subject: yup
      .string()
      .optional()
      .test('required subject', 'Subject is required', value => {
        if (required) {
          return !!value && value.trim() !== '';
        }
        return true;
      }),
    body: yup
      .string()
      .optional()
      .test('required body', 'Body is required', value => {
        if (required) {
          return !!value && value.trim() !== '';
        }
        return true;
      }),
    attachments: yup
      .array()
      .of(
        yup.object().shape({
          attachment: yup.object().required('Attachment is required'),
          s3File: yup.object().required('S3 file is required'),
        }),
      )
      .optional(),
  }) as yup.Schema<
    | (SendRichEmailFieldValue & {
        isAutoSendEnabled: boolean;
      })
    | undefined
  >;
};

export const makeSendEmailValidationSchema = ({ required }: ValidationSchemaProps) => {
  return yup.object().shape({
    lastSentDate: yup.number().test('email-sent', 'Send this email.', value => {
      if (required) {
        return !!value;
      }
      return true;
    }),
  });
};
