import { FieldType, FormFieldWidgetWithValue } from '@process-street/subgrade/process';
import { VStack } from 'components/design/next';
import * as React from 'react';
import { match } from 'ts-pattern';
import {
  ApprovalWidegtLabelRenderer,
  ApprovalWidgetDateRenderer,
  ApprovalWidgetEmailRenderer,
  ApprovalWidgetFileRenderer,
  ApprovalWidgetMembersRenderer,
  ApprovalWidgetMultiChoiceRenderer,
  ApprovalWidgetMultiSelectRenderer,
  ApprovalWidgetTableRenderer,
  ApprovalWidgetTextRenderer,
  ApprovalWidgetUrlRenderer,
} from './renderers';
import { NonEmptyArray } from 'app/utils/array-utils';

export type ApprovalWidgetsSectionProps = {
  widgets: NonEmptyArray<FormFieldWidgetWithValue>;
};

const TEXT_RENDERABLE_WIDGET_TYPES = [FieldType.Text, FieldType.Textarea, FieldType.Select, FieldType.Number];

export const ApprovalWidgetsSection = ({ widgets }: ApprovalWidgetsSectionProps) => {
  return (
    <>
      {widgets.map(widget => (
        <VStack key={widget.id} pt="4" w="100%">
          <VStack alignItems="flex-start" w="full">
            <ApprovalWidegtLabelRenderer widget={widget} />
            {match(widget.fieldType)
              .when(
                fieldType => TEXT_RENDERABLE_WIDGET_TYPES.includes(fieldType),
                () => <ApprovalWidgetTextRenderer widget={widget} />,
              )
              .with(FieldType.Email, () => <ApprovalWidgetEmailRenderer widget={widget} />)
              .with(FieldType.Url, () => <ApprovalWidgetUrlRenderer widget={widget} />)
              .with(FieldType.File, () => <ApprovalWidgetFileRenderer widget={widget} />)
              .with(FieldType.Date, () => <ApprovalWidgetDateRenderer widget={widget} />)
              .with(FieldType.MultiChoice, () => <ApprovalWidgetMultiChoiceRenderer widget={widget} />)
              .with(FieldType.MultiSelect, () => <ApprovalWidgetMultiSelectRenderer widget={widget} />)
              .with(FieldType.Members, () => <ApprovalWidgetMembersRenderer widget={widget} />)
              .with(FieldType.Table, () => <ApprovalWidgetTableRenderer widget={widget} />)
              .otherwise(() => null)}
          </VStack>
        </VStack>
      ))}
    </>
  );
};
