import * as React from 'react';
import { useActor } from '@xstate/react';
import { NumberFormFieldActor } from './number-form-field-machine';
import { HStack, Input, InputGroup, InputLeftAddon, InputRightAddon, VStack } from 'components/design/next';
import { FormsWidgetMenu, FormsWidgetMenuItems } from '../../forms-widget-menu';
import { FormsWidgetMenuContainer } from '../../forms-widget-menu/forms-widget-menu-container';
import { FormFieldLabel } from '../common/form-field-label';
import { WidgetActorProvider } from 'pages/forms/_id/shared/widget-context';
import { SettingsModalHeader } from '../common/settings/settings-modal-content';
import { numberSettingsSchema } from './number-form-field-schema';
import { StringUtils } from '@process-street/subgrade/util';
import { WidgetListItemDragIcon } from '../../widgets-list/widget-list-item-drag-icon';
import { NumberFormFieldSettingsFields } from './number-form-field-settings-fields';
import { FormFieldRecentlyMovedIndicator } from '../common/form-field-recently-moved-indicator';
import { match, P } from 'ts-pattern';
import { ViewModeInteractionWrapper } from '../../view-mode-interaction-wrapper/view-mode-interaction-wrapper';
import { WidgetTaskAutomationIndicator } from 'app/pages/templates/_id/components/ai-task-template-widget-indicator';
import { TaskTemplate, TemplateType } from '@process-street/subgrade/process';
import { PreventClickOverlay } from '../common/prevent-click-overlay';

export type NumberFormFieldWidgetProps = {
  isFirst: boolean;
  isLast: boolean;
  actor: NumberFormFieldActor;
};

export const NumberFormFieldWidget: React.FC<React.PropsWithChildren<NumberFormFieldWidgetProps>> = ({
  actor,
  isFirst,
  isLast,
}) => {
  const [current, send] = useActor(actor);
  const { widget, labelActor, recentlyMovedFrom, template, isReadOnly } = current.context;
  const ref = React.useRef<HTMLDivElement | null>(null);

  const placeholder = match(widget)
    .with({ config: { defaultValue: P.when(Boolean) } }, ({ config: { defaultValue } }) => defaultValue)
    .with({ config: { placeholder: P.when(Boolean) } }, ({ config: { placeholder } }) => placeholder)
    .otherwise(() => 'Numbers will be typed here');

  const isWorkflow = template.templateType === TemplateType.Playbook;

  const handleSelectTaskTemplate = (taskTemplate: TaskTemplate) => send({ type: 'SELECT_TASK_TEMPLATE', taskTemplate });

  return (
    <WidgetActorProvider widgetActorRef={actor}>
      <FormsWidgetMenuContainer>
        <VStack
          alignItems="flex-start"
          flex="1"
          maxW="88"
          ref={node => {
            ref.current = node;
            if (node && !current.context.inputNode) {
              send({ type: 'SET_NODE', node });
            }
          }}
          scrollMarginBottom={17}
        >
          {recentlyMovedFrom && <FormFieldRecentlyMovedIndicator from={recentlyMovedFrom} />}
          <HStack justifyContent="space-between" w="full">
            {labelActor && <FormFieldLabel isReadOnly={isReadOnly} actor={labelActor} />}
            {isWorkflow && (
              <HStack justifyContent="flex-end" w="auto">
                <WidgetTaskAutomationIndicator widget={widget} onSelectTaskTemplate={handleSelectTaskTemplate} />
              </HStack>
            )}
          </HStack>

          <ViewModeInteractionWrapper templateId={template.id}>
            <InputGroup>
              {widget.config.unitLocation === 'prefix' && widget.config.unit ? (
                <InputLeftAddon>{widget.config.unit}</InputLeftAddon>
              ) : null}

              <Input
                id={`form-field-widget-${current.context.widget.id}`}
                w="full"
                placeholder={placeholder}
                isDisabled
                borderColor="gray.200"
                _disabled={{ bgColor: 'gray.50', opacity: 1 }}
                pointerEvents="all"
              />

              {widget.config.unitLocation === 'suffix' && widget.config.unit ? (
                <InputRightAddon>{widget.config.unit}</InputRightAddon>
              ) : null}

              {isReadOnly ? (
                <PreventClickOverlay />
              ) : (
                <>
                  <WidgetListItemDragIcon />
                  <FormsWidgetMenu>
                    <FormsWidgetMenuItems.Required widget={widget} />
                    <FormsWidgetMenuItems.Settings widget={widget} schema={numberSettingsSchema}>
                      <SettingsModalHeader>
                        {StringUtils.getNonEmpty(widget.label, 'Untitled number field')}
                      </SettingsModalHeader>

                      <NumberFormFieldSettingsFields
                        templateRevisionId={widget.templateRevision.id}
                        templateType={template.templateType}
                      />
                    </FormsWidgetMenuItems.Settings>
                    <FormsWidgetMenuItems.ConditionalLogic
                      widget={widget}
                      templateRevisionId={widget.templateRevision.id}
                      templateType={template.templateType}
                    />

                    <FormsWidgetMenuItems.Divider />

                    <FormsWidgetMenuItems.Duplicate />
                    <FormsWidgetMenuItems.MoveToStep widget={widget} />
                    <FormsWidgetMenuItems.MoveUp isDisabled={isFirst} />
                    <FormsWidgetMenuItems.MoveDown isDisabled={isLast} />
                    <FormsWidgetMenuItems.Delete />
                  </FormsWidgetMenu>
                </>
              )}
            </InputGroup>
          </ViewModeInteractionWrapper>
        </VStack>
      </FormsWidgetMenuContainer>
    </WidgetActorProvider>
  );
};
