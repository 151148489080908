import * as React from 'react';
import { Box } from 'components/design/next';
import { SendEmailWidgetActor } from './send-email-widget-machine';
import { FormResponseMachineHooks } from '../../form-response-body/form-response-machine-hooks';
import { TaskMachineHooks } from '../../task/task-machine-hooks';
import { SendEmailHooks } from './send-email-hooks';
import { useMount } from 'react-use';
import { useSelector } from '@xstate/react';
import { ChecklistWidgetMachineSelectors } from '../../../utils/widget-machine-selectors';
import { SendRichEmailChecklistWidget } from './send-rich-email-checklist-widget';

export type SendEmailContentWidgetProps = {
  actor: SendEmailWidgetActor;
};

export const SendEmailWidget: React.FC<SendEmailContentWidgetProps> = ({ actor }) => {
  const widget = SendEmailHooks.useWidget(actor);
  const api = SendEmailHooks.useApi(actor);
  const formFieldValue = SendEmailHooks.useFormFieldValue(actor);
  const checklistRevisionId = SendEmailHooks.useChecklistRevisionId(actor);
  const isReadOnly = SendEmailHooks.useIsReadOnly(actor);
  const value = SendEmailHooks.useValue(actor);
  const templateRevision = FormResponseMachineHooks.useTemplateRevision();
  const currentTaskActorRef = FormResponseMachineHooks.useCurrentTaskActorRef();
  const currentTask = TaskMachineHooks.useTask(currentTaskActorRef);
  const ref = React.useRef<HTMLDivElement | null>(null);
  const inputNode = SendEmailHooks.useCurrentNode(actor);
  const currentUser = FormResponseMachineHooks.useCurrentUser();
  const isHiddenByRule = useSelector(actor, ChecklistWidgetMachineSelectors.getIsHiddenByRule);

  useMount(function setCurrentUser() {
    api.onSetCurrentUser(currentUser);
  });
  if (isHiddenByRule) {
    return null;
  }
  return (
    <Box
      w="full"
      ref={node => {
        ref.current = node;
        if (node && !inputNode) {
          api.onSetNode(node);
        }
      }}
    >
      <SendRichEmailChecklistWidget
        actor={actor}
        widget={widget}
        value={value}
        formFieldValue={formFieldValue}
        checklistRevisionId={checklistRevisionId}
        templateRevisionId={templateRevision.id}
        readOnly={isReadOnly}
        taskId={currentTask.id}
        onEditModeEnabled={() => api.onFocus()}
        onCancel={() => api.onBlur()}
      />
    </Box>
  );
};
