import React from 'react';
import { Checklist, Comment, CommentMethod, TaskWithTaskTemplate } from '@process-street/subgrade/process';
import { GetAttachmentsQuery } from 'features/comments/query-builder';
import { isNotIdRef } from '@process-street/subgrade/core';
import { Divider, List, useToast, VStack } from 'components/design/next';

import { isChecklistActionable } from 'utils/checklist';
import { GetAllActivitiesByTypeQuery } from 'features/activities/query-builder';
import { ActivityObjectType } from '@process-street/subgrade/activity';
import { useQueryClient } from 'react-query';
import { useOneOffTaskDrawerStore } from 'app/features/one-off-tasks/components/shared/one-off-task-drawer-store';
import { AttachmentItem } from 'app/features/comments/components/common/attachment/attachment-item';
import { CommentItem } from './comment-item';
import { FormResponseMachineHooks } from 'app/pages/responses/_id/components/form-response-body/form-response-machine-hooks';
import { TaskMachineHooks } from 'app/pages/responses/_id/components/task/task-machine-hooks';
import { TaskActor } from 'app/pages/responses/_id/components/task/task-machine';
import { NewCommentBox } from './new-comment-box';

export type TaskCommentsProps = {
  currentTaskActor: TaskActor;
  task: TaskWithTaskTemplate;
};

const SCROLL_DELAY = 500;

export const TaskComments = ({ currentTaskActor, task }: TaskCommentsProps) => {
  const queryClient = useQueryClient();
  const { id: taskId, taskTemplate } = task;
  const activeStepId = taskTemplate.group.id;
  const toast = useToast();
  const { options } = useOneOffTaskDrawerStore();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const checklistRevision = FormResponseMachineHooks.useChecklistRevision();
  const currentUser = FormResponseMachineHooks.useCurrentUser();
  const template = FormResponseMachineHooks.useTemplate();
  const api = TaskMachineHooks.useApi(currentTaskActor);
  const templateId = isNotIdRef(checklistRevision.templateRevision)
    ? checklistRevision.templateRevision.template.id
    : '';

  const checklistCommentsEnabled = template.checklistCommentsEnabled;
  const checklist = isNotIdRef(checklistRevision.checklist) ? checklistRevision.checklist : ({} as Checklist);

  const comments = FormResponseMachineHooks.useCommentsByTaskId(activeStepId);

  const attachments = FormResponseMachineHooks.useAttachmentsByTaskGroupId(activeStepId);

  const onSendComment = (content: Comment['content']) =>
    api.onAddComment({
      content,
      audit: {
        createdBy: currentUser,
        createdDate: new Date().getTime(),
      },
      taskId,
      method: CommentMethod.Task,
    });

  const onAddAttachment = () => {
    toast({
      title: 'Attachment created',
      status: 'success',
    });
    queryClient.invalidateQueries(
      GetAttachmentsQuery.getKey({ checklistRevisionId: checklistRevision.id, templateId }),
    );
    queryClient.invalidateQueries(
      GetAllActivitiesByTypeQuery.getKey({
        checklistId: checklist.id,
        type: 'checklist',
        objectType: ActivityObjectType.Attachment,
      }),
    );
  };

  const allItems = React.useMemo(
    () => [...(comments ?? []), ...(attachments ?? [])].sort((a, b) => a.audit.createdDate - b.audit.createdDate),
    [comments, attachments],
  );

  React.useEffect(
    function scrollToComments() {
      if (comments && options?.shouldScrollToComments) {
        setTimeout(() => {
          containerRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, SCROLL_DELAY);
      }
    },
    [comments, options?.shouldScrollToComments],
  );

  const shouldShowNewCommentBox = isChecklistActionable(checklist);

  return checklistCommentsEnabled ? (
    <VStack ref={containerRef} alignItems="flex-start" spacing="4" mb="4" mt={10} w="full">
      <Divider mb="1" />
      <List spacing="1" w="full">
        {allItems.map(item => {
          if ('file' in item) {
            return (
              <AttachmentItem
                {...{
                  key: item.id,
                  attachment: item,
                  checklistId: checklist.id,
                  templateId,
                  isFromOneOffTask: false,
                }}
              />
            );
          }
          return (
            <CommentItem {...{ key: item.id, checklistId: checklist.id, comment: item, templateId, currentUser }} />
          );
        })}
      </List>
      {shouldShowNewCommentBox && (
        <NewCommentBox
          {...{
            onSendComment,
            onAddAttachment,
            checklistId: checklist.id,
            currentUser,
            taskId,
            fontSize: 'md',
          }}
        />
      )}
    </VStack>
  ) : (
    <></>
  );
};
