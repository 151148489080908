import { MembersFormFieldWidget, MembersFormFieldValue, MembersFieldValue } from '@process-street/subgrade/process';
import { UpdateFormFieldValueMutationResponse } from 'features/widgets/query-builder';
import { ActionObject, ActorRefFrom, assign, createMachine, forwardTo, sendParent, spawn, StateFrom } from 'xstate';
import { FormFieldMachineBuilderProps, WithFormFieldMachineEvent } from '../../../types';
import {
  makeValidationMachine,
  ValidationActorRef,
  ValidationParentEvent,
  ValidationSelectors,
} from '../validation-machine';
import {
  makeRulesEngineTargetMachine,
  sendRulesActorFormFieldValueUpdate,
} from '../../form-response-body/rules-engine-machine';
import { makeUpdateFormFieldValueMutation } from '../make-update-form-field-value-mutation';
import { FormResponseErrorToasts } from 'pages/responses/_id/utils/form-response-error-toasts';
import { AxiosError } from 'axios';
import { makeErrorLoggerAction } from 'app/utils/machines';
import { makeMembersFormFieldValidationSchema } from 'app/pages/forms/_id/edit/components/form-fields/members-form-field/members-form-field-schema';
import { Muid } from '@process-street/subgrade/core';
import _isEmpty from 'lodash/isEmpty';
import { useSelector } from '@xstate/react';
import { useCallback, useMemo } from 'react';
import { identitySelector } from '../form-field-machine-hooks';

export type ValueType = { organizationMemberships: string[] };

export type Context = {
  value: MembersFieldValue;
  widget: MembersFormFieldWidget;
  formFieldValue: MembersFormFieldValue | undefined;
  validationActor: ValidationActorRef<MembersFieldValue | undefined>;
  rulesEngineTargetActor: ActorRefFrom<typeof makeRulesEngineTargetMachine>;
  inputNode: HTMLElement | null;
  selectedGroupId: Muid;
  id: string;
};

export type Event =
  | Exclude<
      WithFormFieldMachineEvent<ValidationParentEvent, MembersFieldValue, MembersFormFieldValue | undefined>,
      { type: 'CHANGE' | 'UPDATE_VALUE' }
    >
  | { type: 'CHANGE' | 'UPDATE_VALUE'; value: MembersFieldValue | undefined };

export const makeMembersFormFieldMachine = ({
  formFieldWidget,
  formFieldValue,
  autoFocus,
  checklistRevisionId,
  sharedContext,
  isEditable,
  inputNode,
}: FormFieldMachineBuilderProps<MembersFormFieldWidget>) => {
  const validationSchema = makeMembersFormFieldValidationSchema({ required: formFieldWidget.required });

  const { groupId } = formFieldWidget.config;

  const value: MembersFieldValue = {
    organizationMembershipIds: formFieldValue?.fieldValue.organizationMembershipIds ?? [],
  };

  const initialState = isEditable ? 'enabled' : 'disabled';

  const id = `members-form-field-machine:${formFieldWidget.id}`;

  const isEmpty = (value: MembersFieldValue | undefined) => {
    if (!value || !value.organizationMembershipIds || value.organizationMembershipIds.length === 0) {
      return true;
    } else {
      return _isEmpty(value?.organizationMembershipIds?.[0]);
    }
  };
  return createMachine(
    {
      context: () => ({
        id,
        inputNode,
        value,
        widget: formFieldWidget,
        formFieldValue,
        validationActor: spawn(
          makeValidationMachine({
            validationSchema,
            initialValue: formFieldValue?.fieldValue,
            isEmpty,
          }),
          {
            name: `members-form-field-validation-actor:${formFieldWidget.id}`,
          },
        ),
        rulesEngineTargetActor: spawn(
          makeRulesEngineTargetMachine({ type: 'widget', widgetHeaderGroupId: formFieldWidget.header.group.id }),
          { name: 'hidden-by-rule-actor', sync: true },
        ),
        selectedGroupId: groupId,
      }),
      schema: {
        context: {} as Context,
        events: {} as Event,
        services: {} as {
          updateFormFieldValue: {
            data: UpdateFormFieldValueMutationResponse;
          };
        },
      },
      tsTypes: {} as import('./members-form-field-machine.typegen').Typegen0,
      id,
      predictableActionArguments: true,
      preserveActionOrder: true,
      type: 'parallel',
      on: {
        SET_NODE: { actions: ['assignNode'] },
        SCROLL_INTO_VIEW: { actions: ['scrollIntoView'] },
        SYNC_FORM_FIELD_VALUE: { actions: ['syncFormFieldValue'] },
      },
      states: {
        input: {
          initial: initialState,
          states: {
            disabled: {
              on: {
                ENABLE: {
                  target: 'enabled',
                },
              },
            },
            enabled: {
              initial: 'idle',
              on: { DISABLE: 'disabled' },
              states: {
                idle: { on: { FOCUS: 'focused' } },
                focused: {
                  on: {
                    BLUR: 'idle',
                    CHANGE: { actions: ['updateValue', 'sendRulesActorFormFieldValueUpdate'] },
                  },
                },
              },
            },
          },
        },

        autoFocus: {
          initial: autoFocus ? 'enabled' : 'disabled',
          states: { disabled: {}, enabled: {} },
        },

        mutation: {
          initial: 'idle',
          states: {
            idle: {
              on: {
                UPDATE_VALUE: 'updating',
              },
            },
            updating: {
              invoke: {
                src: 'updateFormFieldValue',
                onDone: {
                  target: 'idle',
                  actions: ['sendParentFormFieldValueUpdate', 'assignFormFieldValue'],
                },
                onError: {
                  target: 'idle',
                  actions: ['logError', 'showErrorToast'],
                },
              },
            },
          },
        },

        // This state is a kind of controller to forward events up and down
        // Since it is a parallel state, in can listen for events without blocking or getting blocked by nested states
        validation: {
          initial: 'enabled',
          states: {
            enabled: {
              on: {
                CHANGE: { actions: 'forwardToValidation' },
                REVEAL_INVALID: { actions: 'forwardToValidation' },
                BLUR: { actions: 'forwardToValidation' },
                VALID: { actions: 'sendParentValid' },
                INVALID: { actions: 'sendParentInvalid' },
                HIDE: { actions: 'sendParentValid', target: 'disabled' },
              },
            },
            disabled: {
              on: {
                REVEAL: { target: 'enabled', actions: 'restoreValidationWithParent' },
              },
            },
          },
        },
      },
    },
    {
      services: {
        updateFormFieldValue: async (_, event) =>
          makeUpdateFormFieldValueMutation({
            queryClient: sharedContext.queryClient,
            body: {
              checklistRevisionId,
              widgetId: formFieldWidget.id,
              organizationMembershipIds: event.value?.organizationMembershipIds,
            },
          }).execute(),
      },
      actions: {
        logError: makeErrorLoggerAction(id),
        assignNode: assign({ inputNode: (_, event) => event.node }),
        updateValue: assign({
          value: (_, event) => event.value as MembersFieldValue,
        }),
        syncFormFieldValue: assign({
          value: (ctx, event) => event.value?.fieldValue ?? ctx.value,
          formFieldValue: (_, event) => event.value,
        }),
        showErrorToast: (_, event) => FormResponseErrorToasts.showFormFieldUpdateErrorToast(event.data as AxiosError),
        sendParentInvalid: sendParent({ type: 'INVALID_WIDGET', widgetId: formFieldWidget.id }),
        sendParentValid: sendParent({ type: 'VALID_WIDGET', widgetId: formFieldWidget.id }),
        forwardToValidation: forwardTo(ctx => ctx.validationActor) as ActionObject<Context, Event>,
        restoreValidationWithParent: sendParent((ctx, _evt) => {
          if (ctx.validationActor.getSnapshot()?.matches('valid')) {
            return { type: 'VALID_WIDGET', widgetId: formFieldWidget.id };
          }
          return { type: 'INVALID_WIDGET', widgetId: formFieldWidget.id };
        }),

        sendRulesActorFormFieldValueUpdate: (_ctx, event) =>
          sendRulesActorFormFieldValueUpdate({
            formFieldValue: {
              ...formFieldValue!,
              fieldValue: event.value as MembersFieldValue,
            },
            formFieldWidget,
          }),
        scrollIntoView: ctx => {
          ctx.inputNode?.scrollIntoView();
        },
        sendParentFormFieldValueUpdate: sendParent((_ctx, evt) => ({
          type: 'FORM_FIELD_VAUE_UPDATE',
          formFieldValue: evt.data.formFieldValue,
        })),
        assignFormFieldValue: assign({
          formFieldValue: (ctx, evt) => ({
            ...ctx.formFieldValue,
            ...(evt.data.formFieldValue as MembersFormFieldValue),
          }),
        }),
      },
    },
  );
};

export type MembersFormFieldMachine = ReturnType<typeof makeMembersFormFieldMachine>;
export type MembersFormFieldMachineState = StateFrom<MembersFormFieldMachine>;
export type MembersFormFieldActor = ActorRefFrom<MembersFormFieldMachine>;

export namespace MembersFormFieldSelectors {
  export const getId = (state: MembersFormFieldMachineState) => state.context.id;

  export const getInputNode = (state: MembersFormFieldMachineState) => state.context.inputNode;

  export const getIsAutofocused = (state: MembersFormFieldMachineState) => state.matches('autoFocus.enabled');

  export const getIsInputDisabled = (state: MembersFormFieldMachineState) => {
    return state.matches('input.disabled');
  };

  export const getFormFieldValue = (state: MembersFormFieldMachineState) => state.context.formFieldValue;

  export const getValidationActor = (state: MembersFormFieldMachineState) => {
    return state.context.validationActor;
  };

  export const getValue = (state: MembersFormFieldMachineState) => state.context.value;

  export const getSelectedGroupId = (state: MembersFormFieldMachineState) => state.context.selectedGroupId;

  export const getWidget = (state: MembersFormFieldMachineState) => state.context.widget;
}

export namespace MembersFormFieldHooks {
  export const useId = (actorRef: MembersFormFieldActor) => {
    return useSelector(actorRef, MembersFormFieldSelectors.getId);
  };

  export const useFormFieldValue = (actorRef: MembersFormFieldActor) => {
    return useSelector(actorRef, MembersFormFieldSelectors.getFormFieldValue);
  };

  export const useInputNode = (actorRef: MembersFormFieldActor) => {
    return useSelector(actorRef, MembersFormFieldSelectors.getInputNode);
  };

  export const useIsAutofocused = (actorRef: MembersFormFieldActor) => {
    return useSelector(actorRef, MembersFormFieldSelectors.getIsAutofocused);
  };

  export const useIsInputDisabled = (actorRef: MembersFormFieldActor) => {
    return useSelector(actorRef, MembersFormFieldSelectors.getIsInputDisabled);
  };

  export const useIsInvalid = (actorRef: MembersFormFieldActor) => {
    const validationActor = useValidationActor(actorRef);
    return ValidationSelectors.isActorInvalidVisible(validationActor);
  };

  export const useValidationActor = (actorRef: MembersFormFieldActor) => {
    return useSelector(actorRef, MembersFormFieldSelectors.getValidationActor);
  };

  const validationMachineSelector = (state: MembersFormFieldMachineState) => state.context.validationActor;
  export function useValidationSnapshot(parentRef: MembersFormFieldActor) {
    const validationActor = useSelector(parentRef, validationMachineSelector) as ValidationActorRef<any>;
    const validationSnapshot = useSelector(validationActor, identitySelector);
    return validationSnapshot;
  }

  export const useValidationErrorMessage = (parentRef: MembersFormFieldActor) => {
    const validationSnapshot = useValidationSnapshot(parentRef);
    return validationSnapshot.context.errorMessage;
  };

  export const useSelectedGroupId = (actorRef: MembersFormFieldActor) => {
    return useSelector(actorRef, MembersFormFieldSelectors.getSelectedGroupId);
  };

  export const useValue = (actorRef: MembersFormFieldActor) => {
    return useSelector(actorRef, MembersFormFieldSelectors.getValue);
  };

  export const useWidget = (actorRef: MembersFormFieldActor) => {
    return useSelector(actorRef, MembersFormFieldSelectors.getWidget);
  };

  export const useApi = (actorRef: MembersFormFieldActor) => {
    const onChange = useCallback(
      (value: MembersFieldValue | undefined) => {
        actorRef.send({ type: 'CHANGE', value });
      },
      [actorRef],
    );

    const onBlur = useCallback(() => {
      actorRef.send({ type: 'BLUR' });
    }, [actorRef]);

    const onFocus = useCallback(() => {
      actorRef.send({ type: 'FOCUS' });
    }, [actorRef]);

    const onSetNode = useCallback(
      (node: HTMLDivElement | null) => {
        actorRef.send({ type: 'SET_NODE', node });
      },
      [actorRef],
    );

    return useMemo(() => ({ onChange, onBlur, onFocus, onSetNode }), [onChange, onBlur, onFocus, onSetNode]);
  };
}
