import { FormControl, FormErrorMessage, Icon, InputGroup, InputLeftElement, VStack } from 'components/design/next';
import React from 'react';
import { UrlFormFieldActor, UrlHooks } from './url-form-field-machine';
import { useSelector } from '@xstate/react';
import { FormResponseLabel } from '../common';
import { ChecklistWidgetMachineSelectors } from '../../../utils/widget-machine-selectors';
import { FormFieldMachineHooks } from '../form-field-machine-hooks';
import { SelectableDisabledInput } from '../common/ui';
import { useMatch } from '@process-street/adapters/navigation';
import { WidgetUtils } from '@process-street/subgrade/process';
import { MaskedInputParsers, MaskedInput } from 'app/features/widgets/components/masked-input';
import { FormFieldValueHelperText } from 'app/pages/runs/_id/components/form-field-value-helper-text';
import identity from 'lodash/identity';
import { Option } from 'space-monad';
import { MergeTagStringReplacementUtils } from '@process-street/subgrade/merge-tags';
import { match } from 'ts-pattern';

export interface UrlFormFieldProps {
  actor: UrlFormFieldActor;
}

export const UrlFormField: React.FC<React.PropsWithChildren<UrlFormFieldProps>> = ({ actor }) => {
  const api = FormFieldMachineHooks.useApi(actor);

  const isInputDisabled = FormFieldMachineHooks.useIsInputDisabled(actor);
  const inputNode = FormFieldMachineHooks.useIsInputDisabled(actor);
  const isAutofocused = FormFieldMachineHooks.useIsAutofocused(actor);
  const errorMessage = FormFieldMachineHooks.useValidationErrorMessage(actor);
  const isHiddenByRule = useSelector(actor, ChecklistWidgetMachineSelectors.getIsHiddenByRule);
  const isInvalid = FormFieldMachineHooks.useIsInvalid(actor);
  const machineValue = UrlHooks.useValue(actor);
  const widget = UrlHooks.useWidget(actor);
  const formFieldValue = UrlHooks.useFormFieldValue(actor);

  const isChecklist = useMatch('checklistV2');
  const setValue: React.ChangeEventHandler<HTMLInputElement> = e => api.onChange(e.target.value);

  const handleReset = () => {
    const widgetDefaultValue = widget.config.defaultValue;
    if (widgetDefaultValue) {
      api.onChange(WidgetUtils.hasVariables(widgetDefaultValue) ? '' : widgetDefaultValue, true);
    }
  };

  const widgetDefaultValue = Option<string>(widget.config?.defaultValue)
    .map(defaultValue => MergeTagStringReplacementUtils.replaceUnknownTagsValues(defaultValue))
    .get();

  const value = React.useMemo(() => {
    if (!machineValue && widgetDefaultValue) {
      return widgetDefaultValue;
    }

    return machineValue;
  }, [machineValue, widgetDefaultValue]);

  const hasDefaultValue = WidgetUtils.hasDefaultValue({ formFieldValue, widget });
  const hasVariables = WidgetUtils.hasVariables(widget.config.defaultValue);
  const maskedInputParser = React.useMemo(
    () => (hasDefaultValue && hasVariables ? MaskedInputParsers.makeParser('merge-tags') : identity),
    [hasDefaultValue, hasVariables],
  );
  const html = React.useMemo(() => maskedInputParser(String(value)), [maskedInputParser, value]);

  const ref = React.useRef<HTMLDivElement | null>(null);

  return isHiddenByRule ? null : (
    <FormControl
      ref={node => {
        ref.current = node;
        if (node && !inputNode) {
          api.onSetNode(node);
        }
      }}
      as={VStack}
      alignItems="stretch"
      isInvalid={isInvalid}
      isRequired={widget.required}
    >
      <FormResponseLabel>{widget.label || 'Untitled Website'}</FormResponseLabel>
      <InputGroup>
        <InputLeftElement px="3">
          {/* mt because it looks slightly off center to input */}
          <Icon icon="globe" size="4" color="gray.400" />
        </InputLeftElement>
        <MaskedInput html={html} fontSize="md" maskProps={{ pt: '7.5px', pl: '39px' }} w={{ base: '100%', lg: 78 }}>
          <SelectableDisabledInput
            w={{ base: '100%', lg: '78' }}
            borderColor="gray.300"
            value={value}
            placeholder="https://www.process.st"
            onFocus={() => api.onFocus()}
            onBlur={() => api.onBlur()}
            onChange={setValue}
            isDisabled={isInputDisabled}
            autoFocus={isAutofocused}
            pl="10"
            bgColor={match({ hasDefaultValue })
              .with({ hasDefaultValue: true }, () => 'yellow.100')
              .otherwise(() => undefined)}
          />
        </MaskedInput>
      </InputGroup>
      {isChecklist && (
        <FormFieldValueHelperText
          formFieldValue={formFieldValue}
          defaultValue={widget.config.defaultValue}
          onResetDefaultValue={handleReset}
        />
      )}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
