import * as React from 'react';
import { memo } from 'react';
import { TemplateRevision } from '@process-street/subgrade/process';
import { Skeleton } from 'components/design/next';
import * as yup from 'yup';
import { KeyInput } from 'pages/templates/_id/components/code-task-template-editor/input-list/key-input';
import { ValueInput } from 'pages/templates/_id/components/code-task-template-editor/input-list/value-input';
import {
  KeyValueList,
  KeyValueListProps,
  KeyValueMapping,
} from 'pages/templates/_id/components/code-task-template-editor/key-value-list/key-value-list';
import { CodeTaskTemplateEditorMode } from 'pages/templates/_id/components/code-task-template-editor/machine/code-task-machine';

export type InputListProps = {
  mappings: KeyValueMapping[];
  onUpdate: (mappings: KeyValueMapping[]) => void;
  templateRevisionId: TemplateRevision['id'];
  mode: CodeTaskTemplateEditorMode;
};

export const InputList: React.FC<InputListProps> = ({ mappings, onUpdate, templateRevisionId, mode }) => {
  const ValueComponent: KeyValueListProps['ValueComponent'] = memo(({ index, mode }) => (
    <ValueInput index={index} templateRevisionId={templateRevisionId} mode={mode} />
  ));

  return (
    <Skeleton isLoaded={mode !== 'loading'} w="full">
      <KeyValueList
        mappings={mappings}
        onSubmit={onUpdate}
        validationSchema={mode === 'test' ? testSchema : schema}
        KeyComponent={KeyInput}
        ValueComponent={ValueComponent}
        mode={mode}
      />
    </Skeleton>
  );
};

const schema = yup.object().shape({
  mappings: yup.array().of(
    yup.object().shape({
      key: yup.string().required('Key is required'),
      value: yup.string().required('Value is required'),
    }),
  ),
});

const testSchema = yup.object().shape({
  mappings: yup.array().of(
    yup.object().shape({
      key: yup.string().required('Key is required'),
      value: yup.string(),
    }),
  ),
});
