import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogProps,
  Button,
} from 'components/design/next';
import { FormResponseMachineHooks } from 'app/pages/responses/_id/components/form-response-body/form-response-machine-hooks';
import { TaskMachineHooks } from 'app/pages/responses/_id/components/task/task-machine-hooks';
import { Comment } from '@process-street/subgrade/process';

type DeleteAlertDialogProps = {
  commentId: Comment['id'];
} & Omit<AlertDialogProps, 'children' | 'leastDestructiveRef'>;

export const DeleteCommentDialog: React.FC<React.PropsWithChildren<DeleteAlertDialogProps>> = ({
  commentId,
  onClose,
  isOpen,
  ...props
}) => {
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const currentTaskActor = FormResponseMachineHooks.useCurrentTaskActorRef();
  const api = TaskMachineHooks.useApi(currentTaskActor);

  const handleDeleteComment = () => api.onDeleteComment(commentId);

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} {...props} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Comment
          </AlertDialogHeader>
          <AlertDialogBody>This comment will be deleted and irrecoverable!</AlertDialogBody>
          <AlertDialogFooter>
            <Button variant="secondary" ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              aria-label="delete comment"
              variant="danger"
              onClick={() => {
                handleDeleteComment();
                onClose();
              }}
              ml={3}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
