import { ChecklistRevision, Template, Attachment } from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import {
  useQuery as useRQuery,
  QueryKey,
  UseQueryOptions,
  QueryClient,
  QueryObserverOptions,
  QueryObserver,
} from 'react-query';
import { axiosService } from 'services/axios-service';

export namespace GetAttachmentsQuery {
  export type Params = {
    checklistRevisionId?: ChecklistRevision['id'];
    templateId?: Template['id'];
  };
  export type Response = Attachment[];
  export const key = ['attachments', 'checklist-revision'];
  export const getKey = (params: Params): QueryKey => [...key, params];
  export const queryFn = (params: Params) =>
    axiosService
      .getAxios()
      .get<Response>(`/1/attachments`, {
        params: {
          where: {
            'task.taskTemplate.templateRevision.templateId': { _eq: params.templateId },
            'task.checklistRevisionId': { _eq: params.checklistRevisionId },
            'status': { _eq: 'Active' },
          },
          include: 'createdBy,task.taskTemplate.templateRevision,file',
        },
      })
      .then(res => res.data);
  export const useQuery = <Select = Response>(
    params: Params,
    options: UseQueryOptions<Response, AxiosError, Select> = {},
  ) => {
    return useRQuery(getKey(params), () => queryFn(params), options);
  };
  export const makeQueryObserver = ({
    queryClient,
    options,
    ...params
  }: Params & {
    queryClient: QueryClient;
    options: QueryObserverOptions<Response, AxiosError>;
  }) => {
    return new QueryObserver<Response, AxiosError>(queryClient, {
      queryKey: getKey(params),
      queryFn: () => queryFn(params),
      enabled: Boolean(params.checklistRevisionId && params.templateId),
      ...options,
    });
  };
}
