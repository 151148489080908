import * as React from 'react';
import { Box, FormControl, FormErrorMessage, HStack, Icon, IconButton, Text, VStack } from 'components/design/next';
import { MembersFormFieldActor, MembersFormFieldHooks } from './members-form-field-machine';
import { useSelector } from '@xstate/react';
import { ChecklistWidgetMachineSelectors } from '../../../utils/widget-machine-selectors';
import { FormResponseLabel } from '../common';
import { FormResponseMachineHooks } from '../../form-response-body/form-response-machine-hooks';
import { BlvdMembersPicker } from 'app/components/design/BlvdMembersPicker';
import { GridHelper } from 'app/components/dashboard/services/grid-helper';
import { OrganizationMembershipStatus, OrganizationMembershipWithUser } from '@process-street/subgrade/core';
import { MemberItem } from 'app/components/common/MemberOption';
import _keyBy from 'lodash/keyBy';
import { ChakraAvatar } from 'app/components/design/next/chakra-avatar';
import { components, GroupBase, SelectComponentsConfig } from 'react-select';
import { ThemeProvider2024 } from 'app/components/design/next/theme-provider-2024';
import { MenuSearchHeader } from 'app/components/design/BlvdSelect/components';
import { createUsableContext } from '@process-street/subgrade/util';
import { useMatch } from '@process-street/adapters/navigation';
import { FormFieldAudit } from '../common/form-field-audit';
import { GetDeletedByIdQuery } from 'app/features/organization-memberships/query-builder';

export interface MembersFormFieldProps {
  actor: MembersFormFieldActor;
}

export const MembersFormField = ({ actor }: MembersFormFieldProps) => {
  const api = MembersFormFieldHooks.useApi(actor);

  const widget = MembersFormFieldHooks.useWidget(actor);
  const formFieldValue = MembersFormFieldHooks.useFormFieldValue(actor);
  const id = MembersFormFieldHooks.useId(actor);
  const selectedGroupId = MembersFormFieldHooks.useSelectedGroupId(actor);
  const inputNode = MembersFormFieldHooks.useInputNode(actor);
  const isInvalid = MembersFormFieldHooks.useIsInvalid(actor);
  const isDisabled = MembersFormFieldHooks.useIsInputDisabled(actor);
  const isAutofocused = MembersFormFieldHooks.useIsAutofocused(actor);
  const errorMessage = MembersFormFieldHooks.useValidationErrorMessage(actor);

  const isChecklist = useMatch('checklistV2');
  const isHiddenByRule = useSelector(actor, ChecklistWidgetMachineSelectors.getIsHiddenByRule);

  const groupMemberships = FormResponseMachineHooks.useGroupMembersByGroupId(selectedGroupId);

  const memberItems = React.useMemo(
    () =>
      GridHelper.convertOrganizationMembershipsWithUserToMemberItems(
        groupMemberships.map(
          groupMembership => groupMembership.organizationMembership as OrganizationMembershipWithUser,
        ),
      ),
    [groupMemberships],
  );

  const memberItemsMap = React.useMemo(() => _keyBy(memberItems, 'organizationMembershipId'), [memberItems]);

  const valueFromForm = MembersFormFieldHooks.useValue(actor);
  const organizationMembershipId = valueFromForm?.organizationMembershipIds?.[0];
  const existingMember = organizationMembershipId ? memberItemsMap[organizationMembershipId] : undefined;

  const { data: deletedMember } = GetDeletedByIdQuery.useQuery(
    { organizationMembershipId },
    { enabled: !!organizationMembershipId && !existingMember },
  );

  const resolvedSelectedUser = existingMember
    ? existingMember
    : deletedMember
    ? GridHelper.convertOrganizationMembershipWithUserToMemberItem(deletedMember)
    : undefined;

  const value = resolvedSelectedUser;

  const handleChange = React.useCallback(
    (value: MemberItem) => api.onChange({ organizationMembershipIds: [value.organizationMembershipId] }),
    [api],
  );

  const handleClear = React.useCallback(() => {
    api.onChange({ organizationMembershipIds: [] });
  }, [api]);

  const ref = React.useRef<HTMLDivElement | null>(null);

  const styles = getSelectStyles(isDisabled);
  const blvdProps = {
    isDisabled,
    autoFocus: isAutofocused,
    onFocus: api.onFocus,
    onBlur: api.onBlur,
    components: selectComponents,
    styles,
    isClearable: true,
    borderColor: 'red',
  };

  return isHiddenByRule ? null : (
    <FormControl
      ref={node => {
        ref.current = node;
        if (node && !inputNode) {
          api.onSetNode(node);
        }
      }}
      as={VStack}
      alignItems="stretch"
      isInvalid={isInvalid}
      isRequired={widget.required}
    >
      <FormResponseLabel>{widget.label || 'Untitled Members'}</FormResponseLabel>
      <HStack
        w="full"
        gap={0}
        sx={{
          '.blvd-select': { width: '100%' },
          '.blvd-select__control': {
            borderColor: isInvalid ? 'var(--ps-colors-red-500)' : 'var(--ps-colors-gray-200)',
          },
        }}
      >
        <MembersFormFieldProvider handleClear={handleClear}>
          <BlvdMembersPicker
            items={memberItems}
            id={id}
            value={value}
            blvdSelectProps={blvdProps}
            placeholder="Select a member"
            onSelect={handleChange}
          />
        </MembersFormFieldProvider>
      </HStack>
      {isChecklist && formFieldValue && <FormFieldAudit isDisabled={isDisabled} audit={formFieldValue.audit} />}

      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};

const UserDisplay = ({ memberItem }: { memberItem: MemberItem }) => (
  <>
    <ChakraAvatar
      url={memberItem.avatarUrl}
      name={memberItem.name}
      size="xs"
      isDeactivated={memberItem.status && memberItem.status === OrganizationMembershipStatus.Inactive}
    />
    <VStack alignItems="flex-start" spacing="0">
      <Text color="gray.600">{memberItem.name}</Text>
    </VStack>
  </>
);

const getSelectStyles = (isDisabled: boolean) => {
  return {
    control: (base: any) => ({
      ...base,
      'justifyContent': 'flex-start',
      'width': '100%',
      'height': '40px',
      '.blvd-select__indicators': { flexGrow: 1 },
      '.blvd-select__clear-indicator': { order: 3, padding: 0 },
      '.blvd-select__value-container--has-value': {
        padding: 0,
      },
      '.chakra-avatar__initials': { fontSize: 'var(--ps-font-sizes-sm)' },
      'background-color': 'transparent',
      'opacity': isDisabled ? 0.4 : 1,
    }),
    menuPortal: (base: any) => ({
      ...base,
      '.blvd-select__menu__separator': { display: 'none' },
      '.blvd-select__option': { cursor: 'pointer' },
      '.chakra-avatar__initials': { fontSize: 'var(--ps-font-sizes-sm)' },
      '.blvd-select__menu__header input': { paddingLeft: '32px' },
      '.blvd-select__menu__header__search-icon': {
        position: 'absolute',
        left: '12px',
        top: '12px',
        color: 'var(--ps-colors-gray-400)',
      },
    }),
  };
};

const selectComponents: SelectComponentsConfig<MemberItem, false, GroupBase<MemberItem>> = {
  Option: ({ children: _, ...props }) => {
    return (
      <components.Option {...props}>
        <HStack spacing="2">
          <HStack>
            <UserDisplay memberItem={props.data} />
          </HStack>
        </HStack>
      </components.Option>
    );
  },
  DropdownIndicator: ({ children: _, ...props }) => {
    const value = props.getValue();
    return (
      <HStack color="gray.600" gap={2} justifyContent="space-between" w="full" px={2}>
        <HStack>{value.length > 0 && <UserDisplay memberItem={value[0]} />}</HStack>
        <Icon icon="chevron-down" size="4" variant="far" color="gray.600" />
      </HStack>
    );
  },
  // Prevents arbitrary label appearing before dropdown
  ValueContainer: props => {
    const nullValueStyles = { w: 0, overflow: 'hidden', h: 0 };
    return (
      <Box sx={props.getValue() ? {} : nullValueStyles} p={0}>
        <components.ValueContainer {...props} />
      </Box>
    );
  },
  Menu: props => {
    const { children } = props;
    return (
      <components.Menu {...props}>
        <ThemeProvider2024>
          <MenuSearchHeader key="menu-search-header" autoFocus={false} {...props} />
          {children}
        </ThemeProvider2024>
      </components.Menu>
    );
  },
  ClearIndicator: props => {
    const { handleClear } = useMembersFormFieldContext();

    return (
      <components.ClearIndicator {...props}>
        <IconButton
          aria-label="Remove selection"
          icon={<Icon size="4" icon="close" />}
          variant="secondary"
          borderBottomLeftRadius={0}
          borderTopLeftRadius={0}
          width="38px"
          height="38px"
          onClick={handleClear}
          onTouchEnd={e => {
            e.stopPropagation();
            handleClear();
          }}
        />
      </components.ClearIndicator>
    );
  },
};

export const [useMembersFormFieldContext, MembersFormFieldContext] = createUsableContext<Context>({
  hookName: 'useMembersFormFieldContext',
  providerName: 'MembersFormFieldProvider',
});

type Context = {
  handleClear: () => void;
};

export const MembersFormFieldProvider = ({ children, handleClear }: React.PropsWithChildren<Context>) => {
  const value = React.useMemo(() => ({ handleClear }), [handleClear]);
  return <MembersFormFieldContext.Provider value={value}>{children}</MembersFormFieldContext.Provider>;
};
