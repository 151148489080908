import { AxiosError } from 'axios';
import { approvalRulesApi } from './approval-rules.api';
import { checklistAssignmentApi } from './checklist-assignment.api';
import { checklistRevisionApi } from './checklist-revision.api';
import { checklistStatsApi } from './checklist-stats.api';
import { checklistApi } from './checklist.api';
import { conditionalRuleApi } from './conditional-rule.api';
import { configure } from './configure';
import { deviceTokenApi } from './device-token.api';
import { dueDateRuleApi } from './due-date-rule.api';
import { folderApi } from './folder.api';
import { groupMembershipApi } from './group-membership.api';
import { groupApi } from './group.api';
import { inboxApi } from './inbox.api';
import { mentionApi } from './mention.api';
import { notificationConfigApi } from './notification-config.api';
import { organizationMembershipApi } from './organization-membership.api';
import { planApi } from './plan.api';
import { s3Api } from './s3.api';
import { ssoApi } from './sso.api';
import { subscriptionApi } from './subscription.api';
import { taskStatsApi } from './task-stats.api';
import { templateRevisionApi } from './template-revision.api';
import { templateApi } from './template.api';
import { versionApi } from './version.api';

export const Api = {
  configure,
  approvalRulesApi,
  checklistApi,
  checklistAssignmentApi,
  checklistRevisionApi,
  checklistStatsApi,
  conditionalRuleApi,
  deviceTokenApi,
  dueDateRuleApi,
  folderApi,
  groupApi,
  groupMembershipApi,
  inboxApi,
  mentionApi,
  notificationConfigApi,
  organizationMembershipApi,
  planApi,
  subscriptionApi,
  s3Api,
  ssoApi,
  taskStatsApi,
  templateApi,
  templateRevisionApi,
  versionApi,
};

export function isAxiosError<Data = Record<string, any>>(error: unknown): error is AxiosError<Data> {
  return !!error && !!(error as any).isAxiosError;
}
