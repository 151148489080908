import * as React from 'react';
import { RichTextEditor, TinyMCEEditor } from 'features/rich-text';
import { match } from 'ts-pattern';
import { TextAreaRichEditorHelper } from './textarea-rich-editor-helper';
import { usePrintStore } from 'app/components/react-root/print-store';
import { BoxProps } from 'components/design/next';

type TextAreaRichEditorProps = {
  hasDefaultValue?: boolean;
  readOnly: boolean;
  editable: boolean;
  placeholder?: string;
  markdownValue?: string;
  editorWrapperProps?: Partial<BoxProps>;
  setValue: (value: string) => void;
  onFocus: () => void;
  onBlur: () => void;
};

const toolbar = [['bold', 'italic', 'strikethrough'], ['link'], ['blocks'], ['bullist', 'numlist']]
  .map(section => section.join(' '))
  .join(' ');

export const TextAreaRichEditor: React.FC<TextAreaRichEditorProps> = ({
  hasDefaultValue = false,
  readOnly,
  editable,
  markdownValue,
  placeholder,
  editorWrapperProps,
  setValue,
  onFocus,
  onBlur,
}) => {
  const hasInitialized = React.useRef(false);

  const backgroundColor = match({ hasDefaultValue, readOnly })
    .with({ readOnly: true }, () => 'gray.200')
    .with({ hasDefaultValue: true }, () => 'yellow.100')
    .otherwise(() => undefined);

  const initialValue = React.useRef(TextAreaRichEditorHelper.markdown2html(markdownValue ?? '')).current;
  const [editor, setEditor] = React.useState<TinyMCEEditor | undefined>(undefined);

  React.useEffect(
    function syncWidgetContentInEditor() {
      if (markdownValue === undefined || !editor) return;
      const editorMarkdownValue = TextAreaRichEditorHelper.html2markdown(editor.getContent());
      if (editorMarkdownValue !== markdownValue) {
        editor.setContent(TextAreaRichEditorHelper.markdown2html(markdownValue));
      }
    },
    [editor, markdownValue],
  );

  const onChange = React.useCallback(
    (content: string) => {
      // If the editor hasn't finished initializing, skip the onChange. This will avoid sending an unncessesary a ffv update to the machine
      if (!hasInitialized.current) {
        hasInitialized.current = true;
        return;
      }
      const markdown = TextAreaRichEditorHelper.html2markdown(content);
      setValue(markdown);
    },
    [setValue],
  );

  const handleFocus = React.useCallback(() => {
    onFocus();
  }, [onFocus]);

  const handleBlur = React.useCallback(() => {
    onBlur();
  }, [onBlur]);

  const { isPrintView, isPrintPage } = usePrintStore();
  const isPrintMode = isPrintView || isPrintPage;

  return (
    <RichTextEditor
      source="LongTextWidgetChecklist"
      editorWrapperProps={{
        w: 'full',
        px: 4,
        borderWidth: '1px',
        borderColor: 'gray.200',
        borderRadius: '4px',
        borderStyle: 'solid',
        backgroundColor,
        className: 'rich-editor-wrapper',
        minH: '80px',
        _focusWithin: { borderColor: 'blue.500', boxShadow: '0 0 0 1px var(--ps-colors-blue-500)' },
        ...editorWrapperProps,
      }}
      init={{
        placeholder,
        toolbar,
      }}
      editorProps={{
        onInit: (_evt, editor) => setEditor(editor),
        initialValue,
        onEditorChange: onChange,
        onFocus: handleFocus,
        onBlur: handleBlur,
        disabled: !editable || readOnly || isPrintMode,
      }}
    />
  );
};
