import angular from 'angular';
import { reactToAngular } from 'angulareact';
import { AutomatedTaskListItemWrapper } from './components/automated-task-list-item';
import { ChecklistTableFormField } from 'pages/runs/_id/components/checklist-table-form-field-widget';
import { ChecklistUrlFormFieldWidget } from 'pages/runs/_id/components/checklist-url-form-field-widget';
import { ChecklistEmailFormFieldWidget } from 'pages/runs/_id/components/checklist-email-form-field-widget';
import { ChecklistNumberFormField } from 'pages/runs/_id/components/checklist-number-form-field-widget';
import { ChecklistSelectFormFieldWidget } from 'pages/runs/_id/components/checklist-select-form-field-widget';
import { ChecklistTextFormFieldWidget } from 'pages/runs/_id/components/checklist-text-form-field-widget';
import { ChecklistTextareaFormFieldWidget } from 'pages/runs/_id/components/checklist-textarea-form-field-widget';
import { withErrorBoundary } from 'components/error-boundary';

export const WorkflowRunPageModule = angular
  .module('workflowRunPage', [])
  .component(
    'psChecklistEmailFormFieldWidget',
    reactToAngular(withErrorBoundary(ChecklistEmailFormFieldWidget), [
      'widget',
      'editable',
      'debounceDelay',
      'onUpdateValue',
      'onInteractionEnd',
      'isInvalid',
      'formFieldValue',
      'readOnly',
    ]),
  )
  .component(
    'psChecklistNumberFormField',
    reactToAngular(withErrorBoundary(ChecklistNumberFormField), [
      'editable',
      'widget',
      'formFieldValue',
      'onUpdateValue',
      'onInteractionEnd',
      'isInvalid',
      'readOnly',
    ]),
  )
  .component(
    'psChecklistSelectFormFieldWidget',
    reactToAngular(withErrorBoundary(ChecklistSelectFormFieldWidget), [
      'widget',
      'formFieldValue',
      // Added this just to trigger updates when changing a field value from my work
      'fieldValue',
      'editable',
      'isInvalid',
      'readOnly',
      'onUpdateValue',
      'onInteractionEnd',
    ]),
  )
  .component(
    'psChecklistTableFormField',
    reactToAngular(withErrorBoundary(ChecklistTableFormField), [
      'checklistRevision',
      'editable',
      'formFieldValue',
      'isInvalid',
      'onUpdateValue',
      'readOnly',
      'widget',
    ]),
  )
  .component(
    'psChecklistTextFormField',
    reactToAngular(withErrorBoundary(ChecklistTextFormFieldWidget), [
      'editable',
      'formFieldValue',
      'onInteractionEnd',
      'onKeyUp',
      'onUpdateValue',
      'widget',
      'isInvalid',
      'readOnly',
    ]),
  )
  .component(
    'psChecklistTextareaFormField',
    reactToAngular(withErrorBoundary(ChecklistTextareaFormFieldWidget), [
      'editable',
      'formFieldValue',
      'onInteractionEnd',
      'onKeyUp',
      'onUpdateValue',
      'widget',
      'isInvalid',
      'readOnly',
    ]),
  )
  .component(
    'psChecklistUrlFormFieldWidget',
    reactToAngular(withErrorBoundary(ChecklistUrlFormFieldWidget), [
      'widget',
      'editable',
      'onUpdateValue',
      'onInteractionEnd',
      'isInvalid',
      'formFieldValue',
      'readOnly',
    ]),
  )
  .component(
    'psAutomatedTaskListItem',
    reactToAngular(AutomatedTaskListItemWrapper, ['taskTemplate', 'isFirst', 'isLast']),
  ).name;
