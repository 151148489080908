import { Muid } from '@process-street/subgrade/core';
import {
  FormFieldValue,
  FormFieldValueUpdateResult,
  FormFieldWidget,
  isFormFieldWidget,
  isSelectFormFieldWidget,
  SelectFormFieldConfig,
  SelectFormFieldWidget,
  Widget,
} from '@process-street/subgrade/process';
import { AxiosError } from 'axios';
import { ResolvedMergeTagsByChecklistRevisionIdQuery } from 'features/merge-tags/query-builder';
import { QueryClient, useMutation, UseMutationOptions } from 'react-query';
import { axiosService } from 'services/axios-service';
import {
  GetFormFieldValuesByChecklistRevisionIdQuery,
  GetFormFieldValuesByChecklistRevisionIdQueryResponse,
} from './get-form-field-values-by-checklist-revision-id';
import {
  GetWidgetsByChecklistRevisionIdQuery,
  GetWidgetsByChecklistRevisionIdQueryResponse,
} from './get-widgets-by-checklist-revision-id';
import { GetDataSetMergeTagsByChecklistRevisionQuery } from 'pages/reports/data-sets/query-builder/get-data-set-merge-tags';

export type UpdateFormFieldValueMutationParams = NonNullable<FormFieldValue['fieldValue']> & {
  checklistRevisionId: Muid;
  widgetId: Muid;
};

export type UpdateFormFieldValueMutationResponse<T extends FormFieldValue = FormFieldValue> =
  FormFieldValueUpdateResult<T>;

export const UpdateFormFieldValueMutation = {
  key: ['update', 'checklist-revisions', 'widgets', 'form-field-value'],
  mutationFn: <T extends FormFieldValue = FormFieldValue>({
    checklistRevisionId,
    widgetId,
    ...body
  }: UpdateFormFieldValueMutationParams) =>
    axiosService
      .getAxios()
      .put<UpdateFormFieldValueMutationResponse<T>>(
        `/1/checklist-revisions/${checklistRevisionId}/widgets/${widgetId}/form-field-value`,
        body,
      )
      .then(res => res.data),

  updateFormFieldValuesOnSuccess:
    (queryClient: QueryClient) =>
    async ({
      formFieldValue,
    }: Pick<UpdateFormFieldValueMutationResponse, 'formFieldValue' | 'checklistTaskAssignments'>) => {
      const {
        checklistRevision: { id: checklistRevisionId },
        formFieldWidget: { id: widgetId },
      } = formFieldValue;

      const widgets =
        queryClient.getQueryData<GetWidgetsByChecklistRevisionIdQueryResponse>(
          GetWidgetsByChecklistRevisionIdQuery.getKey({ checklistRevisionId }),
        ) ??
        (await queryClient.fetchQuery(GetWidgetsByChecklistRevisionIdQuery.getKey({ checklistRevisionId }), () =>
          GetWidgetsByChecklistRevisionIdQuery.queryFn({ checklistRevisionId }),
        ));

      if (!widgets || widgets.length === 0) {
        throw new Error('Error fetching widgets');
      }

      const formFieldWidget = widgets.find(w => w.id === widgetId);
      if (!formFieldWidget) {
        return; // widget can be in not permitted task, we don't care then
      }
      if (!isFormFieldWidget(formFieldWidget)) {
        throw new Error(`Widget ${widgetId} is not a form field widget`);
      }

      // If there is no cache, we need to preload it
      if (!queryClient.getQueryData(GetFormFieldValuesByChecklistRevisionIdQuery.getKey({ checklistRevisionId }))) {
        await queryClient.fetchQuery(GetFormFieldValuesByChecklistRevisionIdQuery.getKey({ checklistRevisionId }), () =>
          GetFormFieldValuesByChecklistRevisionIdQuery.queryFn({ checklistRevisionId }),
        );
      }

      queryClient.setQueryData(
        GetFormFieldValuesByChecklistRevisionIdQuery.getKey({ checklistRevisionId }),
        (current: GetFormFieldValuesByChecklistRevisionIdQueryResponse | undefined) => {
          const withWidget: GetFormFieldValuesByChecklistRevisionIdQueryResponse[number] = {
            ...formFieldValue,
            formFieldWidget,
          };
          // TODO This is likely unnecessary since that cache will always exist
          if (!current) return [withWidget];

          let matchFound = false;
          const updated = current.map(ffv => {
            if (ffv.id === formFieldValue.id) {
              matchFound = true;
              return withWidget;
            }
            return ffv;
          });
          if (!matchFound) updated.push(withWidget);
          return updated;
        },
      );
      queryClient.invalidateQueries(ResolvedMergeTagsByChecklistRevisionIdQuery.key);
      if (isDataSetLinkedSelectWidget(formFieldWidget)) {
        queryClient.invalidateQueries(GetDataSetMergeTagsByChecklistRevisionQuery.getKey({ checklistRevisionId }));
      }
    },
  // we need to make sure that we return a rejected promise with the response because it's what angular land is expecting
  updateFormFieldValueOnError: (error: AxiosError) => Promise.reject(error.response),
};

export function isDataSetLinkedSelectWidget(
  formFieldWidget: Widget | FormFieldWidget,
): formFieldWidget is SelectFormFieldWidget & { config: Required<SelectFormFieldConfig> } {
  return isSelectFormFieldWidget(formFieldWidget) && Boolean(formFieldWidget.config.linkId);
}

export const useUpdateFormFieldValueMutation = (
  options: UseMutationOptions<
    UpdateFormFieldValueMutationResponse,
    AxiosError,
    UpdateFormFieldValueMutationParams
  > = {},
) => {
  return useMutation(UpdateFormFieldValueMutation.key, UpdateFormFieldValueMutation.mutationFn, options);
};
